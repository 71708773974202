import ContactReadContainer from '../../containers/contact/ContactReadContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function ContactReadPage({ match }: any) {
  const { itemId } = useParams();

  return (
    <>
      <MainLayout>
        <ContactReadContainer itemId={itemId} />
      </MainLayout>
    </>
  );
}

export default ContactReadPage;
