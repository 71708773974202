import React from 'react';
// import { ReactComponent as StarSvg } from '../../assets/star.svg';
import { ReactComponent as StarSvg } from '../../assets/rs.svg';
// svg를 이미지 폴더 안에 있는 파일로 저장했다는 가정하에 import.

interface Props {
  fillColor: string;
}

const StarIcon = ({ fillColor = '#dcdcdc' }: Props) => {
  return (
    <div>
      {/* <StarSvg fill={fillColor} style={{ marginRight: '3px', width:"10px", height:"10px"}}/> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 13" fill={fillColor}>
        <clipPath>
          <rect height="15" />
        </clipPath>
        <path d="M9,2l2.163,4.279L16,6.969,12.5,10.3l.826,4.7L9,12.779,4.674,15,5.5,10.3,2,6.969l4.837-.69Z" transform="translate(-2 -2)" />
        {/* <use clipPath={`url(#StarClip)`} href={`#Star`} fill='#F2EF33' */}
        <use clipPath={`url(#StarClip)`} href={`#Star`} fill={fillColor} />
      </svg>
    </div>
  );
};

export default StarIcon;
