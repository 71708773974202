import nonethumb from './../../assets/img/noneIMG.png';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { fetchListByDate, FETCH_LIST_BY_DATE } from '../../modules/board';
import { useDispatch, useSelector } from 'react-redux';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import { Link } from 'react-router-dom';
import Bookmark from './Bookmark';

function DayCalandar() {
  const dispatch = useDispatch();

  const [baseDayjs, setDayjs] = useState([dayjs()]);
  const [selectedDay, setSelectedDay] = useState(dayjs());    
  const today = dayjs();

  const { stores, isLoading, myInfo } = useSelector(({ auth, board, loading }: any) => ({
    stores: board.boards.data,
    isLoading: loading[FETCH_LIST_BY_DATE],
    myInfo: auth.myInfo,
  }));

  const getStoresBydate = date => {
    setSelectedDay(dayjs(date));
    dispatch(fetchListByDate(date, myInfo ? myInfo.userId : null));
  };

  useEffect(() => {
    const nextMonth = today.add(1, 'month');
    const nextResult = getDatesStartToLast(selectedDay, nextMonth, 'next');
    setDayjs(nextResult);
    dispatch(fetchListByDate(today.format('YYYY-MM-DD'), myInfo ? myInfo.userId : null));
  }, []);

  return (
    <>
      <div className="calendar-list">
        <ul>
          {!!baseDayjs &&
            baseDayjs.map((day, index) => (
              <li className="" key={index} onClick={() => getStoresBydate(day)}>
                <div className={selectedDay.format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD') ? 'calendar-list-box-select' : ''}>
                  <span>{today.format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD') ? 'TODAY' : dayjs(day).format('ddd')}</span>
                  <p>{dayjs(day).date()}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <div className="calendar-popup-list">
        <div className="moreview">
          <Link to="/popup">전체보기</Link>
        </div>
        <ul>
          {/* {stores != null &&
            !!stores.length &&
            stores.map((store: any, index) => (
              <li key={index}>
                <div>
                  <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
                    <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
                  </Link>
                  <Link to={`/popup/${store.storeId}`}>
                    <ul>
                      <li className="popup-name">
                        <p>{store.title}</p>
                      </li>
                      <li className="popup-location">
                        <img src={slidemapicon} /> {store.topLevelAddress}
                      </li>
                    </ul>
                  </Link>
                  <p
                    onClick={() => {
                      bookmarkClick(true, store.storeId);
                    }}
                  >
                    <Bookmark store={store} likeStore={likeStore} myInfo={myInfo} />
                  </p>
                  <p className="pre-wrap">
                    {store.preRegister && <span className="pre">사전예약</span>}{' '}
                    {store.reservation.length > 0 && (
                      <span className="pre own">
                        사전예약
                      </span>
                    )}
                  </p>
                  <p className="popup-date">
                    {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
                  </p>
                </div>
              </li>
            ))} */}
          {stores != null && !!stores?.length ? (
            stores.map((store: any, index) => (
              <li key={index}>
                <div>
                  <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
                    <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
                  </Link>
                  <Link to={`/popup/${store.storeId}`}>
                    <ul>
                      <li className="popup-name">
                        <p>{store.title}</p>
                      </li>
                      <li className="popup-location">
                        <img src={slidemapicon} alt="location" /> {store.topLevelAddress}
                      </li>
                    </ul>
                  </Link>                  
                  <Bookmark store={store} myInfo={myInfo} />                  
                  <p className="pre-wrap">
                    {store.preRegister && <span className="pre">사전예약</span>} {store.reservation.length > 0 && <span className="pre own">사전예약</span>}
                  </p>
                  <p className="popup-date">
                    {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
                  </p>
                </div>
              </li>
            ))
          ) : (
            <div className="calendar-popup-list-blank"></div> // stores가 없거나 길이가 0일 때 빈 화면을 렌더링
          )}
        </ul>
        <Link to="/popup" className="full-btn more-list" style={{ marginTop: '10px', marginBottom: '20px' }}>
          더보기
        </Link>
      </div>
    </>
  );
}
export default DayCalandar;

const getDatesStartToLast = (startDate: Dayjs, lastDate: Dayjs, buttonType: 'next' | 'prev') => {
  const result = [];
  let flag = false;
  // TODO 조건이 너무 지저분함. 바꿔야한다.
  for (let i = 1; i < lastDate.diff(startDate, 'days') + 1; i++) {
    if (startDate.add(i, 'day').format('YYYY-MM-DD') === dayjs().add(1, 'day').format('YYYY-MM-DD') || (buttonType === 'next' && flag === false)) {
      result.push(startDate);
      flag = true;
    } else {
      result.push(startDate.add(i - 1, 'day').format('YYYY-MM-DD'));
    }
  }
  return result;
};
