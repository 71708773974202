import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MagazineList from '../../components/magazine/MagazineList';
import { fetchList, FETCH_LIST } from '../../modules/magazine';
import { isAdmin as hasRoleAdmin } from '../../modules/selector';
import Footer from '../../components/common/Footer';

const MagazineListContainer = () => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  const { magazineItems, isLoading, isAdmin } = useSelector((state: any) => ({
    magazineItems: state.pds.pdsItems,
    isLoading: state.loading[FETCH_LIST],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <MagazineList magazineItems={magazineItems} isLoading={isLoading} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default MagazineListContainer;
