import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import createRequestSaga from '../lib/createRequestSaga';
import * as api from '../lib/api';

export const FETCH_LIST = 'placelist/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'placelist/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'placelist/FETCH_LIST_FAILURE';

export const fetchList = createAction(FETCH_LIST, userId => ({ userId }));

// 비동기 액션을 수행하는 태스크 작성
const fetchPlaceListSaga = createRequestSaga(FETCH_LIST, api.fetchPlaceList);

export function* placeListSaga() {
  yield takeLatest(FETCH_LIST, fetchPlaceListSaga);
}

export const initialState = {
  placelist: [],
  error: null,
};

// 리듀서 함수 정의
const placelist = createReducer(initialState, {
  [FETCH_LIST]: state => ({
    ...state,
    placelist: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    placelist: action.payload,
  }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
});

export default placelist;
