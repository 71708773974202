import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import * as api from '../lib/api';
import createRequestSaga from '../lib/createRequestSaga';

// 액션 타입
export const FETCH_ONE = 'promotion/FETCH_ONE';
const FETCH_ONE_SUCCESS = 'promotion/FETCH_ONE_SUCCESS';
const FETCH_ONE_FAILURE = 'promotion/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'promotion/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'promotion/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'promotion/FETCH_LIST_FAILURE';

export const FETCH_WINNER_LIST = 'promotion/FETCH_WINNER_LIST';
const FETCH_WINNER_LIST_SUCCESS = 'promotion/FETCH_WINNER_LIST_SUCCESS';
const FETCH_WINNER_LIST_FAILURE = 'promotion/FETCH_WINNER_LIST_FAILURE';

// 액션 생성 함수
export const fetchOne = createAction(FETCH_ONE, (promotionId: any) => promotionId);
export const fetchList = createAction(FETCH_LIST);
export const fetchWinnerList = createAction(FETCH_WINNER_LIST, (promotionId: any) => promotionId);

// 비동기 액션을 수행하는 태스크 작성
const fetchOneSaga = createRequestSaga(FETCH_ONE, api.fetchPromotion);
const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchPromotionList);
const fetchWinnerListSaga = createRequestSaga(FETCH_WINNER_LIST, api.fetchPromotionWinnerList);

// 코드그룹 사가 함수 작성
export function* promotionSaga() {
  yield takeLatest(FETCH_ONE, fetchOneSaga);
  yield takeLatest(FETCH_LIST, fetchListSaga);
  yield takeLatest(FETCH_WINNER_LIST, fetchWinnerListSaga);
}

// 초기 상태
const initialState = {
  promotion: null,
  promotions: [],
  promotionWinners: [],
  error: null,
};

// 리듀서 함수 정의 (상세 조회 상태인 promotion 변화를 일으킨다.)
const promotion = createReducer(initialState, {
  [FETCH_ONE]: state => ({
    ...state,
    promotion: null,
  }),
  [FETCH_ONE_SUCCESS]: (state, action) => ({
    ...state,
    promotion: action.payload,
  }),
  [FETCH_ONE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [FETCH_LIST]: state => ({
    ...state,
    promotions: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) =>
    // console.log(action.payload.data),
    ({
      ...state,
      promotions: action.payload.data,
    }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [FETCH_WINNER_LIST]: state => ({
    ...state,
    promotionWinners: [],
  }),
  [FETCH_WINNER_LIST_SUCCESS]: (state, action) => ({
    ...state,
    promotionWinners: action.payload.data,
  }),
  [FETCH_WINNER_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
});

export default promotion;
