import React, { useState } from 'react';
import './reservation.scss';


function ReservationAlert({ type }: any) {
  return (
    <div className='reservation-alert'>
        {type === 'noTime' && (
            <>
                <p>선택하신 시간의 예약이 불가합니다.</p>
                <p>예약 시간을 다시 선택해 주세요.</p>
            </>
        )}
        {type === 'noDate' && (
            <>
                <p>선택하신 일자 중 예약 가능한 시간이 없습니다.</p>
                <p>예약 일자를 다시 선택해 주세요.</p>
            </>
        )}
        {type === 'fullyBook' && (
            <>
                <p>예약 가능한 시간이 없습니다.</p>
            </>
        )}
        <button type="button" className='reservation-btn red'>확인</button>
    </div>
  );
}

export default ReservationAlert;
