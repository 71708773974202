import Marketing from '../../components/policy/Marketing';
import MainLayout from '../../layout/MainLayout';

function MarketingPage() {
  return (
    <>
      <MainLayout>
        <Marketing />
      </MainLayout>
    </>
  );
}

export default MarketingPage;
