import React, { useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import './reservation.scss';
// 임시 QR
import { ReactComponent as QR } from './qr.svg';
import notice from './../../assets/img/common/notice.png';
import dayjs from 'dayjs';

function ReservationQR({ type, userReservation, waitingCount, isLoading, updateReservationStatus }: any) {
  let showQr = true;
  if (type === 'PRE' && userReservation) {
    const today = dayjs().format('YYYY-MM-DD');
    userReservation.reservationScheduleMap.reservationSchedule.reservationDate = today;
    const dayDiff = dayjs(today).diff(dayjs(userReservation.reservationScheduleMap.reservationSchedule.reservationDate), 'days');
    if (dayDiff === -1 || dayDiff === 0) {
      showQr = true;
    }
  }
  return (
    <article className="inner white-back qr-wrap">
      {type === 'PRE' && (
        <>
          <div className="red-header">
            <p>{userReservation.reservation.store.title}</p>
            <p>입장 확인</p>
          </div>
          <div className="qr-info-wrap">
            <div className="qr-area">
              {!showQr && (
                <div className="svg">
                  <p>아직 QR코드가 생성되지 않았습니다.</p>
                  <p>QR코드는 입장 하루 전 생성됩니다.</p>
                </div>
              )}
              {showQr && <img src={userReservation.qrUrl} alt={`${userReservation.reservation.store.title} 사전예약`} />}
            </div>
            <p className="real-waiting-info-text">입장 순서가 되면 해당 입장 QR코드를 직원에게 보여주세요.</p>
            <div className="info-wrap">
              <p>
                팝업스토어명 : <span>{userReservation.reservation.store.title}</span>
              </p>
              <p>
                예약자 명 : <span>{userReservation.name}</span>
              </p>
              <p>
                연락처 : <span>{userReservation.phone}</span>
              </p>
              <p>
                예약일자 : <span>{userReservation.reservationScheduleMap.reservationSchedule.reservationDate}</span>
              </p>
              <p>
                예약시간 : <span>{userReservation.reservationScheduleMap.reservationSchedule.reservationTime}</span>
              </p>
              <p>
                방문인원 : <span>{userReservation.withGuest}</span>명
              </p>
              <p>
                접수일시 : <span>{dayjs(userReservation.reservationTime).format('YYYY-MM-DD HH:mm')}</span>
              </p>
            </div>
          </div>
          <button type="button" className="light-gray reservation-btn" onClick={() => updateReservationStatus()}>
            예약 취소하기
          </button>
        </>
      )}
      {type === 'ON-SITE' && (
        <>
          <div className="red-header">
            <p>{userReservation.reservation.store.title}</p>
            <p>대기 정보</p>
          </div>
          <div className="qr-info-wrap">
            <div className="qr-area">
              {/* 임시 QR // QR코드 있을 때 */}
              <QR className="svg" />
            </div>
            <p className="real-waiting-info-text bold">입장 순서가 되면 해당 입장 QR코드를 직원에게 보여주세요.</p>
            {/* <p className="real-waiting-info-text bold">
              곧 고객님의 입장이 시작될 예정입니다.
              <br />
              스토어 앞에서 대기해 주세요!
            </p> */}
            <div className="real-waiting-wrap">
              <div className="wating-number">
                <p className="tit">나의 대기번호</p>
                <p className="num">{userReservation.inOrder}</p>
              </div>
              <div className="wating-team">
                <p className="tit">고객님 앞</p>
                <p className="num">{waitingCount}팀</p>
              </div>
            </div>
            <div className="info-wrap">
              <p>
                대기 팝업스토어명 : <span>{userReservation.reservation.store.title}</span>
              </p>
              <p>
                대기번호 : <span>{userReservation.inOrder}</span>
              </p>

              <p>
                접수일시 : <span>{dayjs(userReservation.reservationTime).format('YYYY-MM-DD HH:mm')}</span>
              </p>
            </div>
          </div>
          <button type="button" className="light-gray reservation-btn" onClick={() => updateReservationStatus()}>
            대기 취소하기
          </button>
          <div className="notice-area">
            <img src={notice} alt="" />
            <ul className="notice-list">{ReactHtmlParser(userReservation.reservation.notice)}</ul>
          </div>
        </>
      )}
    </article>
  );
}

export default ReservationQR;
