import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SEARCH_INFO_LIST, fetchSearchInfoList } from '../../modules/searchInfo';
import InputDate from './InputDate';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

function Search() {
  const today = dayjs();
  const dispatch = useDispatch();
  const areaSearchWrapperRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {searchInfos, isLoading, searchInfoQueryTime} = useSelector(({ loading, searchInfo }: any) => ({
    searchInfos: searchInfo.searchInfos.data,
    searchInfoQueryTime : searchInfo.searchInfoQueryTime,
    isLoading: loading[FETCH_SEARCH_INFO_LIST],
  }));
  
  const searchDate = [
    { name: 'today', label: '오늘', startDate: today.format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') },
    { name: 'week', label: '+7일', startDate: today.format('YYYY-MM-DD'), endDate: today.add(7, 'day').format('YYYY-MM-DD') },
    { name: 'twoWeeks', label: '+2주', startDate: today.format('YYYY-MM-DD'), endDate: today.add(14, 'day').format('YYYY-MM-DD') },
  ];

  useEffect(() => {    
    console.log('searchInfoQueryTime:', searchInfoQueryTime.format('YYYY-MM-DD HH:mm:ss') , dayjs().add(-3, 'hour').format('YYYY-MM-DD HH:mm:ss'),!searchInfoQueryTime , searchInfoQueryTime < dayjs().add(-3, 'hour'));
    if( !searchInfoQueryTime  || searchInfoQueryTime < dayjs().add(-3, 'hour')) {
      console.log('searchInfoQueryTime:', searchInfoQueryTime);
      dispatch(fetchSearchInfoList());    
    }
  }, [dispatch]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = event => {
    if (areaShown === true && areaSearchWrapperRef && !areaSearchWrapperRef.current.contains(event.target)) {
      setAreaSelect(current => !current);
      setAreaShown(current => !current);
    }
  };

  const [areaShown, setAreaShown] = useState(false);

  //날짜선택 추가
  const [plusPeriod, setPlusPeriod] = useState(true);
  const periodClick = (isClick: boolean) => {
    setPlusPeriod(current => !current);
  };

  //상세 검색조건 추가
  const [plusSearch, setPlusSearch] = useState(true);
  const plusClick = (isClick: boolean) => {
    setPlusSearch(current => !current);
  };

  //지역선택~
  const [areaSelect, setAreaSelect] = useState(true);
  const [selectedArea, setSelectedArea] = useState('all');
  const [subArea, setSubArea] = useState([{}]);
  const [selectedSubArea, setSelectedSubArea] = useState(['all']);
  const [selectedSubAreaItem, setSelectedSubAreaItem] = useState([]);

  const [selectedDate, setSelectedDate] = useState(searchDate[0]);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: today.format('YYYY-MM-DD'), endDate: today.format('YYYY-MM-DD') });
  const [selectedCategory, setSelectedCategory] = useState();
  // const [awardsCategory, setAwardsCategory] = useState(['전체', '패션', '뷰티', '음식', '음료', '콘텐츠', '취미/여가', '금융', '연예', '캐릭터', '가전/디지털', '리빙', '게임']);
  const areaClick = async (isClick: boolean, siName: string) => {
    if (siName !== 'all') {
      if(siName !== selectedArea) {
        setSelectedSubArea([]);        
      }
      setSelectedArea(siName);
      setAreaSelect(current => !current);
      setAreaShown(true);
      const subAreaList = searchInfos.address.guInfo.filter(v => v.siName === siName && v.guName !== null);
      setSubArea(subAreaList);
    } else {
      setSelectedArea(siName);
    }
  };
  //  console.log('selectedArea:', selectedArea, subArea, searchInfos?.address?.siInfo);
  const subAreaClick = async (siName, guName) => {
    // 이미 선택 된 subArea 는 제거
    let newSelectedSubArea = [];
    if( guName === 'all'&& selectedSubArea.length === subArea.length) {
      newSelectedSubArea = [];
    } else if( guName === 'all') {
      newSelectedSubArea = subArea.map((g : any )=> g.guName);
    } else if (selectedSubArea.includes(guName)) {
      newSelectedSubArea = selectedSubArea.filter(value => {
        return value !== guName;
      });            
    } else {
      newSelectedSubArea = [...selectedSubArea, guName];      
    }
    const newSelectSubAreaItemList = searchInfos.address.guInfo.filter(v => v.siName === siName && v.guName !== null && newSelectedSubArea.find( g => g === v.guName));
    setSelectedSubAreaItem(newSelectSubAreaItemList);
    setSelectedSubArea(newSelectedSubArea);    
  };

  useEffect(() => {
    if (selectedArea === 'all') {
      // searchParams.set('area', 'all');
      searchParams.delete('address1');
      searchParams.delete('address2');
      setSearchParams(searchParams);      
    } else
     if (selectedSubArea != null && selectedSubArea.length > 0) {
      // console.log("selectedSubArea:", selectedSubArea, searchParams,  searchParams.toString());          
      // const area = selectedSubAreaItem.length === 0 && selectedArea.length > 0 ? selectedArea : selectedSubAreaItem.map( s => s.siName + ' ' + s.guName ).join(",");      
      // searchParams.set('area', area);
      searchParams.set('address1', selectedArea);
      searchParams.set('address2', selectedSubAreaItem.map( s => s.guName ).join(","));
      setSearchParams(searchParams);         
    } else if( selectedArea.length > 0) {
      // searchParams.set('area', selectedArea);
      searchParams.set('address1', selectedArea);
      searchParams.delete('address2');
      setSearchParams(searchParams);
    }  
  }, [selectedSubArea, selectedArea]);

  useEffect(() => {
    if (selectedCategory) {
      console.log("selectedCategory:", selectedCategory, searchParams,  searchParams.toString());
      searchParams.set('hashtag', `팝업스토어 어워즈 ${selectedCategory}`);
      setSearchParams(searchParams);      
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedDateRange) {
      console.log("selectedDateRange:", selectedDateRange, searchParams.toString(), searchParams, {...searchParams, ...selectedDateRange});
      searchParams.set('fromDate', selectedDateRange.startDate);
      searchParams.set('toDate', selectedDateRange.endDate);
      setSearchParams(searchParams);      
    }
  }, [selectedDateRange]);

  //  dateRangeClick
  const categoryClick = async category => {
    setSelectedCategory(category);
    // setAwardsCategory(category);
  };


  const dateClick = (clickIdx, dateInfo, isRange) => {
    console.log('dateClick:', clickIdx, dateInfo, isRange);
    if (!isRange) {      
      setSelectedDate(dateInfo);
      setSelectedDateRange({ startDate: dateInfo.startDate, endDate: dateInfo.endDate });      
    } else {
      if (dateInfo.startDate) {
        if (dateInfo.startDate <= selectedDateRange.endDate) {
          setSelectedDateRange({ startDate: dateInfo.startDate, endDate: selectedDateRange.endDate });          
        } else {
          setSelectedDateRange({ startDate: today.format('YYYY-MM-DD'), endDate: selectedDateRange.endDate });          
        }
      } else if (dateInfo.endDate) {
        if (dateInfo.endDate >= selectedDateRange.startDate) {
          setSelectedDateRange({ startDate: selectedDateRange.startDate, endDate: dateInfo.endDate });          
        } else {
          setSelectedDateRange({ startDate: selectedDateRange.startDate, endDate: today.format('YYYY-MM-DD') });          
        }
      }
    }
    //navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const [cTranslateX, setCTranslateX] = useState(0);
  const handlePrevClickC = () => {
    // 이전 버튼 클릭 시 translateX 값을 변경
    // translateX가 0일땐 동작하지 않기
    if (cTranslateX == 0) {
      return;
    } else {
      setCTranslateX(cTranslateX => cTranslateX + 200);
    }
  };

  const handleNextClickC = () => {
    // 다음 버튼 클릭 시 translateX 값을 변경
    setCTranslateX(cTranslateX => cTranslateX - 200);
  };

  // setSearchParams(searchParams);
  // applyFilter(null, null, null, selectedDateRange, area, { status: 'open', sort: '["createdAt","desc"]' });
  // }
  // }, [selectedDateRange, selectedDate]);
  return (
    <article>            
      <div className="search-filter-box">
        <ul className="filter-category">
          <li>
            <p>날짜</p>
            <div className="filter-contents">
              <ul className="filter-contents-list">
                {searchDate.map((dateInfo, idx) => (
                  <li
                    className={selectedDate.label === dateInfo.label ? 'select' : ''}
                    key={idx}
                    onClick={() => {
                      dateClick(idx, dateInfo, false);
                    }}
                  >
                    {dateInfo.label}
                  </li>
                ))}

                <li
                  onClick={() => {
                    periodClick(true);
                  }}
                >
                  직접선택
                </li>
              </ul>
            </div>
          </li>
          <li className={plusPeriod ? 'period-li' : 'period-li-shown'}>
            <p> </p>
            <div className="filter-contents">
              <ul className="filter-contents-list filter-date-pick">
                <li>
                  <InputDate _id={'start-date'} name={'시작 날짜 선택'} placeholder={'시작 날짜 선택'} value={selectedDateRange.startDate} onChange={e => dateClick(null, { startDate: e.target.value }, true)}></InputDate>
                </li>
                <li>
                  <InputDate _id={'end-date'} name={'종료 날짜 선택'} placeholder={'종료 날짜 선택'} value={selectedDateRange.endDate} onChange={e => dateClick(null, { endDate: e.target.value }, true)}></InputDate>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <p>지역</p>
            <div ref={areaSearchWrapperRef}  key={'area_1'} className="filter-contents">
              <ul className="filter-contents-list" key={'area_1_ul'}>
                {/* 선택한 항목에는 select class가 붙음 ss*/}                    
                <li className={selectedArea === 'all' ? 'select' : ''} onClick={() => { areaClick(true, 'all'); }} >
                  전체
                </li>                    
                {searchInfos?.address?.siInfo && searchInfos.address.siInfo.map((si: any, index) => (
                <li key={index} className={selectedArea === si.siName ? 'select' : ''} onClick={() => { areaClick(true, si.siName); }}>
                  {si.siName}
                </li>                      
                ))}
              </ul>
            </div>
          </li>
          {/* <li className={plusSearch ? 'filter-plus' : 'filter-plus-none'}>
            <a
              onClick={() => {
                plusClick(true);
              }}
            >
              상세검색조건 추가하기
            </a>
          </li> */}
          {/* <li className={'noneborder-filter noneborder-filter-shown'}>
            <p>카테고리</p>
            <div className="filter-contents">
              <div className="prev" onClick={handlePrevClickC}></div>
              <ul className="filter-contents-list" style={{ transform: `translate3D(${cTranslateX}px, 0, 0)` }}>
                <li className="select">전체</li>
                {searchInfos.category.map((category: any, index) => (
                  <li key={index} onClick={() => categoryClick(category)}>
                    {category.name}
                  </li>
                ))}
              </ul>
              <div className="next" onClick={handleNextClickC}></div>
            </div>
          </li> */}

          {/* <li className={'noneborder-filter noneborder-filter-shown'}>
            <p>어워즈 카테고리</p>
            <div className="filter-contents">
              <div className="prev" onClick={handlePrevClickC}></div>
              <ul className="filter-contents-list" style={{ transform: `translate3D(${cTranslateX}px, 0, 0)` }}>
                {awardsCategory.map((category: any, index) => (
                  <li className={selectedCategory === category ? 'select' : ''} key={index} onClick={() => categoryClick(category)}>
                    {category}
                  </li>
                ))}
              </ul>
              <div className="next" onClick={handleNextClickC}></div>
            </div>
          </li> */}

          {/* <li className={plusSearch ? 'filter-plus-none' : 'filter-plus'}>
            <a
              onClick={() => {
                plusClick(false);
              }}
            >
              상세검색조건 접기
            </a>
          </li> */}
        </ul>
      </div>
      <div ref={areaSearchWrapperRef} key={'area_2'} className={areaSelect ? 'area-box popuplist-filter-box' : 'area-box popuplist-filter-box popuplist-filter-box-up'}>
        <ul key={'area_2_ul'}>
          {/*클릭하면 다른 li에는 select가 없어지고 클릭한 요소에만 select클래스 추가*/}
          <li 
              // className={selectedSubArea.length === 0  ? 'select' : ''}                    
              onClick={() => {
                subAreaClick(selectedArea, 'all');
              }}
            >전체
          </li>            
          {subArea.map((gu: any, index) => (            
            <li key={index} data-index={index}              
              className={selectedSubArea.includes(gu.guName) ? 'select' : ''}                    
              onClick={() => {
                subAreaClick(gu.siName, gu.guName);
              }}
            >
              {gu.guName}
            </li>            
          ))}
        </ul>
      </div>
    </article>
  );
}

export default Search;
