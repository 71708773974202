import { useCallback, useEffect, useState } from 'react';
import AdminPlaceListRegister from '../../components/placelist/AdminPlaceListRegister';
import { addPlaceList, addBannerList, getBannerList } from '../../lib/api';
import httpStatusHandler from '../../util/httpStatusHandler';

const AdminPlaceListRegisterContainer = ({ history }: any) => {
  const [myInfo, setMyInfo] = useState(null);
  const [currentBannerList, setCurrentBannerList] = useState([]);
  const getMainBannerList = useCallback(myInfo => {
    console.log('a');
    const innerFunc = async myInfo => {
      try {
        const bannerList = await getBannerList({
          userId: myInfo ? myInfo.userId : null,
        });
        console.log(bannerList.data);
        setCurrentBannerList(bannerList.data.data);
      } catch (err: any) {
        throw err;
      }
    };
    innerFunc(myInfo);
  }, []);
  useEffect(() => {
    getMainBannerList(myInfo);
  }, []);

  const [isPassed, setIsPassed] = useState(false);
  if (!isPassed) {
    const plpw = prompt('관리자입력');
    if (plpw === 'sksmsvkqvmffj') {
      //나는팝플러
      setIsPassed(true);
    }
  }
  const onRegister = async placeListInfo => {
    try {
      const result = await addPlaceList(placeListInfo);
      if (result.status === 200) {
        alert('등록 완료');
        console.log(result.data);
      } else {
        // 플레이스리스트 등록 실패
        alert('등록에 실패했습니다.');
        return false;
      }
    } catch (err) {
      httpStatusHandler(err, history);
    }
  };
  const onBannerRegister = async bannerList => {
    try {
      alert(bannerList);
      const result = await addBannerList(bannerList);
      if (result.status === 200) {
        alert('등록 완료');
      } else {
        // 플레이스리스트 등록 실패
        alert('등록에 실패했습니다.');
        return false;
      }
    } catch (err) {
      alert('등록에 실패했습ㄴ디ㅏ.');
    }
  };
  return (
    <>
      {currentBannerList.length > 1 && isPassed && (
        <>
          <AdminPlaceListRegister currentBannerList={currentBannerList} onRegister={onRegister} onBannerRegister={onBannerRegister} />
        </>
      )}
    </>
  );
};

export default AdminPlaceListRegisterContainer;
