import StoreCommentListContainer from '../../containers/comment/StoreCommentListContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function StoreCommentListPage({ match }: any) {
  const { storeId } = useParams();
  console.log('page call / ' + storeId);
  return (
    <>
      <MainLayout>
        <StoreCommentListContainer storeId={storeId} />
      </MainLayout>
    </>
  );
}

export default StoreCommentListPage;
