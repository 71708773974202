import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import profile from './../../../assets/img/member/profile.svg';
import messageIcon from './../../../assets/img/event-template/message.png';
import { maskingName } from '../../common/PopplyUtil';

function EventChat(promotionChats) {
  // 세팅
  let slidesToShowSize = 1;
  const chatRef = useRef(null);
  useEffect(() => {
    let chatHeight = 820;
    chatHeight = chatRef.current?.offsetHeight;
    // height 에 따른 댓글 개수
    if (promotionChats.promotionChats) {
      const promotionChatsLength = promotionChats.promotionChats.length;
      if (chatHeight >= 820) {
        slidesToShowSize = promotionChatsLength >= 10 ? 10 : promotionChatsLength;
      } else if (chatHeight < 820) {
        slidesToShowSize = promotionChatsLength >= 6 ? 6 : promotionChatsLength;
      }
    }
    setSettings({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShowSize,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0px',
      arrows: false,
      swipeToSlide: true,
      vertical: true,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 6,
          },
        },
      ],
    });
  }, []);

  const [settings, setSettings] = useState({});

  return (
    <div className="event-chat-wrapper" ref={chatRef}>
      <Slider {...settings}>
        {!!promotionChats.promotionChats.length &&
          promotionChats.promotionChats.map((pc, idx) => {
            return (
              <div key={idx} className="slide-chat">
                <div className="name-wrap">
                  <img src={profile} alt="" />
                  <p className="user-name">
                    <span>{pc.userName}</span> 님
                  </p>
                </div>
                <div className="chat-wrap">
                  <p>{pc.comment}</p>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
export default EventChat;
