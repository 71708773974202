import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

function QnaRegisterForm({ attachments, onRegister, myInfo }: any) {
  const [qnaInfo, setQnaInfo] = useState({
    category: 'event',
    title: '제목을 입력해주세요.',
    content: '문의글을 입력해주세요.',
  });

  const changeContents = contentData => {
    setQnaInfo({ ...qnaInfo, content: contentData });
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!myInfo) {
      alert('로그인이 필요한 서비스입니다.');
      return;
    }
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      onRegister(qnaInfo);
    } else {
      return;
    }
  };
  const changeQnaInfo = e => {
    const { value, id } = e.target;
    setQnaInfo({ ...qnaInfo, [id]: value });
  };
  return (
    <article>
      <form onSubmit={handleSubmit} className="text-form">
        <div className="text-form-inner">
          <span>
            자주 묻는 질문을 모아둔 FAQ 게시판에서 원하시는 정보를 찾지 못하셨다면?
            <br />
            1:1문의를 통해 궁금한 점을 보내주시면 답변 드리겠습니다.
            <br />
            문의 주신 내용에 대한 답변은 1:1문의 게시판에서 확인하실 수 있습니다.
          </span>
          <p>
            <label htmlFor="inquiry-category" style={{ paddingRight: '10px' }}>
              문의유형
            </label>
            <select id="category" name="문의유형" onChange={changeQnaInfo}>
              <option value="event">이벤트</option>
              <option value="use">사이트이용</option>
              <option value="bug">오류제보</option>
              <option value="shop">입점문의</option>
              <option value="etc">기타</option>
            </select>
          </p>

          <p>
            <label htmlFor="title">문의 제목</label>
            <input type="text" onChange={changeQnaInfo} id="title" name="문의제목" placeholder="제목을 입력해주세요." />
          </p>
          <p className="edit-area">
            <label htmlFor="inquiry-content">문의 내용</label>

            <Editor
              editor={ClassicEditor as any}
              config={{
                placeholder: qnaInfo.content,
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeContents(data);
              }}
            />
          </p>
          {/* <p className="checkbox-wrap">
            <input type="checkbox" id="required-privacy" name="개인정보이용동의" />
            <label htmlFor="required-notice">개인정보수집 동의</label>
          </p>
          <span>1:1문의 답변을 위해 수집한 항목(이름, 이메일주소)는 1:1문의 접수, 답변 안내를 위해 수집합니다. 수집항목은 상담 접수 및 처리 이후 3개월까지 보관하며, 이후 회원 별도 요청 없는 경우 암호화 처리하여 별도 보관합니다.</span> */}
        </div>

        <div className="likebutton-wrap">
          <button type="submit" className="likebutton submit">
            등록
          </button>
          <a href="/qna" className="likebutton">
            취소
          </a>
        </div>
      </form>
    </article>
  );
}
export default QnaRegisterForm;
