import React from 'react';
import { Link } from 'react-router-dom';

function EventLine() {
  return (
    <div className="event-line">
      <div className="box-wrap">
        <div className="box box-1"></div>
        <div className="box box-2"></div>
        <div className="box box-3"></div>
        <div className="box box-4"></div>
        <div className="mark-wrap">
          <div className="mark"></div>
        </div>
      </div>
      <div className="event-icon"></div>
    </div>
  );
}
export default EventLine;
