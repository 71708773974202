import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMainBannerList, fetchPlaceList, updateLikeStore } from '../../lib/api';

import { fetchList, FETCH_LIST } from '../../modules/placelist';
import { useLocation, useSearchParams } from 'react-router-dom';
import AdminPlaceList from '../../components/placelist/AdminPlaceList';
import PopupBanner from '../../components/common/PopupBanner';
import dayjs from 'dayjs';

const AdminPlaceListContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isBottom, setIsBottom] = useState(false);

  const { placelist, isLoading, myInfo } = useSelector(({ auth,placelist, loading }: any) => ({
    placelist: placelist.placelist,
    isLoading: loading[FETCH_LIST],
    myInfo: auth.myInfo,
  }));

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => window.removeEventListener('scroll', handleUserScroll);
  }, []);

  useEffect(() => {
    dispatch(fetchList(myInfo ? myInfo.userId : null));
  }, [dispatch]);
  
  const [mainBanner5, setMainBanner5] = useState([]);  
  useEffect(() => {
    getMainBannerList(myInfo);
  }, []);
  const getMainBannerList = useCallback(myInfo => {
    const innerFunc = async myInfo => {
      try {
        const response = await fetchMainBannerList(myInfo ? myInfo.userId : null);
        const scheduled = response.data.data.banner2;
        const seongsu = response.data.data.banner5;
        setMainBanner5(seongsu);
      } catch (err: any) {
        throw err;
      }
    };
    innerFunc(myInfo);
  }, []);

  function handleUserScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + window.innerHeight + 20 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  return (
    <>
      <article className="inner" style={{marginBottom:'100px'}}>
        <AdminPlaceList adminPlaceList={placelist.data} />
        <PopupBanner storeList={mainBanner5} title={'성수 팝업 풀코스'} month={`${dayjs().month() + 1}월`} type={'seongsu'} myInfo={myInfo} />
      </article>
    </>
  );
};

export default AdminPlaceListContainer;
