import PopupListContainer from '../../containers/popup/PopupListContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function PopupListPage({ match }: any) {
  const { query } = useParams();
  return (
    <>
      <MainLayout>
        <PopupListContainer query={query} />
      </MainLayout>
    </>
  );
}

export default PopupListPage;
