import { FormEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RadioForm from './RadioForm';
import CheckboxForm from './CheckboxForm';
import ShortTextForm from './ShortTextForm';
import LongTextForm from './LongTextForm';
import StarRate from './StarRate';

// 현장대기/사전예약 ( reservationType )의 사후(POST) 설문
function postSurvey({ survey, onRegister, registerComment, isLoading, myInfo }: any) {
  const postReview = '팝업스토어 방문은 어떠셨나요? 방문에 대한 후기 작성 후, 동의해 주시면 해당 팝업스토어 후기에 바로 등록 됩니다.';
  const handleSubmit = (e: FormEvent) => {
    const reservation = survey.reservation;
    e.preventDefault();
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      // onRegister(reservation.store.storeId, reservation.reservationId, reservation.type, surveyInfo);
    } else {
      return;
    }
  };
  const changeInformation = async () => {};
  return (
    <>
      <article className="inner white-back survey-wrap">
        {survey && (
          <>
            <div className="header">
              <h1>팝업스토어 이름 팝업스토어</h1>
              <p>방문 경험 설문</p>
            </div>
            <div className="sub-text">
              <p>안녕하세요. 우리들의 팝한 플레이스 리스트 팝플리입니다.</p>
              <p>
                회원님이 방문하셨던 '<span>팝업스토어 이름</span>'에 대한 방문 경험 설문을 진행합니다.
              </p>
              <p style={{ marginTop: '20px' }}>응답해 주시는 내용은 통계적인 자료 분석용으로만 이용되며, 보다 나은 상품 및 서비스를 제공하는 자료로 활용됩니다.</p>
            </div>
            <div className="survey-form-wrap">
              <form onSubmit={handleSubmit}>
                <>
                  {survey.question &&
                    survey.question.length &&
                    survey.question.map(q => {
                      if (q.type === 'radio') {
                        return <RadioForm question={q} changeInformation={changeInformation} />;
                      } else if (q.type === 'check') {
                        return <CheckboxForm question={q} changeInformation={changeInformation} />;
                      } else if (q.type === 'essay') {
                        return <ShortTextForm question={q} changeInformation={changeInformation} />;
                      }
                    })}
                </>

                {/* 사후설문 별점, 리뷰 -> 팝플리 후기 연동해야 함 */}
                <StarRate />
                <LongTextForm qTitle={postReview} />

                {/* 다른 설문 유형은 붙을 수도 있고 안 붙을 수도 있고... */}
                {/* <RadioForm />
            <CheckboxForm />
            <ShortTextForm /> */}

                <label>
                  <input type="checkbox" required />
                  후기 게시에 동의합니다.
                </label>
                {/* 해당 버튼 클릭 시 폼 제출 */}
                <button type="submit" className="reservation-btn red">
                  완료
                </button>
              </form>
            </div>
          </>
        )}
      </article>
    </>
  );
}

export default postSurvey;
