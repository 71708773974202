import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactMenus from '../../../components/contact/ContactMenus';
import FaqRegisterForm from '../../../components/contact/faq/FaqRegisterForm';
import * as api from '../../../lib/api';
// import { addAttach, removeAttach } from '../../../modules/faq';
import httpStatusHandler from '../../../util/httpStatusHandler';

const FaqRegisterContainer = () => {
  // 스토어 Dispatch 사용 가능
  const navigate = useNavigate();
  // 스토어 상태 조회
  const { attachments } = useSelector((state: any) => ({
    attachments: state.faq.attachments,
  }));

  const onRegister = async (itemName: string, description: string) => {
    const itemObject = {
      itemName: itemName,
      description: description,
      files: attachments,
    };

    try {
      const response = await api.writeFaq(itemObject);
      alert('등록이 완료되었습니다.');
      navigate(`/faq/read/${response.data.itemId}`);
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  return (
    <>
      <ContactMenus onMenu={'faq'} />
      <FaqRegisterForm attachments={attachments} onRegister={onRegister} />
    </>
  );
};

export default FaqRegisterContainer;
