import axios from 'axios';
import storageManager from '../util/StorageManager';

export const getAuthorizationToken = async () => {
  const token = await storageManager.getValueAsync("token", 100).catch((e) => {console.log(e); return null;});
  //console.log("getAuthorizationToken", token, typeof token)
  return token?.access_token ?? '';
  //return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token'))?.access_token : '';
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

client.interceptors.request.use(
  async config => {
    // 요청이 전달되기 전에 작업 수행
    if (config.headers['Authorization']) {
      config.headers['X-Authorization'] = config.headers['Authorization'];
    }
    const token = await getAuthorizationToken(); // 비동기로 토큰을 가져옵니다\
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);


// 응답 인터셉터 추가 (선택사항)
client.interceptors.response.use(
  response => {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    return response;
  },
  error => {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    if (error.response.status === 401) {
      // 401 Unauthorized 에러 처리
      // 예: 로그인 페이지로 리다이렉트
    }
    return Promise.reject(error);
  },
);
export default client;
