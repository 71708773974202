import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne, FETCH_ONE } from '../../modules/board';

import StoreCommentList from '../../components/comment/StoreCommentList';

const StoreCommentListContainer = ({ storeId }) => {
  const dispatch = useDispatch();
  const { store, isLoading, myInfo } = useSelector((state: any) => ({
    store: state.board.store,
    isLoading: state.loading[FETCH_ONE],    
    myInfo: state.auth.myInfo
  }));

  useEffect(() => {
    dispatch(fetchOne(storeId, myInfo ? myInfo.userId : null));
  }, [dispatch, storeId]);

  return (
    <>
      <StoreCommentList store={store} isLoading={isLoading} storeId={storeId} myInfo={myInfo} />
    </>
  );
};

export default StoreCommentListContainer;
