import * as React from 'react';
import { Link } from 'react-router-dom';
import nonethumb from './../../assets/img/noneIMG.png';
import arrow from './../../assets/img/common/arrow_right_small.svg';

function ReservationStoreList({ userReservations, isLoading }: any) {
  //인기순~
  const [filterSelect, setfilterSelect] = React.useState(true);
  const filterClick = (isClick: boolean) => {
    setfilterSelect(current => !current);
  };

  //진행중~
  const [statusSelect, setStatusSelect] = React.useState(true);
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };

  return (
    <>
      <article className="inner" style={{ paddingBottom: '100px' }}>
        <div className="submenu">
          {/*클릭하면 클래스 on 붙어야 함*/}
          <Link to="/member">
            <h2>내 정보 </h2>
          </Link>
          <Link to="/user/save-store">
            <h2>관심팝업 </h2>
          </Link>
          <Link to="/member/reservation/pre">
            <h2>사전예약 </h2>
          </Link>
          <Link to="/member/reservation/onSite" className="on">
            <h2>현장예약 </h2>
          </Link>
        </div>
        {isLoading && '로딩중...'}
        {/* 현장예약 css 따로?? TODO */}
        <div className="pre-reservation-list">
          {userReservations.length >= 1 &&
            userReservations.map((userReservation, idx) => {              
                if( userReservation && userReservation.reservationScheduleMap ) {                  
                  return UserReservationItem(userReservation)                  
                } else {   
                  return <></>          
                }
            })}
        </div>
      </article>
    </>
  );

  function UserReservationItem(userReservation: any): React.ReactNode {
    return <div className="pre-reservation-content">
      <div className="pre-reservation-content-inner">
        <img className="thumbnail" src={userReservation.reservation.store.thumbnails ? userReservation.reservation.store.thumbnails : nonethumb} alt="팝플리 썸네일" />
        <div className="right">
          <div className="info-text">
            <p className="popupstore-name">{userReservation.reservation.store.title}</p>
            <p className="pre-reservation-status done">{userReservation.status}</p>
            <p>
              총 방문인원 : <span>{userReservation.withGuest}</span>명
            </p>
          </div>
        </div>
      </div>
      <Link to={`/member/reservation/${userReservation.userReservationId}`} className="reservation-link-btn">
        현장예약 상세 보기 <img src={arrow} className="arrow" alt="" />
      </Link>
    </div>;
  }
}

export default ReservationStoreList;
