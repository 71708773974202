import FaqRegisterContainer from '../../../containers/contact/faq/FaqRegisterContainer';
import MainLayout from '../../../layout/MainLayout';

function ContactRegisterPage() {
  return (
    <>
      <MainLayout>
        <FaqRegisterContainer />
      </MainLayout>
    </>
  );
}

export default ContactRegisterPage;
