// import styled from "styled-components";
import { useState, useEffect } from 'react';
import Stars from './Star';

function StarRate({ starAvg, userName, isDetail }) {
  // const AVR_RATE = starAvg * 20;
  // const STAR_IDX_ARR = ['first', 'second', 'third', 'fourth', 'last'];
  // const [ratesResArr, setRatesResArr] = useState([0, 0, 0, 0, 0]);
  // const calcStarRates = () => {
  //   let tempStarRatesArr = [0, 0, 0, 0, 0];
  //   // console.log(starAvg);
  //   let starVerScore = (AVR_RATE * 70) / 100;
  //   // console.log(starVerScore);
  //   let idx = 0;
  //   while (starVerScore > 14) {
  //     tempStarRatesArr[idx] = 14;
  //     idx += 1;
  //     starVerScore -= 14;
  //   }
  //   tempStarRatesArr[idx] = starVerScore;
  //   return tempStarRatesArr;
  // };
  // useEffect(() => {
  //   console.log(starAvg);
  //   setRatesResArr(calcStarRates);
  // }, []);
  // return (
  //   <div style={{ display: 'flex', width: '100%' }}>
  //     {STAR_IDX_ARR.map((item, idx) => {
  //       return (
  //         <span className="star_icon" key={`${item}_${idx}`}>
  //           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 13" fill="#CACACA">
  //             <clipPath id={`${item}StarClip`}>
  //               <rect width={`${ratesResArr[idx]}`} height="15" />
  //             </clipPath>
  //             <path
  //               id={`${item}Star`}
  //               d="M9,2l2.163,4.279L16,6.969,12.5,10.3l.826,4.7L9,12.779,4.674,15,5.5,10.3,2,6.969l4.837-.69Z"
  //               transform="translate(-2 -2)"
  //             />
  //             <use clipPath={`url(#${item}StarClip)`} href={`#${item}Star`} fill="#F0002E" />
  //           </svg>
  //         </span>
  //       );
  //     })}
  //   </div>
  // );
  // console.log(starAvg);
  let stars = '';
  for (let i = 0; i < starAvg; i++) {
    stars += '★';
  }
  return (
    <div>
      {userName} <span>{stars}</span> {starAvg}
    </div>
  );
}

export default StarRate;
