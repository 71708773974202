import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/common/SearchBar';
import { fetchMainBannerList, updateLikeStore } from '../../lib/api';
import { FETCH_LIST, fetchSearchList } from '../../modules/board';
import { useLocation, useSearchParams } from 'react-router-dom';
import PopupSearchList from '../../components/popup/PopupSearchList';
import PopupListBlank from '../../components/popup/PopupListBlank';
import PopupBanner from '../../components/common/PopupBanner';
import dayjs from 'dayjs';

const PopupSearchListContainer = ({ query }) => {
  const dispatch = useDispatch();  
  const [isBottom, setIsBottom] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { stores, isLoading, searchStores, page, myInfo } = useSelector(({ auth, board, loading, searchStores }: any) => ({
    stores: board.boards,
    searchStores: board.searchStores,
    isLoading: loading[FETCH_LIST],
    page: board.page,
    myInfo: auth.myInfo,
  }));

  if (stores.data && searchStores.length > 0) {
    stores.data = [...stores.data, ...searchStores];
  }

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => window.removeEventListener('scroll', handleUserScroll);
  }, []);

  useEffect(() => {
    console.log('PopupListContainer = searchParams', searchParams.toString());
    dispatch(fetchSearchList(searchParams.toString(), myInfo ? myInfo.userId : null));
  }, [searchParams]);
    
  const [openStores, setOpenStores] = useState([]);
  const [scheduledStores, setScheduledStores] = useState([]);
  const [doneStores, setDoneStores] = useState([]);

  useEffect(() => {    
    if (stores.data && stores.data.length > 0) {
      const fromDate = searchParams.get("fromDate");
      const toDate = searchParams.get("toDate");
      setOpenStores(stores.data.filter(s => {        
        return toDate >= s.startDate  &&  fromDate <= s.endDate
    })
        );
      setScheduledStores(stores.data.filter(s => toDate < s.startDate ));
      setDoneStores(stores.data.filter(s => fromDate > s.endDate));
    }
  }, [stores]);

  function handleUserScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + window.innerHeight + 20 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  useEffect(() => {
    getMainBannerList(myInfo);
  }, []);

  const [mainBanner2, setMainBanner2] = useState([]);
  const [mainBanner5, setMainBanner5] = useState([]);
  const getMainBannerList = useCallback(myInfo => {
    const innerFunc = async myInfo => {
      try {
        const response = await fetchMainBannerList(myInfo ? myInfo.userId : null);
        const scheduled = response.data.data.banner2;
        const seongsu = response.data.data.banner5;
        setMainBanner2(scheduled);
        setMainBanner5(seongsu);        
      } catch (err: any) {
        throw err;
      }
    };
    innerFunc(myInfo);
  }, []);
  
  const keyword = searchParams.get('query');
  const searchParamsString = searchParams.toString();
  return (
    <>
      <article className="inner">
        <Search />
        {isLoading || stores.data === undefined ? ( // 로딩 중일 때 빈페이지
          <div></div>
        ) : stores.length === 0 || stores.data.length === 0 ? (
          <div style={{ paddingBottom: '100px' }}>
            <PopupListBlank keyword={keyword} />
            <div className="blank-tit">
              <h2>여긴 어때요?</h2>
              <p>팝플리가 현재 가장 인기 있는 팝업을 추천해드립니다</p>
            </div>
            <PopupBanner storeList={mainBanner2} title={'놓치면 손해 곧 오픈 예정!'} month={`${dayjs().month() + 1}월`} type={'scheduled'}  myInfo={myInfo} />
            <PopupBanner storeList={mainBanner5} title={'성수 팝업 풀코스'} month={`${dayjs().month() + 1}월`} type={'seongsu'}  myInfo={myInfo} />
          </div>
        ) : openStores.length > 0 || scheduledStores.length > 0 || doneStores.length > 0 ? (
          <div className="popup-search-list-wrap">
            <PopupSearchList stores={openStores} isLoading={isLoading} query={searchParamsString}  myInfo={myInfo}  searchStatusOption={'open'} />
            <PopupSearchList stores={scheduledStores} isLoading={isLoading} query={searchParamsString}  myInfo={myInfo}  searchStatusOption={'scheduled'} />
            <PopupSearchList stores={doneStores} isLoading={isLoading} query={searchParamsString}  myInfo={myInfo}  searchStatusOption={'done'} />
          </div>
        ) : (
          <p></p>
        )}
      </article>
    </>
  );
};

export default PopupSearchListContainer;
