import Service from '../../components/policy/Service';
import MainLayout from '../../layout/MainLayout';

function ServicePage() {
  return (
    <>
      <MainLayout>
        <Service />
      </MainLayout>
    </>
  );
}

export default ServicePage;
