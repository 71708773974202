import MemberListContainer from '../../containers/member/MemberListContainer';
import MainLayout from '../../layout/MainLayout';

export default function MemberListPage() {
  return (
    <>
      <MainLayout>
        <MemberListContainer />
      </MainLayout>
    </>
  );
}
