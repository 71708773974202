import { useEffect, useRef, useState } from 'react';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import './common.scss';
import logoimg from './../../assets/img/POPPLY.png';
import searchimg from './../../assets/img/layout/search.svg';
import menuimg from './../../assets/img/layout/menu-bar.svg';
import arrowLeft from './../../assets/img/common/arrow_left_small.svg';
import closeimg from './../../assets/img/common/btn_close.svg';
import MainHeaderContainer from '../../containers/common/MainHeaderContainer';

function MenuBar({ bgRef, isAuthorized, isAdmin }: { bgRef: any; isAuthorized: boolean; isAdmin: boolean }) {
  const [scroll, setScroll] = useState(false);
  const menuWrapperRef = useRef(null);
  const searchWrapperRef = useRef(null);
  const searchInputWrapperRef = useRef<HTMLInputElement>(null);
  // 검색 관련
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // 이벤트 리스너 추가
  const keywordRecommends = ['더현대', '신세계백화점', '성수', '패션', '이벤트', '키즈', '굿즈', '전시', '데이트', '포토존'];

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  useEffect(() => {
    if (searchParams.get('query') !== null) {
      navigate(`/popup/search?${searchParams.toString()}`);
    }
  }, [searchParams]);
  // 외부영역 감지 후 창 닫기
  const handleClickOutside = event => {
    if (isShown === false && searchWrapperRef && !searchWrapperRef.current.contains(event.target)) {
      setIsShown(current => !current);
      bgRef.current.style.display = 'none';
    }
    if (openMenu === false && menuWrapperRef && !menuWrapperRef.current.contains(event.target)) {
      setOpenMenu(current => !current);
      bgRef.current.style.display = 'none';
    }
  };
  //팝업 레이어 관련
  const [isShown, setIsShown] = useState(true);
  const searchClick = (isClick: boolean) => {
    setIsShown(current => !current);
    if (searchInputWrapperRef.current !== null) searchInputWrapperRef.current.focus();

    isClick ? (bgRef.current.style.display = 'block') : (bgRef.current.style.display = 'none');
  };

  const [openMenu, setOpenMenu] = useState(true);
  const menuClick = (isClick: boolean) => {
    setOpenMenu(current => !current);
    isClick ? (bgRef.current.style.display = 'block') : (bgRef.current.style.display = 'none');
  };
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const searchHandler = (event: any) => {
  //   setSearchParams({...searchParams, query: event.currentTarget.value, status: 'all'});
  //   // setSearchkeyWord(event.currentTarget.value);
  // };
  function handlerEnterSearch(e: any): void {
    if (e.key === 'Enter') {
      submit(e);
    }
  }
  const submit = (e: any) => {
    e.preventDefault();
    setIsShown(current => !current);
    bgRef.current.style.display = 'none';
    // console.log('searchParams submit', searchParams.toString());
    searchParams.set('query', searchInputWrapperRef.current.value);
    searchParams.set('status', 'all');
    setSearchParams(searchParams);
    // navigate(`/popup/search?${searchParams.toString()}`);
  };

  return (
    <>
      {/* <nav style={{ display: isShown ? 'flex' : 'none' }} data-align="center" className={scroll ? 'div-menu-bar sticky' : 'div-menu-bar'}> */}
      <nav data-align="center" className={scroll ? 'div-menu-bar sticky' : 'div-menu-bar'}>
        <h1>
          <Link to="/">
            <img src={logoimg} alt="팝플리 로고이미지" />
            {/* <LogoSvg /> */}
          </Link>
        </h1>
        <ul className="header-icon">
          <li
            className="header-search-container"
            onClick={() => {
              searchClick(true);
            }}
          >
            <p className="header-search-query">{searchParams?.get('query')}</p>
            <p className="header-search-wrap">
              {/* <img
                src={searchimg}
                alt="검색"
                onClick={() => {
                  searchClick(true);
                }}
              /> */}
            </p>
          </li>
          <li>
            <img
              src={menuimg}
              alt="메뉴 열기"
              onClick={() => {
                menuClick(true);
              }}
            />
          </li>
        </ul>
      </nav>
      <div ref={searchWrapperRef} className={isShown ? 'search-box' : 'search-box down'}>
        <header>
          <img
            src={arrowLeft}
            alt="지역, 팝업스토어 이름, 테마 키워드를 검색해 보세요!"
            onClick={() => {
              searchClick(false);
            }}
            style={{ width: '10px' }}
          />
          <p>
            <input type="search" ref={searchInputWrapperRef} placeholder="지역, 팝업스토어 이름, 테마 키워드 검색" onKeyDown={handlerEnterSearch} defaultValue={searchParams.get('query')} />
            <a>
              <img src={searchimg} alt="지역, 팝업스토어 이름, 테마 키워드를 검색해 보세요!" onClick={submit} />
            </a>
          </p>
        </header>
        <div className="search-box-inner">
          <p>추천 키워드</p>
          <ul>
            {keywordRecommends.map((keyword, index) => (
              <li
                key={index}
                onClick={() => {
                  setIsShown(current => !current);
                  bgRef.current.style.display = 'none';
                  searchInputWrapperRef.current.value = keyword;
                  searchParams.set('query', keyword);
                  searchParams.set('status', 'all');
                  setSearchParams(searchParams);
                }}
              >
                {keyword}
              </li>
            ))}
          </ul>
          <Link to="/popup">상세 검색</Link>
        </div>
      </div>
      <div ref={menuWrapperRef} className={openMenu ? 'menu-box' : 'menu-box down'}>
        <header>
          <h1>
            <Link to="/">
              <img src={logoimg} alt="팝플리 메뉴, 팝업스토어 메뉴" />
            </Link>
          </h1>
          <a
            onClick={() => {
              menuClick(false);
            }}
          >
            <img src={closeimg} alt="메뉴 열기" />
          </a>
        </header>
        <ul className="menu-box-top">
          <li>
            <Link to="/popup">
              POPUP <span>팝업발견</span>
            </Link>
          </li>
          <li style={{ marginBottom: '24px' }}>
            <Link to="/maps">
              MAP <span>위치기반 검색</span>
            </Link>
          </li>
          <li style={{ display: 'none' }}>
            <Link to="/business"> 비즈니스</Link>
          </li>
          <li style={{ marginBottom: '24px' }}>
            <Link to="/placelist">
              MY PLACE LIST <span>마이플리</span>
            </Link>
          </li>
          <li>
            <Link to="/magazine">
              MAGAZINE <span>매거진</span>
            </Link>
          </li>
          <li>
            <Link to="/event">
              EVENT <span>이벤트</span>
            </Link>
          </li>
          <li style={{ marginBottom: '24px' }}>
            <Link to="/award2023/intro">
              AWARDS <span>어워즈</span>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              SUPPORT <span>고객센터</span>
            </Link>
          </li>
          <li>
            <Link to="/popupreport">
              HEY, HERE <span>팝업제보</span>
            </Link>
          </li>
        </ul>
        <MainHeaderContainer />
      </div>
    </>
  );
}

export default MenuBar;
