import { useRef } from 'react';
import MenuBarContainer from '../containers/common/MenuBarContainer';
import BottomNavigationContainer from '../containers/common/BottomNavigationContainer';
import ScrollToTop from '../components/common/ScrollToTop';
import AuthProvider from '../provider/AuthProvider';

function MainLayout({ children }: { children: any }) {
  const bgRef = useRef(null); // 검색, 햄버거 클릭시 백그라운드 display 처리를 위함.
  
  return (
    <>
      <div className="container">        
        {/* <MainHeaderContainer /> */}
        <MenuBarContainer bgRef={bgRef} />
        <ScrollToTop />
        <div ref={bgRef} className="bg" />
        <AuthProvider children={children}/>                
        <BottomNavigationContainer />
      </div>
    </>
  );
}

export default MainLayout;
