import { Link } from 'react-router-dom';
import { getOriginalFileName } from './../ContactUtil';

function FaqRead({ faq, attachments, isLoading, itemId, onRemove, isAdmin }: any) {
  return (
    <article>
      {/* <div>제목</div>
      <div>내용</div> */}
    </article>
  );
}

export default FaqRead;
