import { useParams } from 'react-router-dom';
import ReservationRegisterContainer from '../../../containers/popup/reservation/ReservationRegisterContainer';
import MainLayout from '../../../layout/MainLayout';

function OnSiteReservationDetailPage({ match }: any) {
  const { storeId, hash } = useParams();
  return (
    <>
      <MainLayout>
        <ReservationRegisterContainer storeId={storeId} type={'onSite'} hash={hash} />
      </MainLayout>
    </>
  );
}

export default OnSiteReservationDetailPage;
// encodeURIComponent

// http://192.168.20.135:3000/popup/518/reservation/onSite/Cb5POg+e3rtlHWReTd74DLrgj/7yBzyEaPwKuh1Xo6mRtaYXjwVe2INvbYBbWkSsMhIwQGmjcu8K9cCg/sfeQQ==
