import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../common/Footer';

function Service() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <article className="inner site-document">
        <h2>POPPLY 서비스 이용약관</h2>
        <h3>제 1장 총칙</h3>
        <p className="term-title">제 1조(목적)</p>
        <span className="term-text">
          이 약관은 ㈜이너스커뮤니티(이하"회사"라 한다)가 운영하는 정보제공 온라인 플랫폼(이하 "플랫폼"이라 한다)에서 제공하는 모든 서비스(이하
          "서비스"라 한다)를 이용함에 있어 "회사"와 "회원"의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다
        </span>
        <p className="term-title">제 2조(약관의 효력 및 변경)</p>
        <ul>
          <li>① 이 약관의 내용은 서비스 화면에 게시하거나 기타 방법으로 회원에게 공지함으로써 효력이 발생합니다.</li>
          <li>
            ② 회사는 합리적인 사유가 발생하는 경우에 이 약관을 변경할 수 있으며, 약관이 변경되는 경우에는 최소한 7일 전에 1항과 같은 방법으로
            공지합니다.
          </li>
          <li>③ 해당 플랫폼에 새로운 서비스가 개설될 경우 별도의 명시된 설명이 없는 한 이 약관에 따라 제공됩니다</li>
        </ul>
        <p className="term-title">제 3조(약관 외 준칙)</p>
        <ul>
          <li>① 약관에 명시되지 않은 사항은 정부가 제정한 법령과 지침, 기준 등 관계 법령 또는 상관례에 따릅니다.</li>
          <li>
            ② "회사"는 이 약관의 내용을 및 대표자 성명, 소재지 주소(소비자의 불만을 처리할 수 있는 주소를 포함), E-mail 주소, 전화등록번호,
            개인정보보호책임자 등을 “회원”이 알 수 있도록 “플랫폼” 화면에 게시합니다.
          </li>
          <li>③ "회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 동의하지 않는 경우에는 서비스 이용을 중단할 수 있습니다.</li>
          <li>④ “회원”이 전3항에 따라 약관에 반대의사를 표시하지 않고 “서비스”를 이용하는 경우에는 약관에 동의한 것으로 봅니다.</li>
        </ul>
        <p className="term-title">제 4조 (정의)</p>
        <span className="term-text">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span>
        <ul>
          <li>① "플랫폼"이란 “회사”에서 서비스 운영 및 제공을 위해 운영하는 웹사이트</li>
          <li>② "회원"이란 약관에 따라 제공하는 서비스 이용계약을 체결한 개인이나 법인 또는 법인에 준하는 단체를 말합니다.</li>
          <li>③ “비회원”이라 함은 회원에 가입하지 않고 “플랫폼”이 서비스를 제공하는 자를 말합니다.</li>
          <li>④ “서비스 중지”란 정상이용 중 회사가 정한 일정한 요건에 따라 일정기간 동안 서비스의 제공을 중지하는 것입니다.</li>
          <li>⑤ “해지”란 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것입니다.</li>
        </ul>
        <h3>제 2장 이용계약 체결</h3>
        <p className="term-title">제 5조 (회원가입)</p>
        <ul>
          <li>① “회원”은 “플랫폼”이 정한 절차에 따라 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.</li>
          <li>② 이용계약 시작 시점은 회원의 이용신청에 대해 “회사”가 이용승낙한 시점으로 합니다.</li>
          <li>
            ③ 다음과 같은 경우 이용신청자의 이용 신청 승낙을 제한할 수 있고, 이 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
            <ul>
              <li>가) 서비스 관련 설비 용량이 부족한 경우</li>
              <li>나) 기술상 문제가 있는 경우</li>
              <li>
                다) 이용신청자가 이 약관에 의하여 이전에 회원자격을 상실한 사실이 있는 경우. 단, 회원자격 상실 후 회사의 회원 재가입 승낙을 얻은
                경우에는 예외로 합니다.
              </li>
              <li>라) 기타 부득이한 사정이 있는 경우</li>
            </ul>
          </li>
          <li>
            ④ 이용신청자는 신청시 다음 사항을 준수하여야 합니다.
            <ul>
              <li>1. 가입신청 양식에는 실명을 사용합니다.</li>
              <li>2. 다른 사람의 명의를 사용하여 기재하지 않습니다.</li>
              <li>3. 가입신청 양식의 내용은 현재의 사실과 일치해야 합니다.</li>
              <li>4. 사회의 안녕, 질서 또는 미풍양속을 저해할 목적으로 신청해서는 안됩니다.</li>
              <li>5. 회원신청시 등록한 내용에 변경이 생긴 경우 현재의 사실과 일치하는 완전한 정보로 갱신해야 합니다.</li>
              <li>
                6. 이용신청자가 제공한 정보가 부정확하거나 현재의 사실과 일치하지 않는 경우, 또는 그러하다고 의심할 수 있는 합리적인 이유가 있는 경우
                서비스 이용을 제한 받을 수 있습니다.
              </li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 6조 (개인정보 제공에 대한 동의)</p>
        <ul>
          <li>
            ① 회원들이 회사 및 제휴업체의 서비스를 편리하게 사용할 수 있도록 하기 위해 회원 정보는 회사 및 제휴 업체에 제공될 수 있습니다. 이 경우
            회사는 사전에 공지하며 이에 동의하지 않는 회원은 등록을 취소할 수 있습니다.
          </li>
          <li>② 다만 계속 이용하는 경우 동의하는 것으로 간주합니다.</li>
          <li>
            ③ 전기통신사업법 등 법률 규정에 의해 국가 기관의 요구가 있는 경우, 수사상 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타
            관계법령 절차에 따른 정보제공 요청이 있는 경우 회원 정보가 제공 될 수 있습니다.
          </li>
          <li>④ 회사는 업무와 관련하여 회원전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 사용하거나 이를 다른 기관에 제공할 수 있습니다.</li>
          <li>⑤ 회원이 약관의 내용에 동의하지 않고 등록을 취소 하는 경우 회사는 회원의 정보를 삭제합니다.</li>
          <li>
            ⑥ 회사는 서비스를 통해 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 회원은 쿠키 수신을 거부하거나 쿠키 수신에 대해 경고하도록 브라우저
            설정을 변경할 수 있습니다.
          </li>
        </ul>
        <h3>제 3장 서비스 이용</h3>
        <p className="term-title">제 7조 (서비스의 이용 시간)</p>
        <ul>
          <li>
            ① 회사는 회원의 이용신청을 승낙한 때부터 즉시 서비스를 개시합니다. 단, 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 게시하지 못하는
            경우, 서비스에 공지하거나 회원에게 즉시 이를 통지합니다.
          </li>
          <li>
            ② 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만 회사가 업무상 또는 기술상의 이유로 서비스의 전부 또는 일부가 일시 중지
            되거나, 운영상의 목적으로 회사가 정한 기간에는 서비스의 전부 또는 일부가 일시 중지 될 수 있습니다. 이러한 경우 회사는 사전 또는 사후 이를
            공지합니다.
          </li>
          <li>③ 회사는 서비스 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 사전에 공지합니다.</li>
          <li>④ 서비스 이용에 관한 문의는 대표 일반문의 이메일 계정 ad@inuscomm.co.kr 로 보내주시면 신속하게 답변 드리겠습니다.</li>
        </ul>
        <p className="term-title">제 8조 (서비스의 내용)</p>
        <span className="term-text">“플랫폼”에서 제공하는 서비스는 다음과 같습니다.</span>
        <ul>
          <li>
            <p>1. 시설에 대한 정보 제공 및 사전예약 서비스</p>
          </li>
          <li>
            <p>2. “이용자”의 위치를 기반으로 근거리에 있는 시설 정보 제공</p>
          </li>
        </ul>
        <ul>
          <li>
            ① 회사는 변경될 서비스의 내용 및 제공일자를 회원에게 등록된 이메일, 카카오 알림 또는 사이트 내 공지사항을 통해 통지할 수 있으며 서비스를
            변경하여 제공할 수 있습니다.
          </li>
          <li>
            ② 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며 이에 대하여 관련법에 특별한
            규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
          </li>
        </ul>
        <p className="term-title">제 9조 (서비스 이용 및 개인정보 제공동의)</p>
        <ul>
          <li>① 회사가 요구하는 회원 정보는 본 이용계약을 이행하고 이용계약 상 서비스 제공을 위한 목적으로 이용합니다.</li>
          <li>
            ② 회사는 회원이 서비스 이용 중 필요하다고 인정되는 프로젝트 모집 제안을 이메일, 유무선 매체, 서신우편, 카카오톡과 같은 메신저 등의
            방법으로 회원에게 제공할 수 있습니다. 다만, 회원이 원하는 경우에 수신을 거부할 수 있습니다.
          </li>
        </ul>
        <p className="term-title">제 10조 (서비스의 요금)</p>
        <ul>
          <li>
            ① 회사가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.
          </li>
          <li>② 유료서비스 이용요금의 회수방법은 회사와 계약한 전자지불업체에서 정한 방법에 의합니다.</li>
        </ul>
        <p className="term-title">제 11조 (게시물 또는 내용물의 삭제)</p>
        <ul>
          <li>
            ① 회사는 서비스의 게시물 또는 내용물에 법적인 문제가 발생하거나 게시기간을 초과하는 경우, 사전 통지나 동의 없이 이를 삭제하거나 등록
            거부할 수 있으며, 이에 대해 회사는 책임을 지지 아니합니다.
          </li>
          <li>
            ② 회원 거부 및 탈퇴 등으로 회원 정보가 삭제되어 작성자 본인을 확인할 수 없는 경우 게시물 편집 및 삭제가 불가능합니다. 회원이 게시한
            게시물의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 모두 삭제해야 합니다.
          </li>
          <li>③ 천재지변 또는 이에 준하는 불가항력, 시스템의 오류 등으로 게시물이 손실 및 삭제 될 수 있습니다.</li>
          <li>④ 게시물의 손실 및 삭제로 인한 피해에 대해서 회사는 별도의 보상을 하지 않습니다.</li>
        </ul>
        <p className="term-title">제 12조 (개인정보 보호)</p>
        <ul>
          <li>① “회사”는 “이용자”의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. </li>
          <li>
            ② “회사”는 회원 가입 서비스에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 업무상 책임이행을 위해 서비스이용 이전에 본인확인이 필요한
            경우 최소의 특정 개인정보를 수집할 수 있습니다.
          </li>
          <li>③ “회사”는 “이용자”의 개인정보 수집시 “이용자”에게 그 목적을 공지하고 동의를 받습니다.</li>
          <li>
            ④ “회사”는 수집된 개인정보를 목적 외의 용도로 사용할 수 없고, 새로운 이용 목적이 발생하거나 개인정보를 제3자에게 제공하는 경우에는 이용
            제공 단계에서 “이용자”에게 그 목적을 알리고 동의를 받습니다.
          </li>
          <li>
            ⑤ “플랫폼”이 제 ②항과 제 ③항에 의해 “이용자”의 동의를 받는 경우에는 정보 수집 목적 및 이용 목적, 제3자에 정보에 대한 제공 관련
            사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호에 관한 감시」 제22조 제1항이 규정한 사항을 미리
            포함하거나 청구해야 하며 "이용자"는 이에 대하여 동의할 수 있습니다.
          </li>
          <li>
            ⑥ 회원은 언제든지 본인의 개인정보를 열람하고 변경사항을 정정할 수 있습니다. 회원이 오류의 정정을 요구한 경우에 회사는 그 오류를 정정할
            때까지 당해 개인정보를 이용하지 않습니다.
          </li>
          <li>⑦ "플랫폼" 또는 그로부터 개인정보를 제공 받은 제3자는 개인정보의 수집목적을 달성하는 즉시 개인정보를 지체 없이 파기하도록 합니다.</li>
          <li></li>
        </ul>
        <h3>제 4장 권리와 의무</h3>
        <p className="term-title">제13조 (회사의 의무)</p>
        <ul>
          <li>
            ① 회사는 특별한 사유가 없는 한 서비스 제공설비를 항상 운용 가능한 상태로 유지 보수하여야 하며, 안정적으로 서비스를 제공할 수 있도록 최선의
            노력을 다하여야 합니다.
          </li>
          <li>
            ② 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리일정을 서비스
            또는 전자우편을 통하여 동 회원에게 통지하여야 합니다.
          </li>
        </ul>
        <p className="term-title">제14조 (회원의 의무)</p>
        <ul>
          <li>
            ① 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에
            방해되는 행위를 하여서는 아니 됩니다.
          </li>
          <li>② 회원은 회사의 사전 동의 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없으며, 법에 저촉되는 자료를 배포 또는 게재할 수 없습니다.</li>
          <li>
            ③ 회원은 자신의 ID와 비밀번호를 유지 관리할 책임이 있으며 자신의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대해 전적인 책임이
            있습니다.
          </li>
          <li>④ 또한 자신의 ID와 비밀번호가 자신의 승낙 없이 사용되었을 경우 즉시 회사에 통보하여야 합니다.</li>
          <li>
            ⑤ 회원은 회사의 사전승낙 없이는 서비스를 이용하여 얻은 정보를 복사, 복제, 변경, 번역, 출판, 방송, 광고, 영업, 기타의 방법으로 사용하거나
            이를 타인에게 제공하는 행위 활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 행위의 결과에 대하여 회사는 책임을 지지
            않습니다. 이와 같은 영업활동에 대하여 회원은 회사에 대하여 손해배상의무를 갖습니다.
          </li>
          <li>
            ⑥ 회원은 서비스와 관련하여 다음 사항을 하여서는 아니됩니다.
            <ul>
              <li>1. 자신의 홈페이지와 게시판에 음란물을 게재 또는 음란사이트를 링크하거나 , 유포 등 사회질서를 해치는 행위</li>
              <li>2. 공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 유포하는 행위</li>
              <li>3. 타인의 명예를 훼손 또는 모욕하거나 불이익을 주는 행위</li>
              <li>4. 회사 또는 제 3자의 저작권, 지적재산권 등 기타 권리를 침해하는 행위</li>
              <li>5. 관계 법령에 의하여 그 제조, 수입, 전송 또는 게시가 금지되는 기술, 제품, 정보의 제조, 수입, 전송 또는 게시 행위</li>
              <li>6. 범죄적 행위와 결부된다고 판단되는 행위</li>
              <li>7. 해킹 또는 컴퓨터 바이러스를 유포하는 일, 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</li>
              <li>8. 다른 회원 ID를 부정 사용하는 행위</li>
              <li>9. 다른 사용자의 개인 정보를 수집, 저장하는 행위</li>
              <li>10. 회사 직원, 포럼 리더 등을 포함한 타인을 사칭하는 행위</li>
              <li>11. 서비스를 통해 전송된 컨텐츠의 발신인을 위조하는 행위</li>
              <li>12. 타인을 스톡(stalk)하거나, 괴롭히는 행위</li>
              <li>13. 서비스의 운영에 지장을 주거나 줄 우려가 있는 일체의 행위, 기타 관계 법령에 위배되는 행위</li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 15조 (양도 금지)</p>
        <span className="term-text">
          회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
        </span>
        <p className="term-title">제 16조 (게시물에 대한 권리 및 책임)</p>
        <ul>
          <li>
            ① 회원이 게시한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는 회원이 게시하거나 등록하는 서비스의 내용물이 다음 각
            항에 해당하거나 소송이 제기되는 경우에 사전통지 없이 임시 조치 및 행사할 수 있습니다. , 이에 대해 회사는 아무런 책임도 지지 않습니다.
            <ul>
              <li>1. 정보통신망법 및 저작권법 등 법을 위반한 경우</li>
              <li>2. 동의 없이 타인의 정보를 게시물에 보이게 하는 경우</li>
              <li>3. 남을 비방할 목적으로 하는 사실을 유포하여 남의 명예를 훼손하는 글 또는 남을 비방하는 게시물의 경우</li>
              <li>4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용일 경우</li>
              <li>5. 동일한 내용을 중복하여 게시하는 등 게시판 규칙에 어긋나는 경우</li>
              <li>6. 회사에서 규정한 게시물 원칙에 맞지 않거나 게시물을 작성 위치가 적절하지 않은 경우</li>
              <li>7. 영리 및 광고의 목적의 내용인 경우</li>
              <li>8. 기타 서비스 운영 정책에 반하는 경우</li>
            </ul>
          </li>
          <li>
            ② 회사는 권리자의 요청이 없는 경우라도 회사 또는 제3자의 권리침해가 인정되는 경우 쟁점이 될 수 있는 권리를 표시하고 기타 회사 채권 및
            관련법이 이행되는 경우에는 관련법에 따라 해당 게시물에 대해 임시 조치(블라인드 ) 및 삭제 등의 조치가 취해질 수 있습니다.
          </li>
          <li>
            ③ 회원이 서비스에 게시물을 게재하는 경우, 회원은 회사가 게시물을 제·전송·데이터베이스 제작, 출판물, 간행물 등의 2차적 저작물 작성의 형태로
            이용 및 언론기관 등에 제공하는 것, 서비스 내에서 다른 회원이 본인의 게시물 등을 스크랩 기능 등을 이용하여 복제 또는 전송 등의 형태로
            이용하는 것에 동의한 것으로 봅니다. 이를 원하지 않으실 경우 게시물 삭제를 하실 수 있습니다.
          </li>
          <li>
            ④ 회사가 제 3항 이외의 형태로 회원의 게시물을 영리적 목적으로 이용하고자 하는 경우에는 전화, 팩스 또는 전자우편 등(“연락처”라고 한다.)을
            이용하여 회원의 동의를 얻어야 합니다. 다만, 회원이 제공한 연락처가 사실과 다르거나, 회사의 잘못 없이 연락처를 알 수 없거나, 회사에게
            상당한 기간 내에 의사를 표시하지 아니하는 경우에는 회원이 동의한 것으로 간주합니다.
          </li>
        </ul>
        <p className="term-title">제 17조 (손해배상 및 면책조항)</p>
        <ul>
          <li>① 회사가 제공하는 서비스 중 무료 서비스의 경우에는 손해배상에 해당되지 않습니다.</li>
          <li>② 회사는 그 손해가 천재지변 등 불가항력이거나 이용자의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지 아니합니다.</li>
          <li>③ 유료서비스의 경우 각 서비스 별로 제공되는 손해배상규정에 의해 보상받을 수 있습니다. </li>
          <li>
            ④ 회사는 회원이 서비스를 통해 게재 또는 제공한 정보, 자료, 사실의 정확성, 신뢰성 등 내용에 관하여 어떠한 보증도 하지 아니하며 회원의
            서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해 책임을 지지 않습니다.
          </li>
          <li>⑤ 회사는 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 아니합니다.</li>
          <li>
            ⑥ 회사는 회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할
            책임도 없습니다.
          </li>
          <li>⑦ 회사는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제 됩니다.</li>
          <li>
            ⑧ 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에 발생되는 모든 손해를 배상하여야
            하며, 동 손해로부터 회사를 면책 시켜야 합니다.
          </li>
          <li>
            ⑨ 회사는 서비스에서 제공되는 전자우편의 메시지, 게시판의 게시물 등이 보유 되는 최대일수, 송수신 할 수 있는 전자우편 메시지의 최대크기,
            회원에게 할당되는 최대 디스크 공간 등 일반 사용에 대한 제한을 할 수 있습니다.
          </li>
          <li>⑩ 또한 일정 기간 동안 활동이 없는 계정을 해지할 수 있습니다.</li>
        </ul>
        <p className="term-title">제 18조 (분쟁의 해결)</p>
        <ul>
          <li>① 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.</li>
          <li>② 모든 노력에도 불구하고 소송이 제기될 경우, 소송에 관한 것은 "회사"의 주소지상의 법원으로 정합니다.</li>
          <li>③ 회원의 서비스 사용으로 발생되는 모든 법적 문제는 대한민국 관계법령의 적용으로 합니다.</li>
        </ul>
        <p className="term-title">부칙 (시행일)</p>
        <span className="term-text">본 약관은 2023년 03월 09일부터 적용됩니다.</span>
      </article>
      {/* <Footer /> */}
    </>
  );
}

export default Service;
