import MemberReadContainer from '../../containers/member/MemberReadContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
export default function MemberReadPage({ match }: { match: any }) {
  const { userNo } = useParams();

  return (
    <>
      <MainLayout>
        <MemberReadContainer userNo={userNo} />
      </MainLayout>
    </>
  );
}
