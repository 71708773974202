import { useState, useRef, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import nonethumb from './../../assets/img/noneIMG.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Bookmark from './Bookmark';
import { updateLikeStore } from '../../lib/api';

function PopupBanner({ storeList, title, month, type, myInfo }: any) {
  let query = '';
  if (type === 'seoul') {
    query = '/search?query=서울';
  } else if (type === 'scheduled') {
    query = '/search?area=all&status=scheduled';
  } else if (type === 'expo') {
    query = '/search?query=전시';
  } else if (type === 'goods') {
    query = '/search?query=굿즈';
  } else if (type === 'seongsu') {
    query = '/search?query=성수';
  }
  const [dragging, setDragging] = useState(false);
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);
  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);
  const moveToDetailPage = (storeId: number, e: any) => {
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      window.location.href = `/popup/${storeId}`;
    }
  };

  //팝업배너 전체 너비 구하기
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const a = Number(width / 280).toFixed(1);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Number(a),
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    touchThreshold: 100,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  };

  return (
    <>
      <div className="popupbanner-wrap">
        <header>
          <h2 className="popupbanner-tit">
            {title} <span className="key-text">{month ? month : ''}</span>
          </h2>
          <div className="moreview">
            <Link to={`/popup${query}`}>더보기</Link>
          </div>
        </header>

        <div className="popupbanner-inner" ref={componentRef}>
          {settings.slidesToShow !== 0 && (
            <>
              <Slider {...settings}>
                {storeList &&
                  storeList.length > 0 &&
                  storeList.map((store, idx) => (
                    <div key={idx} className="popupbanner-list">                    
                      <Bookmark store={store} myInfo={myInfo} />                                            
                      <div
                        className="slide-img-wrap"
                        onClick={e => {
                          moveToDetailPage(store.storeId, e);
                        }}
                        key={idx}
                      >
                        <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" loading="lazy" />
                      </div>
                      <ul className="popupbanner-list-info">
                        <li className="popup-name">
                          <p>{store.title}</p>
                        </li>
                        <li className="popup-location">
                          <img src={slidemapicon} alt="" loading="lazy"/> {store.topLevelAddress}
                        </li>
                      </ul>
                    </div>
                  ))}
              </Slider>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PopupBanner;

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth ? myRef.current?.offsetWidth : 0,
      height: myRef.current?.offsetHeight ? myRef.current?.offsetHeight : 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
