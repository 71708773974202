import { useParams } from 'react-router-dom';
import PreReservationDetailContainer from '../../containers/member/PreReservationDetailContainer';
import MainLayout from '../../layout/MainLayout';

export default function PreReservationDetailPage() {
  const { userReservationId } = useParams();
  return (
    <>
      <MainLayout>
        <PreReservationDetailContainer userReservationId={userReservationId} />
      </MainLayout>
    </>
  );
}
