import React, { useMemo } from 'react';
import StarIcon from './StarIcon';

interface Props {
  index: number;
  rating: number;
  hoverRating: number;
  onSaveRating: (index: number) => void;
}

const Stars = (props: Props) => {
  const { index, rating, hoverRating, onSaveRating } = props;

  const fillColor = useMemo(() => {
    if (hoverRating >= index) {
      // console.log('!!!!');
      return '#F0002E';
    } else if (!hoverRating && rating >= index) {
      console.log(rating);
      return '#F0002E';
    }
    return '#CACACA'; // #dcdcdc === 회색
  }, [rating, hoverRating, index]);

  return (
    <div onClick={() => onSaveRating(index)}>
      <StarIcon fillColor={fillColor} />
    </div>
  );
};

export default Stars;
