import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactMenus from '../../../components/contact/ContactMenus';
import FaqList from '../../../components/contact/faq/FaqList';
import { fetchList, FETCH_LIST } from '../../../modules/faq';
import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
import Footer from '../../../components/common/Footer';

const ContactListContainer = () => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  const { faqList, isLoading, isAdmin } = useSelector((state: any) => ({
    faqList: state.faq.faqs,
    isLoading: state.loading[FETCH_LIST],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <ContactMenus onMenu={'faq'} />
      <FaqList faqList={faqList} isLoading={isLoading} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default ContactListContainer;
