import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import arrow_bottom from './../../../assets/img/common/icon_answerBottom_write.png';
import arrow_top from './../../../assets/img/common/icon_arrowTop_white.png';

function FaqList({ faqList, isLoading, isAdmin }: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <article style={{minHeight:'50vh'}}>
      {isLoading && '로딩중...'}
      {!isLoading && faqList && (
        <>
          <div className="faq-list-wrap">
            <ul>
              {faqList.map((faq: any, index) => (
                <li>
                  <p className="faq-category">{faq.category}</p>
                  <a
                    onClick={() => {
                      setMenuOpen(menuOpen => !menuOpen);
                      setActiveIndex(activeIndex === index ? null : index);
                    }}
                  >
                    <p>
                      {faq.question}

                      <a>
                        <img src={menuOpen && activeIndex === index ? arrow_top : arrow_bottom} />
                      </a>
                    </p>
                  </a>
                  <div className={activeIndex === index ? ' active' : ''}>{faq.answer}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="page-area">
            <ul>
              {/* <li>
                <a className="prev-btn"></a>
              </li> */}
              <li>
                <a className="on">1</a>
              </li>
              {/* <li>
                <a>2</a>
              </li>
              <li>
                <a>3</a>
              </li> */}
              {/* <li>
                <a className="next-btn"></a>
              </li> */}
            </ul>
          </div>
        </>
      )}
    </article>
  );
}

export default FaqList;
