import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SaveStoreList from '../../components/member/SaveStoreList';
import { FETCH_SAVE_STORE_LIST, fetchSaveStoreList } from '../../modules/member';
import Footer from '../../components/common/Footer';

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; //DEV

const SaveStoreListContainer = () => {
  const location = useLocation();
  const onSignIn = () => {
    const state = location.pathname + location.search;
    const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
    window.location.href = kauthUrl;
    // window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code`;
  };
  const dispatch = useDispatch();    
  const { stores, isLoading, myInfo } = useSelector(({ member, auth,loading }: any) => ({
    stores: member.stores.data,
    isLoading: loading[FETCH_SAVE_STORE_LIST],
    myInfo: auth.myInfo,
  }));  
  // console.log("SaveStoreListContainer", myInfo);
  useEffect(() => {
    if(myInfo) {
      dispatch(fetchSaveStoreList(myInfo.userId));    
    }
  }, [myInfo]); 
  return (
    <>
      <SaveStoreList stores={stores} isLoading={isLoading} myInfo={myInfo} onSignIn={onSignIn} />
      <Footer />
    </>
  );
};

export default SaveStoreListContainer;
