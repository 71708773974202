import { useParams } from 'react-router-dom';
import PreSurveyContainer from '../../containers/survey/PreSurveyContainer';
import MainLayout from '../../layout/MainLayout';

function PreSurveyRegisterPage({ match }: any) {
  const { storeId, reservationId, hash } = useParams();
  return (
    <>
      <MainLayout>
        <PreSurveyContainer storeId={storeId} reservationId={reservationId} hash={hash} />
      </MainLayout>
    </>
  );
}

export default PreSurveyRegisterPage;
