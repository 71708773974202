import BizQnaRegisterContainer from '../../../containers/contact/bizqna/BizQnaRegisterContainer';
import MainLayout from '../../../layout/MainLayout';

function BizQnaRegisterPage() {
  return (
    <>
      <MainLayout>
        <BizQnaRegisterContainer />
      </MainLayout>
    </>
  );
}

export default BizQnaRegisterPage;
