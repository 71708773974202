import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import OnSiteReservationDetail from '../../components/member/OnSiteReservationStoreDetail';
import { fetchDetail, FETCH_DETAIL } from '../../modules/userReservation';
import * as api from '../../lib/api';
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; //DEV

const OnSiteReservationDetailContainer = ({ userReservationId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onSignIn = () => {
    const state = location.pathname + location.search;
    const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
    window.location.href = kauthUrl;
  };
  const dispatch = useDispatch();
  const { userReservation, isLoading, myInfo } = useSelector((state: any) => ({
    userReservation: state.userReservation.userReservation,
    isLoading: state.loading[FETCH_DETAIL],
    myInfo: state.auth.myInfo,
  }));
  let userObj;

  useEffect(() => {
    if (myInfo) {
      dispatch(fetchDetail(userReservationId, myInfo.userId, 'ON-SITE'));
    }
  }, [dispatch]);

  const updateReservationStatus = async () => {
    try {
      const updateStatus = {
        status: 'CANCEL',
        hash: userReservation.hash,
      };
      const response = await api.updateUserReservation({ userId: myInfo.userId, userReservation: updateStatus });
      if (response.status === 200) {
        navigate(`/member/reservation/onSite`);
      }
    } catch (err: any) {
      if (err.response.status === 400) {
        alert('잘못된 요청입니다.');
      } else if (err.response.status === 401) {
        alert('로그인이 필요합니다.');
        // history.push('/signin');
      } else {
        alert(err.response.data.message);
      }
    }
  };
  return <>{userReservation && <OnSiteReservationDetail userReservation={userReservation} isLoading={isLoading} user={userObj} onSignIn={onSignIn} updateReservationStatus={updateReservationStatus} />}</>;
};

export default OnSiteReservationDetailContainer;
