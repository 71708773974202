import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupList from '../../components/popup/PopupList';
import Map from '../../components/common/Map';
import { useLocation, useSearchParams } from 'react-router-dom';
import Search from '../../components/common/SearchBar';
import { FETCH_SEARCH_LIST, fetchSearchList } from '../../modules/board';



const MapListContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [searchParams, ] = useSearchParams();  
  const {stores, myInfo} = useSelector((state: any) => ({    
    stores: state.board.boards,
    isLoading: state.loading[FETCH_SEARCH_LIST],
    myInfo: state.auth.myInfo,
  }));

  useEffect(() => {
    let isMouted = true;
    if( isMouted ) {
      dispatch(fetchSearchList(`${location.search}`, myInfo ? myInfo.userId : null));
    }
    return () => {
      isMouted = false;
    };
  }, [searchParams]);
  
  const [mapBounds, setMapBounds] = useState(null);

  return (
    // <MapList maps={maps} isLoading={isLoading} isAdmin={isAdmin} />
    <>
      {/* {!!daaa && ( */}
      
        <>
          <article className="inner">
            <Search />
            {!!stores && stores.data && (
              <>              
              <Map datas={stores.data} zoomControl={true} isStore={false} updateBounds={setMapBounds} />
              <PopupList stores={stores.data} isLoading={false} query={location.search} myInfo={myInfo} filters={{gpsBoundFilter:mapBounds}} />
              </>
            )}
          </article>
        </>
      
    </>
  );
};

export default MapListContainer;
