import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWinnerList, FETCH_WINNER_LIST } from '../../modules/promotion';
import EventWinnerList from '../../components/codegroup/EventWinnerList';

const EventWinnerListContainer = ({ promotionId }) => {
  const dispatch = useDispatch();

  const { promotionWinners, isLoading, myInfo } = useSelector(
    (state: any) => (
      console.log(state),
      {
        promotionWinners: state.promotion.promotionWinners,
        isLoading: state.loading[FETCH_WINNER_LIST],
        myInfo: state.auth.myInfo,
      }
    ),
  );

  useEffect(() => {
    dispatch(fetchWinnerList(promotionId.eventId));
  }, [dispatch, promotionId]);

  return <EventWinnerList promotionWinners={promotionWinners} isLoading={isLoading} promotionId={promotionId} myInfo={myInfo} />;
};

export default EventWinnerListContainer;
