import PopupSearchListContainer from '../../containers/popup/PopupSearchListContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function PopupSearchListPage({ match }: any) {
  const { query } = useParams();
  return (
    <>
      <MainLayout>
        <PopupSearchListContainer query={query} />
      </MainLayout>
    </>
  );
}

export default PopupSearchListPage;
