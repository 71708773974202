function RadioForm({ question, changeInformation }: any) {
  // console.log('!@#!@#');
  // console.log(question);
  // console.log('!@#!@#');
  return (
    <>
      {question && (
        <div className="q-wrap">
          <p className="q-tit">{question.title}</p>
          <div className="a-wrap radio">
            {question.surveyQuestionCase.map(c => {
              return (
                <label>
                  <input type="radio" name={question.surveyQuestionId} value="male" onChange={() => changeInformation({ surveyQuestionId: question.surveyQuestionId, surveyQuestionCaseId: c.surveyQuestionCaseId }, 'radio')} required />
                  {c.case}
                </label>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default RadioForm;
