import { useEffect, useState } from 'react';
import MagazineSlide from '../MagazineSlide';

// 인사이트1 - 뷰티, 패션
import main1 from './../../../assets/magazine/magazine1/main1.jpg';
import img1 from './../../../assets/magazine/magazine1/vote1.png';
import slide1 from './../../../assets/magazine/magazine1/sub1.jpg';
import slide2 from './../../../assets/magazine/magazine1/sub2.jpg';
import slide3 from './../../../assets/magazine/magazine1/sub4.jpg';
import slide4 from './../../../assets/magazine/magazine1/sub7.jpg';
import slide5 from './../../../assets/magazine/magazine1/sub6.jpg';
import slide6 from './../../../assets/magazine/magazine1/sub8.jpg';
import slide7 from './../../../assets/magazine/magazine1/sub9.jpg';
import slide8 from './../../../assets/magazine/magazine1/sub10.jpg';
import slide9 from './../../../assets/magazine/magazine1/sub11.jpg';
import main2 from './../../../assets/magazine/magazine1/main2.jpg';
import slide01 from './../../../assets/magazine/magazine1/sub01.jpg';
import slide02 from './../../../assets/magazine/magazine1/sub02.jpg';
import slide03 from './../../../assets/magazine/magazine1/sub03.jpg';
import slide04 from './../../../assets/magazine/magazine1/sub04.jpg';
import slide05 from './../../../assets/magazine/magazine1/sub05.jpg';
import slide06 from './../../../assets/magazine/magazine1/sub06.jpg';
import img2 from './../../../assets/magazine/magazine1/sub08.jpg';
import slide07 from './../../../assets/magazine/magazine1/fashion1.jpg';
import slide08 from './../../../assets/magazine/magazine1/fashion2.jpg';
import slide09 from './../../../assets/magazine/magazine1/fashion3.jpg';
import slide10 from './../../../assets/magazine/magazine1/fashion4.jpg';
import img3 from './../../../assets/magazine/magazine1/sub09.png';

function MagazineReadDetail1() {
  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="magazine-detail-header">
        <h2>MAGAZINE</h2>
        <h1>MZ세대가 선정한 올해의 팝업스토어 - 뷰티·패션 편</h1>
        <ul>
          <li>
            <span>2023.12.15</span>
          </li>
          {/* <li>
                <span>·</span>
              </li>
              <li>
                <span>조회수</span>
                <span>102</span>
              </li>
              <li>
                <span>·</span>
              </li>
              <li>
                <span>좋아요</span>
                <span>5</span>
              </li> */}
        </ul>
      </div>

      {/* 매거진 본문내용 -> 글자는 <p> 태그로 감쌀 수 있도록 해주세여 */}
      <div className="magazine-detail-text-wrap">
            <div className="magazine-detail-content">
              <p className="big-text">뷰티 1위</p>
              <p>탬버린즈 '토일렛 프래그런스 팝업스토어'</p>
              <img src={main1} alt="탬버린즈 '토일렛 프래그런스 팝업스토어' 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">디올과 샤넬을 뛰어넘은 국내 브랜드, '탬버린즈'</p>
              <img src={img1} alt="팝업스토어 어워즈2023 투표 결과표, 탬버린즈 토일렛 프래그런스 팝업스토어가 30.9%로 1위" />
              <p>유저들의 약 31%가 팝업스토어 투표 후 선택 이유로 ‘브랜드를 더 이해할 수 있었어요’를 꼽은 것에서 확인할 수 있듯, 2023년 탬버린즈 브랜드에 대한 MZ 세대의 관심은 엄청났다. (포토존 26%, 굿즈 11%)</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide1, slide2, slide3, slide4]} />
              <p>탬버린즈는 ‘토일렛 에피소드’를 통해 방해받고 싶지 않은 공간과 순간에 대한 이야기를 기획했으며, 가장 편안한 공간에서 떠오르는 영감을 끄적이는 순간, 아무 생각을 하지 않고 음악을 듣는 순간을 위한 공간을 마련했다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide5, slide6]} />
              <p>그뿐만 아니라 제품 구매 고객을 위한 인스타그래머블한 굿즈를 준비했으며, 매출과 바이럴 요소 두 가지 모두를 잡을 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide7, slide8, slide9]} />
              <p className="caption">이미지 출처: 인스타그램 @c_yejiii</p>
              <p>한 달간 진행된 팝업스토어를 통해 총 6천 개 이상의 콘텐츠가 네이버, 인스타그램 등에서 생산되었으며, 후기를 통한 새로운 고객 방문으로 이어지는 선순환 구조를 만들었다는 평이다.</p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">패션 1위</p>
              <p>버버리 '버버리 성수 로즈'</p>
              <img src={main2} alt="버버리 '버버리 성수 로즈' 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">새로워진 버버리가 궁금해 성수로 달려간 MZ세대</p>
              <MagazineSlide settings={commonSettings} images={[slide01, slide02]} />
              <p>10월 초부터 약 한 달 간 진행된 버버리 팝업스토어는 성수를 보라색으로 물들였다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide03, slide04, slide05, slide06]} />
              <p>버버리 하면 떠오르는 체크 패턴이 아닌 잉글리쉬 로즈를 모티브로 한 겨울 컬렉션은 팝업스토어 공간뿐만 아니라 성수동 구석구석을 장미 꽃잎 모양 깃발과 간판으로 실로 ‘버버리 스트리트’를 만들었던 것! </p>
            </div>
            <div className="magazine-detail-content">
              <img src={img2} alt="" />
              <p>또한 영국의 유명 레스토랑인 ‘노만'과의 콜라보를 통해 팝업스토어 내 카페를 운영하며, 방문자들이 크리에이티브 디렉터 ‘다니엘 리'의 새로운 버버리 스타일과 함께 영국의 문화를 간접적으로 경험할 수 있는 기회를 제공했다.</p>
              <p>새로운 버버리가 궁금했던 MZ세대들은 성수동에서 세 군데의 팝업스토어 공간을 돌며 굿즈를 받기 위한 미션에 참여했다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide07, slide08, slide09, slide10]} />
              <p className="caption">이미지 출처 : 인스타그램 @luxxtv</p>
              <p>팝업스토어가 진행된 기간 동안 약 1만여 개 이상의 콘텐츠가 생산되었으며, 네이버 기준 31만 건의 검색량을 기록하며 전달 대비 20%의 큰 상승을 보였다. </p>
            </div>
            <div className="magazine-detail-content">
              <img src={img3} alt="" />
              <p>MZ세대의 핫플레이스로 손꼽히는 성수에서 새로운 출발을 알리고자 했던 버버리의 전략이 브랜드 인지도 제고 및 팝업스토어 흥행에 큰 영향을 끼친 것으로 보여진다.</p>
            </div>
          </div>
    </div>
  );
}

export default MagazineReadDetail1;
