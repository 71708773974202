import { useState, useCallback, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import './brand_sign.scss';

const defaultPwdStyle = {
  fontSize: '0.8em',
  color: '#767676',
  paddingBottom: '0.5em',
};

const checkedPwdStyle = {
  ...defaultPwdStyle,
  color: 'green',
  paddingBottom: '0.5em',
};

const uncheckedPwdStyle = {
  ...defaultPwdStyle,
  color: '#f0002e',
  paddingBottom: '0.5em',
};

function SignUpForm({ onSignUp }: { onSignUp: Function }) {
  // 컴포넌트 상태 설정
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyCategory, setCompanyCategory] = useState('');
  const [companyBizNum, setCompanyBizNum] = useState('');
  const [userName, setUserName] = useState('');
  const [userNum, setUserNum] = useState('');
  const [directNum, setDirectNum] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [isPwdSame, setPwdSame] = useState(false);

  // const [job, setJob] = useState("")
  // const [jobCodes, setJobCodes] = useState([])

  // 회원정보 변경을 처리하는 함수
  const handleChangeUserId = useCallback(e => {
    setUserId(e.target.value);
  }, []);

  const handleChangePassword = useCallback(e => {
    setPassword(e.target.value);
  }, []);

  const handleChangeConfirmPassword = useCallback(
    e => {
      setConfirmPassword(e.target.value);

      const confirmPassword = e.target.value;
      if (password && confirmPassword) {
        if (password === confirmPassword) {
          setPwdSame(true);
        } else {
          setPwdSame(false);
        }
      }
    },
    [password],
  );

  const handleChangeCompanyName = useCallback(e => {
    setCompanyName(e.target.value);
  }, []);

  const handleChangeCompanyCategory = useCallback(e => {
    setCompanyCategory(e.target.value);
  }, []);

  const handleChangeCompanyBizNum = useCallback(e => {
    setCompanyBizNum(e.target.value);
  }, []);

  const handleChangeUserName = useCallback(e => {
    setUserName(e.target.value);
  }, []);

  const handleChangeUserNum = useCallback(e => {
    setUserNum(e.target.value);
  }, []);

  const handleChangeDirectNum = useCallback(e => {
    setDirectNum(e.target.value);
  }, []);

  const handleChangeUserEmail = useCallback(e => {
    setUserEmail(e.target.value);
  }, []);

  // handle form submit event
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSignUp(userId, password, confirmPassword, companyName, companyCategory, companyBizNum, userName, userNum, directNum, userEmail, '');
    },
    [userId, password, confirmPassword, companyName, companyCategory, companyBizNum, userName, userNum, directNum, userEmail],
  );

  // const handleChangeJob = useCallback((e) => {
  //     setJob(e.target.value)
  // }, [])

  // get job code list
  // const getJobCodeList = async () => {
  //     try {
  //         const response = await fetchJobCodeList()
  //         // setJobCodes(response.data)
  //     } catch (error) {
  //         throw error
  //     }
  // }

  // // get job code list in mounting process
  // useEffect(() => {
  //     getJobCodeList()
  // }, [])

  return (
    <article className="brand-sign">
      <h2>브랜드 회원가입</h2>
      <form className="brand-sign-form" onSubmit={handleSubmit}>
        <table>
          <colgroup>
            <col width="15%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <td>아이디</td>
              <td>
                <input type="text" value={userId} onChange={handleChangeUserId} placeholder="아이디를 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>비밀번호</td>
              <td>
                <input type="password" value={password} onChange={handleChangePassword} placeholder="보안에 강한 비밀번호를 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>비밀번호 확인</td>
              <td>
                <p style={confirmPassword === '' ? defaultPwdStyle : isPwdSame ? checkedPwdStyle : uncheckedPwdStyle}>
                  {confirmPassword === '' && '* 비밀번호를 정확하게 입력해주세요.'}
                  {confirmPassword !== '' && isPwdSame && '✓ 일치합니다.'}
                  {confirmPassword !== '' && !isPwdSame && '✗ 일치하지 않습니다.'}
                </p>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={handleChangeConfirmPassword}
                  placeholder="입력한 비밀번호를 다시 한 번 입력해주세요."
                />
                <br />
              </td>
            </tr>
            <tr>
              <td>회사명</td>
              <td>
                <input type="text" value={companyName} onChange={handleChangeCompanyName} placeholder="정확한 회사명을 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>업종</td>
              <td>
                <input type="text" value={companyCategory} onChange={handleChangeCompanyCategory} placeholder="정확한 업종 정보를 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>사업자등록번호</td>
              <td>
                <input
                  type="number"
                  value={companyBizNum}
                  onChange={handleChangeCompanyBizNum}
                  placeholder="오탈자 없이 사업자등록번호를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>담당자명</td>
              <td>
                <input type="text" value={userName} onChange={handleChangeUserName} placeholder="실제 담당자 이름을 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>담당자 연락처</td>
              <td>
                <input type="number" value={userNum} onChange={handleChangeUserNum} placeholder="연락 가능한 담당자 연락처를 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>회사 직통 번호</td>
              <td>
                <input type="number" value={directNum} onChange={handleChangeDirectNum} placeholder="연락 가능한 회사 직통 번호를 입력해주세요." />
              </td>
            </tr>
            <tr>
              <td>담당자이메일</td>
              <td>
                <input type="text" value={userEmail} onChange={handleChangeUserEmail} placeholder="연락 가능한 담당자 이메일 주소를 입력해주세요." />
              </td>
            </tr>
            {/* <tr>
                            <td>직업</td>
                            <td>
                                <select onChange={handleChangeJob}>
                                    {jobCodes.map((jobCode: any) => (
                                        <option value={jobCode.value} key={jobCode.value}>{jobCode.label}</option>
                                    ))}
                                </select>
                            </td>
                        </tr> */}
            <tr>
              <td colSpan={2} align="center">
                <button type="submit" className="full-btn">
                  회원가입
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <p>
        <Link to="/signin" className="line-link">
          로그인
        </Link>
      </p>
    </article>
  );
}

export default SignUpForm;
