import CoinPayListContainer from '../../containers/coin/CoinPayListContainer';
import MainLayout from '../../layout/MainLayout';

function CoinPayListPage() {
  return (
    <>
      <MainLayout>
        <CoinPayListContainer />
      </MainLayout>
    </>
  );
}

export default CoinPayListPage;
