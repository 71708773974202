import FaqListContainer from '../../../containers/contact/faq/FaqListContainer';
import MainLayout from '../../../layout/MainLayout';

function ContactListPage() {
  return (
    <>
      <MainLayout>
        <FaqListContainer />
      </MainLayout>
    </>
  );
}

export default ContactListPage;
