import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import { getOriginalFileName } from '../ContactUtil';
import ReactHtmlParser from 'html-react-parser';
import { useState } from 'react';
function QnaRead({ qna, attachments, isLoading, qnaId, onRemove, isAdmin, admin, adminRegister }: any) {
  const [qnaInfo, setQnaInfo] = useState(qna);
  const changeContents = answerData => {
    setQnaInfo({ ...qnaInfo, answer: answerData });
  };
  const answerSubmit = data => {
    window.confirm('정말 답변을 하시겠습니까?');
    adminRegister(data);
  };
  return (
    <article>
      {isLoading && '로딩중...'}
      {!isLoading && qna && (
        <>
          <div className="view-wrap">
            <h1 className="subject-title">{qna.title}</h1>
            <p>{dayjs(qna.createdAt).format('YYYY-MM-DD')}</p>
            <div className="view-inner">{ReactHtmlParser(qna.content)}</div>
            <div className="answer"> 팝플리 답변 : {qna.isAnswered && ReactHtmlParser(qna.answer)}</div>
          </div>
          <div className="likebutton-wrap">
            {admin && (
              <>
                <Editor
                  editor={ClassicEditor as any}
                  config={{
                    placeholder: '답변을달아주세요',
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    changeContents(data);
                  }}
                />

                <button onClick={() => answerSubmit(qnaInfo)} className="likebutton danger">
                  답변 달기 (관리자)
                </button>
                <button onClick={onRemove} className="likebutton danger">
                  삭제
                </button>
              </>
            )}
            <Link to={`/qna`} className="likebutton">
              목록
            </Link>
          </div>
        </>
      )}
    </article>
  );
}

export default QnaRead;
