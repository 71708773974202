import OnSiteReservationContainer from '../../containers/member/OnSiteReservationContainer';
import MainLayout from '../../layout/MainLayout';

export default function OnSiteReservationListPage() {
  return (
    <>
      <MainLayout>
        <OnSiteReservationContainer />
      </MainLayout>
    </>
  );
}
