import { Link } from 'react-router-dom';

function PopupReportList({ popupReportList, isLoading, isAdmin }: any) {
  return (
    // 공지사항
    <article style={{minHeight:'50vh'}}>
      {isLoading && '로딩중...'}
      <div className="list-wrap">
        <div className="upside-button-area">
          <Link to="/popupreport/create" className="likebutton">
            제보하기
          </Link>
        </div>
        <ul>
          <li>
            <a>
              {/* Link 로 바꾸시면 됩니다 ! */}
              <p>제보합니다!</p>
              <p>
                <span className="state state-ok">확인</span> {/*state-ok class variable*/}
                <span className="date">2023-02-13</span>
              </p>
            </a>
          </li>
          <li>
            <a>
              {/* Link 로 바꾸시면 됩니다 ! */}
              <p>제보합니다!</p>
              <p>
                <span className="state state-ok">확인</span> {/*state-ok class variable*/}
                <span className="date">2023-02-13</span>
              </p>
            </a>
          </li>
        </ul>
      </div>
      <div className="page-area">
        <ul>
          <li>
            <a className="on">1</a>
          </li>
        </ul>
      </div>
      {!isLoading && popupReportList && (
        <>
          {isAdmin && (
            <div className="upside-button-area">
              <Link to="/faq/create" className="likebutton">
                새로 만들기
              </Link>
            </div>
          )}
          <table className="table-board">
            <thead>
              <tr>
                <th align="center">번호</th>
                <th align="center">제목</th>
                <th align="center">날짜</th>
              </tr>
            </thead>
            <tbody>
              {!popupReportList.length && (
                <tr>
                  <td colSpan={3}>List is emtpy.</td>
                </tr>
              )}
              {!!popupReportList.length &&
                popupReportList.map((faq: any) => (
                  <tr key={faq.itemId}>
                    <td align="center">{faq.itemId}</td>
                    <td align="left">
                      <Link to={`/faq/${faq.itemId}`}>{faq.itemName}</Link>
                    </td>
                    <td align="center">{faq.viewCnt}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </article>
  );
}

export default PopupReportList;
