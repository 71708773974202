import HomeContainer from '../containers/HomeContainer';
import MainLayout from '../layout/MainLayout';

function HomePage() {
  return (    
    <MainLayout>
        <HomeContainer />
    </MainLayout>    
  );
}

export default HomePage;
