import MapListContainer from '../../containers/map/MapListContainer';
import MainLayout from '../../layout/MainLayout';

function MapListPage() {
  return (
    <>
      <MainLayout>
        <MapListContainer />
      </MainLayout>
    </>
  );
}

export default MapListPage;
