import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ONE, fetchOne } from '../../modules/survey';
import { useNavigate } from 'react-router-dom';
import PostSurvey from '../../components/survey/postSurvey';

const PostSurveyContainer = ({ storeId, reservationId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { survey, isLoading, myInfo } = useSelector((state: any) => ({
    survey: state.survey,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));

  /**
   * 
   * @param data 
   * data:{
   *  
   *  comment:{
  *     storeId:storeId,
        storeDetailId:??,
        comment:'~~',
        starts:5,
        type:'store'
   * }
   * }
   */
  const onRegister = async data => {
    try {
      // const addSurveyResult = await addPreSurvey(myInfo.userId,data);
      // if (addSurveyResult.status === 200) {
      //   const addCommentResult = await addComment(storeId, data.comment);
      //   alert('설문 제출에 성공했습니다 .감사합니다.');
      //   navigate(`/popup?area=all`);
      // }
    } catch (err) {
      alert('설문 제출에 실패했습니다.');
    }
  };

  useEffect(() => {
    dispatch(fetchOne(storeId, reservationId, 'POST', myInfo ? myInfo.userId : null));
  }, [dispatch]);

  return (
    <>
      <PostSurvey survey={survey} reservationType={'POST'} isLoading={isLoading} myInfo={myInfo} />
    </>
  );
};

export default PostSurveyContainer;
