import bookmark from './../../assets/img/popup/bookmark.svg';
import bookmark_on from './../../assets/img/popup/bookmark_on.svg';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createGeneralData, deleteGeneralData } from '../../modules/generalData';

function Bookmark({ store, myInfo }) {
  const location = useLocation();
  const dispatch = useDispatch();  
  const { lnkUserStore } = useSelector(({generalData}: any) => {    
    return {lnkUserStore: generalData.generalData.lnkUserStore}
  });  
  const storeId = store.storeId;
  const lnkUserStoreItem = lnkUserStore && myInfo ? lnkUserStore?.find(l => l.lnkType == 'Favor' && l.storeId == storeId ) : null;  
  const bookmarkClick = async () => {
    if (!myInfo) {
      window.location.href = `/member?state=${encodeURIComponent(location.pathname + location.search + (location.search ? '&': '?')
       + 'scroll_position=' + window.scrollY)}`;
    } else {
      if( lnkUserStoreItem ) {
        store.totalUserLike -= 1;
        dispatch(deleteGeneralData('lnkUserStore', { idx: lnkUserStoreItem?.idx, userId : myInfo.userId, storeId, lnkType: 'Favor' } ));
             
      } else {
        store.totalUserLike += 1;
        dispatch(createGeneralData('lnkUserStore',{ userId : myInfo.userId, storeId, lnkType: 'Favor'}  ));        
      
      }
    }
  };

  return (    
    <>
    <a className="bookmark-wrap" onClick={bookmarkClick}>        
      <img src={lnkUserStoreItem != null ? bookmark_on : bookmark} alt="" className="popup-bookmark" />
    </a>
    </>
  );
}

export default Bookmark;
