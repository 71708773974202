import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const BrandQr = ({ qrImg }) => {
  // const {isL}
  // useQuery("getData",async () => {
  //     try {
  //       const res = await axios.get(
  //         "http://localhost:13003/dat"
  //       );
  //       setData(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   { refetchInterval: 5000 }
  // );
  return (
    <>
      <div className="addbanner-inner">
        <img src={qrImg} />
      </div>
    </>
  );
};

export default BrandQr;
