import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne, FETCH_ONE } from '../../../modules/reservation';
import { useLocation, useNavigate } from 'react-router-dom';
import ReservationRegister from '../../../components/reservation/ReservationRegister';
import { addUserReservation, updateQR } from '../../../lib/api';
import Cookies from 'js-cookie';
import AlertPopup from '../../../components/common/alert/AlertPopup';
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
// const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

// 사전, 현장대기 조회,신청 페이지
const ReservationRegisterContainer = ({ storeId, type, hash }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [qrValid, setQrValid] = useState(false);
  const [reservationQrHash, setReservationQrHash] = useState();
  const [showAlert, setShowAlert] = useState(true);

  const { reservation, isLoading, myInfo } = useSelector((state: any) => ({
    reservation: state.reservation.reservation,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));

  useEffect(() => {
    if (reservation) {
      if (!myInfo && type === 'pre' && !!!reservation.reservation.availableGuest) {
        alert('로그인이 필요한 서비스 입니다.');
        const state = `/popup/${storeId}`;
        const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
        window.location.href = kauthUrl;
        return;
      } else if (type === 'onSite') {
        async function qrCheck() {
          const response = await updateQR(hash);
          if (!response.data.isSuccess) {
            alert(response.data.data.msg);
            setQrValid(false);
            navigate('/');
          } else {
            // alert('!');
            setQrValid(true);
            setReservationQrHash(response.data.data.reservationQr.reservationHash);
          }
        }
        qrCheck();
      } else {
        if (reservation.reservation.userReservation && reservation.reservation.userReservation.length > 0) {
          if (reservation.reservation.userReservation[0].status === 'WAITING') {
            alert('예약 내역이 존재하는 팝업스토어 입니다.');
            setQrValid(false);
            window.location.href = `/member/reservation/pre`;
          }
        }
        setQrValid(true);
      }
    } else {
      setQrValid(true);
      // window.location.href = `/member`;
    }
  }, [reservation]);
  const onRegister = async (storeId, reservationId, reservationType, reservationInfo) => {
    try {
      // setShowAlert(false);
      // return;
      if (type === 'pre' || type === 'PRE') {
        if (myInfo || reservation.reservation.availableGuest) {
          const registReservation = await addUserReservation(storeId, reservationId, reservationType, myInfo ? myInfo.userId : undefined, reservationInfo);
          if (registReservation.status === 200) {
            if (registReservation.data.code === 200 && registReservation.data.isSuccess) {
              alert('등록되었습니다');
              const userReservation = registReservation.data.data.userReservation;
              const userHash = userReservation.hash;
              const reservationId = userReservation.reservationId;
              const userInfo = { name: userReservation.name, phone: userReservation.phone };
              Cookies.set('reservationUser', JSON.stringify(userInfo), { expires: 1 });
              // 예약 확인 페이지 이동.
              navigate(`/popup/${storeId}/reservation/${reservationId}/${encodeURIComponent(userHash)}`);
            } else {
              alert(registReservation.data.data.msg);
              // navigate(`/popup/${storeId}/reservation/${reservationId}/${encodeURIComponent(userHash)}`);
            }
          }
        } else {
          alert('회원 정보가 없습니다 로그인해주세요');
        }
      } else if (type === 'onSite') {
        reservationInfo.reservationQrHash = hash;
        const registReservation = await addUserReservation(storeId, reservationId, reservationType, undefined, reservationInfo);
        if (registReservation.status === 200) {
          if (registReservation.data.code === 200 && registReservation.data.isSuccess) {
            alert('등록되었습니다');
            const userReservation = registReservation.data.data.userReservation;
            const userHash = userReservation.hash;
            const reservationId = userReservation.reservationId;
            // 예약 확인 페이지 이동.
            navigate(`/popup/${storeId}/reservation/${reservationId}/${encodeURIComponent(userHash)}`);
          } else {
            alert(registReservation.data.data.msg);
          }
        }
      }
    } catch (err) {
      console.log(err);
      alert('실패했습니다.');
    }
  };

  useEffect(() => {
    if (type === 'pre') {
      dispatch(fetchOne(storeId, myInfo ? myInfo.userId : null, 'PRE', null));
    } else if (type === 'onSite') {
      dispatch(fetchOne(storeId, myInfo ? myInfo.userId : null, 'ON-SITE', null));
    }
  }, [dispatch]);

  useEffect(() => {
    if (reservation) {
      // 사전예약의 경우에만 설문조사 검사.
      if (type === 'pre') {
        if (reservation.isDone) {
          alert('예약이 종료되었습니다.');
          navigate(`/popup/${storeId}`);
          return;
        }
        if (!reservation.availableGuest && !reservation.surveyUser) {
          alert('사전 설문조사를 먼저 작성해주세요');
          navigate(`/popup/${storeId}/reservation/${reservation.reservation.reservationId}/survey/pre`);
        }
      }
      console.log(reservation);
    }
  }, [reservation]);

  return (
    <>
      {/* {showAlert && <AlertPopup type={'alert'} msg={'현장대기 중복 신청입니다. <p>ㅈ디ㅏ르짇라ㅡㅈㄷ</p>'} btnText={'확인'} btnText2={undefined} successfunction={undefined} failFunction={undefined} />} */}
      {reservation && !isLoading && qrValid && <ReservationRegister reservation={reservation.reservation} surveyUser={reservation.surveyUser} myInfo={myInfo} onRegister={onRegister} isLoading={isLoading} type={type} survey={reservation.reservation.reservationSurveyMap[1].survey} />}
    </>
  );
};

export default ReservationRegisterContainer;
