import { Button } from '@material-ui/core';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import nonethumb from './../../assets/img/noneIMG.png';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import bookmark from './../../assets/img/popup/bookmark.svg';
import bookmark_on from './../../assets/img/popup/bookmark_on.svg';
import explore_icon from './../../assets/img/explore_icon.svg';
import Bookmark from '../common/Bookmark';
import QRScannerComponent from '../member/QRScannerComponent';

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noreferrer');
};

function ExploreList({ items, myInfo, isLoading, stores }: any) {  

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  // console.log('!!');
  // console.log(likeStore);
  // console.log(stores);
  return (
    <article className="inner gradiant">
      {/* {myInfo === null && (
        <div className="page-info-area">
          <div className="page-info-area-inner">
            <img src={explore_icon} alt="" />
            <h5>내 취향을 EXPLORE 해보세요!</h5>
            <p>로그인 후, 마이페이지에서 내 관심사를 설정하면 관심사에 맞는 팝업스토어를 찾을 수 있어요.</p>
            <Link to="/member" className="full-btn full-btn-red">
              마이페이지
            </Link>
          </div>
        </div>
      )}

      {stores === null ||
        (stores === undefined && (
          // 카테고리 선택했지만 데이터가 없는경우
          <div className="page-info-area page-info-area-explore">
            <div className="page-info-area-inner">
              <img src={explore_icon} alt="" />
              <h5>아직 내 관심사 팝업이 등록되지 않았네요..!</h5>
              <p>내 관심사와 일치하는 팝업스토어가 등록되길 기다려주세요.</p>
              <p>지금은 다른 사람들이 좋아하는 팝업스토어를 둘러보면 어떨까요?</p>
              <Link to="/popup" className="full-btn full-btn-red">
                이동하기
              </Link>
            </div>
          </div>
        ))}
      {!isLoading && stores && (
        // 카테고리 선택했고 데이터가 있는경우 board 컴포넌트 이용

        <>
          <div className="calendar-popup-list popuplist-board">
            <ul>
              {stores.map((store: any, index) => (
                <li key={index}>
                  <div>
                    <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
                      <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
                    </Link>
                    <Link to={`/popup/${store.storeId}`}>
                      <ul>
                        <li className="popup-name">
                          <p>{store.title}</p>
                        </li>
                        <li className="popup-location">
                          <img src={slidemapicon} /> {store.topLevelAddress}
                        </li>
                      </ul>
                    </Link>
                    <p
                      onClick={() => {
                        bookmarkClick(true, store.storeId);
                      }}
                    >
                      <Bookmark store={store} likeStore={likeStore} myInfo={myInfo} />
                    </p>
                    <p className="popup-date">
                      {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )} */}
      <QRScannerComponent />
    </article>
  );
}

export default ExploreList;
