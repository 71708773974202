import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../common/Footer';

function Marketing() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <article className="inner site-document">
        <h2>광고 및 마케팅 수신 동의</h2>
        <div>
          <p className="info">
          팝플리는 개인정보 보호법 제 22조 제 4항과 제 39조의 3에 따라 사용자의 광고성 정보 수신과 이에 따른 개인정보 처리에 대한 동의를 받고 있습니다. 약관에 동의하지 않으셔도 팝플리의 모든 서비스를 이용하실 수 있습니다. 다만 이벤트, 혜택 등의 제한이 있을 수 있습니다.
          </p>
        </div>
        <p className="term-title">개인정보 수집 이용 목적</p>
        <span className="term-text">
        이벤트 운영 및 광고성 정보 전송 
        </span>
        <ul>
          <li>
            <p>개인정보 수집항목</p>
            <ul className="sub-list">
              <li>이름, 휴대폰번호, 이메일</li>
            </ul>
          </li>
          <li>
            <p>개인정보 수집 이용 목적</p>
            <ul className="sub-list">
              <li>이벤트 운영 및 광고성 정보 전송</li>
              <li>서비스 관련 정보 전송</li>
            </ul>
          </li>
          <li>
            <p>보유 및 이용기간</p>
            <ul className="sub-list">
              <li>동의 철회 시 또는 회원 탈퇴 시까지</li>
            </ul>
          </li>
          <li>
            <p>동의 철회 방법</p>
            <ul className="sub-list">
              <li>마이페이지에서 변경 (혹은 1:1문의로 요청)</li>
            </ul>
          </li>
          <li>
            <p>전송 방법</p>
            <ul className="sub-list">
              <li>휴대폰 문자메세지(SMS), Email, 카카오톡 등</li>
            </ul>
          </li>
        </ul>
      </article>
      {/* <Footer /> */}
    </>
  );
}

export default Marketing;
