import { useEffect, useState } from 'react';
import MagazineSlide from '../MagazineSlide';

// 인사이트2 - 음식, 음료
import awards2 from './../../../assets/magazine/awards_listThumbnail2.png';
import main01 from './../../../assets/magazine/magazine2/main1.jpg';
import slide21 from './../../../assets/magazine/magazine2/sub01.jpg';
import slide22 from './../../../assets/magazine/magazine2/sub02.jpg';
import slide23 from './../../../assets/magazine/magazine2/sub03.jpg';
import slide24 from './../../../assets/magazine/magazine2/sub04.jpg';
import slide25 from './../../../assets/magazine/magazine2/sub05.jpg';
import slide26 from './../../../assets/magazine/magazine2/sub06.jpg';
import slide27 from './../../../assets/magazine/magazine2/sub07.jpg';
import slide28 from './../../../assets/magazine/magazine2/sub08.jpg';
import main02 from './../../../assets/magazine/magazine2/main2.jpg';
import slide201 from './../../../assets/magazine/magazine2/sub01.png';
import slide202 from './../../../assets/magazine/magazine2/sub02.png';
import slide203 from './../../../assets/magazine/magazine2/sub06.png';
import slide204 from './../../../assets/magazine/magazine2/sub03.png';
import slide205 from './../../../assets/magazine/magazine2/sub10.png';
import slide206 from './../../../assets/magazine/magazine2/fnb2-1.jpg';
import slide207 from './../../../assets/magazine/magazine2/fnb2-2.jpg';
import slide208 from './../../../assets/magazine/magazine2/fnb2-3.jpg';
import slide209 from './../../../assets/magazine/magazine2/fnb2-4.jpg';
import slide210 from './../../../assets/magazine/magazine2/sub05.png';
import slide211 from './../../../assets/magazine/magazine2/sub07.png';

function MagazineReadDetail2() {
  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="magazine-detail-header">
        <h2>MAGAZINE</h2>
        <h1>MZ세대가 선정한 올해의 팝업스토어 - 음식·음료 편</h1>
        <ul>
          <li>
            <span>2023.12.15</span>
          </li>
          {/* <li>
                <span>·</span>
              </li>
              <li>
                <span>조회수</span>
                <span>102</span>
              </li>
              <li>
                <span>·</span>
              </li>
              <li>
                <span>좋아요</span>
                <span>5</span>
              </li> */}
        </ul>
      </div>

      {/* 매거진 본문내용 -> 글자는 <p> 태그로 감쌀 수 있도록 해주세여 */}
      <div className="magazine-detail-text-wrap">
            <div className="magazine-detail-content">
              <p className="big-text">음식 1위</p>
              <p>동대문엽기떡볶이 '동대문 엽기떡볶이 팝업스토어'</p>
              <img src={main01} alt="동대문엽기떡볶이 ‘동대문 엽기떡볶이 팝업스토어’ 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">K-빨간 맛 대표주자, '동대문 엽기떡볶이'</p>
              <MagazineSlide settings={commonSettings} images={[slide21, slide22, slide23, slide24]} />
              <p className="caption">이미지 출처 : 인스타그램 @chaeyul_eyam, @rala.on</p>
              <p>MZ세대의 소울푸드 중 하나인 엽기떡볶이가 출시 21주년을 맞아 '동대문 엽기떡볶이 팝업스토어'를 진행했다. 3주 동안 진행된 행사에서는 팝업스토어에서만 맛볼 수 있는 한정 메뉴, 엽기떡볶이의 공식 캐릭터인 ‘여비와 더기'가 출현하는 떡볶이 게임 체험존, 매운맛 인기투표 등 다양한 체험 거리를 마련해 단순 취식 위주의 팝업스토어가 아닌 복합 체험 공간을 완성했다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide25, slide26, slide27, slide28]} />
              <p className="caption">이미지 출처 : 인스타그램 @chaeyul_eyam</p>
              <p>특히, 랜덤 뽑기 머신 이벤트로 제공된 다양한 굿즈와 ‘엽떡네컷’ 인증샷이 소셜 미디어에서 화제가 되며, 1천4백 개 이상의 팝업스토어 관련 콘텐츠가 생산되었다. 또한 팝업스토어 사전 예약 마감뿐만 아니라 오픈 2시간 만에 현장 입장이 종료될 만큼 문전성시를 이뤘다. </p>
              <p>동대문엽기떡볶이는 팝업스토어라는 오프라인 공간을 통해 ‘한정판 메뉴’를 출시하고 참여를 유도했으며, 팝업스토어 방문 고객의 찐 후기를 빠르게 취합하는 기회로 활용했다는 긍정적인 평가를 받았다. </p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">음료 1위</p>
              <p>처음처럼 새로 '새로 02-57 팝업스토어'</p>
              <img src={main02} alt="처음처럼 새로 '새로 02-57 팝업스토어' 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">잘 만든 세계관 하나, 열 제품 부럽지 않아! '처음처럼 새로'</p>
              <MagazineSlide settings={commonSettings} images={[slide201, slide202, slide203]} />
              <p>롯데칠성음료는 제로 슈거 소주 ‘처음처럼 새로’ 출시 1주년을 기념해 브랜드 캐릭터 ‘새로구미’의 생일 잔치 콘셉트로 팝업스토어를 기획했다. 방문자들로 하여금 2D 애니메이션으로만 구현되어 왔던 세계관을 직접 체험할 수 있도록 해 캐릭터와 브랜드 세계관을 다시금 알리고, ‘제로 슈거’라는 제품의 특성을 간접적으로 경험할 수 있도록 공간을 구성했다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide204, slide205]} />
              <p>특히, 직접 새로 세계관 속 구미호가 되어 사진을 남길 수 있는 포토존, 360도 회전하며 사진을 찍을 수 있는 포토존 등 컨셉추얼한 공간 내부의 콘텐츠들이 SNS를 통해 바이럴 되었다.</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide206, slide207, slide208, slide209]} />
              <p className="caption">이미지 출처 : 인스타그램 @tw_ilight_s2</p>
              <p>그 결과 팝업스토어가 진행된 2주 동안 약 2만 명의 방문자가 다녀갔을 뿐만 아니라, 네이버와 인스타그램을 통해 총 10만여 개의 콘텐츠가 생산되었다.</p>
              <p>한편 새로구미 유튜브는 캐릭터를 활용한 세계관 콘텐츠를 통해 1개월여 만에 1천만 건 이상의 조회 수를 기록했고, 인스타그램은 구미호 컨셉에 맞는 연애 상담 콘텐츠 등을 업로드해 누적 좋아요 수 2천7백 개를 얻는 등 MZ세대와 적극적으로 소통하며 브랜드 팬덤을 구축했다. </p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[slide210, slide211]} />
              <p>주류시장 후발주자였던 새로의 캐릭터를 활용한 차별화 전략이 애니메이션 콘텐츠뿐만 아니라 오프라인 영역까지 이어지며, 이색적인 체험을 즐기고 인증샷을 찍어 SNS에 올리는 것을 즐기는 MZ세대들에게 효과적으로 작용한 것으로 보여진다. </p>
            </div>
          </div>
    </>
  );
}

export default MagazineReadDetail2;
