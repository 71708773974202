import profileNone from './../../assets/img/member/profile.svg';
import { useEffect, useRef, useState ,useLayoutEffect} from 'react';
import { Link, useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import StarRate from '../common/StarRate';
import Slider from 'react-slick';
import profile from './../../assets/img/member/profile.svg';
import arrow_left from './../../assets/img/common/arrow_left_small.svg';
import { addComment, addCommentImage } from '../../lib/api';
import btn_close from './../../assets/img/common/btn_close.svg';
import Stars from '../common/Star';
import photo from '../../assets/img/popup/image-add.png';
import cmt from './../../assets/img/member/member-icon2.svg';
import GuestAccess from '../auth/GuestAccess';

function StoreCommentList({ store, storeId, myInfo, isLoading }: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const location = useLocation();
  const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
  const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; //PROD
  // const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

  const state = location.pathname + location.search;
  const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
  const onSignIn = () => {
    // window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_HOME}&response_type=code&state=${state}`;
    window.location.href = kauthUrl;
  };
  const [dragging, setDragging] = useState(false);
  let starAvg = 0;
  let storeThumbnails = [];
  if (store) {
    store = store.data.store;
    console.log(store);
    const totalStars = store.storeDetail.comments.reduce((a, b) => a + b.stars, 0);
    store.storeDetail.comments.map((comment, idx) =>
      comment.storeDetailCommentImages.map((image, idx) => {
        if (idx === 0 && image.url !== null) {
          storeThumbnails.push(image);
        }
      }),
    );
    starAvg = totalStars / store.storeDetail.comments.length;
  }

  const [comment, setComment] = useState('');
  const commentHandler = (event: any) => {
    setComment(event.currentTarget.value);
  };

  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);
  const onUpload = async (e: any) => {
    const images = e.target.files;

    setFiles(images);
    let fileURLs = [];
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = () => {
        fileURLs[i] = reader.result;
        setImageSrc([...fileURLs]); // 파일의 컨텐츠
      };
    }
  };

  const commentSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (files.length > 0) {
      for (const image of files) {
        formData.append('files', image);
      }
    }

    const detailComment = {
      storeDetailComment: {
        userId: myInfo.userId,
        storeId: storeId,
        storeDetailId: store.storeDetail.storeDetailId,
        comment: comment,
        stars: rating,
      },
    };
    const addCommentResult = await addComment(storeId, detailComment);
    if (addCommentResult.status === 200) {
      const storeId = addCommentResult.data.data.storeId;
      const commentId = addCommentResult.data.data.storeDetailCommentId;
      const uploadImages = await addCommentImage(storeId, commentId, formData);
      if (uploadImages.status === 200) {
        alert('등록이 완료되었습니다.');
      } else {
        alert('등록에 실패했습니다.');
      }
    }

    setComment('');
    // history.go();
    navigate(0);
  };

  useEffect(() => {
    console.log(comment);
  }, [comment]);

  const [notifyLayer, setNotifyLayer] = useState(true);
  const notifyLayerOpen = (isClick: boolean) => {
    setNotifyLayer(current => !current);
  };

  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const onSaveRating = (index: number) => {
    setRating(index);
  };

  const [reviewWriteLayer, setReviewWriteLayer] = useState(!(searchParams.has("doing_write") && searchParams.get("doing_write") =='true'));
  const reviewWriteLayerOpen = (isClick: boolean) => {
    setReviewWriteLayer(current => !current);
    setSearchParams( {...searchParams, 'doing_write': 'true'});
  };
  const settings = {
    dots: true,
    dotsClass: 'popupdetail-banner-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //후기상세보기레이어팝업 오픈
  const componentRef = useRef<HTMLInputElement>();
  const [reviewDetail, setReviewDetail] = useState(true);
  const [selectedComment, setSelectedComment] = useState(null);
  const [currentOffsetWidth, setCurrentOffsetWidth] = useState(Number);
  let asdasd
  const reviewDetailLayerOpen = (isClick: boolean, comment: Object, e: any) => {
    console.log('1')
    console.log(comment)
    console.log('1')
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      setCurrentOffsetWidth(componentRef.current.offsetWidth);
      
      setReviewDetail(current => !current);
      setSelectedComment(comment);
    }
    if (filteredComment !== null) setFilteredComment(null);
  };

  //후기상세보기레이어팝업 오픈 
  const [reviewImg, setReviewImg] = useState(true);
  const [filteredComment, setFilteredComment] = useState(null);
  const reviewImgLayerOpen = (isClick: boolean) => {
    setReviewImg(current => !current);
  }; 

  const thumbnailClick = commentImage => {
    const a = store.storeDetail.comments.find(comment => comment.storeDetailCommentId === commentImage.storeDetailCommentId);
    setFilteredComment(a);
  };

  useEffect(() => {
    if (filteredComment !== null) {
      // reviewImgLayerOpen(false); // TODO
      reviewDetailLayerOpen(true, filteredComment, null);
    }
  }, [filteredComment]);

  //전체 너비 구하기
  
  const { width, height } = useContainerDimensions(componentRef);
  const slidesToShowSize = Number(currentOffsetWidth / 290).toFixed(1);
  const settings2 = {
    dots: false,
    infinite: false,
    slidesToShow: Number(slidesToShowSize),
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
  };

  return (
    <article ref={componentRef}>
      {store && (
        <>
          <div className="popupdetail-banner">
            <Slider {...settings}>
              {store.storeImage.map((image: any, index) => (
                <div key={index} className="slide-content">
                  <div className="slide-img-wrap">
                    <img src={image.url} alt="ab" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="popupdetail-wrap" style={{ paddingBottom: '100px' }}>
            <ul className="popupdetail-top">
              <li>
                <Link to={`/popup/${storeId}`}>
                  <img src={arrow_left} alt="" style={{ width: '8px' }} />
                </Link>
              </li>
              <li>
                <ul>
                  <li></li>
                  <li></li>
                </ul>
              </li>
            </ul>
            <div className="submenu">
              <Link to={`/popup/${store.storeId}`}>
                <h2>정보</h2>
              </Link>
              <Link to={`/${store.storeId}/comment/list`} className="on">
                <h2>후기</h2>
              </Link>
              {/* <Link to="/user/visit-store">
                  <h2>주변추천</h2>
                </Link> */}
            </div>
            <div className="review-info">
              <p>
                {starAvg ? starAvg.toFixed(1) : 0} <span>({store.storeDetail.comments.length}명참여)</span>
              </p>
              <ul>
                <li>
                  <div>
                    <div>★</div>
                    <p>{starAvg ? starAvg.toFixed(1) : 0}</p>
                  </div>
                </li>
              </ul>
            </div>

            {/* 사진후기 */}
            <div className="review-image-list">
              <div className="moreview-wrap">
                <h3>
                  사진 후기
                  <p>
                    <span>{storeThumbnails.length}</span>
                  </p>
                </h3>
                <div className="moreview">
                  <a
                    onClick={e => {
                      reviewImgLayerOpen(true);
                    }}
                  >
                    모아보기
                  </a>
                </div>
              </div>
              <div className="review-image-area">
                {storeThumbnails.length > 0 &&
                  storeThumbnails.map((image, idx) => (
                    <>
                      {idx < 8 && (
                        <div className="review-image-item">
                          <div
                            className="img-wrap"
                            onClick={e => {
                              reviewImgLayerOpen(true);
                            }}
                          >
                            <img src={image.url} alt="" />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>

            <div className="review-list">
              {store.storeDetail.comments.length < 1 && (
                <>
                  <div className='review-slide-blank'>
                    <img src={cmt} alt="" />
                    <span>등록된 후기가 없습니다.<br /><span className='tit'>첫 번째 후기를 작성해 보세요!</span></span>
                  </div>
                  <div className="button-wrap">
                    <button
                      type="button"
                      onClick={() => {
                        reviewWriteLayerOpen(true);
                      }}
                    >
                      후기 남기기
                    </button>
                  </div>
                </>
              )}
              {store.storeDetail.comments.length > 0 &&
                store.storeDetail.comments.map((comment, idx) => (
                  <>
                    <div
                      className="review-contents"
                      key={idx}
                      onClick={e => {
                        reviewDetailLayerOpen(true, comment, e);
                      }}
                    >
                      <ul>
                        <li>
                          <img src={profile} alt="프로필사진" />
                          <p>
                            <span>
                              <span>
                                <StarRate starAvg={comment.stars} userName={comment.userName} isDetail={false} />
                              </span>
                            </span>
                          </p>
                        </li>
                        <li>
                          <p className="comment">{comment.comment}</p>
                        </li>
                        <li>
                          <ul className="review-image">
                            {/* 최대 1개까지만 이미지가 보이도록 함 */}
                            {comment.storeDetailCommentImages.map((image, idx) => (
                              <>
                                {idx < 1 && (
                                  <li>
                                    <img src={image.url} alt="" />
                                  </li>
                                )}
                                {idx === 2 && (
                                  <li className="more-image">
                                    <div></div>
                                    <img src={image.url} alt="" />
                                  </li>
                                )}
                              </>
                            ))}
                          </ul>
                          {/* TODO */}
                          {/* 버튼 클릭하면 버튼에 thumb-up 클래스 붙어야 함*/}
                          {/* <button type="button">
                            <div className="thumb-wrap">
                              <p>111 &nbsp;&nbsp;&nbsp;</p>
                            </div>
                          </button> */}
                        </li>
                      </ul>
                    </div>
                  </>
                ))}
            </div>
          </div>
          <div className={reviewWriteLayer ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review'}>
            {!myInfo && (
              <>
                <header>
                  <p className="layer-tit">
                    <button
                      type="button"
                      onClick={() => {
                        reviewWriteLayerOpen(false);
                      }}
                    >
                      <img src={btn_close} alt="닫기 버튼" />
                    </button>
                  </p>
                </header>
                <GuestAccess />            
              </>
            )}
            {myInfo && (
              <>
                <header>
                  <p className="layer-tit">
                    후기 작성
                    <button
                      type="button"
                      onClick={() => {
                        reviewWriteLayerOpen(false);
                      }}
                    >
                      <img src={btn_close} alt="닫기 버튼" />
                    </button>
                  </p>
                </header>
                <div className="layer-review-inner">
                  <p>
                    <span>{store.mainBrand}</span>
                    {store.title}
                  </p>
                  <div className="layer-review-inner-write">
                    <span>드래그해서 별점을 매겨주세요!</span>
                    <p className="drag-star">
                      {[1, 2, 3, 4, 5].map(idx => {
                        return <Stars index={idx} key={idx} rating={rating} hoverRating={hoverRating} onSaveRating={onSaveRating} />;
                      })}
                    </p>
                    <div className="review-file-wrap" style={{ position: 'relative', width: '100%' }}>
                      <textarea
                        rows={5}
                        placeholder="방문하신 팝업스토어는 어떠셨나요? 후기를 통해 회원님의 멋진 경험을 다른 분들과 공유해보세요!&#13;&#10;해당 팝업스토어와 관련없는 내용 혹은 이미지를 업로드하신 경우 별도의 통보 없이 삭제 및 혜택이 회수됩니다."
                        onChange={commentHandler}
                        value={comment}
                      />
                    </div>

                    <div className="popup-input-wrap popup-input-file-wrap">
                      <label htmlFor="p_image_upload">
                        <span><img src={photo} alt="사진 아이콘" /> 사진 첨부하기</span>
                        <input type="file" id="p_image_upload" accept="image/*" multiple onChange={e => onUpload(e)} name="files" />
                      </label>
                      <p className="p_input_info_text">* 이미지는 정사이즈를 권장합니다. 가로 또는 세로로 긴 사진의 경우 사진 일부가 잘릴 수 있습니다.</p>
                      <ul className="popup-input-thumbnail-wrap">
                        {/* 이미지 등록하면 계속 늘어나는 형태 */}
                        {imageSrc.length > 0 &&
                          imageSrc.map((img, idx) => {
                            return (
                              <li key={idx}>
                                <div className="thumbnail-img-wrap">
                                  <img src={img} alt="" />
                                </div>
                              </li>
                            );
                          })}
                        {imageSrc.length < 1 && (
                          <>
                            <li>
                              <div className="thumbnail-img-wrap">{/* <img src={slideimg1} alt="" /> */}</div>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>

                    <button type="button" onClick={commentSubmit}>
                      후기 등록
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* 레이어팝업 _ 후기 상세보기 */}
          <div className={reviewDetail ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review detail'}>
            <header>
              <p className="layer-tit">
                후기 상세보기
                <button
                  type="button"
                  onClick={e => {
                    reviewDetailLayerOpen(false, null, e);
                  }}
                >
                  <img src={btn_close} alt="닫기 버튼" />
                </button>
              </p>
            </header>
            <div className="layer-review-inner">
              <div className="review-contents review-contents-layer">
                <ul>
                  <li>
                    <div>
                      <img src={profile} alt="프로필사진" />
                      <p>
                        <span>
                          <StarRate starAvg={selectedComment ? selectedComment.stars : 0} userName={selectedComment ? selectedComment.userName : ''} isDetail={true} />
                        </span>
                      </p>
                    </div>
                    <button
                      type="button"
                      className="notify"
                      onClick={() => {
                        notifyLayerOpen(true);
                      }}
                    >
                      신고
                    </button>
                  </li>
                  <li>
                    <p className="comment">{selectedComment ? selectedComment.comment : ''}</p>
                  </li>
                  <li>
                    <div className="detail-review-image">
                      <Slider {...settings2}>
                          {selectedComment &&
                          selectedComment.storeDetailCommentImages.length > 0 &&
                            selectedComment.storeDetailCommentImages.map((image, idx) => (
                              <>
                                <div className="slide-content review-image-slide">
                                  <div className="slide-img-wrap">
                                          <img key={idx} src={image.url} alt="" />
                                  </div>
                                </div>
                              </>
                            ))}
                      </Slider>
                    </div>
                  </li>
                </ul>
              </div>
              {/* //TODO  */}
              {/* <div className="layer-page">
                <a>
                  <img src={arrow_left} alt="" />
                  이전
                </a>
                <a>
                  다음
                  <img src={arrow_right} alt="" />
                </a>
              </div> */}
            </div>
          </div>
          {/* 사진 모아보기 레이어 팝업 */}
          <div className={reviewImg ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review'}>
            <header>
              <p className="layer-tit">
                사진후기 모아보기
                <button
                  type="button"
                  onClick={() => {
                    reviewImgLayerOpen(false);
                  }}
                >
                  <img src={btn_close} alt="닫기 버튼" />
                </button>
              </p>
            </header>
            <div className="layer-review-inner">
              <ul className="layer-review-image-list">
                {/* 이미지 li 안에 img 태그로 들어가게끔 */}
                {storeThumbnails.length > 0 &&
                  storeThumbnails.map((image, idx) => (
                    <>
                      <li
                        onClick={e => {
                          thumbnailClick(image);
                        }}
                      >
                        <img src={image.url} alt="" />
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </article>
  );
}

export default StoreCommentList;

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
