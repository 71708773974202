import React from 'react';
import { useParams } from 'react-router-dom';
import EventReadContainer from '../../containers/event/EventReadContainer';
import MainLayout from '../../layout/MainLayout';

function EventReadPage({ match }: { match: any }) {
  const promotionId = useParams();
  return (
    <>
      <MainLayout>
        <EventReadContainer promotionId={promotionId} />
      </MainLayout>
    </>
  );
}

export default EventReadPage;
