import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from './auth';
import loading from './loading';
import promotion, { promotionSaga } from './promotion';
import codeDetail, { codeDetailSaga } from './codedetail';
import member, { memberSaga } from './member';
import board, { boardSaga } from './board';
import map, { mapSaga } from './map';
import item, { itemSaga } from './item';
import coin, { coinSaga } from './coin';
import useritem, { userItemSaga } from './useritem';
import magazine, { pdsSaga } from './magazine';
import home, { homeSaga } from './home';
import contact, { contactSaga } from './contact';
import faq, { faqSaga } from './faq';
import popupReport, { popupreportSaga } from './popupreport';
import qna, { qnaSaga } from './qna';
import searchInfo, { searchInfoSaga } from './searchInfo';
import reservation, { reservationSaga } from './reservation';
import userReservation, { userReservationSaga } from './userReservation';
import survey, { surveySaga } from './survey';
import placelist, { placeListSaga } from './placelist';
import generalData, {generalDataSaga} from './generalData';
// 루트 리듀서
const rootReducer = combineReducers({
  auth,
  loading,
  promotion,
  codeDetail,
  member,
  board,
  map,
  item,
  coin,
  useritem,
  pds: magazine,
  home,
  contact,
  faq,
  popupReport,
  qna,
  searchInfo,
  reservation,
  userReservation,
  survey,
  placelist,
  generalData
});
// 루트 사가
export function* rootSaga() {
  yield all([authSaga(), promotionSaga(), codeDetailSaga(), memberSaga(), boardSaga(), mapSaga(), itemSaga(), coinSaga(), userItemSaga(), pdsSaga(), homeSaga(), contactSaga(), faqSaga(), popupreportSaga(), qnaSaga(), searchInfoSaga(), reservationSaga(), userReservationSaga(), surveySaga(), placeListSaga(), generalDataSaga()]);
}

export default rootReducer;

// 루트 리듀서의 반환값을 유추
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용해야 하므로 내보내기
export type RootState = ReturnType<typeof rootReducer>;
