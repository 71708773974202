import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from './../../../assets/img/common/btn_close_black.svg';
import noticeIcon from './../../../assets/img/event-template/notice.png';
import warningIcon from './../../../assets/img/event-template/warning.png';

function EventAlert({ isLogin, myInfo, isHateSpeech, agreePrivacy, changePrivacy, changeHateSpeech }) {
  const [isClose, setIsClose] = useState(false);

  const closeLoginModal = async () => {
    console.log('click');
    setIsClose(true);
  };
  return (
    <>
      {/* 일반 notice alert */}
      <div className="event-alert alert-notice" style={{ display: (isLogin && myInfo) || isClose ? 'none' : 'block' }}>
        <div className="event-alert-wrap">
          <div
            className="alert-close"
            onClick={e => {
              closeLoginModal();
            }}
          >
            <img src={closeIcon} alt="" />d
          </div>
          <div className="alert-header">
            <img src={noticeIcon} alt="" />
            <p>Notice</p>
          </div>
          <div className="alert-inner">
            <p className="alert-tit">이벤트 둘러보기만 가능해요!</p>
            <p className="alert-txt">로그인 후 이벤트 참여 가능합니다 :)</p>

            {/* <p className="alert-txt">0m 이내의 위치에서만 참여 가능한 이벤트입니다. 현재 위치에서는 방문 메시지 남기기 이벤트는 참여할 수 없어요!</p> */}
          </div>
        </div>
      </div>

      {/* 에러 메시지 송출 */}
      <div className="event-alert alert-error" style={{ display: isHateSpeech ? 'block' : 'none' }}>
        <div className="event-alert-wrap">
          <div className="alert-header">
            <img src={warningIcon} alt="" />
            <p>송출 불가!</p>
          </div>
          <div className="alert-inner">
            <p className="alert-tit">부적절한 단어가 포함되어 있어 송출이 불가합니다. 메시지를 다시 확인해주세요.</p>
            <p className="alert-txt">* 욕설, 비방, 혐오 등의 부적절한 표현은 피하고 즐겁고 아름다운 추억을 남겨주세요.</p>
          </div>
          {/* 팝업 닫고, 메시지 내용 지워진 상태에서 커서 가 있어야 함 */}
          <button type="button" className="event-alert-btn" onClick={e => changeHateSpeech(e)}>
            메시지 다시 남기기
          </button>
        </div>
      </div>

      {/* 에러 메시지 송출 */}
      <div className="event-alert alert-error" style={{ display: agreePrivacy === true || agreePrivacy === null || agreePrivacy === undefined ? 'none' : 'block' }}>
        <div className="event-alert-wrap">
          <div className="alert-header">
            <img src={warningIcon} alt="" />
            <p>송출 불가!</p>
          </div>
          <div className="alert-inner">
            <p className="alert-tit">개인정보 수집 및 이용 동의 후 리뷰 전송이 가능합니다.</p>
          </div>
          <button type="button" className="event-alert-btn" onClick={e => changePrivacy(e)}>
            닫기
          </button>
        </div>
      </div>
    </>
  );
}
export default EventAlert;
