import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import MainHeader from '../../components/common/MainHeader';

import { setAccessToken, setMyInfo } from '../../modules/auth';
import client from '../../lib/client';
import storageManager from '../../util/StorageManager';
import { fetchOne } from '../../modules/member';
// login 여부와 로그인한 사용자 정보를 속성값으로 수신

const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV
const MainHeaderContainer = ({ myInfo, member }: { myInfo: any, member: any }) => {

  useEffect(() => {
    if( myInfo ) {
      dispatch(fetchOne(myInfo ? myInfo.userId : null));
    }
  }, [myInfo]);
  
  const dispatch = useDispatch();
  // REST API 서버와 통신하는 모듈에 공통으로 설정한 요청헤더의 Authorization 필드를 삭제한다.
  // 쿠키에 저장된 액세스 토큰을 제거한다.
  // 스토어 상태에 저장된 액세스 토큰을 빈 문자열로 초기화한다.
  // 스토어 상태에 저장된 사용자정보를 무효화한다.
  const onLogout = () => {
    delete client.defaults.headers.common.Authorization;
    storageManager.removeValue("token");
    storageManager.removeValue("user");    
    window.location.href = REDIRECT_HOME;
    dispatch(setAccessToken(''));
    dispatch(setMyInfo(null));
  };

  return <MainHeader myInfo={myInfo} member={member} onLogout={onLogout} />;
};

// 스토어 상태를 가공한 정보를 컴포넌트 속성으로 전달
export default connect((state: any) => {
  return {    
    myInfo: state.auth.myInfo,
    member: state.member.member,
  };
})(MainHeaderContainer);

