import React, { useState } from 'react';
import Calendar from 'react-calendar';
import close from './../../assets/img/common/btn_close.svg';
import dayjs from 'dayjs';

function ReactCalendar({ selectedDate, onDateChange, calendarOpen, startDate, endDate, disabledDate }: any) {
  //   오늘 날짜 클릭
  const today = dayjs();
  const handleDateChange = async date => {
    onDateChange(dayjs(date));
  };
  const handleTodayClick = () => {
    if (today < startDate || today > endDate) {
      alert('예약 가능한 날짜가 아닙니다.');
      return false;
    } else {
      onDateChange(today);
    }
  };

  return (
    <div className="calendar-wrap">
      <div className="calendar-header">
        <button type="button" className="today-btn" onClick={handleTodayClick}>
          Today
        </button>
        <p>예약 날짜를 선택해 주세요.</p>
        <button
          type="button"
          className="close-btn"
          onClick={() => {
            calendarOpen(true);
          }}
        >
          <img src={close} alt="" />
        </button>
      </div>
      <Calendar
        value={selectedDate}
        calendarType="US" // 일요일부터 시작
        locale="ko"
        formatDay={(locale, date) => dayjs(date).format('D')}
        next2Label={null} // 달력에서 >> 안 보이기
        prev2Label={null} // 달력에서 << 안 보이기
        minDate={new Date(startDate.format('YYYY-MM-DD'))} // 날짜 선택 최소값 - 기간설정
        maxDate={new Date(endDate.format('YYYY-MM-DD'))} // 날짜 선택 최대값 - 기간설정
        onChange={handleDateChange} // 예약일자 선택
        // tileDisabled={({ date, view }) => (disabledDate ? disabledDate.includes(dayjs(date).format('YYYY-MM-DD')) : '')}
      />
    </div>
  );
}

export default ReactCalendar;
