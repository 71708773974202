import { useParams } from 'react-router-dom';
import BrandQrContainer from '../containers/BrandQrContainer';
import MainLayout from '../layout/MainLayout';

function PostSurveyRegisterPage({ match }: any) {
  const { storeId, reservationId } = useParams();
  return (
    <>
      <MainLayout>
        <BrandQrContainer storeId={storeId} reservationId={reservationId} />
      </MainLayout>
    </>
  );
}

export default PostSurveyRegisterPage;
