import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactMenus from '../../../components/contact/ContactMenus';
import QnaRegisterForm from '../../../components/contact/qna/QnaRegisterForm';
import * as api from '../../../lib/api';
import { addAttach, removeAttach } from '../../../modules/qna';
import httpStatusHandler from '../../../util/httpStatusHandler';

const QnaRegisterContainer = () => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const navigate = useNavigate();
  const { attachments, myInfo } = useSelector((state: any) => ({
    attachments: state.faq.attachments,
    myInfo: state.auth.myInfo,
  }));

  useEffect(() => {
    if (myInfo) {
      setUser(myInfo);
    }
    // if (window.localStorage.getItem('user')) {
    //   const userObj = JSON.parse(window.localStorage.getItem('user'));
    //   setUser(userObj);
    // }
  }, []);

  const onRegister = async (qna, files) => {
    try {
      const userId = myInfo.userId;
      const response = await api.writeQna({ qna }, userId);
      alert('등록이 완료되었습니다.');
      navigate(`/qna/${response.data.data.qnaId}`);
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  return (
    <>
      <ContactMenus onMenu={'qna'} />
      <QnaRegisterForm attachments={attachments} onRegister={onRegister} myInfo={myInfo} admin={admin} />
    </>
  );
};

export default QnaRegisterContainer;
