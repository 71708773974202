import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import mapRedMarker from './../../assets/img/map/map-red.svg';
import contentMyMarker from './../../assets/img/map/pin_center.png';
import contentMyMarker2 from './../../assets/img/map/pin_outside.png';
import gps from './../../assets/img/map/gps.png';
import nonethumb from './../../assets/img/noneIMG.png';
import styles from './Map.module.scss';
import { getFixedLat, getFixedLng } from '../../util/InusUtils';

function Map({ datas, zoomControl, isStore, updateBounds }: { datas: any; zoomControl: boolean; isStore: boolean; updateBounds?: any }) {
  const mapElement = useRef(null);
  const [myLocation1, setMyLocation] = useState<naver.maps.LatLng | null>(null);
  const navigate = useNavigate();
  const [detailView, setDetailView] = useState(false);
  const [detailViewDatas, setDetailViewDatas] = useState({
    storeId: null,
    mainBrand: null,
    title: null,
    address: null,
    startDate: null,
    endDate: null,
    thumbnails: null,
  });

  setTimeout(function () {
    window.dispatchEvent(new Event('resize')); // 레이어 팝업 발생 시 깨지는 이슈 해결책 resize.
  }, 300);

  useEffect(() => {
    let isMounted = true;
    // Naver Map
    if (!mapElement.current || !naver.maps || !datas) return;

    const markers: naver.maps.Marker[] = [];
    const infoWindows: naver.maps.InfoWindow[] = [];
    // const location = myLocation1 ? myLocation1 : new naver.maps.LatLng(36.54283, 126.0589872);
    const location = myLocation1 ? myLocation1 : new naver.maps.LatLng(37.544641, 127.05589);
    const mapOptions: naver.maps.MapOptions = {
      center: location,
      zoom: 13,
      mapDataControl: false,
      zoomControl,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);    
    naver.maps.Event.addListener(map, 'bounds_changed', function(bounds) {      
      console.log('bounds_changed', bounds);
      if( updateBounds ) {                
        /* min : 좌측 하단, max : 우측 상단 */
        if( map.getBounds().getMin().y !== map.getBounds().getMax().y ) {
          updateBounds(map.getBounds());
        }
      }
    });    
    let currentLocation = null;
    if (isStore) {
      const cc = new naver.maps.LatLng(getFixedLat(datas), getFixedLng(datas)); 
      currentLocation = cc;           
    } else if (navigator.geolocation && !isStore) {
      navigator.geolocation.getCurrentPosition(position => {
        const cc = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
        if (isMounted) {    
          currentLocation = cc;    
          const marker1 = new naver.maps.Marker({
            position: currentLocation, // TODO
            map,
            animation: naver.maps.Animation.DROP,
            icon: {
              content: `<div class=${styles.myPin}><img src=${contentMyMarker} alt="내 위치" class=${styles.centerPin} /><img src=${contentMyMarker2} alt="" class=${styles.outsidePin} /></div>`,
            },
          });   
          map.setCenter(new naver.maps.LatLng(currentLocation));   
          //setMyLocation(cc);
        }
      });
    } 

    if (!isStore) {
      for (let i = 0; i < datas.length; i++) {
        const popupTitle = `<div class="naver-container img-container"><img src=${datas[i].thumbnails ? datas[i].thumbnails : nonethumb} /></div>`;
        const content = `<div class="naver-marker-wrap"> <img src=${mapRedMarker} alt="팝업스토어 위치"/><div class="naver-marker-popup-name" style='color:black'>${datas[i].name}</div></div>`;
        const otherMarkers = new naver.maps.Marker({
          position: new naver.maps.LatLng(getFixedLat(datas[i]), getFixedLng(datas[i])),
          map,
          icon: {
            content,
            size: new naver.maps.Size(16, 23),
          },
        });
        const infoWindow = new naver.maps.InfoWindow({
          content: popupTitle,
          backgroundColor: '#ffffff00',
          borderColor: '#ffffff00',
          disableAnchor: true,
          pixelOffset: new naver.maps.Point(0, -10),
        });
        markers.push(otherMarkers);
        infoWindows.push(infoWindow);
        naver.maps.Event.addListener(markers[i], 'click', getClickHandler(markers, infoWindows, map, i, datas[i]));
      } 
    } else if (isStore) {
      // const content = `<div class="naver-marker-wrap"> <img src=${mapRedMarker} alt="팝업스토어 위치"/></div></div>`;
      const datasMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(getFixedLat(datas), getFixedLng(datas)),
        map,
        icon: {
          content: `<div class="naver-marker-wrap"> <img src=${mapRedMarker} alt="팝업스토어 위치"/><div class="naver-marker-popup-name naver-marker-popup-name-full-name" style='color:black'>${datas.name}</div></div>`,
          size: new naver.maps.Size(16, 23),
        },
      });
      map.setCenter(new naver.maps.LatLng(getFixedLat(datas), getFixedLng(datas)));
    }
    
    let locationBtnHtml = `<img style="cursor:pointer;" src=${gps} alt="내 위치"/>`;
    naver.maps.Event.once(map, 'init', function () {
      let myLocation = new naver.maps.CustomControl(locationBtnHtml, {
        position: naver.maps.Position.LEFT_BOTTOM,
      });
      myLocation.setMap(map);
      naver.maps.Event.addDOMListener(myLocation.getElement(), 'click', function () {        
        map.setCenter(currentLocation);
      });     
    });    
    return () => {
      isMounted = false;
    };
    // setMarker(marker1);
  }, [datas]);

  const getClickHandler = (markers, infoWindows, map, seq: number, datas) => {
    return () => {
      const marker = markers[seq];
      const infoWindow = infoWindows[seq];

      if (infoWindow.getMap()) {
        infoWindow.close();
      } else {
        infoWindow.open(map, marker);
        map.setZoom(14, true);
        map.panTo(marker.position);
      }
      setDetailView(true);
      setDetailViewDatas(datas);
    };
    // 인포레이어 데이터 추가
  };  
  return (
    <>
      {/* <article className="map-area-wrap" ref={mapWrapper}> */}
      <article className="map-area-wrap">
        <div ref={mapElement} className="map-area" />
        {/* 상세페이지 마커 클릭 시 이 부분에 상세 정보 나와야 한다. */}
        {detailViewDatas && (
          <div
            className="map-info-layer"
            style={{ display: detailView === true ? 'block' : 'none' }}
            onClick={() => {
              navigate(`/popup/${detailViewDatas.storeId}`);
            }}
          >
            <div className="map-info-layer-inner">
              <div className="thumbnail-wrap" style={{ backgroundImage: `url(${detailViewDatas.thumbnails ? detailViewDatas.thumbnails : nonethumb})` }}></div>
              <div className="info-wrap">
                <p className="brand-name">{detailViewDatas.mainBrand}</p>
                <p className="popup-title">{detailViewDatas.title}</p>
                <p className="popup-address">{detailViewDatas.address}</p>
                <p className="popup-date">
                  {dayjs(detailViewDatas.startDate).format('YY.MM.DD')} - {dayjs(detailViewDatas.endDate).format('YY.MM.DD')}
                </p>
              </div>
            </div>
          </div>
        )}
      </article>
    </>
  );
}

export default Map;
