import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupList from '../../components/popup/PopupList';
import Search from '../../components/common/SearchBar';

// import Search2 from '../../components/common/SearchBar2';
import { FETCH_LIST, fetchSearchList } from '../../modules/board';
import { useLocation, useSearchParams } from 'react-router-dom';

const PopupListContainer = ({ query, filters } : {query: any, filters?: any}) => {
  const dispatch = useDispatch();
  const location = useLocation();  
  const [isBottom, setIsBottom] = useState(false);



  const { stores, isLoading, searchStores, page, myInfo } = useSelector(({ auth,board, loading, searchStores }: any) => ({
    stores: board.boards,
    searchStores: board.searchStores,
    isLoading: loading[FETCH_LIST],
    page: board.page,
    myInfo: auth.myInfo,
  }));

  if (stores.data && searchStores.length > 0) {
    stores.data = [...stores.data, ...searchStores];
  }

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => window.removeEventListener('scroll', handleUserScroll);
  }, []);

  // useEffect(() => {
  //   alert('location');
  //   console.log(location.search);
  //   if (!location.search) {
  //     alert(false);
  //     dispatch(fetchList(myInfo ? myInfo.userId : null));
  //   } else {
  //     alert(true);
  //     dispatch(fetchSearchList(location.search, myInfo ? myInfo.userId : null));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isBottom) {
  //     if (stores.data && stores.data.length) {
  //       dispatch(fetchListMore(page + 1));
  //     } else {
  //       // fetch another batch
  //       // dispatch(fetchList());
  //     }
  //     setIsBottom(false);
  //   }
  // }, [isBottom, dispatch, setIsBottom]);
  
  const [searchParams, ] = useSearchParams();

  useEffect(() => {
    console.log('PopupListContainer = searchParams', searchParams.toString());
    dispatch(fetchSearchList(searchParams.toString(), myInfo ? myInfo.userId : null));
  }, [searchParams]);

  function handleUserScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + window.innerHeight + 20 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  return (    
    <article className="inner">
      <Search />
      <PopupList stores={stores.data} isLoading={isLoading} query={location.search} myInfo={myInfo} filters={filters}/>
    </article>    
  );
};

export default PopupListContainer;
