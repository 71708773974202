import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../lib/api';
import httpStatusHandler from '../../util/httpStatusHandler';
import { isAdmin as hasRoleAdmin } from '../../modules/selector';
import MagazineRead from '../../components/magazine/MagazineRead';
import magazine, { fetchAttachList, fetchOne, FETCH_ONE, resetAttach } from '../../modules/magazine';
import Footer from '../../components/common/Footer';

const MagazineReadContainer = ({ magazineId }: any) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  // const { magazineItem, isLoading, isAdmin } = useSelector((state: any) => ({
  //   magazineItem: state.magazine.magazineItem,
  //   isLoading: state.loading[FETCH_ONE],
  //   isAdmin: hasRoleAdmin(state),
  // }));

  return (
    <>
      <MagazineRead magazineId={Number(magazineId)} />
      <Footer />
    </>
  );
};

export default MagazineReadContainer;
