import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ShortTextForm({ question, changeInformation }) {
  const [query, setQuery] = useState('');
  const WAIT_TIME = 2000;
  useEffect(() => {
    const timeOutId = setTimeout(() => changeInformation({ surveyQuestionId: question.surveyQuestionId, surveyQuestionText: query }, 'text'), WAIT_TIME);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <>
      {question && (
        <div className="q-wrap">
          <p className="q-tit">{question.title}</p>
          <div className="a-wrap">
            <input type="text" required placeholder="텍스트를 입력해주세요" onChange={event => setQuery(event.target.value)} value={query} />
          </div>
        </div>
      )}
    </>
  );
}

export default ShortTextForm;
