import VisitStoreListContainer from '../../containers/member/VisitStoreListContainer';
import MainLayout from '../../layout/MainLayout';

export default function VisitStoreListPage() {
  return (
    <>
      <MainLayout>
        <VisitStoreListContainer />
      </MainLayout>
    </>
  );
}
