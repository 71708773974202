import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';

import sort_icon from './../../assets/img/common/sort-icon.svg';
import filter_icon from './../../assets/img/common/filter-icon.svg';

import calImg from './../../assets/img/cal-pop.png';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import bookmark from './../../assets/img/popup/bookmark.svg';
import bookmark_on from './../../assets/img/popup/bookmark_on.svg';
import Footer from '../common/Footer';
import searchbg from './../../assets/img/layout/search-bg.svg';
import Bookmark from '../common/Bookmark';
import { useSelector } from 'react-redux';

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noreferrer');
};
function VisitStoreList({ stores, isLoading }: any) {

  const { myInfo } = useSelector(({auth}: any) => ({myInfo : auth.myInfo}));
  //인기순~
  const [filterSelect, setfilterSelect] = React.useState(true);
  const filterClick = (isClick: boolean) => {
    setfilterSelect(current => !current);
  };

  //진행중~
  const [statusSelect, setStatusSelect] = React.useState(true);
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };

  return (
    <>
      <article className="inner" style={{ paddingBottom: '100px' }}>
        <div className="submenu">
          {/*클릭하면 클래스 on 붙어야 함*/}
          <Link to="/member">
            <h2>내 정보 </h2>
          </Link>
          <Link to="/user/save-store">
            <h2>관심팝업 </h2>
          </Link>
          {/* <Link to="/user/visit-store" className="on">
            <h2>다녀온 팝업 </h2>
          </Link> */}
          <Link to="/member/reservation/pre">
            <h2>사전예약 </h2>
          </Link>
          {/* <Link to="/user/reservation/onSite">
            <h2>현장예약 </h2>
          </Link> */}
        </div>
        {isLoading && '로딩중...'}
        {!isLoading && stores && (
          <>
            <div className="search-box search-box-cont">
              <header>
                <p>
                  <input type="search" placeholder="지역, 팝업스토어명, 테마 키워드 검색" />
                  <a>
                    <img src={searchbg} alt="" />
                  </a>
                </p>
              </header>
            </div>
            <div className="popuplist-filter">
              <p
                onClick={() => {
                  statusClick(true);
                }}
              >
                진행 중 <img src={sort_icon} />
              </p>
              <p
                onClick={() => {
                  filterClick(true);
                }}
              >
                <img src={filter_icon} /> 인기 순
              </p>
            </div>
            <div className={statusSelect ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
              <ul>
                {/*클릭하면 다른 li에는 select가 없어지고 클릭한 요소에만 select클래스 추가*/}
                <li className="select">진행 중</li>
                <li>사전예약 중</li>
                <li>오픈 예정</li>
                <li>종료</li>
              </ul>
            </div>
            <div className={filterSelect ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
              <ul>
                {/*클릭하면 다른 li에는 select가 없어지고 클릭한 요소에만 select클래스 추가*/}
                <li className="select">인기 순</li>
                <li>추천 순</li>
                <li>리뷰 많은 순</li>
                <li>별점 높은 순</li>
              </ul>
            </div>
            <div className="calendar-popup-list popuplist-board">
              <ul>
                {stores != null &&
                  !!stores.length &&
                  stores.map((store: any, index) => PopupVisitStoreCard(index, store, myInfo))
                }
              </ul>
            </div>
          </>
        )}
      </article>
    </>
  );

  function PopupVisitStoreCard(index: any, store: any, myInfo: any) {    
    return <li key={index}>
      <div>
        <Link to={`/popup/${store.storeId}`}>
          <img src={calImg} alt="" className="popup-img" />
        </Link>
        <Link to={`/popup/${store.storeId}`}>
          <ul>
            <li className="popup-name">
              <p>{store.title}</p>
            </li>
            <li className="popup-location">
              <img src={slidemapicon} /> {store.topLevelAddress}
            </li>
          </ul>
        </Link>
        <Bookmark store={store} myInfo={myInfo} />
        <img src={bookmark_on} alt="" className="popup-bookmark" />
        <p className="popup-date">
          {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
        </p>
      </div>
    </li>;
  }
}

export default VisitStoreList;
