import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../../components/common/Footer';
import Business from '../../components/business/Business';
import { useNavigate } from 'react-router-dom';

const BusinessContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // window.open('https://biz.popply.co.kr');
    navigate('/');
  }, []);
  return (
    <>
      <Business />

      <Footer />
    </>
  );
};

export default BusinessContainer;
