import QnaListContainer from '../../../containers/contact/qna/QnaListContainer';
import MainLayout from '../../../layout/MainLayout';

function QnaListPage() {
  return (
    <>
      <MainLayout>
        <QnaListContainer />
      </MainLayout>
    </>
  );
}

export default QnaListPage;
