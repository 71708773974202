import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactList from '../../components/contact/ContactList';
import ContactMenus from '../../components/contact/ContactMenus';
import { fetchList, FETCH_LIST } from '../../modules/contact';
import { isAdmin as hasRoleAdmin } from '../../modules/selector';
import Footer from '../../components/common/Footer';

const ContactListContainer = () => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  // 스토어 상태 조회
  const { noticeList, isLoading, isAdmin } = useSelector((state: any) => ({
    noticeList: state.contact.contacts,
    isLoading: state.loading[FETCH_LIST],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <ContactMenus onMenu={'contact'} />
      <ContactList noticeList={noticeList} isLoading={isLoading} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default ContactListContainer;
