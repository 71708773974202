import { useDispatch, useSelector } from "react-redux";
import { fetchGeneralDataList } from "../modules/generalData";
import { useEffect } from "react";
import { authMe, setAccessToken, setMyInfo } from "../modules/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import storageManager from "../util/StorageManager";

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const KAKAO_OAUTH = process.env.REACT_APP_KAKAO_OAUTH;

/**PROD */
const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI; // PROD
// const KAKAO_REDIRECT_URI = process.env.REACT_APP_DEV_KAKAO_REDIRECT_URI; // DEV
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

const AuthProvider = ({children}) =>{  

  const navigate = useNavigate();
  const {myInfo} = useSelector(({auth}: any) => ({ myInfo: auth.myInfo })); 
  const dispatch = useDispatch();
  const [searchParams, ] = useSearchParams();
  const secretCode = searchParams.get('secretCode');

 
  const getJwtTokenHandler = async ({ kakaoToken, apple_jwt_token, secretToken, state }: 
    { kakaoToken?: string, apple_jwt_token?: string, secretToken?: string, state: string} ) => {
      console.log('getJwtTokenHandler', kakaoToken, apple_jwt_token, secretToken, state);
    const res = await axios.post(KAKAO_REDIRECT_URI, { access_token: kakaoToken, apple_jwt_token, secretToken, state });
    console.log('getJwtTokenHandler', res);
    if (res.status === 201 || res.status === 200) {
      const loginUser = res.data.user;
      console.log('getAppleJwtTokenHandler', res.data);
      storageManager.setValue('token',{access_token: res.data.jwt,});
      //window.localStorage.setItem('user', JSON.stringify(loginUser));
      //window.localStorage.setItem('token',JSON.stringify({access_token: res.data.jwt,}),);  
      setMyInfo(loginUser);      
      console.log('state:', state);
      if (state !== '/' && state !== '/member/login') {
        navigate(state);
      } else {
        window.location.href = REDIRECT_HOME;
      }
    } else {
      window.alert('로그인에 실패하였습니다.');
    }
    return res;
  };

  

  const getKakaoTokenHandler = async (code: string, state: string) => {
    const data: any = {
      grant_type: 'authorization_code',
      client_id: KAKAO_CLIENT_ID,
      redirect_uri: REDIRECT_HOME,
      code: code,
    };
    const queryString = Object.keys(data)
      .map((k: any) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
      .join('&');

    //토큰 발급 REST API
    const res = await axios.post(KAKAO_OAUTH, queryString, { headers: { 'Content-type': 'application/x-www-form-urlencoded;charset=utf-8' } });
    console.log("KAKAO TOKEN ", res.data);
    const loginRes = await getJwtTokenHandler( { kakaoToken: res.data.access_token, state} );
    return loginRes;
  };


  useEffect(() => {
    if (searchParams.has('secretCode')) {
      console.log('secretCode:', searchParams.get('secretCode'));
      getJwtTokenHandler( { secretToken: secretCode.toString(), state: searchParams.get('state')});      
    }
  }, [searchParams, secretCode]);

  useEffect(() => {
    if (searchParams.has("code")) {
      console.log('query:', searchParams.get("code"), searchParams.has("code"));
      getKakaoTokenHandler(searchParams.get("code"), searchParams.get('state'));
    }         
  }, [searchParams]);
  useEffect(() => {       
    if(searchParams.has("apple_jwt_token")) {          
      getJwtTokenHandler( {apple_jwt_token: searchParams.get("apple_jwt_token") , state: searchParams.get('state')});      
    }
  }, [searchParams]);
  
  useEffect(() => {
    if( myInfo ) {      
      dispatch(fetchGeneralDataList("LnkUserStore",  {userId: myInfo.userId} ));
      storageManager.setValue("myInfo", myInfo);      
    } else {
      
    }
  }, [myInfo]);

  useEffect(() => {
    storageManager.getValueAsync("token").then( (token) =>
      {       
        if(!myInfo  && token != null) {
          console.log("Call AuthMe: ", myInfo, token );
          dispatch(authMe())      
        }
      }
    )
  }, [myInfo])

  return <>{children}</>;
}

export default AuthProvider;