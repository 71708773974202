import React from 'react';
import { Link } from 'react-router-dom';

// 로그인 레이아웃 템플릿의 헤더영역 표시
function HomeHeader() {
  return (
    <article className="inner">
      <header className="title-div">
        {/* algin center */}
        <Link to="/">&lt; 메인페이지</Link>
      </header>
    </article>
  );
}

export default HomeHeader;
