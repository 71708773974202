import React, { useCallback, useState } from 'react';
import blank from './../../assets/img/popup/blank-list.png';
import parse from 'html-react-parser';

function PopupListBlank({ keyword }) {
  // const text = new URLSearchParams(queryText);
  // const keyword = text.get('query');

  return (
    <>
      <div className="blank-list">
        <img src={blank} alt="" />
        <p className="keyword-wrap">
          ❝ <span className="keyword">{keyword}</span> ❞에 맞는 결과가 없네요
        </p>
      </div>
    </>
  );
}
export default PopupListBlank;
