import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import sort_icon from './../../assets/img/common/sort-icon.svg';
import PopupSlider from '../common/PopupSlider';
import { sortOptions, statusOptions } from '../../types/PopplyTypes';


// 사전예약 링크 연결
const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noreferrer');
};

function PopupSearchList({ stores, myInfo, isLoading, query, searchStatusOption }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, ] = useSearchParams();
  const [sortOption, setSortOption] = useState(sortOptions.find(s => s.value = searchParams?.get("sort"))??sortOptions[0]); // 정렬 기본값: 인기순  
  const [statusOption, setStatusOption] = useState(statusOptions.find(s => s.value == searchParams?.get("status"))??statusOptions[1]); // 기간 기본값: 모든 기간
 
  const [sortSelect, setSortSelect] = useState(true); // 인기순 / 추천순 / 리뷰많은 순 / 별점 높은 순
  const [statusSelect, setStatusSelect] = useState(true); // 전체 / 진행중 / 오픈 예정 / 종료

  // const sortClick = (isClick: boolean) => {
  //   setSortSelect(current => !current);
  //   if (!statusSelect) statusClick(false);
  // };
  // const sortChangeClick = option => {
  //   setSortOption(option);
  //   sortClick(false);
  //   // setFilter(filter => ({ ...filter, sort: option.value }));
  //   searchParams.set('sort[]', option.value);
  //   navigate(`${location.pathname}?${searchParams.toString()}`);
  // };
  useEffect(() => {
    if (searchStatusOption) {
      setStatusOption(statusOptions.find(status => status.value === searchStatusOption));
    }
  }, [searchStatusOption]);
  // useEffect(() => {
  //   if (searchParams.get('status') === 'all') {
  //     setStatusOption({ name: '전체', value: 'all' });
  //   }
  // }, [searchParams]);

  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
    // if (!sortSelect) sortClick(false);
  };

  const statusChangeClick = option => {
    setStatusOption(option);
    statusClick(false);
    if (option.value !== null) searchParams.set('status', option.value);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <article>
      {isLoading && '로딩중...'}
      <div
        className="popuplist-filter popup-search-filter"
        onClick={() => {
          statusClick(true);
        }}
      >
        <p>{statusOption.name}</p>
        <img src={sort_icon} className={statusSelect ? 'on' : ' '} />
      </div>
      <div className={statusSelect ? 'popup-slide-container on' : 'popup-slide-container'}>
        <PopupSlider stores={stores} myInfo={myInfo} statusOption={statusOption} />
      </div>
    </article>
  );
}

export default PopupSearchList;
