import { useParams } from 'react-router-dom';
import ReservationRegisterContainer from '../../../containers/popup/reservation/ReservationRegisterContainer';
import MainLayout from '../../../layout/MainLayout';

function PreReservationDetailPage({ match }: any) {
  const { storeId, hash } = useParams();
  return (
    <>
      <MainLayout>
        <ReservationRegisterContainer storeId={storeId} type={'pre'} hash={hash} />
      </MainLayout>
    </>
  );
}

export default PreReservationDetailPage;
