import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import * as api from '../lib/api';
import createRequestSaga from '../lib/createRequestSaga';


// 액션 타입
export const FETCH = 'generalData/FETCH';
const FETCH_SUCCESS = 'generalData/FETCH_SUCCESS';
const FETCH_FAILURE = 'generalData/FETCH_FAILURE';

export const UPDATE = 'generalData/UPDATE';
const UPDATE_SUCCESS = 'generalData/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'generalData/UPDATE_FAILURE';

export const DELETE = 'generalData/DELETE';
const DELETE_SUCCESS = 'generalData/DELETE_SUCCESS';
const DELETE_FAILURE = 'generalData/DELETE_FAILURE';

export const CREATE = 'generalData/CREATE';
const CREATE_SUCCESS = 'generalData/CREATE_SUCCESS';
const CREATE_FAILURE = 'generalData/CREATE_FAILURE';

export const FETCH_LIST = 'generalData/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'generalData/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'generalData/FETCH_LIST_FAILURE';

// 액션 생성 함수
export const fetchGeneralData = createAction(FETCH, (dataType: string,  idx: any) => ({dataType, idx}));
export const fetchGeneralDataList = createAction(FETCH_LIST, (dataType: string, data: any) => ({dataType, data}));
export const createGeneralData = createAction(CREATE, (dataType: string, data: any) => ({dataType, data}));
export const updateGeneralData = createAction(UPDATE, (dataType: string, data: any) => ({dataType, data}));
export const deleteGeneralData = createAction(DELETE, (dataType: string, data: any) => ({dataType, data}));

// 비동기 액션을 수행하는 태스크 작성

export const fetchGeneralDataSaga = createRequestSaga(FETCH, api.fetchGeneralData);
export const fetchGeneralDataListSaga = createRequestSaga(FETCH_LIST, api.fetchGeneralDataList);
export const createGeneralDataSaga = createRequestSaga(CREATE, api.createGeneralData);
export const updateGeneralDataSaga = createRequestSaga(UPDATE, api.updateGeneralData);
export const deleteGeneralDataSaga = createRequestSaga(DELETE, api.deleteGeneralData);


// 코드그룹 사가 함수 작성
export function* generalDataSaga() {
  yield takeLatest(FETCH, fetchGeneralDataSaga);
  yield takeLatest(FETCH_LIST, fetchGeneralDataListSaga);
  yield takeLatest(CREATE, createGeneralDataSaga);
  yield takeLatest(UPDATE, updateGeneralDataSaga);
  yield takeLatest(DELETE, deleteGeneralDataSaga);  
}

// 초기 상태
const initialState = {
  generalData : { lnkUserStore : [] },    
  error: null,
};

// 리듀서 함수 정의 (상세 조회 상태인 codeGroup 변화를 일으킨다.)
const qna = createReducer(initialState, {

  [FETCH_LIST]: state => ({
    ...state,    
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => {
    const data = {  [action.payload.path] : action.payload.data??[] };
    return {
      ...state,
      generalData: { ...state.generalData, ...data },    
    }
  },
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [CREATE]: state => ({
    ...state,
  }),
  [CREATE_SUCCESS]: (state, action) => {
    const data = {  [action.payload.path] : [ ...state.generalData[action.payload.path], action.payload.data??[] ] };
    return {
      ...state,
      generalData: { ...state.generalData, ...data },    
    }
  },
  [CREATE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [UPDATE]: state => ({
    ...state,
  }),
  [UPDATE_SUCCESS]: (state, action) => {
    const data = {  [action.payload.path] : [ ...state.generalData[action.payload.path], action.payload.data??[] ] };
    return {
      ...state,
      generalData: { ...state.generalData, ...data },    
    }
  },
  [UPDATE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [DELETE]: state => ({
    ...state,
  }),
  [DELETE_SUCCESS]: (state, action) => {
    const updatedData = state.generalData[action.payload.path].filter(item => item.idx !== action.payload.data.idx);
    const updatedGeneralData = { ...state.generalData, [action.payload.path]: updatedData };
    return {
      ...state,
      generalData: updatedGeneralData,
    }
  },
  [DELETE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
});

export default qna;
