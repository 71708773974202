import { useEffect, useState } from 'react';
import MagazineSlide from '../MagazineSlide';

// 인사이트5 - 연예, 콘텐츠, 취미/여가
import awards5 from './../../../assets/magazine/magazine5/ent_main.jpg';
import eSlide1 from './../../../assets/magazine/magazine5/ent_slide1.jpg';
import eSlide2 from './../../../assets/magazine/magazine5/ent_slide2.jpg';
import eSlide3 from './../../../assets/magazine/magazine5/ent_slide3.jpg';
import eSlide4 from './../../../assets/magazine/magazine5/ent_slide4.jpg';
import eReason from './../../../assets/magazine/magazine5/eReason.png';
import contMain from './../../../assets/magazine/magazine5/cont_main.jpg';
import cSlide1 from './../../../assets/magazine/magazine5/cont_slide1.jpg';
import cSlide2 from './../../../assets/magazine/magazine5/cont_slide2.jpg';
import cSlide3 from './../../../assets/magazine/magazine5/cont_slide3.jpg';
import cSlide4 from './../../../assets/magazine/magazine5/cont_slide4.jpg';
import cSlide5 from './../../../assets/magazine/magazine5/cont_slide5.jpg';
import cReason from './../../../assets/magazine/magazine5/cReason.png';
import hdMain from './../../../assets/magazine/magazine5/hd_main.jpg';
import hdSlide1 from './../../../assets/magazine/magazine5/hd_slide1.jpg';
import hdSlide2 from './../../../assets/magazine/magazine5/hd_slide2.jpg';
import hdSlide3 from './../../../assets/magazine/magazine5/hd_slide3.jpg';
import hdSlide4 from './../../../assets/magazine/magazine5/hd_slide4.jpg';
import hdSlide5 from './../../../assets/magazine/magazine5/hd_slide5.jpg';
import hReason from './../../../assets/magazine/magazine5/hReason.png';

function MagazineReadDetail5() {
  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // 틱톡임베드
  // const TikTokEmbed = ({ videoId }) => {
  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.src = 'https://www.tiktok.com/embed.js';
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);

  //   return (
  //     <blockquote className="tiktok-embed" cite={`https://www.tiktok.com/@danimarielettering/video/${videoId}`} data-video-id={videoId} style={{ maxWidth: '605px', minWidth: '325px' }}>
  //       <section>
  //         <a target="_blank" title="@danimarielettering" href={`https://www.tiktok.com/@danimarielettering?refer=embed`}></a>
  //         {/* Thirsty after you catch on fire? @Stanley 1913 is like no problem i gotchu */}
  //         <a title="fyp" target="_blank" href={`https://www.tiktok.com/tag/fyp?refer=embed`}></a>
  //         <a title="carfire" target="_blank" href={`https://www.tiktok.com/tag/carfire?refer=embed`}></a>
  //         <a title="accident" target="_blank" href={`https://www.tiktok.com/tag/accident?refer=embed`}></a>
  //         <a title="stanleycup" target="_blank" href={`https://www.tiktok.com/tag/stanleycup?refer=embed`}></a>
  //         <a target="_blank" title="♬ original sound - Danielle" href={`https://www.tiktok.com/music/original-sound-${videoId}?refer=embed`}></a>
  //       </section>
  //     </blockquote>
  //   );
  // };

  return (
    <>
      {/* 썸네일 */}
      <div className="magazine-detail-header">
        <h2>MAGAZINE</h2>
        <h1>MZ세대가 선정한 올해의 팝업스토어 - 연예·콘텐츠·취미/여가 편</h1>
        <ul>
          <li>
            <span>2024.01.02</span>
          </li>
          {/* <li>
                <span>·</span>
              </li>
              <li>
                <span>조회수</span>
                <span>102</span>
              </li>
              <li>
                <span>·</span>
              </li>
              <li>
                <span>좋아요</span>
                <span>5</span>
              </li> */}
        </ul>
      </div>

      {/* 매거진 본문내용 -> 글자는 <p> 태그로 감쌀 수 있도록 해주세여 */}
      <div className="magazine-detail-text-wrap">
            <div className="magazine-detail-content">
              <p className="big-text">연예</p>
              <p>Bunnyland 팝업스토어</p>
              <img src={awards5} alt="Bunnyland 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">뉴진스와 스포티파이가 만나 'MZ세대 취향저격 팝업스토어' 등장!<br />'버니랜드 팝업스토어'</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[eSlide1, eSlide2, eSlide3, eSlide4]} />
              <p>음원 스트리밍 서비스 스포티파이가 4세대 인기 걸그룹 뉴진스와 함께 팝업스토어를 진행했다. 팝업스토어는 총 5가지 미션을 완수해야 하는 코스로 구성되었는데, 그중 플레이리스트 플레이 스테이션, 버니 비치 등 주요 코스는 스포티파이 앱을 통해 참여하는 미션들로 채워졌다. 미션을 완수하면 뉴진스 곡 플레이리스트 코드가 새겨진 키치한 디자인의 포토카드, 카세트 모양의 버니랜드 마스킹 테이프, 베케이션 패키지, 토이카메라, 키링 등 풍성한 구성의 한정판 굿즈들을 무료로 증정해 뉴진스 팬덤 및 MZ세대 사이에서 큰 화제를 모았다.</p>
              <p>스포티파이와 뉴진스가 탄생시킨 캐릭터 '스포티버니니' 도 긍정적인 반응을 불러왔다. '스포티파이'와 뉴진스 팬덤명인 '버니즈' 를 합쳐 만든 스포티버니니는 몸통과 헤드셋에 스포티파이 로고를 달고 있는 토끼 캐릭터로, 팝업스토어 내에서 증정하는 굿즈 및 내부 곳곳의 오브제로 꾸며져 귀엽고 키치한 무드를 선호하는 방문객들의 취향을 저격해 SNS상에서 긍정적인 반응을 일으켰다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={eReason} alt="Bunnyland 팝업스토어 선택 이유 중 1위는 '이벤트가 새롭고 재밌었어요'이다." />
              <p>그 결과 2만 명의 방문자가 버니랜드 팝업스토어를 다녀갔을 뿐만 아니라, 네이버와 인스타그램을 통해 13,000여 개의 컨텐츠가 발행되는 등 큰 화제를 모을 수 있었다. 또한 어워즈에서도 유저들의 24%가 선정 사유로 ‘이벤트’를, 23%가 포토존을, 22%가 브랜드를 뽑으며 연예 부문 대상을 차지할 수 있었다.</p>
              <p>버니랜드 팝업스토어는 스포티파이 앱을 이용한 이벤트에 참여하면 굿즈를 제공함으로써, 스포티파이는 자연스럽게 브랜드 인지도 및 유저를 확보할 수 있었으며, 뉴진스 또한 신곡 화제성 및 스트리밍 수 모두 잡을 수 있었다. 굿즈와 앱 서비스를 연계한 전략적인 이벤트, 그리고 MZ세대의 취향을 저격한 키치한 Y2K 감성의 공간 구성이 SNS 화제성에 힘을 보태면서, 두 브랜드 모두 긍정적인 효과를 얻을 수 있었던 것으로 보여진다.</p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">콘텐츠</p>
              <p>더 퍼스트 슬램덩크 팝업스토어</p>
              <img src={contMain} alt="더 퍼스트 슬램덩크 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className='big-text'>영화는 거들 뿐, 팝업스토어로 세대 통합 일궈낸 ‘슬램덩크’</p>
              <MagazineSlide settings={commonSettings} images={[cSlide1, cSlide2, cSlide3, cSlide4, cSlide5]} />
              <p>지난  1월,  슬램덩크가 &lt;더 퍼스트 슬램덩크&gt; 영화 개봉과 함께 12일간 더현대 서울에서 팝업스토어를 진행했다. 팝업스토어는 농구 코트와 라커룸를 연상시키는 공간으로 꾸며졌으며 강백호, 송태섭, 채수, 정대만 등 각 슬램덩크 캐릭터들의 굿즈 판매와 포토존을 중심으로 구성되었다.</p>
              <p>특히 굿즈 존에서는 일본에서 수입한 슬램덩크 캐릭터들의 유니폼과 피규어 등 200여 종의 한정판 굿즈 뿐만 아니라, 국내 브랜드와 컬래버레이션 한 60여 종의 액세서리 굿즈, 의류 및 잡화 굿즈 20여 종 등 다양한 굿즈를 선보였는데, 당시 영화 누적 관객 수 167만 명을 돌파해 이른바 '슬램덩크 열풍'이 불며, 캐릭터들의 한정판 유니폼과 피규어를 구매하기 위한 대기행렬이 오픈 시간 전부터 이어졌다. 또한 슬램덩크로 인해 농구용품 및 원작 만화에 대한 구매율도 함께 증가했는데, 원작 만화 판매율이 영화 개봉 한 달 만에 2000% 이상 증가한 것으로 밝혀져 영화를 통해 슬램덩크를 처음 접하게 된 1020 팬들이 자연스럽게 원작에 대한 소장 욕구 및 농구에 대한 관심으로 연결된 것으로 보여진다.</p>
              <p>슬램덩크는 국내에서는 1990년대 만화를 통해 이름을 알리며 남성들 사이에서 선풍적인 인기를 끌었는데, 이번 팝업스토어는 당시의 추억과 동심을 가진 3040 고객뿐만 아니라, 영화를 통해 새롭게 유입된 1020세대 고객까지 열광하게 만든 점이 흥미롭다. 기존 3040세대에게는 향수를 자극하는 공간으로, 내 취향과 가치에 부합한다면 주저 없이 지갑을 여는 1020세대에게는 영화를 통해 발견한 새로운 자신의 취향을 표출할 수 있는 공간으로 세대를 막론한 덕후들에게 사랑받는 팝업스토어가 될 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={cReason} alt="더 퍼스트 슬램덩크 팝업스토어 선택 이유 1위는 '사진 찍을 공간이 많았어요'이다." />
              <p>그 결과, 12일 동안 총 2만여 명의 방문자가 팝업스토어를 다녀갔을 뿐만 아니라, 하루 평균 약 1억 원의 매출을 발생시켜 큰 화제를 모았다. 또한 네이버 검색량은 1월 한 달간 278만 건 이상으로 집계되었고, 어워즈에서도 유저의 50%가 선정 사유로 '포토존'과 '굿즈'를 꼽으며 콘텐츠 부문 1위를 당당히 차지할 수 있었다.</p>
              <p>한편, 슬램덩크 팝업스토어의 열기는 더현대서울을 시작으로, 더현대 대구, 잠실 롯데시네마 월드타워에서도 이어졌고, 최근 영화 개봉 1주년 확대 상영을 기념하여 2024년 1월 4일부터 AK 플라자 홍대에서 감사 팝업스토어를 진행하는 등 적극적으로 팬들과의 오프라인 접점을 만들어 나가고 있다.</p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">취미/여가</p>
              <p>야놀자 홀리데이마켓 IN 성수</p>
              <img src={hdMain} alt="야놀자 홀리데이마켓 IN 성수 메인 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className='big-text'>해외 여행 사진을 건질 수 있는 팝업스토어가 있다?!<br />'야놀자 홀리데이마켓'</p>
              <MagazineSlide settings={commonSettings} images={[hdSlide1, hdSlide2, hdSlide3, hdSlide4, hdSlide5]} />
              <p>지난 7월 야놀자가 휴가철을 앞두고 국내 여행뿐만 아니라 해외여행 시에도 야놀자 서비스 이용률을 증가시키기 위해, 진행 중인 ‘놀자, 계산적으로’ 캠페인에 맞추어 인기 해외여행지에 여행을 간 컨셉의 팝업스토어를 기획했다.</p>
              <p>그중 가장 화제가 되었던 것은 실내 포토존이다. 입장 시 지폐 모양의 팝플렛을 수령할 수 있는 환전소 부스부터, 베트남, 태국, 괌, 일본 등 인기 여행지의 특징을 살린 나라별 마켓 컨셉의 굿즈샵뿐만 아니라, 비비드한 색감의 외국어 간판들로 공간을 꾸며 방문자들에게 이국적인 무드를 선사함과 동시에 인증샷을 유도해 SNS에서 바이럴 될 수 있었다.</p>
              <p>또한 야놀자의 '놀자, 계산적으로' 캠페인을 간접적으로 경험할 수 있는 '여행 물가 퀴즈'와 앱 유저를 대상으로 한 100% 당첨 럭키드로우 이벤트를 통해 브랜드의 여행 상품 및 캠페인 인지도 제고 및 유저를 확보할 수 있었다. 특히, 럭키드로우 경품은 5등 자판기 음료(전원 증정)부터 4등 앱 내에서 사용할 수 있는 3만 원 권 할인쿠폰(700명), 3등 앱 내에서 사용할 수 있는 5만 원 권 할인쿠폰(80명), 2등 에버랜드 종일 이용권(110명) 및 1등 오사카 왕복 항공권(20명)까지 당첨 인원 및 상품군을 매력적으로 구성함으로써 앱 설치 및 회원가입을 인증해야 하는 진입장벽이 있음에도 불구하고 새로운 공간 경험과 여행을 즐기는 MZ세대의 많은 참여를 불러일으킬 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={hReason} alt="야놀자 홀리데이마켓 IN 성수 선택 이유 1위는 '사진 찍을 공간이 많았어요'이다." />
              <p>그 결과, 일평균 1000명 이상의 방문객들이 팝업스토어를 찾았을 뿐만 아니라 네이버를 통해 약 1만 건의 콘텐츠가, 인스타그램을 통해 6천 개 이상의 게시물이 발행되었다. 또한 브랜드 검색량이 팝업스토어 오픈 전 달 대비 23.97% 상승했고, 어워즈에서도 유저의 31%가 포토존을, 24%가 브랜드를 선정 이유로 택하며 취미/여가 부문의 대상을 차지할 수 있었다.</p>
              <p>한편, 야놀자는 성수에 이어 스타필드 하남에서도 '야놀자 홀리데이마켓' 팝업스토어를 진행했다. 스타필드 하남에서는 성수점에는 없었던 '키즈 게임존' 및 '어린이 여권 만들기', '솜사탕 증정 이벤트' 등 가족 단위의 고객이 많은 지역 특성에 맞춘 이벤트를 추가로 마련해 '아이들과 함께 가기 좋은 팝업스토어'로 화제를 모으기도 했다.</p>
            </div>
          </div>
    </>
  );
}

export default MagazineReadDetail5;
