import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../common/Footer';

function Privacy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <article className="inner site-document">
        <h2>POPPLY 개인정보 처리방침</h2>
        <div>
          <p className="info">
            주식회사 이너스커뮤니티(이하 “회사”라함)는 회원의 개인정보를 중요시하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호에
            관한 법률 등 정보통신서비스 제공자가 준수할 관련사항의 개인정보보호규정을 준수하며, 관련하여 의거한 개인정보 취급방침을 정하여 권익 보호에
            최선을 다하고 있습니다.
          </p>
          <p>본 개인정보취급방침은 회사에서 제공하는 온라인 웹 및 모바일 장치 서비스에 적용되며 다음과 같은 내용을 준수합니다.</p>
        </div>
        <p className="term-title">제 1조 (개인정보 수집 동의)</p>
        <span className="term-text">회사는 회원이 최초 카카오 등의 소셜 로그인을 시도할 때 회원 동의를 받아 개인정보를 수집하고 있습니다.</span>
        <p className="term-title">제 2조 (수집하는 개인정보 항목)</p>
        <span className="term-text">
          회사가 원활한 서비스 제공을 위해 수집하는 개인정보 항목은 다음과 같습니다. 회원의 서비스 제공을 위해 정보를 수집할 때마다 별도의 동의를 얻고
          있으며 선택정보는 입력하지 않아도 서비스 이용에 제한은 없습니다.
        </span>
        <ul>
          <li>
            <p>가) 회원가입</p>
            <ul className="sub-list">
              <li>필수항목 : 이메일(카카오계정), 프로필사진, 닉네임 , 성별, 출생년도, 휴대폰번호</li>
              <li>선택항목 : 온라인 SNS정보</li>
              <li>수집 및 이용목적 : 회원가입 및 회원관리</li>
            </ul>
          </li>
          <li>
            <p>나) 위치기반 서비스 제공 – 회사는 위치정보사업자로부터 위치정보를 전달 받아 아래와 같은 위치기반 서비스를 제공합니다.</p>
            <ul className="sub-list">
              <li>필수항목 : 개인위치정보</li>
              <li>
                수집 및 이용목적 : 위치정보를 활용한 검색 결과 및 콘텐츠 제공, 이용자 위치를 활용한 광고정보 제공, 이용자 보호 및 부정 이용 방지
              </li>
            </ul>
          </li>
          <li>
            <p>나) 마케팅 및 광고에 활용</p>
            <ul className="sub-list">
              <li>필수항목 : 이름, 휴대폰번호, 이메일</li>
              <li>자동수집정보 : 접속로그, 접속IP정보, 쿠키, 서비스 이용기록</li>
              <li>수집 및 이용목적 : 서비스 이용 정보 통계/분석과 마케팅, 광고성 정보 전달</li>
            </ul>
          </li>
          <li>
            <p>다) 문의 및 고객서비스</p>
            <ul className="sub-list">
              <li>필수항목 : 이름, 휴대폰번호, 이메일</li>
              <li>자동수집정보 : 접속로그, 접속IP정보, 쿠키, 서비스 이용기록</li>
              <li>수집 및 이용목적 : 서비스 관련상담 및 문의사항 처리, 서비스 이용 만족도 조사</li>
            </ul>
          </li>
          <li>
            <p>라) 배송이 필요한 이벤트 당첨 시 경품 수령 과정에서 해당 서비스 이용자에 한해 추가적인 개인정보가 수집될 수 있습니다.</p>
          </li>
          <li>
            <p>마) 서비스 이용과정에서 아래와 같은 정보들이 수집됩니다.</p>
            <ul className="sub-list">
              <li>
                이용자의 브라우저 종류 및 OS, 방문기록(IP Address, 접속시간), 쿠키 - 회사는 사이트 회원가입 절차, 문의절차, 이벤트나 경품 행사, 제휴사
                등의 방법으로 수집하여 이동 전화 및 유/무선 인터넷 서비스를 사용할 때 생성정보 수집 도구를 통한 방법(쿠키) 등으로도 개인정보를
                수집합니다.
              </li>
            </ul>
          </li>
          <li>
            <p>
              바) 미·허위 정보 입력 시에 회원은 자신의 정보에 대한 정확성 및 적법성을 보장해야 합니다. 만약에 이를 위반하여 타인의 정보를 도용하는 등
              각종 방법으로 허위 정보를 입력한 경우에는 회사는 회원을 관계법령에 따라 민형사상 조치 등 제반 법적 조치를 취하거나 강제 탈퇴 시킬 수
              있습니다.
            </p>
          </li>
        </ul>
        <p className="term-title">제 3조 (개인정보 보유 및 이용기간)</p>
        <span className="term-text">
          원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
          동안 보존합니다.
        </span>
        <ul>
          <li>
            <p>보존 항목 : 로그인ID</p>
          </li>
          <li>
            <p>보존 근거 : 신규 가입자 회원 아이디와 탈퇴 아이디 간의 충돌 방지 </p>
          </li>
          <li>
            <p>보존 기간 : 5년</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>보존 항목 : 개인위치정보</p>
          </li>
          <li>
            <p>
              보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항 에 근거하여 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실
              확인자료를 위치정보시스템에 자동으로 기록
            </p>
          </li>
          <li>
            <p>보존 기간 : 6개월 이상</p>
          </li>
        </ul>
        <span className="term-text">
          그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
        </span>
        <ul>
          <li>
            <p>표시/광고에 관한 기록 ▶ 6개월 (전자상거래등에서의 소비자보호에 관한 법률)</p>
          </li>
          <li>
            <p>계약 또는 청약철회 등에 관한 기록 ▶ 5년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
          </li>
          <li>
            <p>대금결제 및 재화 등의 공급에 관한 기록 ▶ 5년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
          </li>
          <li>
            <p>소비자의 불만 또는 분쟁처리에 관한 기록 ▶ 3년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
          </li>
          <li>
            <p>신용정보의 수집/처리 및 이용 등에 관한 기록 ▶ 3년 (신용정보의 이용 및 보호에 관한 법률)</p>
          </li>
        </ul>
        <p className="term-title">제 4조 (개인정보의 파기절차 및 방법)</p>
        <span className="term-text">
          회사는 원칙적으로 개인정보의 수집 및 이용목적이 완료되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
        </span>
        <ul>
          <li>
            <p>가) 파기 절차</p>
            <ul>
              <li>
                사용자가 회원 가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)내부 방침 및 기타 관련 법령에
                의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정기간 저장된 후 파기됩니다.
              </li>
              <li>별도의 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.</li>
            </ul>
          </li>
          <li>
            <p>나) 파기 방법</p>
            <ul className="sub-list">
              <li>전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
              <li>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 5조 (개인정보 제공 및 공유)</p>
        <span className="term-text">
          회사는 이용자의 개인정보를 원칙적으로 서비스와 무관한 제 3자에게 동의 없이 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.
        </span>
        <ul className="sub-list">
          <li>이용자들이 사전에 동의한 경우</li>
          <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
        </ul>
        <p className="term-title">제 6조 (수집한 개인정보의 위탁 및 제 3자 제공)</p>
        <ul>
          <li>
            <p>가) 회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
            <ul>
              <li>수탁업체 : CJ대한통운(주)</li>
              <li>제공하는 개인정보 항목 : 이름, 휴대폰번호, 주소</li>
              <li>개인정보 보유 및 이용기간 : 경품 배송 완료 이후 파기. 단, 법률에 특별한 규정이 있을 경우 법률에 따라 보관</li>
              <li>위탁업무 : 택배 서비스</li>
            </ul>
            <ul>
              <li>수탁업체 : (주)케이티엠하우스</li>
              <li>제공하는 개인정보 항목 : 이름, 휴대폰번호</li>
              <li>개인정보 보유 및 이용기간 : 경품 발송 완료 이후 파기. 단, 법률에 특별한 규정이 있을 경우 법률에 따라 보관</li>
              <li>위탁업무 : 모바일 상품권 발송</li>
            </ul>
          </li>
          <li>
            <p>
              나) 해당 프로젝트에 입점하는 업체의 경우 당첨자에 한해 배송 등의 이유로 고객님의 개인정보를 위탁할 수 있으며 항목, 업무 등은 해당
              프로젝트에서 자세히 고지합니다.
            </p>
          </li>
          <li>
            <p>다) 제 3자 제공 업무 발생 시, 필요시점에 회원에게 별도로 알리고 동의 받는 절차를 진행합니다.</p>
          </li>
          <li>
            <p>
              라) 제 3자 제공 동의를 거부하실 수 있으며, 동의 거부는 회원가입과 홈페이지 서비스 사용에 영향을 미치지 않으며, 일부 혜택제공이 불가할 수
              있습니다.
            </p>
          </li>
          <li>
            <p>
              마) 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회
              개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
            </p>
          </li>
          <li>
            <p>바) 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.</p>

            <ul className="sub-list">
              <li>개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우</li>
              <li>
                개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
              </li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 7조 (이용자 및 법정대리인의 권리와 그 행사방법)</p>
        <span className="term-text">
          이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할
          수도 있습니다.이용자 혹은 만 14세 미만 아동의 개인정보 조회/수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를
          위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화
          또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
        </span>
        <span className="term-text">
          귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
          개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        </span>
        <span className="term-text">
          회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라
          처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        </span>
        <p className="term-title">제 8조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)</p>
        <span className="term-text">
          회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
          보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다
        </span>
        <ul>
          <li>
            <p>▶ 쿠키 등 사용 목적</p>
            <ul>
              <li>
                회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
                등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을
                설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
              </li>
            </ul>
          </li>
          <li>
            <p>▶ 쿠키 설정 거부 방법</p>
            <ul>
              <li>
                예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
                확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 &gt; 인터넷
                옵션 &gt; 개인정보 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
              </li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 9조 (개인정보보호를 위한 기술적·관리적 보호대책)</p>
        <span className="term-text">
          회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적
          대책을 강구하고 있습니다.
        </span>
        <ul>
          <li>
            <p>가) 개인정보 암호화</p>
            <ul>
              <li>개인정보와 관련한 중요한 데이터는 저장 및 데이터 전송 시 암호화하여 보안기능을 통해 보호됩니다.</li>
            </ul>
          </li>
          <li>
            <p>나) 해킹 등에 대비한 기술적 대책</p>
            <ul>
              <li>
                회사는 해킹이나 컴퓨터 바이러스에 의해 이용자의 개인정보가 도난 당하거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 최신 백신
                프로그램을 주기적으로 업데이트하여 개인정보나 자료가 누출되거나 손상되지 않도록 방지하며, 기타 개인정보에 대한 접근 통제를 위해 필요한
                조치를 다하고 있습니다.
              </li>
            </ul>
          </li>
          <li>
            <p>다) 개인정보처리 임직원의 최소화 및 교육 </p>
            <ul>
              <li>
                회사의 개인정보 관련 취급 임직원은 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자에 한정시키고 있고, 담당자에 대한
                수시 교육을 통하여 회사는 개인정보 취급 정책의 준수를 항상 강조하고 있습니다.
              </li>
            </ul>
          </li>
        </ul>
        <p className="term-title">제 10조 (개인정보에 관한 민원 서비스)</p>
        <span className="term-text">
          회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보관리책임자 또는 담당부서를 지정하고 있습니다.
        </span>
        <span className="term-text">
          회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리 책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
          이용자들의 신고사항에 대해 신속하게 답변을 드릴 것입니다.
        </span>
        <ul>
          <li>
            <p>회원서비스담당부서 : 임현주</p>
          </li>
          <li>
            <p>전화번호 02-519-1262</p>
          </li>
          <li>
            <p>이메일 : hjlim@inuscomm.co.kr</p>
          </li>
          <li>
            <p>개인정보관리책임자 : 황순용</p>
          </li>
          <li>
            <p>전화번호 : 02-519-1246</p>
          </li>
          <li>
            <p>이메일 : dt@inuscomm.co.kr</p>
          </li>
        </ul>
        <span className="term-text">기타 개인정보 침해에 대한 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</span>
        <ul>
          <li>- 개인정보침해신고센터 (http://www.118.or.kr / 118)</li>
          <li>- 정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02-580-0533~4)</li>
          <li>- 대검찰청 금리범죄수사과 (http://www.spo.go.kr / 02-3480-2000)</li>
          <li>- 경찰청 사이버테러대응센터 (http://www.ctrc.go.kr/ 02-392-0330)</li>
        </ul>
        <p className="term-title">제 11조 (고지의 의무)</p>
        <span className="term-text">
          현 개인정보취급방침의 내용 추가, 삭제 및 수정이 있을 시에는 사이트의 "공지사항"을 통해 공고하도록 하겠습니다.
        </span>
      </article>
      {/* <Footer /> */}
    </>
  );
}

export default Privacy;
