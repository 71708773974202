import React from 'react';
import { useNavigate } from 'react-router-dom';

const withNavigateLogging = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  return (props: P) => {
    const navigate = useNavigate();

    const navigateWithLogging = (path: string, options?: any) => {
      console.log(`Navigating to: ${path}`, options);
      console.trace('Navigation Trace');
      navigate(path, options);
    };

    return <WrappedComponent {...props} navigate={navigateWithLogging} />;
  };
};

export default withNavigateLogging;


export const getFixedLat = ({ latitude, longitude }: { latitude?: number; longitude?: number }) => {
  /* 임시로 만든 함수 , latitude 100 보다 크면 (태평양 한복판이면 , 둘을 바꾼다. )*/
  return latitude > 100 ? longitude : latitude;
}

export const getFixedLng = ({ latitude, longitude }: { latitude?: number; longitude?: number }) => {
  return latitude > 100 ? latitude : longitude;
}

export const isMobile = () => {
  //return false;
  const os = getOS();   
  return os  === "Windows Phone" ||  os === "Android" || os === "iOS"
  //return os === "iOS"
}
export const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera!;  

  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
  }

  if (/Macintosh|Mac OS X/.test(userAgent)) {
      return "MacOS";
  }

  if (/Windows NT/.test(userAgent)) {
      return "Windows";
  }

  if (/Linux/.test(userAgent)) {
      return "Linux";
  }

  return "Unknown";
}

export const convertToKoreanDay = (day: string): string => {
  switch (day) {
    case 'Sun':
      return '일';
    case 'Mon':
      return '월';
    case 'Tue':
      return '화';
    case 'Wed':
      return '수';
    case 'Thu':
      return '목';
    case 'Fri':
      return '금';
    case 'Sat':
      return '토';
    default:
      return '-';
  }
};
