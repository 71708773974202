import MemberModifyContainer from '../../containers/member/MemberModifyContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
export default function MemberModifyPage({ match }: { match: any }) {
  const { userNo } = useParams();

  return (
    <>
      <MainLayout>
        <MemberModifyContainer userNo={userNo} />
      </MainLayout>
    </>
  );
}
