import ReactHtmlParser from 'html-react-parser';
import './reservation.scss';
import ReactCalendar from '../common/ReactCalendar';
import arrow_bottom from './../../assets/img/common/icon_answerBottom_write.png';
import arrow_bottom_black from './../../assets/img/common/icon_answerBottom_black.png';
import { FormEvent, useEffect, useRef, useState } from 'react';
import calendarIcon from './../../assets/img/common/calendar-line.png';
import { ReactComponent as KakaoLoginSvg } from './../../assets/img/kakao_login.svg';
import dayjs from 'dayjs';
import ReservationAlert from './ReservationAlert';

function ReservationRegister({ reservation, surveyUser, myInfo, onRegister, isLoading, type }: any) {
  // const type = reservation.type;

  const [withGuest, setWithGuest] = useState(1);
  const [reservationScheduleId, setReservationScheduleId] = useState(null);
  const [disabledDate, setDisabledDate] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '010-',
  });
  const maxCount = reservation?.guestLimits;
  // 입력한 유저 정보
  const changeUserInfo = e => {
    if (e.target.id === 'phone') {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
        .replace(/(\-{1,2})$/g, '');
    }
    const { value, id } = e.target;

    setUserInfo({ ...userInfo, [id]: value });
  };

  // 인원 감소
  const minusNum = () => {
    if (withGuest > 1) {
      setWithGuest(withGuest - 1);
    }
  };
  // 인원 증가
  const plusNum = () => {
    if (withGuest < maxCount) {
      setWithGuest(withGuest + 1);
    }
  };

  // 예약시간 - 기본 10:00-10:50 선택되어 있음
  const [selectedTime, setSelectedTime] = useState('10:00 - 10:50');
  const handleTimeClick = (time: string, reservationSchedule) => {
    setReservationScheduleId(reservationSchedule.reservationScheduleId);
    setSelectedTime(time);
  };

  //예약 날짜 disable
  const checkScheduleGuests = a => {
    if (!reservation) return;

    const filteredDatesSet = [];
    const scheduledDate = [];
    const dateArr = getDates(reservation.startDate, reservation.endDate);

    reservation.reservationSchedule.filter(schedule => {
      const sameDateSchedules = reservation.reservationSchedule.filter(otherSchedule => otherSchedule.reservationDate === schedule.reservationDate);
      const unAvailableSchedule = sameDateSchedules.every(s => s.currentAvailableGuests <= withGuest);
      if (unAvailableSchedule && !filteredDatesSet.includes(schedule.reservationDate)) {
        filteredDatesSet.push(schedule.reservationDate);
      }
      scheduledDate.push(schedule.reservationDate);
    });
    const filteredDisabledDate = dateArr.filter(da => !scheduledDate.includes(da));
    setDisabledDate(filteredDisabledDate);
  };
  function getDates(startDate, endDate) {
    var dateArray = [];
    var currentDate = dayjs(startDate);
    var stopDate = dayjs(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'));
      currentDate = dayjs(currentDate).add(1, 'days');
    }
    return dateArray;
  }
  // 예약 날짜
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [openCalendar, setOpenCalendar] = useState(true);
  const calendarOpen = (isClick: boolean) => {
    //가능한 날짜 찾아오기
    checkScheduleGuests(withGuest);
    setOpenCalendar(current => !current);

    setReservationScheduleId(null);
    setSelectedTime(null);
  };

  useEffect(() => {
    checkScheduleGuests(withGuest);
  }, [withGuest]);

  const [privacyCollectFold, setPrivacyCollectFold] = useState(true);
  const privacyCollectFoldClick = (isClick: boolean) => {
    setPrivacyCollectFold(current => !current);
  };

  const [privacyProvideFold, setPrivacyProvideFold] = useState(true);
  const privacyProvideFoldClick = (isClick: boolean) => {
    setPrivacyProvideFold(current => !current);
  };

  const [marketingFold, setMarketingFold] = useState(true);
  const marketingFoldClick = (isClick: boolean) => {
    setMarketingFold(current => !current);
  };

  const selectReservationDate = async selectDate => {
    setSelectedDate(selectDate);
    calendarOpen(false);
  };

  // 모두동의체크
  const [allAgreeChecked, setAllAgreeChecked] = useState(false);
  const [personalInfoChecked, setPersonalInfoChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);

  useEffect(() => {
    // 개인정보 동의와 마케팅 동의 중 하나라도 체크되면 모두 동의도 체크
    if (personalInfoChecked && marketingChecked) {
      setAllAgreeChecked(true);
    } else {
      setAllAgreeChecked(false);
    }
  }, [personalInfoChecked, marketingChecked]);

  const handleAllAgreeChange = () => {
    const newAllAgreeChecked = !allAgreeChecked;
    setAllAgreeChecked(newAllAgreeChecked);
    setPersonalInfoChecked(newAllAgreeChecked);
    setMarketingChecked(newAllAgreeChecked);
  };

  const handleCheckboxChange = checkboxType => {
    switch (checkboxType) {
      case 'personalInfo':
        setPersonalInfoChecked(!personalInfoChecked);
        break;
      case 'marketing':
        setMarketingChecked(!marketingChecked);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (personalInfoChecked === false) {
      alert('개인정보 제공 동의는 필수 항목입니다.');
      return;
    } else if (!reservationScheduleId) {
      alert('예약 시간을 선택해주세요.');
      return;
    } else {
      const isConfirm = window.confirm('등록하시겠습니까?');
      if (isConfirm) {
        const reservationInfo = {
          reservationScheduleId: reservationScheduleId,
          withGuest: withGuest,
          providePrivacy: personalInfoChecked,
          agreeMarketing: marketingChecked,
        };
        if (type === 'onSite' || reservation.availableGuest) {
          reservationInfo['name'] = userInfo.name;
          reservationInfo['phone'] = userInfo.phone;
        }
        onRegister(reservation.store.storeId, reservation.reservationId, reservation.type, reservationInfo);
      }
    }
  };

  const phone = useRef();
  return (
    <article className="inner reservation-wrap">
      {/* {myInfo && reservation && type === 'pre' && ( */}
      {myInfo && reservation && surveyUser && type === 'pre' && (
        <>
          <header>
            <p>사전예약</p>
          </header>
          <div className="reservation-form">
            <form onSubmit={handleSubmit}>
              <div className="field-wrap">
                <p className="small-text">예약 팝업스토어명</p>
                <h1 className="popupstore-name">{reservation.store.title}</h1>
              </div>
              <div className="field-wrap date-wrap">
                <p className="small-text">예약 일자</p>
                <p
                  className="date-pick"
                  onClick={() => {
                    calendarOpen(false);
                  }}
                >
                  {dayjs(selectedDate).format('YYYY-MM-DD')} <img src={calendarIcon} alt="" />
                </p>
                <div className={openCalendar ? 'calendar-layer' : 'calendar-layer show'}>
                  <ReactCalendar onDateChange={selectReservationDate} calendarOpen={calendarOpen} startDate={dayjs(reservation.startDate)} endDate={dayjs(reservation.endDate)} disabledDate={disabledDate} />
                </div>
              </div>
              <div className="field-wrap">
                <p className="small-text">예약 시간</p>
                <div className="popupstore-time-btn-wrap">
                  {reservation.reservationSchedule &&
                    reservation.reservationSchedule.slice().map(time => {
                      return selectedDate.format('YYYY-MM-DD') === time.reservationDate && time.currentAvailableGuests > 0 && dayjs().isBefore(dayjs(`${time.reservationDate} ${time.reservationTime}`)) ? (
                        <button key={time.reservationTime} type="button" className={`popupstore-time-btn ${selectedTime === time.reservationTime ? 'select' : ''}`} onClick={() => handleTimeClick(time.reservationTime, time)}>
                          {time.reservationTime}
                        </button>
                      ) : (
                        ''
                      );
                    })}
                </div>
              </div>
              <div className="field-wrap">
                <p className="small-text">
                  방문 인원 선택 (본인포함 <span>{maxCount}</span>인 까지)
                </p>
                <div className="reservation-amount-wrap">
                  <p>방문 인원</p>
                  <button type="button" className="amount-button minus" disabled={withGuest === 1} onClick={minusNum}></button>
                  <input type="number" className="countNum" value={withGuest} required />
                  <button type="button" className="amount-button plus" disabled={withGuest < 1 || withGuest === maxCount} onClick={plusNum}></button>
                </div>
              </div>
              <div className="field-wrap">
                <p className="field-tit">팝업스토어 방문 안내</p>
                <div className="field-text-wrap">
                  <div className="notice-wrap">{ReactHtmlParser(reservation.notice)}</div>
                </div>
              </div>

              <div className="field-wrap">
                <div className="field-agree-wrap">
                  <p className="all-agree-wrap">
                    <label>
                      <input type="checkbox" id="all-agree-checkbox" checked={allAgreeChecked} onChange={handleAllAgreeChange} />
                      모두 확인하였으며 동의합니다.
                    </label>
                    <span>전체 동의에는 필수 및 선택 정보에 대한 동의가 포함되어 있으며, 개별적으로 동의를 선택할 수 있습니다.</span>
                  </p>
                  <p
                    className="agree-tit"
                    onClick={() => {
                      privacyProvideFoldClick(false);
                    }}
                  >
                    <label>
                      <input type="checkbox" required checked={personalInfoChecked} onChange={() => handleCheckboxChange('personalInfo')} />
                      개인정보 제공 동의(필수)
                    </label>
                    <img src={arrow_bottom} className={privacyProvideFold ? 'arrow' : 'arrow move'} alt="" />
                  </p>

                  {/* 더블하트 // 비회원 기준 개인정보 제공 동의 */}
                  <div className={privacyProvideFold ? 'agree-content' : 'agree-content show'}>
                    <p>&lt;개인정보 제공 동의&gt;</p>
                    <ul>
                      <li>
                        <p>
                          1. 개인정보를 제공받는 자 : <span>{reservation.store.mainBrand} , 팝플리</span>
                        </p>
                      </li>
                      <li>
                        <p>2. 제공하는 기본 개인정보 항목: 이름, (휴대)전화번호</p>
                      </li>
                      <li>
                        <p>3. 개인정보를 제공받는 자의 이용목적 : 사업자회원과 예약이용자의 원활한 서비스 진행, 민원처리 등 고객상담</p>
                      </li>
                      <li>
                        <p className="emphasis-text">4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 위 개인정보 이용목적 달성 시 까지 이용합니다.</p>
                      </li>
                      <li>
                        <p>5. 동의 거부권 등에 대한 고지 : 정보주체는 개인정보 제공 동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한됩니다.</p>
                      </li>
                    </ul>
                  </div>
                  <p
                    className="agree-tit"
                    onClick={() => {
                      marketingFoldClick(false);
                    }}
                  >
                    <label>
                      <input type="checkbox" checked={marketingChecked} onChange={() => handleCheckboxChange('marketing')} />
                      마케팅 수신 동의(선택)
                    </label>
                    <img src={arrow_bottom} className={marketingFold ? 'arrow' : 'arrow move'} alt="" />
                  </p>

                  <div className={marketingFold ? 'agree-content' : 'agree-content show'}>
                    <p>&lt;마케팅 수신 동의&gt;</p>
                    <ul>
                      <li>
                        <p>회사는 사용자가 수집 및 이용에 동의한 개인정 보를 이용하여 SMS(MMS)등 전자적 전송 매체를 통해 오전 8시부터 오후 9시까지 개인 맞춤형 광고 및 정보를 전송할 수 있습니다. 본 동의는 거부하실 수 있으나, 거부시 이벤트 및 프로모션 안내, 유용한 맞춤 정보를 받아보실 수 없습니다.</p>
                      </li>
                      <li>
                        <p>1. 마케팅 정보 수집 목적 : 전화, 문자, 카카오톡 등을 통한 광고성 정보 전송</p>
                        <p>2. 마케팅 정보 수집 항목 : 휴대폰번호</p>
                        <p>3. 마케팅 활용 주체 : (주)이너스커뮤니티</p>
                        <p className="emphasis-text">4. 보유 및 이용기간 : 마케팅 활용동의 철회시까지</p>
                      </li>
                      <li>
                        <p>광고성정보수신의 변경은 아래의 방법으로 할 수 있습니다.</p>
                      </li>
                      <li>
                        <p>1. 각 SNS 내 수신 차단 메뉴</p>
                        <p>2. 팝플리 고객센터 1:1문의로 요청 혹은 02)519-1231로 요청</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <button type="submit" className="reservation-btn full-btn red">
                사전예약하기
              </button>
            </form>
          </div>
          <ReservationAlert type={'noDate'} />
        </>
      )}
      {reservation && type === 'pre' && reservation.availableGuest && (
        <>
          <header>
            <p>사전예약</p>
          </header>
          <div className="reservation-form">
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '40px' }}>
                <h1 className="reservation-store-title">{reservation.store.title} 대기를 위해 정보를 입력해 주세요.</h1>
                <div className="field-wrap field-top-wrap">
                  <label className="small-text">이름</label>
                  <input type="text" id="name" min="2" required value={userInfo.name} onChange={changeUserInfo} />
                </div>
                <div className="field-wrap field-top-wrap">
                  <label className="small-text">연락처</label>
                  {/* TODO수정님 */}
                  <input type="text" id="phone" maxLength={13} required value={userInfo.phone} ref={phone} onChange={changeUserInfo} />
                  {/* <div className="field-wrap field-top-wrap"> */}
                  <p>* 입력해주신 연락처로 예약 완료 안내 및 입장 안내 알림톡을 보내드립니다.</p>
                  <p> 핸드폰 번호를 정확히 입력하여 주세요.</p>
                  {/* </div> */}
                </div>
              </div>
              <div className="field-wrap">
                <p className="small-text">예약 팝업스토어명</p>
                <h1 className="popupstore-name">{reservation.store.title}</h1>
              </div>
              <div className="field-wrap date-wrap">
                <p className="small-text">예약 일자</p>
                <p
                  className="date-pick"
                  onClick={() => {
                    calendarOpen(false);
                  }}
                >
                  {dayjs(selectedDate).format('YYYY-MM-DD')} <img src={calendarIcon} alt="" />
                </p>
                <div className={openCalendar ? 'calendar-layer' : 'calendar-layer show'}>
                  <ReactCalendar onDateChange={selectReservationDate} calendarOpen={calendarOpen} startDate={dayjs(reservation.startDate)} endDate={dayjs(reservation.endDate)} disabledDate={disabledDate} />
                </div>
              </div>
              <div className="field-wrap">
                <p className="small-text">예약 시간</p>
                <div className="popupstore-time-btn-wrap">
                  {reservation.reservationSchedule &&
                    reservation.reservationSchedule
                      .slice()
                      // .reverse()
                      .map(time => {
                        return selectedDate.format('YYYY-MM-DD') === time.reservationDate && time.currentAvailableGuests > 0 && dayjs().isBefore(dayjs(`${time.reservationDate} ${time.reservationTime}`)) ? (
                          // return selectedDate.format('YYYY-MM-DD') === time.reservationDate && time.currentAvailableGuests >= withGuest && dayjs().isBefore(dayjs(`${time.reservationDate} ${time.reservationTime}`)) ? (
                          <button key={time.reservationTime} type="button" className={`popupstore-time-btn ${selectedTime === time.reservationTime ? 'select' : ''}`} onClick={() => handleTimeClick(time.reservationTime, time)}>
                            {time.reservationTime}
                          </button>
                        ) : (
                          ''
                        );
                      })}
                </div>
              </div>
              <div className="field-wrap">
                <p className="small-text">
                  방문 인원 선택 (본인포함 <span>{maxCount}</span>인 까지)
                </p>
                <div className="reservation-amount-wrap">
                  <p>방문 인원</p>
                  <button type="button" className="amount-button minus" disabled={withGuest === 1} onClick={minusNum}></button>
                  <input type="number" className="countNum" value={withGuest} required />
                  <button type="button" className="amount-button plus" disabled={withGuest < 1 || withGuest === maxCount} onClick={plusNum}></button>
                </div>
              </div>
              <div className="field-wrap">
                <p className="field-tit">팝업스토어 방문 안내</p>
                <div className="field-text-wrap">
                  <div className="notice-wrap">{ReactHtmlParser(reservation.notice)}</div>
                </div>
              </div>

              <div className="field-wrap">
                <div className="field-agree-wrap">
                  <p className="all-agree-wrap">
                    <label>
                      <input type="checkbox" id="all-agree-checkbox" checked={allAgreeChecked} onChange={handleAllAgreeChange} />
                      모두 확인하였으며 동의합니다.
                    </label>
                    <span>전체 동의에는 필수 및 선택 정보에 대한 동의가 포함되어 있으며, 개별적으로 동의를 선택할 수 있습니다.</span>
                  </p>
                  <p
                    className="agree-tit"
                    onClick={() => {
                      privacyProvideFoldClick(false);
                    }}
                  >
                    <label>
                      <input type="checkbox" required checked={personalInfoChecked} onChange={() => handleCheckboxChange('personalInfo')} />
                      개인정보 제공 동의(필수)
                    </label>
                    <img src={arrow_bottom} className={privacyProvideFold ? 'arrow' : 'arrow move'} alt="" />
                  </p>

                  <div className={privacyProvideFold ? 'agree-content' : 'agree-content show'}>
                    <p>&lt;개인정보 제공 동의&gt;</p>
                    <ul>
                      <li>
                        <p>
                          1. 개인정보를 제공받는 자 : <span>{reservation.store.mainBrand} , 팝플리</span>
                        </p>
                      </li>
                      <li>
                        <p>2. 제공하는 기본 개인정보 항목: 이름, (휴대)전화번호</p>
                      </li>
                      <li>
                        <p>3. 개인정보를 제공받는 자의 이용목적 : 사업자회원과 예약이용자의 원활한 서비스 진행, 민원처리 등 고객상담</p>
                      </li>
                      <li>
                        <p className="emphasis-text">4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 위 개인정보 이용목적 달성 시 까지 이용합니다.</p>
                      </li>
                      <li>
                        <p>5. 동의 거부권 등에 대한 고지 : 정보주체는 개인정보 제공 동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한됩니다.</p>
                      </li>
                    </ul>
                  </div>
                  <p
                    className="agree-tit"
                    onClick={() => {
                      marketingFoldClick(false);
                    }}
                  >
                    <label>
                      <input type="checkbox" checked={marketingChecked} onChange={() => handleCheckboxChange('marketing')} />
                      마케팅 수신 동의(선택)
                    </label>
                    <img src={arrow_bottom} className={marketingFold ? 'arrow' : 'arrow move'} alt="" />
                  </p>

                  <div className={marketingFold ? 'agree-content' : 'agree-content show'}>
                    <p>&lt;마케팅 수신 동의&gt;</p>
                    <ul>
                      <li>
                        <p>회사는 사용자가 수집 및 이용에 동의한 개인정 보를 이용하여 SMS(MMS)등 전자적 전송 매체를 통해 오전 8시부터 오후 9시까지 개인 맞춤형 광고 및 정보를 전송할 수 있습니다. 본 동의는 거부하실 수 있으나, 거부시 이벤트 및 프로모션 안내, 유용한 맞춤 정보를 받아보실 수 없습니다.</p>
                      </li>
                      <li>
                        <p>1. 마케팅 정보 수집 목적 : 전화, 문자, 카카오톡 등을 통한 광고성 정보 전송</p>
                        <p>2. 마케팅 정보 수집 항목 : 휴대폰번호</p>
                        <p>3. 마케팅 활용 주체 : (주)이너스커뮤니티</p>
                        <p className="emphasis-text">4. 보유 및 이용기간 : 마케팅 활용동의 철회시까지</p>
                      </li>
                      <li>
                        <p>광고성정보수신의 변경은 아래의 방법으로 할 수 있습니다.</p>
                      </li>
                      <li>
                        <p>1. 각 SNS 내 수신 차단 메뉴</p>
                        <p>2. 팝플리 고객센터 1:1문의로 요청 혹은 02)519-1231로 요청</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <button type="submit" className="reservation-btn full-btn red">
                사전예약하기
              </button>
            </form>
          </div>
          <ReservationAlert type={'noDate'} />
        </>
      )}
      {reservation && type === 'onSite' && (
        <>
          <div className="waiting-form white-back">
            <div className="waiting-header">
              <div className="popup-info-wrap">
                <img className="popup-thumbnail" src={reservation.store.thumbnails} alt={`${reservation.store.title} 사전예약`} />
                <p className="popupstore-name">{reservation.store.title}</p>
              </div>
              <p className="big-text">현장 대기 신청</p>
            </div>
            <form className="waiting-form-inner" onSubmit={handleSubmit}>
              <div className="user-form">
                <p>카카오톡으로 바로 로그인하고</p>
                <p>현장대기 신청하기</p>
                {/* 로그인 버튼 누르면 로그인과 동시에 현장대기 신청 완료! -> ReservationResult 페이지로 이동해야 함 */}
                <button type="button" className="login-btn">
                  {/* <img src={kakaoLogin2} alt="카카오톡으로 로그인" /> */}
                  <KakaoLoginSvg />
                </button>
              </div>
              <div className="none-user-form">
                <p>비회원으로 현장 대기 신청하기</p>
                <div className="field-wrap">
                  <label className="small-text">이름</label>
                  <input type="text" id="name" min="2" required value={userInfo.name} onChange={changeUserInfo} />
                </div>
                <div className="field-wrap">
                  <label className="small-text">연락처</label>
                  <input type="text" id="phone" maxLength={13} required value={userInfo.phone} ref={phone} onChange={changeUserInfo} />
                </div>
                <div className="field-wrap" style={{ marginTop: '40px' }}>
                  {/* <p className="field-tit">개인정보 수집, 제공</p> */}
                  <div className="field-agree-wrap">
                    <p
                      className="agree-tit"
                      onClick={() => {
                        privacyProvideFoldClick(false);
                      }}
                    >
                      <label>
                        <input type="checkbox" required checked={personalInfoChecked} onChange={() => handleCheckboxChange('personalInfo')} />
                        개인정보 제공 동의(필수)
                      </label>
                      <img src={arrow_bottom_black} className={privacyProvideFold ? 'arrow' : 'arrow move'} alt="" />
                    </p>
                    <div className={privacyProvideFold ? 'agree-content' : 'agree-content show'}>
                      <p>&lt;개인정보 제공 동의&gt;</p>
                      <ul>
                        <li>
                          <p>
                            {/* 231222 TODO 여러 브랜드 */}
                            1. 개인정보를 제공받는 자 : <span>{reservation.store.mainBrand}</span>
                          </p>
                        </li>
                        <li>
                          <p>2. 제공하는 기본 개인정보 항목: 마스킹 처리된 아이디, 이름, (휴대)전화번호</p>
                        </li>
                        <li>
                          <p>3. 개인정보를 제공받는 자의 이용목적 : 사업자회원과 예약이용자의 원활한 서비스 진행, 민원처리 등 고객상담</p>
                        </li>
                        <li>
                          <p className="emphasis-text">4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 위 개인정보 이용목적 달성 시 까지 이용합니다.</p>
                        </li>
                        <li>
                          <p>5. 동의 거부권 등에 대한 고지 : 정보주체는 개인정보 제공 동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한됩니다.</p>
                        </li>
                      </ul>
                    </div>
                    <p
                      className="agree-tit"
                      onClick={() => {
                        marketingFoldClick(false);
                      }}
                    >
                      <label>
                        <input type="checkbox" checked={marketingChecked} onChange={() => handleCheckboxChange('marketing')} />
                        마케팅 수신 동의(선택)
                      </label>
                      <img src={arrow_bottom_black} className={marketingFold ? 'arrow' : 'arrow move'} alt="" />
                    </p>
                    <div className={marketingFold ? 'agree-content' : 'agree-content show'}>
                      <p>&lt;마케팅 수신 동의&gt;</p>
                      <ul>
                        <li>
                          <p>회사는 회원님이 수집 및 이용에 동의한 개인정보를 이용하여 SMS(MMS), SNS 메세지, 이메일 등 다양한 전자적 전송 매체를 통해 오전 8시부터 오후 9시까지 개인 맞춤형 광고 및 정보를 전송할 수 있습니다. 본 동의는 거부하실 수 있으나, 거부시 이벤트 및 프로모션 안내, 유용한 맞춤 정보를 받아보실 수 없습니다.</p>
                        </li>
                        <li>
                          <p>1. 마케팅 정보 수집 목적 : 전화, 문자, 카카오톡, 이메일 등을 통한 광고성 정보 전송</p>
                          <p>2. 마케팅 정보 수집 항목 : 이메일, 휴대폰번호</p>
                          <p className="emphasis-text">3. 보유 및 이용기간 : 회원 탈퇴시 까지</p>
                        </li>
                        <li>
                          <p>광고성정보수신의 변경은 아래의 방법으로 할 수 있습니다.</p>
                        </li>
                        <li>
                          <p>1. 팝플리 로그인&gt;마이페이지&gt;내정보&gt;마케팅 정보수신 설정</p>
                          <p>2. 각 SNS 내 수신 차단 메뉴</p>
                          <p>3. 팝플리 고객센터 1:1문의로 요청 혹은 02)519-1231로 요청</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="field-wrap marketing-wrap">
                  <p>
                    <span className="red-text">마케팅 수신에 동의</span>하면
                  </p>
                  <p>
                    <span className="red-text">다양한 팝플리 소식</span>을 받아보실 수 있습니다!
                  </p>
                </div>
              </div>
              <button type="submit" className="reservation-btn red full-btn">
                대기 신청
              </button>
            </form>
          </div>
        </>
      )}
    </article>
  );
}

export default ReservationRegister;
