import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupRead from '../../components/popup/PopupRead';
import { fetchSimilarStores, userLikeStore } from '../../lib/api';
import { fetchOne, FETCH_ONE, fetchOneByPreview, updateTempStoreUserLike } from '../../modules/board';
import httpStatusHandler from '../../util/httpStatusHandler';
import StoreDetailComment from '../../components/popup/StoreDetailComment';
import PopupBanner from '../../components/common/PopupBanner';
import AddBanner from '../../components/common/AddBanner';
import { useLocation, useNavigate } from 'react-router-dom';
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

const PopupReadContainer = ({ storeId, history, previewId  } : any) => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [likes, setLikes] = useState(0);
  const { store, isLoading, myInfo } = useSelector((state: any) => ({
    store: state.board.store?.data?.store,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));  

  const loadStoreInfo = () => {
    let data = {};
    if( previewId !== undefined && previewId !== "" ) {
      data  = fetchOneByPreview( storeId, previewId )
    } else {
      data = fetchOne(storeId, myInfo ? myInfo.userId : null)     
    }
    dispatch(data);  
  }
  useEffect(loadStoreInfo, [dispatch, storeId, previewId]);
  console.log("PopupReadContainer", store);
  const [similarStore, setSimilarStore] = useState([]);
  const getSimilarStore = useCallback(() => {
    const innerFunc = async () => {
      try {
        if (storeId) {
          const response = await fetchSimilarStores(storeId);
          if (response) {
            setSimilarStore(response.data.data?.similar);
          }
        }
      } catch (err: any) {
        throw err;
      }
    };
    innerFunc();
  }, [storeId]);

  useEffect(() => {
    getSimilarStore();
  }, []);

  const onSaveStore = async () => {
    try {
      if (myInfo === null) {
        const state = location.pathname + location.search;
        const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
        // window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
        window.location.href = kauthUrl;
      } else {
        await userLikeStore(myInfo.userId, { storeIds: storeId });
        fetchOne(storeId, myInfo.userId);
      }
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  return (
    <>
      {!isLoading && store && (
        <div style={{ paddingBottom: '100px' }}>
          {/* <PopupRead store={store} isLoading={isLoading} onSaveStore={onSaveStore} myInfo={myInfo} onBookmarkChange={handleBookmarkChange} /> */}
          <PopupRead store={store} isLoading={isLoading} myInfo={myInfo} />
          <StoreDetailComment store={store} myInfo={myInfo} history={navigate} />
          <article>
            <PopupBanner storeList={similarStore} title={'비슷한 팝업스토어'} />
          </article>

          <AddBanner title={'팝플리에 대해 알고 싶다면?'} content={'궁금한 점이 있으시다면 FAQ나 문의하기를 이용해보세요!'} />
        </div>
      )}
    </>
  );
};

export default PopupReadContainer;
