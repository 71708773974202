import { useLocation, useParams } from 'react-router-dom';
import PopupReadContainer from '../../containers/popup/PopupReadContainer';
import MainLayout from '../../layout/MainLayout';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
function PopupPreviewPage({ match }: any) {
  const { storeId, previewId} = useParams();
  const location = useLocation();
  //const query = useQuery();
  console.log("PopupPreviewPage", location, storeId, previewId);  
  //const previewId = query.get('previewId');
  
  return (
    <>
      <MainLayout>
        <PopupReadContainer storeId={storeId} previewId={previewId} />
      </MainLayout>
    </>
  );
}

export default PopupPreviewPage;
