import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOriginalFileName, removeAttach } from './../ContactUtil';

function FaqRegisterForm({ attachments, onRegister, onAddAttach, onRemoveAttach }: any) {
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');

  const handleChangeItemName = (e: ChangeEvent) => {
    setItemName((e.target as HTMLInputElement).value);
  };

  const handleChangeDescription = (e: ChangeEvent) => {
    setDescription((e.target as HTMLInputElement).value);
  };

  // 첨부파일 변경 처리 함수
  const handleChangeFile = useCallback(
    (e: ChangeEvent) => {
      onAddAttach((e.target as HTMLInputElement).files![0]);
    },
    [onAddAttach],
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onRegister(itemName, description);
    },
    [itemName, description, onRegister],
  );

  return (
    <article>
      {/* <h2>FAQ 등록</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td>자료명111111</td>
              <td>
                <input value={itemName} type="text" onChange={handleChangeItemName} />
              </td>
            </tr>
            <tr>
              <td>파일</td>
              <td>
                <input onChange={handleChangeFile} type="file" />
                <div>
                  {attachments.map((attachment: string, index: number) => (
                    <div key={index}>
                      {getOriginalFileName(attachment)}&nbsp;
                      <span className="btn-cancel" onClick={() => removeAttach(index, onRemoveAttach)}>
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>자료설명</td>
              <td>
                <textarea value={description} rows={5} onChange={handleChangeDescription}></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <button type="submit" className="likebutton success">
            등록
          </button>
          <Link to="/faq" className="likebutton">
            취소
          </Link>
        </div>
      </form> */}
      <form onSubmit={handleSubmit} className="text-form">
        <div className="text-form-inner">
          {/* <p>
            <label htmlFor="faq-title">제목</label>
            <input type="text" id="faq-title" name="faq제목" placeholder="제목을 입력해주세요." />
          </p> */}
          <p>
            <label htmlFor="faq-category">카테고리</label>
            <select id="faq-category" name="FAQ 카테고리">
              <option value="faq-event">이벤트</option>
              <option value="faq-use">사이트이용</option>
              <option value="faq-etc">기타</option>
            </select>
          </p>
          <p className="edit-area">
            <label htmlFor="faq-q">질문</label>
            <textarea id="faq-q" name="질문내용" value={description} rows={5} onChange={handleChangeDescription}></textarea>
          </p>
          <p className="edit-area">
            <label htmlFor="faq-a">답변</label>
            <textarea id="faq-a" name="답변내용" value={description} rows={5} onChange={handleChangeDescription}></textarea>
          </p>
        </div>

        <div className="likebutton-wrap">
          <button type="submit" className="likebutton success">
            등록
          </button>
          <a href="/pds" className="likebutton">
            취소
          </a>
        </div>
      </form>
    </article>
  );
}

export default FaqRegisterForm;
