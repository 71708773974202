import { useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import nonethumb from './../../assets/img/noneIMG.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Bookmark from './Bookmark';
import dayjs from 'dayjs';
function PopupSlider({ stores, myInfo, statusOption }) {
  const sliderRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);
  const handleAfterChange = useCallback(
    newIdx => {
      let pgIdx = 2;
      if (newIdx === 0) {
        pgIdx = 1;
      } else if (newIdx !== 0) {
        if (window.innerWidth < 980) {
          pgIdx = newIdx + 1;
        } else {
          pgIdx = Math.ceil(newIdx / 2) + 1;
        }
      }
      setCurrentIndex(pgIdx);
      setDragging(false);
    },
    [setDragging],
  );

  const moveToDetailPage = (storeId: number, e: any) => {
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      window.location.href = `/popup/${storeId}`;
    }
  };

  const totalStore = stores.length; // 총 콘텐츠 수
  let listStore;
  // if (window.innerWidth < 980) {
  //   if (totalStore <= 3 || statusOption.value === "open") {
  //     listStore = 1;
  //   } else {
  //     listStore = 3;
  //   }
  // } else {
  //   if (totalStore <= 4) {
  //     listStore = 2;
  //   } else {
  //     listStore = 4;
  //   }
  // }
  if (window.innerWidth < 980) {
    if (totalStore >= 3 || statusOption.value === "open") {
      listStore = 3;
    } else {
      listStore = 1;
    }
  } else {
    if (totalStore >= 4) {
      listStore = 4;
    } else {
      listStore = 2;
    }
  }

   // store 갯수에 따른 row 수
   let slideRow;
   if (window.innerWidth < 980) {
     if (totalStore >= 3 || statusOption.value === "open") {
       slideRow = 3;
     } else {
       slideRow = 1;
     }
   } else {
     if (totalStore >= 4) {
       slideRow = 2;
     } else {
       slideRow = 1;
     }
   }
   // store 갯수에 따른 show
  let slideShow;
  if (totalStore === 1) {
    slideShow = 1;
  } else {
    slideShow = 2;
  }

  const pageStore = Math.ceil(totalStore / listStore); // 총 페이지 수

  // slider custom arrow
  const CustomPrevArrow = props => {
    const { onClick } = props;
    const isDisabled = currentIndex === 1;

    return <div className={isDisabled ? 'listPrevious disabled' : 'listPrevious'} onClick={!isDisabled ? onClick : undefined}></div>;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    const isDisabled = currentIndex === pageStore;
    return <div className={isDisabled ? 'listNext disabled' : 'listNext'} onClick={!isDisabled ? onClick : undefined}></div>;
  };

   

  const settings = {
    customPaging: function (i) {
      return (
        <div>
          <p>{statusOption.pageSlideName} </p>
          <span>{currentIndex}</span>
          <span className="gray">/ {pageStore}</span>
        </div>
      );
    },
    dots: true,
    dotsClass: 'popup-search-dots',
    infinite: true,
    slidesToShow: slideShow,
    row: slideRow,
    slidesPerRow: slideRow,
    slidesToScroll: slideShow,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    arrows: true,
    nextArrow: <CustomNextArrow currentIndex={currentIndex} />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          row: slideRow,
          slidesPerRow: slideRow,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="calendar-popup-list popuplist-board popup-search-list">
        <ul>
          <Slider ref={sliderRef} {...settings}>
            {stores != null &&
              !!stores.length &&
              stores.map((store: any, index) => PopupSliderCard(index, store, myInfo))}
          </Slider>
        </ul>
      </div>
    </div>
  );
}
export default PopupSlider;

function PopupSliderCard(index: any, store: any, myInfo: any) {
  return <li key={index} className="search-banner-wrap">
    <div>
      <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
        <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
      </Link>
      <Link to={`/popup/${store.storeId}`}>
        <ul>
          <li className="popup-name">
            <p>{store.title}</p>
          </li>
          <li className="popup-location">
            <img src={slidemapicon} /> {store.topLevelAddress}
          </li>
        </ul>
      </Link>
      <Bookmark store={store} myInfo={myInfo} />
      <p className="pre-wrap">
        {store.preRegister && store.preRegisterLink !== null && (
          <>
            <span className="pre">사전예약</span>
          </>
        )}
        {store.reservation.length > 0 && <span className="pre own">사전예약</span>}
      </p>
      <p className="popup-date">
        {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
      </p>
    </div>
  </li>;
}

