import { useCallback, useEffect, useRef, useState } from 'react';

import './placelist_style.scss';

function Business() {
  return (
    <article className="placelist-wrap">
      <p>팝플리 비즈 보러가기</p>
    </article>
  );
}

export default Business;
