import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import banner1 from './../../assets/img/banner1.png';
import banner2 from './../../assets/img/banner2.png';
import thumb from './../../assets/img/event_thumb.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
function Banner(bannerList) {
  const [dragging, setDragging] = useState(false);
  const [isAddedEvent, setIsAddedEvent] = useState(false);
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);
  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);
  const moveToDetailPage = (storeId: number, e: any) => {
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      window.location.href = `/popup/${storeId}`;
    }
  };

  useEffect(() => {
    console.log(bannerList.bannerList);
    if (bannerList.bannerList.length > 0 && isAddedEvent === false) {
      bannerList.bannerList.splice(bannerList.bannerList.length, 0, { thumbnail: 'thumb' });
      setIsAddedEvent(true);
    }
  }, [bannerList]);

  const moveToEvent = (index: number, e: any) => {
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      console.log('moveToEvent');
      console.log(index);
      if (index === bannerList.bannerList.length - 1) {
        window.location.href = '/contact/4';
      }
    }
  };

  // slider custom arrow
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };

  const navigate = useNavigate();
  const bn1 = () => {
    window.location.href = 'https://popply.co.kr/award2023/awardWinner';
  };
  const bn2 = () => {
    window.location.href = 'https://popply.co.kr/business/brand';
  };

  const settings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Slider {...settings}>
          {bannerList.bannerList.length > 0 &&
            bannerList.bannerList.map((bl, index) =>
              index === bannerList.bannerList.length - 1 ? (
                <div
                  key={index}
                  className="popupbanner-list"
                  onClick={e => {
                    moveToEvent(index, e); // 팝업스토어 id
                  }}
                >
                  <div className="slide-content">
                    <div className="slide-img-wrap">{<img src={thumb} alt="" />}</div>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className="popupbanner-list"
                  onClick={e => {
                    moveToDetailPage(bl.storeId, e); //팝업스토어 id
                  }}
                >
                  <div className="slide-content">
                    <div className="slide-img-wrap">
                      <img src={bl.thumbnails} alt="" />
                    </div>
                    <div>
                      <p className="slide-tit">{bl.title}</p>
                      <p>
                        {dayjs(bl.startDate).format('YY.MM.DD')} - {dayjs(bl.endDate).format('MM.DD')}
                      </p>
                      <p>
                        <img src={slidemapicon} />
                        {bl.topLevelAddress}
                      </p>
                    </div>
                  </div>
                </div>
              ),
            )}
        </Slider>
      </div>
    </>
  );
}
export default Banner;
