import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import * as api from '../lib/api';
import createRequestSaga from '../lib/createRequestSaga';

// action type
export const FETCH_ONE = 'member/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'member/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'member/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'member/FETCH_LIST';
export const FETCH_LIST_SUCCESS = 'member/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'member/FETCH_LIST_FAILURE';

export const FETCH_COMMENT_LIST = 'member/FETCH_COMMENT_LIST';
export const FETCH_COMMENT_LIST_SUCCESS = 'member/FETCH_COMMENT_LIST_SUCCESS';
export const FETCH_COMMENT_LIST_FAILURE = 'member/FETCH_COMMENT_LIST_FAILURE';

export const FETCH_SAVE_STORE_LIST = 'member/FETCH_SAVE_STORE_LIST';
export const FETCH_SAVE_STORE_LIST_SUCCESS = 'member/FETCH_SAVE_STORE_LIST_SUCCESS';
export const FETCH_SAVE_STORE_LIST_FAILURE = 'member/FETCH_SAVE_STORE_LIST_FAILURE';

export const FETCH_VISIT_STORE_LIST = 'member/FETCH_VISIT_STORE_LIST';
export const FETCH_VISIT_STORE_LIST_SUCCESS = 'member/FETCH_VISIT_STORE_LIST_SUCCESS';
export const FETCH_VISIT_STORE_LIST_FAILURE = 'member/FETCH_VISIT_STORE_LIST_FAILURE';

export const UPDATE_LIKE_STORE = 'member/UPDATE_LIKE_STORE';
export const UPDATE_LIKE_STORE_SUCCESS = 'member/UPDATE_LIKE_STORE_SUCCESS';
export const UPDATE_LIKE_STORE_FAILURE = 'member/UPDATE_LIKE_STORE_FAILURE';

export const UPDATE_VISIT_STORE = 'member/FETCH_VISIT_STORE';
export const UPDATE_VISIT_STORE_SUCCESS = 'member/FETCH_VISIT_STORE_SUCCESS';
export const UPDATE_VISIT_STORE_FAILURE = 'member/FETCH_VISIT_STORE_FAILURE';

// createAction
export const fetchOne = createAction(FETCH_ONE, (userNo: number) => userNo);
export const fetchList = createAction(FETCH_LIST);
export const fetchCommentList = createAction(FETCH_COMMENT_LIST, (userNo: number) => userNo);
export const fetchSaveStoreList = createAction(FETCH_SAVE_STORE_LIST, (userNo: number) => userNo);
export const fetchVisitStoreList = createAction(FETCH_VISIT_STORE_LIST, (userNo: number) => userNo);
export const updateLikeStore = createAction(UPDATE_LIKE_STORE, (userNo: number, storeIds: any) => ({ userNo, storeIds }));
export const updateVisitStore = createAction(UPDATE_VISIT_STORE, (userNo: number) => userNo);

// 비동기 액션을 수행하는 태스크
const fetchOneSaga = createRequestSaga(FETCH_ONE, api.fetchMember);
const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchMemberList);
const fetchCommentListSaga = createRequestSaga(FETCH_LIST, api.fetchCommentList);
const fetchSaveStoreListSage = createRequestSaga(FETCH_SAVE_STORE_LIST, api.fetchLikeStoreList);
const fetchVisitStoreListSage = createRequestSaga(FETCH_VISIT_STORE_LIST, api.fetchVisitStoreList);
const fetchLikeStoreSaga = createRequestSaga(UPDATE_LIKE_STORE, api.updateLikeStore);
// saga function
export function* memberSaga() {
  yield takeLatest(FETCH_ONE, fetchOneSaga);
  yield takeLatest(FETCH_LIST, fetchListSaga);
  yield takeLatest(FETCH_COMMENT_LIST, fetchCommentListSaga);
  yield takeLatest(FETCH_SAVE_STORE_LIST, fetchSaveStoreListSage);
  yield takeLatest(FETCH_VISIT_STORE_LIST, fetchVisitStoreListSage);
  yield takeLatest(UPDATE_LIKE_STORE, fetchLikeStoreSaga);
}

// init state
const initialState = {
  member: null,
  members: [],
  comments: [],
  stores: [],
  error: null,
};

// reducer function
const member = createReducer(initialState, {
  [FETCH_ONE]: state => ({
    ...state,    
  }),
  [FETCH_ONE_SUCCESS]: (state, action) => (
    console.log(action.payload.data),
    {
      ...state,
      member: action.payload.data,
      comments: action.payload.data.comments,
    }
  ),
  [FETCH_ONE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [FETCH_LIST]: state => ({
    ...state,
    members: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    members: action.payload,
    comments: action.payload.data.comments,
  }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [FETCH_SAVE_STORE_LIST]: state => (
    console.log(state),
    {
      ...state,
      members: [],
      stores: [],
    }
  ),
  [FETCH_SAVE_STORE_LIST_SUCCESS]: (state, action) => (
    console.log(action.payload),
    {
      ...state,
      members: action.payload,
      stores: action.payload,
    }
  ),
  [FETCH_SAVE_STORE_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [FETCH_VISIT_STORE_LIST]: state => ({
    ...state,
    members: [],
    stores: [],
  }),
  [FETCH_VISIT_STORE_LIST_SUCCESS]: (state, action) => ({
    ...state,
    members: action.payload,
    stores: action.payload,
  }),
  [FETCH_VISIT_STORE_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [UPDATE_LIKE_STORE]: state => ({
    ...state,
    members: [],
    stores: [],
  }),

  [UPDATE_LIKE_STORE_SUCCESS]: (state, action) => ({
    ...state,
    members: action.payload,
    stores: action.payload,
  }),

  [UPDATE_LIKE_STORE_FAILURE]: (state, action) => ({
    ...state,
    members: action.payload,
    stores: action.payload,
  }),
});

export default member;
