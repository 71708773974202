import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { getOriginalFileName } from './ContactUtil';
import ReactHtmlParser from 'html-react-parser';

import arrow from './../../assets/img/common/arrow_left_small.svg';

function ContactRead({ noticeItem, attachments, isLoading, itemId, onRemove, isAdmin }: any) {
  const handleLinkClick = () => {
    window.open('https://popply.co.kr/award2023/intro', '_blank');
  };
  return (
    <article style={{minHeight:'50vh'}}>
      <Link className='go-back' to={'/contact'} style={{display:'block', marginBottom:'40px'}}><img src={arrow} /> 뒤로</Link>
      {/* {isLoading && '로딩중...'} */}
      {!isLoading && noticeItem && (
        <>
          <div className="view-wrap">
            <h1 className="subject-title">{noticeItem.title}</h1>
            <p>{dayjs(noticeItem.createdAt).format('YYYY-MM-DD')}</p>
            <div className="view-inner">
              {ReactHtmlParser(noticeItem.contents)}
              {noticeItem.noticeId === 3 && (
                <p onClick={handleLinkClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  어워즈 보러가기
                </p>
              )}
            </div>
          </div>
          <div className="likebutton-wrap">
            {isAdmin && (
              <>
                <Link to={`/notice/edit/${itemId}`} className="likebutton">
                  편집
                </Link>
                <button onClick={onRemove} className="likebutton danger">
                  삭제
                </button>
              </>
            )}
            <Link to={`/contact`} className="likebutton">
              목록
            </Link>
          </div>
        </>
      )}
    </article>
  );
}

export default ContactRead;
