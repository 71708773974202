import PopupReportListContainer from '../../../containers/contact/popupreport/PopupReportListContainer';
import MainLayout from '../../../layout/MainLayout';

function PopupReportListPage() {
  return (
    <>
      <MainLayout>
        <PopupReportListContainer />
      </MainLayout>
    </>
  );
}

export default PopupReportListPage;
