import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import awards from './../../../assets/img/banner1.png';
import './modal.scss';

function UserCheckModal({ userCertification }) {
  //레이어 닫기
  const [openLayer, setOpenLayer] = useState(true);
  const layerClick = (isClick: boolean) => {
    setOpenLayer(current => !current);
  };
  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '010-',
  });

  const changeUserInfo = e => {
    if (e.target.id === 'phone') {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
        .replace(/(\-{1,2})$/g, '');
    }
    const { value, id } = e.target;

    setUserInfo({ ...userInfo, [id]: value });
  };
  return (
    <div className={openLayer ? 'modal-popup bg-white' : 'modal-popup hide'}>
      <div className="modal-header">
        <p>예약 시 작성했던 이름과 연락처를 입력해 주세요.</p>
      </div>
      <div className="modal-content">
        <label htmlFor="userNameCheck">
          이름
          <input type="text" id="name" placeholder="이름" onChange={changeUserInfo} />
        </label>
        <label htmlFor="userNumberCheck">
          연락처
          <input type="text" id="phone" maxLength={13} required value={userInfo.phone} onChange={changeUserInfo} />
        </label>
        <button type="button" className="full-btn" onClick={() => userCertification(userInfo)}>
          확인
        </button>
      </div>
    </div>
  );
}

export default UserCheckModal;
