import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

function ContactList({ noticeList, isLoading, isAdmin }: any) {
  //총 4개의 탭으로 구성되어있음. 탭 클릭 시 각각의 컴포넌트로 전환필요.
  // 기본이 notice
  // <faq/>
  // <qna/>
  // <popupReport/>
  return (
    // 공지사항
    <article style={{minHeight:'50vh'}}>
      {/* {isLoading && '로딩중...'} */}

      {!isLoading && noticeList && (
        <>
          {/* <div className="required-read-area">
            <p>
              <span>필독</span>필독공지사항영역입니다.
            </p>
            <p>
              <span>필독</span>반드시 읽어주세요!
            </p>
            <p>
              <span>필독</span>유저들을 위한 필독 공지
            </p>
          </div> */}
          <div className="search-area" style={{ marginTop: '0' }}>
            <input type="search" />
          </div>
          <div className="search-input"></div>
          <div className="contact-wrap">
            <table className="table-board">
              <tbody>
                {!!noticeList.length &&
                  noticeList.map((notice: any) => (
                    <tr key={notice.noticeId}>
                      <td>
                        <Link to={`/contact/${notice.noticeId}`}>{notice.title}</Link>
                      </td>
                      <td>{dayjs(notice.createdAt).format('YYYY-MM-DD HH:MM')}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="page-area">
            <ul>
              {/* <li>
                <a className="prev-btn"></a>
              </li> */}
              <li>
                <a className="on">1</a>
              </li>
              {/* <li>
                <a>2</a>
              </li>
              <li>
                <a>3</a>
              </li> */}
              {/* <li>
                <a className="next-btn"></a>
              </li> */}
            </ul>
          </div>
        </>
      )}
      {/* {!isLoading && noticeList && (
        <>
          {isAdmin && (
            <div className="upside-button-area">
              <Link to="/pds/create" className="likebutton">
                새로 만들기
              </Link>
            </div>
          )}
          <table className="table-board">
            <thead>
              <tr>
                <th align="center">번호</th>
                <th align="center">제목</th>
                <th align="center">날짜</th>
              </tr>
            </thead>
            <tbody>
              {!noticeList.length && (
                <tr>
                  <td colSpan={3}>List is emtpy.</td>
                </tr>
              )}
              {!!noticeList.length &&
                noticeList.map((notice: any) => (
                  <tr key={notice.itemId}>
                    <td align="center">{notice.itemId}</td>
                    <td align="left">
                      <Link to={`/pds/read/${notice.itemId}`}>{notice.itemName}</Link>
                    </td>
                    <td align="center">{notice.viewCnt}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )} */}
    </article>
  );
}

export default ContactList;
