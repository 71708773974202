import SaveStoreListContainer from '../../containers/member/SaveStoreListContainer';
import MainLayout from '../../layout/MainLayout';

export default function SaveStoreListPage() {
  return (
    <>
      <MainLayout>
        <SaveStoreListContainer />
      </MainLayout>
    </>
  );
}
