import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BoardRegisterForm from '../../components/popup/BoardRegisterForm';
import { fetchCategoryList, addStore, addStoreImage, addStoreDev, addStoreImageDev } from '../../lib/api';
import httpStatusHandler from '../../util/httpStatusHandler';

const BoardRegisterContainer = ({ history }: any) => {
  const { myInfo } = useSelector(({ auth }: any) => ({
    myInfo: auth.myInfo,
  }));
  const [categories, setCategoryCodes] = useState([]);
  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    const response = await fetchCategoryList();
    setCategoryCodes(response.data.data);
  };

  // useEffect(() => {
  //   if (myInfo === null) {
  //     alert('로그인이 필요합니다.');
  //     history.push('/signin');
  //   }
  // }, [history, myInfo]);

  const onRegister = async (storeInfo, files) => {
    try {
      storeInfo.address = storeInfo.address + ' ' + storeInfo.detailAddress;
      const addStoreResult = await addStore(storeInfo);
      // const addStoreResult = await addStoreDev(storeInfo);
      console.log(addStoreResult);
      if (addStoreResult.status === 200) {
        const storeId = addStoreResult.data.data.storeId;
        const uploadImages = await addStoreImage(storeId, files);
        // const uploadImages = await addStoreImageDev(storeId, files);
        if (uploadImages.status === 200) {
          alert('등록이 완료되었습니다.');

          return true;
        }
      } else {
        // 팝업등록실패
        alert('등록에 실패했습니다.');
        return false;
      }
    } catch (err) {
      httpStatusHandler(err, history);
    }
  };

  return <>{categories.length > 0 && <BoardRegisterForm onRegister={onRegister} categories={categories} />}</>;
};

export default BoardRegisterContainer;
