import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactMenus from '../../../components/contact/ContactMenus';
import BizQnaRegisterForm from '../../../components/contact/bizqna/BizQnaRegisterForm';
import * as api from '../../../lib/api';
import { addAttach, removeAttach } from '../../../modules/qna';
import httpStatusHandler from '../../../util/httpStatusHandler';
import Footer from '../../../components/common/Footer';

const BizQnaRegisterContainer = () => {
  const navigate = useNavigate();
  const { attachments } = useSelector((state: any) => ({
    attachments: state.faq.attachments,
  }));

  const onRegister = async (bizQna, files) => {
    try {
      const response = await api.addBizQna({ bizQna });
      if (response.status === 200) {
        alert('등록이 완료되었습니다.');
      }
      navigate(`/contact`);
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  return (
    <>
      <ContactMenus onMenu={'bizQna'} />
      <BizQnaRegisterForm onRegister={onRegister} />
      <Footer />
    </>
  );
};

export default BizQnaRegisterContainer;
