import { useEffect, useState } from 'react';
import MagazineSlide from '../MagazineSlide';

// 인사이트4 - 리빙, 금융, 가전/디지털
import awards4 from './../../../assets/magazine/magazine4/living_main.jpg';
import financeMain from './../../../assets/magazine/magazine4/finance_main.jpg';
import dgMain from './../../../assets/magazine/magazine4/dg_main.png';
import lSlide1 from './../../../assets/magazine/magazine4/living_slide1.jpg';
import lSlide2 from './../../../assets/magazine/magazine4/living_slide2.jpg';
import lSlide3 from './../../../assets/magazine/magazine4/living_slide3.jpg';
import lSlide4 from './../../../assets/magazine/magazine4/living_slide4.jpg';
import lSlide5 from './../../../assets/magazine/magazine4/living_slide5.jpg';
import lImg from './../../../assets/magazine/magazine4/living_1.png';
import lreason from './../../../assets/magazine/magazine4/reason.png';
import fSlide1 from './../../../assets/magazine/magazine4/f_slide1.jpg';
import fSlide2 from './../../../assets/magazine/magazine4/f_slide2.jpg';
import fSlide3 from './../../../assets/magazine/magazine4/f_slide3.jpg';
import fSlide4 from './../../../assets/magazine/magazine4/f_slide4.jpg';
import fSlide5 from './../../../assets/magazine/magazine4/f_slide5.jpg';
import fSlide6 from './../../../assets/magazine/magazine4/f_slide6.jpg';
import dSlide1 from './../../../assets/magazine/magazine4/d_slide1.jpg';
import dSlide2 from './../../../assets/magazine/magazine4/d_slide2.jpg';
import dSlide3 from './../../../assets/magazine/magazine4/d_slide3.jpg';
import dSlide4 from './../../../assets/magazine/magazine4/d_slide4.jpg';
import dSlide5 from './../../../assets/magazine/magazine4/d_slide5.jpg';
import freason from './../../../assets/magazine/magazine4/freason.png';
import dreason from './../../../assets/magazine/magazine4/dreason.png';

function MagazineReadDetail4() {
  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* 썸네일 */}
      <div className="magazine-detail-header">
        <h2>MAGAZINE</h2>
        <h1>MZ세대가 선정한 올해의 팝업스토어 - 리빙·금융·가전/디지털 편</h1>
        <ul>
          <li>
            <span>2024.01.02</span>
          </li>
          {/* <li>
                <span>·</span>
              </li>
              <li>
                <span>조회수</span>
                <span>102</span>
              </li>
              <li>
                <span>·</span>
              </li>
              <li>
                <span>좋아요</span>
                <span>5</span>
              </li> */}
        </ul>
      </div>

      {/* 매거진 본문내용 -> 글자는 <p> 태그로 감쌀 수 있도록 해주세여 */}
      <div className="magazine-detail-text-wrap">
            <div className="magazine-detail-content">
              <p className="big-text">리빙</p>
              <p>STANLEY COLOR POP-UP</p>
              <img src={awards4} alt="STANLEY COLOR POP-UP 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className="big-text">화재 현장에서도 유일하게 살아남은 화제의 텀블러! '스탠리'</p>
            </div>
            <div className="magazine-detail-content">
              <MagazineSlide settings={commonSettings} images={[lSlide1, lSlide2, lSlide3, lSlide4, lSlide5]} />
              <p>스탠리가 서울 브루어리와 손잡고 팝업스토어를 진행했다. 9일 동안 진행된 행사는 팝업스토어의 메인 상품이었던 '스탠리 비어 파인트'의 다양한 컬러 옵션을 사용해 팝업스토어 내∙외부 공간을 구성했고, '비어 파인트 퐁 게임', '비어 스테인 챌린지' 등 스탠리 파인트를 활용한 게임을 마련했다. 또한, '서울 브루어리 성수'와의 컬래버레이션을 통해, 양조장 투어 및 맥주 시음을 받을 수 있는 이벤트도 함께 구성함으로써 상품과 팝업스토어의 화제성에 더욱 시너지를 낼 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={lreason} alt="STANLEY COLOR POP-UP 선택 이유 중 1위는 '브랜드를 더 이해할 수 있었어요'이다." />
              <p>그 결과, 오프라인으로 총 6,000명의 방문자를 모았고 온라인으로는 네이버를 통해 1,112개의 콘텐츠 발행, 검색량에서도 팝업스토어가 오픈하기 전 월 대비 72.55%의 증가를 확인할 수 있었다. 이번 어워즈 '리빙' 부문에서도 31%의 유저가 선정 사유로 '브랜드'를 뽑은 결과로 미루어 보아, 팝업스토어 진행이 브랜드 및 상품에 대한 인지도 및 친숙도 증가에 영향을 끼친 것으로 보인다.</p>
            </div>
            <div className="magazine-detail-content">
              <a href="https://www.tiktok.com/@danimarielettering/video/7301724587488759070" target="_blank"><img src={lImg} alt="자동차 폭발에도 살아남은 스탠리 텀블러 틱톡 영상 썸네일" /></a>
              <p className='caption'>출처: 틱톡 @danimarielettering</p>
              <p>한편, 스탠리는 등산족과 캠핑족 사이에서 유리 대신 스테인리스스틸을 단열재로 사용한 최초의 금속 보온병을 발명한 브랜드로 입소문을 타왔으며, 지난 11월에는 화재 차량에서도 전소되지 않고 뚜껑 속 얼음까지 그대로 담겨있는 영상이 SNS를 통해 확산되며 '불사조 텀블러'로 화제가 되는 등 기능성 텀블러에 대한 니즈를 가진 비교적 높은 연령대의 고객에게 제한적인 인지도 및 선호도를 가져왔다. 이번 서울 브루어리와 함께한 팝업스토어를 통해 MZ세대 고객과도 오프라인 접점을 만들 수 있었다. 상품 컬러 각인 및 관련 게임과 이벤트를 통해 자연스럽게 방문객들이 브랜드에 대한 즐거운 경험을 남길 수 있도록 유도함으로써 브랜드의 아이덴티티를 젊은 소비자들에게 각인시키는 데 성공한 것으로 보인다.</p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">금융</p>
              <p>트래블로그 팝업</p>
              <img src={financeMain} alt="트래블로그 팝업스토어 메인 포스터 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className='big-text'>떠나자! 성수 국제 공항으로! (feat. 트래블로그)</p>
              <MagazineSlide settings={commonSettings} images={[fSlide1, fSlide2, fSlide3, fSlide4, fSlide5, fSlide6]} />
              <p>하나은행이 성수동에서 7월 약 한 달간 '트래블로그 성수 국제공항 팝업스토어'를 진행했다. '국제공항'이라는 콘셉트에 맞게 팝업스토어 내•외부는 비행기와 공항을 연상시키는 공간으로 꾸며졌고, 입장 시 탑승권 수령부터, 여행지 선택과 함께 캐리어 파우치 커스텀 체험, 캐리어 태그 이니셜 각인, 스카이 포토존, 트래블로그 키오스크 체험 등 실제 여행 시 이동하는 동선인 공항 도착-비행기 탑승 절차-여행-환전 순으로 공간을 구성했다.</p>
              <p>특히 성수 국제공항 입장권, 캐리어 파우치, 캐리어 태그 이니셜 각인, 하나은행의 녹색 컬러로 꾸며진 각종 포토존과 포토부스, 기내식 키트를 연상케 하는 디저트 패키지 등 디테일한 비주얼 요소들이 많은 방문객들의 인증샷을 부추겨 SNS 콘텐츠 생산까지 이어졌다. 그 결과 관련 인스타그램 콘텐츠가 15만 9천 건 이상 발행되었을 뿐만 아니라 네이버 검색량이 전달 대비 23% 증가했고, 결국 운영 기간 연장 요청 쇄도로 인해 이례적으로 기존의 운영 기간보다 일주일 추가 운영하여 팝업스토어 진행 기간 동안 총 1만 6천 명 이상의 방문객을 기록하는 등 큰 흥행으로 이어졌다.</p>
              <p>또한 팝업 후반부 '트래블로그 이용 시 할인 혜택을 직접 확인할 수 있는 키오스크 체험'을 통해, 충전식 외화 결제를 지원하는 하나은행의 체크카드 ‘트래블로그’를 자연스럽게 경험하도록 함으로써 상품 인지도 및 ‘환율 우대, 해외 가맹점 이용 시 수수료 무료 등’의 매력을 해외 결제 및 여행이 잦은 MZ 고객에게 어필할 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={freason} alt="트래블로그 팝업 선택 이유 1위는 '브랜드를 더 이해할 수 있었어요'이다." />
              <p>최근 유통업계 내 활발했던 팝업스토어 붐이 금융권에도 불어오며 화제를 모으고 있다. 이에 대해 전문가들은 금융권 특유의 보수적이고 딱딱한 이미지를 깨기 위한 움직임으로 해석한다. 그러나 하나은행의 팝업스토어는 이번 어워즈 유저의 49%가 ‘포토존’과 ‘브랜드’를 ‘트래블로그 성수 국제공항 팝업스토어’ 선택 이유로 뽑은 것에서 확인할 수 있듯, 단순히 MZ 세대와의 접점을 만든 것에서 그친 것이 아니라 그들이 SNS에 자랑하고 싶게끔 힙한 공간을 구성했다는 점, 그들에게 어려울 수 있는 금융 상품을 재밌게 경험해 볼 수 있는 활동을 마련한 점이 타 브랜드 대비 더욱 큰 팝업 흥행을 불러올 수 있었던 것으로 보인다.</p>
            </div>

            <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
              <p className="big-text">가전/디지털</p>
              <p>갤럭시 스튜디오</p>
              <img src={dgMain} alt="갤럭시 스튜디오 메인 이미지" />
            </div>
            <div className="magazine-detail-content">
              <p className='big-text'>'이 팝업스토어 안에 니 취향 한 개 쯤은 있겠지'<br />성수 카페거리를 갤럭시S23의 무대로 만들어버린 삼성전자의 '갤럭시 스튜디오'</p>
              <MagazineSlide settings={commonSettings} images={[dSlide1, dSlide2, dSlide3, dSlide4, dSlide5]} />
              <p className='caption'>이미지 출처 : 인스타그램 @tidprosk</p>
              <p>삼성전자가 갤럭시S23 출시를 맞아 약 한 달간 성수와 홍대에서 갤럭시 스튜디오 팝업스토어를 진행했다. 독특한 점은 '로컬 투어' 콘셉트로, 한 공간에서만 팝업스토어를 운영한 것이 아니라 성수동 카페거리 내 6곳과 홍대 일대 6곳에서 동시다발적으로 진행한 점이다. (베이직 스튜디오, for the planet 스튜디오, Connectivity 스튜디오, Hi-res 스튜디오, 원밀리언 댄스 스튜디오 등)</p>
              <p>그중 상대적으로 큰 화제를 불러 모은 성수점은 '베이직 스튜디오'를 메인으로, 신제품인 갤럭시S23을 대여해 자유롭게 사용할 수 있도록 했으며, 팝업스토어 곳곳에 숨은 앤트맨 피규어를 찾아 촬영하는 이벤트를 함께 진행해 방문자들로 하여금 갤럭시S23 울트라에 탑재된 스페이스 줌(100배 확대) 기능을 직접 경험할 수 있도록 했다. 또한 'Explore The Warp' 공간에서는 갤럭시 S23을 통해 고사양의 모바일 게임을 즐길 수 있도록 함으로써, 끊기지 않고 깔끔한 게이밍 환경을 직접 체험해 볼 수 있도록 했다.</p>
              <p>체험뿐만 아니라 미술관 못지않은 퀄리티의 전시로도 화제가 됐다. &lt;Eclipse&gt;전시에서는 갤럭시 S23의 광각, 초광각, 망원 렌즈를 모티프로 하여, 세 개의 원판을 감각적으로 형상화 한 작품을 즐길 수 있었고, 네온사인으로 가득한 &lt;Explore The Darkness&gt; 전시를 통해서는 갤럭시 S23의 나이트크래피 기능이 돋보일 수 있는 있는 공간을 마련했다. 베이직 하우스 외에도 5곳의 팝업스토어를 동시 운영하며, 갤럭시 S23을 활용한 나만의 폰트 만들기, 재활용 소재를 사용한 키링 제작, 설치 미술 전시, 갤럭시 S23과 컬래버레이션한 디저트 판매, 댄스팀 원밀리언과 컬래버레이션한 댄스 스튜디오 오픈 등 알찬 이벤트 구성으로 가지각색의 취향과 니즈를 가진 MZ고객을 모두 사로잡을 수 있었다.</p>
            </div>
            <div className="magazine-detail-content">
              <img src={dreason} alt="갤럭시 스튜디오 선택 이유 1위는 '브랜드를 더 이해할 수 있었어요'이다." />
              <p>그 결과, 네이버를 통해 7만 7천 개 이상의 팝업스토어 관련 콘텐츠가 생산되었고, 검색량에서도 전달 대비 834%의 증가율을 확인할 수 있었다. 그뿐만 아니라 어워즈에서도 유저의 55%가 '브랜드'와 '이벤트'를 선정 이유로 뽑으며 갤럭시 스튜디오가 어워즈 가전/디지털 부문 대상을 차지하게 되었다.</p>
              <p>이렇듯 최근 브랜드는 팝업스토어를 단순히 '제품' 판매가 목적이 아닌, 방문객들로 하여금 브랜드와 제품을 직접 체험하고 즐길 수 있는 '경험'을 판매하는 공간으로 활용하고 있다. 이를 통해 소비자들은 팝업스토어 방문을 통해 쉽게 다양한 브랜드 경험을 향유할 수 있게 되었고, 차별화된 경험을 다시 SNS를 통해 나를 표현하는 수단으로 공유하며 또 다른 새로운 고객을 유치할 수 있도록 하는 선순환 구조를 만들고 있다. 갤럭시 스튜디오 또한 팝업스토어에서 갤럭시 S23을 직접적으로 판매하지는 않았지만, 신제품 '갤럭시 S23'의 강점 및 삼성전자의 아이덴티티를 다양한 이벤트와 체험 활동에 녹여냄으로써 브랜드와 고객을 잇는 데에 긍정적인 결과를 불러일으킨 것으로 보인다.</p>
            </div>
          </div>
    </>
  );
}

export default MagazineReadDetail4;
