import { FormEvent, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import './placelist_style.scss';

function AdminPlaceListRegister({ currentBannerList, onRegister, onBannerRegister }) {
  const today = dayjs('2024-06-30');
  const [storeIds, setStoreIds] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState('custom');
  const [isThumbnailStore, setIsThumbnailStore] = useState(false);

  const [placelistInfo, setPlacelistInfo] = useState({
    title: null,
    year: dayjs().year(),
    month: today.month() + 1,
    week: weekOfMonthForStandard(today),
    storeIds: '', // hashtagArr
    isThumbnailStore: isThumbnailStore,
    thumbnailStoreId: 946,
    thumbnail: '',
  });

  useEffect(() => {
    const weeks = weekOfMonthForStandard(today);
    setPlacelistInfo({ ...placelistInfo, week: weeks });
    // alert(weeks);
  }, []);
  const [bannerList, setBannerList] = useState();
  const handleOptionChange = event => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // B가 선택되면 C를 활성화하고, D를 비활성화
    if (selectedValue === 'storeId') {
      setIsThumbnailStore(true);
      setPlacelistInfo({ ...placelistInfo, isThumbnailStore: true });
    } else {
      setIsThumbnailStore(false);
      setPlacelistInfo({ ...placelistInfo, isThumbnailStore: false });
    }
  };

  const changePlaceInfos = e => {
    const { value, id } = e.target;
    setPlacelistInfo({ ...placelistInfo, [id]: value });
  };

  const changeBannerList = e => {
    const ids = e.target.value;
    setBannerList(ids);
  };

  const hashKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const tag = e.currentTarget.value.trim();
    const $HashWrapOuter = document.querySelector('.HashWrapOuter');

    if (e.key === ' ' && tag !== '') {
      setStoreIds(prevStoreIds => [...new Set([...prevStoreIds, tag])]);
      setPlacelistInfo(prevState => ({
        ...prevState,
        storeIds: [...new Set([...storeIds, tag])].toString(),
      }));
      e.currentTarget.value = '';
    }
  };

  const removeHashTag = (removeStoreId: string) => {
    setStoreIds(prevStoreIds => prevStoreIds.filter(tag => tag !== removeStoreId));
    setPlacelistInfo(prevState => ({
      ...prevState,
      storeIds: storeIds.filter(tag => tag !== removeStoreId).toString(),
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      onRegister(placelistInfo);
    }
  };
  const handleBannerSubmit = (e: FormEvent) => {
    e.preventDefault();
    const isConfirm = window.confirm(`${bannerList}가 맞습니까?`);
    if (isConfirm) {
      onBannerRegister(bannerList);
    }
  };

  function weekOfMonthForSimple(date) {
    const firstDay = dayjs(date).startOf('month');
    const firstMonday = firstDay.add((8 - firstDay.day()) % 7, 'day');
    const isFirstDayMonday = firstDay.isSame(firstMonday, 'day');
    const different = date.diff(firstMonday, 'day');
    return Math.floor(different / 7 + (isFirstDayMonday ? 1 : 2));
  }

  // 동일한 주차인지 확인
  function isSameWeek(dateTime1, dateTime2) {
    return weekOfMonthForSimple(dateTime1) === weekOfMonthForSimple(dateTime2);
  }

  // 월 주차 (정식 규정에 따라서 계산)
  function weekOfMonthForStandard(date) {
    const firstDay = dayjs(date).startOf('month');
    const lastDay = dayjs(date).endOf('month');
    const isFirstDayBeforeThursday = firstDay.day() <= 4;

    if (isSameWeek(date, firstDay)) {
      if (isFirstDayBeforeThursday) {
        return 1;
      } else {
        return weekOfMonthForStandard(firstDay.subtract(1, 'day'));
      }
    } else if (isSameWeek(date, lastDay)) {
      const isLastDayBeforeThursday = lastDay.day() >= 4;
      return isLastDayBeforeThursday ? weekOfMonthForSimple(date) + (isFirstDayBeforeThursday ? 0 : -1) : 1;
    } else {
      return weekOfMonthForSimple(date) + (isFirstDayBeforeThursday ? 0 : -1);
    }
  }
  return (
    <article className="inner admin">
      <div className="submenu admin">
        <a href="/board/create">
          <h2>팝업스토어 등록</h2>
        </a>
        <a className="on" href="/placelist/create">
          <h2>플레이스리스트 등록</h2>
        </a>
        <a>
          <h2>브랜드 관리</h2>
        </a>
        <a>
          <h2>매거진 관리</h2>
        </a>
      </div>
      <p style={{ textAlign: 'center', marginBottom: '20px' }}>*PC 최적화 화면입니다. 가급적 PC에서 작업해주시기 바랍니다.</p>
      <p style={{ textAlign: 'center', marginBottom: '20px' }}>
        <div>*현재 메인 배너 : {currentBannerList.map(banner => banner.storeId).join(', ')}</div>
        <div className="likebutton-wrap" style={{ justifyContent: 'center', alignItems: 'center' }}>
          *메인 배너 등록(띄어쓰기 금지, 쉼표로 구분, 순차입력) : <input type="text" onChange={changeBannerList} placeholder="1,2,3,4,5" />
          <button type="submit" onClick={handleBannerSubmit} className="likebutton">
            등록
          </button>
        </div>
        {/* <button onClick={handleBannerSubmit}>등록</button> */}
      </p>
      <div className="admin-popupform">
        {/* 팝업 등록 폼 영역 */}
        <div className={'admin-popupform-write placelist-popupform'}>
          <form onSubmit={handleSubmit}>
            <table>
              <colgroup>
                <col width={'10%'} />
                <col width={'*'} />
              </colgroup>
              <tbody>
                <tr>
                  <td>플레이스리스트 제목</td>
                  <td>
                    <input type="text" id="title" onChange={changePlaceInfos} value={placelistInfo.title} placeholder={'플레이스리스트 제목을 적어주세요.'} />
                  </td>
                </tr>

                <tr>
                  <td>연/월/주</td>
                  <td>
                    <input type="number" className="p_brand" id="year" defaultValue={placelistInfo.year} onChange={changePlaceInfos} placeholder="연" />년
                    <br />
                    <input type="number" className="p_brand" id="month" defaultValue={placelistInfo.month} onChange={changePlaceInfos} placeholder="월" />월
                    <input type="number" className="p_brand" id="week" defaultValue={placelistInfo.week} onChange={changePlaceInfos} placeholder="N주차" />
                    주차
                  </td>
                </tr>

                <tr>
                  <td>팝업스토어 ID</td>
                  <td>
                    {/* <div className="HashWrap" style={hashDivrap}> */}
                    <p className="p_input_info_text">*스페이스바를 누르면 팝업 아이디가 등록됩니다. (예: 1345)</p>
                    <div className="placelist-popup-id-list">
                      <ul>
                        {storeIds.map((atag, idx) => (
                          <li key={idx} onClick={() => removeHashTag(atag)}>
                            {atag} <span> x</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="HashWrap">
                      <div className="HashWrapOuter"></div>
                      <input type="number" id="storeIds" onKeyUp={hashKeyUp} placeholder={'팝업아이디를 입력해주세요'} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>썸네일 설정</td>
                  <td>
                    <div className="setting-thumb">
                      <label>
                        <input type="radio" value="custom" name="options" checked={selectedOption === 'custom'} onChange={handleOptionChange} />
                        직접 입력하기
                      </label>
                      <label>
                        <input type="radio" value="storeId" name="options" checked={selectedOption === 'storeId'} onChange={handleOptionChange} />
                        스토어ID 입력하기
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div style={{ display: isThumbnailStore ? 'none' : 'block' }}>
                      썸네일 이미지 링크: <input type="text" style={{ width: '75%' }} disabled={isThumbnailStore} value={placelistInfo.thumbnail} placeholder="https://cf-templates-..." />
                    </div>
                    <div style={{ display: isThumbnailStore ? 'block' : 'none' }}>
                      대표 스토어ID: <input type="number" disabled={!isThumbnailStore} id="thumbnailStoreId" onChange={changePlaceInfos} placeholder="1433" />
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <td>썸네일 주소</td>
                  <td>
                    <input
                      type="text"
                      id="thumbnail"
                      onChange={e => {
                        setPlacelistInfo(prevState => ({
                          ...prevState,
                          thumbnail: e.target.value,
                        }));
                      }}
                      placeholder={'썸네일에 사용 될 이미지 url'}
                      value={placelistInfo.thumbnail}
                      disabled={isThumbnailStore}
                    />
                  </td>
                </tr> */}
              </tbody>
            </table>

            <div className="likebutton-wrap">
              <button type="submit" className="likebutton">
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </article>
  );
}
export default AdminPlaceListRegister;
