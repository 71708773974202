import { Link, useNavigate } from 'react-router-dom';
// import { getDownloadURL, getOriginalFileName } from './PdsUtil';
import { getOriginalFileName } from './PdsUtil';
// import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import MagazineSlide from './MagazineSlide';

// 각 magazineId별 컴포넌트
import MagazineReadDetail1 from './magazineReadDetail/MagazineReadDetail1';
import MagazineReadDetail2 from './magazineReadDetail/MagazineReadDetail2';
import MagazineReadDetail3 from './magazineReadDetail/MagazineReadDetail3';
import MagazineReadDetail4 from './magazineReadDetail/MagazineReadDetail4';
import MagazineReadDetail5 from './magazineReadDetail/MagazineReadDetail5';

import shareicon from './../../assets/img/popup/share-icon.svg';
import kakao from './../../assets/img/kakao_login_wide.png';

// 인사이트 배너
import bn1 from './../../assets/magazine/banner_1.png';
import bn2 from './../../assets/magazine/banner_2.png';
import bn3 from './../../assets/magazine/banner_3.png';

// 매거진 썸네일
import awards1 from './../../assets/magazine/awards_listThumbnail1.png';
import awards2 from './../../assets/magazine/awards_listThumbnail2.png';
import awards3 from './../../assets/magazine/awards_listThumbnail3.png';
import awards4 from './../../assets/magazine/awards_listThumbnail4.png';
import awards5 from './../../assets/magazine/awards_listThumbnail5.png';

function MagazineRead({ magazineId }: any) {
  // function MagazineRead({ magazineItem, isLoading, itemId, onRemove, isAdmin }: any) {
  // 스크롤에 따라 하단 바 생겼다 사라졌다 함
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [ScrollActive, setScrollActive] = useState(false);
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < prevScrollY) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
    setPrevScrollY(currentScrollY);
  };
  useEffect(() => {
    function scrollListener() {
      window.addEventListener('scroll', handleScroll);
    }
    scrollListener();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // 매거진 하단 배너롤링
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    swipeToSlide: true,
  };

  // 매거진 하단 다른 매거진
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    slidesPerRow: 1,
    arrows: false,
    swipeToSlide: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const currentMagazineId = magazineId;
  // 이미 존재하는 ID 리스트
  const existingIds = [1, 2, 3, 4, 5];
  // 현재 페이지의 magazineId를 제외한 나머지 ID 리스트
  const remainingIds = existingIds.filter(id => id !== currentMagazineId);

  // 이미지 변수를 배열로 정의
  const awardsImages = [awards1, awards2, awards3, awards4, awards5];
  const tit = ['MZ세대가 선정한 올해의 팝업스토어 - 뷰티·패션 편', 'MZ세대가 선정한 올해의 팝업스토어 - 음식·음료 편', 'MZ세대가 선정한 올해의 팝업스토어 - 캐릭터·게임 편', 'MZ세대가 선정한 올해의 팝업스토어 - 리빙·금융·가전/디지털 편', 'MZ세대가 선정한 올해의 팝업스토어 - 연예·콘텐츠·취미/여가 편'];

  // 랜덤하게 3개의 ID 선택
  const getRandomMagazineIds = () => {
    const randomIds = [];
    while (randomIds.length < 3) {
      const randomIndex = Math.floor(Math.random() * remainingIds.length);
      const randomId = remainingIds[randomIndex];
      if (!randomIds.includes(randomId)) {
        randomIds.push(randomId);
      }
    }
    return randomIds;
  };

  const [randomMagazineIds, setRandomMagazineIds] = useState(getRandomMagazineIds);

  useEffect(() => {
    // 페이지에 진입할 때마다 랜덤한 3개의 ID를 업데이트
    setRandomMagazineIds(getRandomMagazineIds());
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentMagazineId]); // currentMagazineId가 변경될 때마다 useEffect 실행

  // 공유 버튼
  const handleCopyClipBoard = async () => {
    const pageTitle = document.title;
    const pageDescription = document.querySelector("meta[property='og:description']").getAttribute('content');

    if (!pageDescription) {
      alert('복사에 실패했습니다.');
      return;
    }
    const copiedText = `${pageTitle}\n${window.location.href}`;
    try {
      await navigator.clipboard.writeText(copiedText);
      alert('클립보드에 복사했습니다!');
    } catch (err) {
      alert('복사에 실패했습니다.');
    }
  };

  const navigate = useNavigate();
  const targetMagazineId = magazineId === 1 ? 2 : 1;
  const dynamicPath = `/magazine/${targetMagazineId}`;
  const handleClick = () => {
    // 페이지 이동 후 스크롤 제일 상단으로 이동
    navigate(dynamicPath);
    window.scrollTo(0, 0);
  };


  // //좋아요 버튼
  // const [goodOn, setGoodOn] = useState(false);
  // const goodClick = (isClick: boolean) => {
  //   setGoodOn(current => !current);
  // };
  return (
    <article style={{ overflow: 'visible' }}>
      {magazineId === 1 && <MagazineReadDetail1 />}
      {magazineId === 2 && <MagazineReadDetail2 />}
      {magazineId === 3 && <MagazineReadDetail3 />}
      {magazineId === 4 && <MagazineReadDetail4 />}
      {magazineId === 5 && <MagazineReadDetail5 />}

      {/* 하단 공통영역 */}
      <div className="magazine-detail-bottom">
        <div>
          <p className="title">우리들의 팝한 플레이스 리스트, 팝플리!</p>
          <p>본 콘텐츠는 저작권법에 의하여 보호받는 저작물입니다.</p>
          <p>본 콘텐츠는 사전 동의 없이 상업적 무단복제와 수정, 캡처 후 배포 도용을 절대 금합니다.</p>
        </div>
        <div className="biz">
          <Link to="/bizQna">비즈니스 문의</Link>
          팝업스토어 비즈니스 문의 : popply@popply.co.kr
        </div>
        <Slider {...settings}>
          <a href="https://www.inuscomm.co.kr/work/categories/popupstore" target="_blank">
            <img src={bn1} alt="팝플리에서 진행한 팝업스토어 바로가기" />
          </a>
          <a href="https://www.inuscomm.co.kr/news-2/%EC%9D%B4%EB%84%88%EC%8A%A4%EC%BB%A4%EB%AE%A4%EB%8B%88%ED%8B%B0%2C-2023%EB%85%84-%ED%8C%9D%EC%97%85%EC%8A%A4%ED%86%A0%EC%96%B4-%EB%8C%80%EC%83%81-12%EA%B0%9C-%EB%B8%8C%EB%9E%9C%EB%93%9C-%EB%B0%9C%ED%91%9C-" target="_blank">
            <img src={bn2} alt="미디어가 주목한 팝플리 바로 가기" />
          </a>
          <a href="https://popply.co.kr/award2023/awardWinner" target="_blank">
            <img src={bn3} alt="팝업스토어 어워즈 2023 대상 발표 보러 가기" />
          </a>
        </Slider>
        <div className="more-contents">
          <p>
            다른 매거진 <span>더보기</span>
          </p>
          <Slider {...settings2}>
            {randomMagazineIds.map(id => (
              <Link key={id} to={`/magazine/${id}`} className="more-contents-wrap">
                <img src={awardsImages[id - 1]} alt="" />
                <p>{tit[id - 1]}</p>
              </Link>
            ))}
          </Slider>
          <p className="toList">
            <Link to="/magazine" />
          </p>
        </div>
      </div>

      {/* 하트 / 공유버튼 */}
      <div className={ScrollActive ? 'magazine-fix-bar' : 'magazine-fix-bar hide'}>
        <ul>
          {/* <li>
              <span>999</span>
              <img
                // onClick={() => {
                //   goodClick(true);
                // }}
                // className={goodOn ? bookmark_on : bookmark}
                alt=""
              />
            </li> */}
          <li>
            <button type="button" className="ga-event" data-event_name="공유_매거진" onClick={handleCopyClipBoard}>
              <img src={shareicon} alt="" />
              <p>공유하기</p>
            </button>
          </li>
        </ul>
      </div>
    </article>
  );
}

export default MagazineRead;
