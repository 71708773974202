
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ScrollToPostionProvider = ({children}) => {
  const [searchParams, setSearchParams] = useSearchParams();  
  const scroll_position = searchParams.get("scroll_position");
  useEffect(() => {
    if(scroll_position) {
      window.scrollTo(0, Number(scroll_position));
      searchParams.delete("scroll_position")
      setSearchParams( searchParams);
    }
  }, [scroll_position]);
return <>{children}</>;
}

export default ScrollToPostionProvider;