import BoardRegisterContainer from '../../containers/popup/BoardRegisterContainer';
import MainLayout from '../../layout/MainLayout';

function BoardRegisterPage() {
  return (
    <>
      <MainLayout>
        <BoardRegisterContainer />
      </MainLayout>
    </>
  );
}

export default BoardRegisterPage;
