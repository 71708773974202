import ContactListContainer from '../../containers/contact/ContactListContainer';
import MainLayout from '../../layout/MainLayout';

function ContactListPage() {
  return (
    <>
      <MainLayout>
        <ContactListContainer />
      </MainLayout>
    </>
  );
}

export default ContactListPage;
