import { useParams } from 'react-router-dom';
import ReservationQrContainer from '../../../containers/popup/reservation/ReservationQRContainer';
import MainLayout from '../../../layout/MainLayout';

function ReservationQrPage({ match }: any) {
  const { storeId, reservationId, hash } = useParams();
  return (
    <>
      <MainLayout>
        <ReservationQrContainer storeId={storeId} reservationId={reservationId} hash={hash} />
      </MainLayout>
    </>
  );
}

export default ReservationQrPage;
