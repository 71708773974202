import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import btnImg1 from './../../../assets/img/event-template/btn1.png';
import btnImg2 from './../../../assets/img/event-template/btn2.png';

function EventBtn({ changePromotionMsg }) {
  //버튼1 클릭
  const [sendMsgBtn, setSendMsgBtn] = useState(true);
  const setSendMsgOpen = (isClick: boolean) => {
    setSendMsgBtn(current => !current);
  };

  //버튼2 클릭
  const [promoWinnerListBtn, setPromoWinnerListBtn] = useState(true);
  const setPromoWinnerListOpen = (isClick: boolean) => {
    setPromoWinnerListBtn(current => !current);
  };

  return (
    <div className="event-btn-wrap">
      <div
        className="event-btn"
        onClick={() => {
          setSendMsgOpen(true);
        }}
      >
        <div className="event-btn-img">
          <img src={btnImg1} alt="" />
        </div>
        <div className={sendMsgBtn ? 'event-btn-open' : 'event-btn-open open'}>
          <p className="event-btn-open-text" onClick={e => changePromotionMsg(e)}>
            방문 메세지 남기기
          </p>
        </div>
      </div>
      <div
        className="event-btn"
        onClick={() => {
          setPromoWinnerListOpen(true);
        }}
      >
        <div className="event-btn-img">
          <img src={btnImg2} alt="" />
        </div>
        <div className={promoWinnerListBtn ? 'event-btn-open' : 'event-btn-open open'}>
          <p className="event-btn-open-text">
            <Link to="/event/4/winner">이벤트 당첨자 보기</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
export default EventBtn;
