import { useNavigate } from 'react-router-dom';
import ContactMenus from '../../../components/contact/ContactMenus';
import PopupReportRegisterForm from '../../../components/contact/popupreport/PopupReportRegisterForm';
import { addPopupReport, addPopupReportImg } from '../../../lib/api';

import httpStatusHandler from '../../../util/httpStatusHandler';

const PopupReportRegisterContainer = () => {
  const navigate = useNavigate();
  const onRegister = async (storeInfo, files) => {
    try {
      storeInfo.address = storeInfo.address + ' ' + storeInfo.detailAddress;
      const addStoreResult = await addPopupReport(storeInfo);
      if (addStoreResult.status === 200) {
        const uploadImages = await addPopupReportImg(addStoreResult.data.data.popupReportId, files);
        if (uploadImages.status === 200) {
          alert('등록이 완료되었습니다.');
          navigate(`/`);
        }
      } else {
        alert('이미지 등록이 실패했습니다.');
      }
    } catch (err) {
      httpStatusHandler(err, null);
    }
  };

  return (
    <>
      <ContactMenus onMenu={'popupreport'} />
      <PopupReportRegisterForm onRegister={onRegister} />
    </>
  );
};

export default PopupReportRegisterContainer;
