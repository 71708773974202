import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../../lib/api';
import httpStatusHandler from '../../../util/httpStatusHandler';
import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
import { fetchAttachList, fetchOne, FETCH_ONE, resetAttach } from '../../../modules/popupreport';
import PopupReportRead from '../../../components/contact/popupreport/PopupReportRead';
import ContactMenus from '../../../components/contact/ContactMenus';
import Footer from '../../../components/common/Footer';

const PopupReportReadContainer = ({ itemId }) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  const { popupReportItem, isLoading, isAdmin } = useSelector((state: any) => ({
    popupReportItem: state.popupreport,
    isLoading: state.loading[FETCH_ONE],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchOne(itemId));
  }, [dispatch, itemId]);

  return (
    <>
      <ContactMenus onMenu={'popupreport'} />
      <PopupReportRead popupReportItem={popupReportItem} isLoading={isLoading} itemId={itemId} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default PopupReportReadContainer;
