import { useState } from 'react';
import './WorkingHours.scss';
import dayjs from 'dayjs';
import { convertToKoreanDay } from '../../util/InusUtils';

export const simplifyWorkingHours = (workingTime) => {
  const dayOrder = ['월', '화', '수', '목', '금', '토', '일'];
  let groupedHours = [];
  let currentGroup = null;

  // 요일 정보가 없는 항목 필터링
  const validWorkingTime = workingTime.filter(wt => wt.day && dayOrder.includes(wt.day));

  validWorkingTime.sort((a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day));

  validWorkingTime.forEach((wt) => {
    const hours = wt.holiday ? '휴무' : `${wt.startDate} - ${wt.endDate}`;
    
    if (!currentGroup || currentGroup.hours !== hours) {
      if (currentGroup) {
        groupedHours.push(currentGroup);
      }
      currentGroup = { days: [wt.day], hours: hours };
    } else {
      currentGroup.days.push(wt.day);
    }
  });

  if (currentGroup) {
    groupedHours.push(currentGroup);
  }

  return groupedHours.map(group => ({
    days: formatDays(group.days),
    hours: group.hours
  }));
};

const formatDays = (days) => {
  if (days.length > 1) {
    return `${days[0]}~${days[days.length - 1]}`;
  } 
  else {
    return days.join(', ');
  }
};
const WorkingHours = ({ workingTime , expanded } : {workingTime: any[], expanded?: boolean}) => {
  const workingTimeList = workingTime.filter(w => w.day)
  // const [isExpanded, setIsExpanded] = useState(expanded??false);
  const simplifiedHours = simplifyWorkingHours(workingTimeList);
  const today = convertToKoreanDay(dayjs().format('ddd'));
  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };  
  return (
    <>
   
        {expanded && (
        simplifiedHours.map((item, index) => {
        
        return (          
          <li key={index} className="working-hours__item">
            <span className="working-hours__days">{item.days} :&nbsp;</span>
            <span className="working-hours__time">{item.hours} {today === item.days ? " (오늘)": ""}</span>
            {/* {index === 0 && (<div  className="working-hours__toggle" onClick={() => toggleExpand()} >
           {isExpanded ? '▲' : '▼'}
          </div>)} */}
          </li>              
          )
        } ))         
        }       
        {!expanded && (        
          workingTimeList.map((item, index) => {
            if(!item.day) return <></>;
            const hours = item.holiday ? '휴무' : `${item.startDate} - ${item.endDate}`;            
            return (
            <li key={index} className={`working-hours__item ${today === item.day ? 'working-hours__item-today' : ''}`}>
              <span className="working-hours__days">{item.day} :&nbsp;</span>
              <span className="working-hours__time">{hours} {today === item.day ? " (오늘)": ""}</span>
              {/* {index === 0 && (<div  className="status-filter__toggle" onClick={() => toggleExpand()} >
              {isExpanded ? '▲' : '▼'}</div>)} */}
            </li> )
          })
        )
        }
          
    </>
  );
};

export default WorkingHours;