import React, { useEffect, useState } from 'react';
import topicon from './../../assets/img/layout/top.svg';

function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY >= 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);

  return (
    <>
      {showButton && (
        <div className="scroll__container">
          <button id="top" onClick={scrollToTop} type="button">
            <img src={topicon} />
          </button>
        </div>
      )}
    </>
  );
}

export default ScrollToTop;

// import {useEffect} from 'react';

// function scrollToTop() {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   useEffect(() => {
//     // 👇️ scroll to top on page load
//     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
//   }, []);

//   return (
//     <div>
//       <h2>Top of the page</h2>

//       <div style={{height: '155rem'}} />

//       {/* 👇️ scroll to top on button click */}
//       <button
//         onClick={() => {
//           window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
//         }}
//         style={{
//           position: 'fixed',
//           padding: '1rem 2rem',
//           fontSize: '20px',
//           bottom: '40px',
//           right: '40px',
//           backgroundColor: '#0C9',
//           color: '#fff',
//           textAlign: 'center',
//         }}
//       >
//         Scroll to top
//       </button>
//     </div>
//   );
// }

// export default scrollToTop
