import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ONE, fetchOne } from '../../modules/survey';
import { useNavigate } from 'react-router-dom';
import PreSurvey from '../../components/survey/preSurvey';

const PreSurveyContainer = ({ storeId, reservationId, hash }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { survey, isLoading, myInfo } = useSelector((state: any) => ({
    survey: state.survey,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));

  const [continueWithUser, setContinueWithUser] = useState(false);

  const onRegister = async data => {
    try {
      data.hash = hash;
      // const addSurveyResult = await addPreSurveyUser({ userId: myInfo.userId, reservationId, surveyId: survey.survey.surveyId, surveyUser: {data} });
      // if (addSurveyResult.status === 200) {
      //   console.log(addSurveyResult);
      //   if (addSurveyResult.data === null) {
      //     alert('이미 설문조사에 참여했습니다.');
      //   }
      alert('설문 제출에 성공했습니다 .감사합니다.');
      navigate(`/popup/${storeId}/reservation/pre`);
      // }
    } catch (err) {
      alert('설문 제출에 실패했습니다.');
    }
  };
  useEffect(() => {
    dispatch(fetchOne(storeId, reservationId, 'PRE', myInfo ? myInfo.userId : null));
  }, [dispatch]);

  console.log(hash);
  useEffect(() => {
    if (!myInfo && hash) {
      const isConfirm = window.confirm('회원으로 진행하시겠습니까?');
      if (!isConfirm) setContinueWithUser(false);
      else if (isConfirm) {
        setContinueWithUser(true);
      }
    }
  }, [myInfo]);
  return <>{survey.survey && <PreSurvey reservationId={reservationId} survey={survey.survey} reservationType={'PRE'} isLoading={isLoading} myInfo={myInfo} hash={hash} onRegister={onRegister} continueWithUser={continueWithUser} />}</>;
};

export default PreSurveyContainer;
