import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PreReservationStoreList from '../../components/member/PreReservationStoreList';
import { FETCH_LIST, fetchList } from '../../modules/userReservation';
import Footer from '../../components/common/Footer';

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; //DEV

const PreReservationContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const onSignIn = () => {
    const state = location.pathname + location.search;
    const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
    window.location.href = kauthUrl;
  };
  const { userReservations, isLoading, myInfo } = useSelector((state: any) => ({
    userReservations: state.userReservation.userReservations,
    isLoading: state.loading[FETCH_LIST],
    myInfo: state.auth.myInfo,
  }));

  let userObj;

  useEffect(() => {
    if (myInfo) {
      dispatch(fetchList(myInfo.userId, 'PRE'));
    }
  }, [dispatch]);
  
  return (
    <>
      {!isLoading && <PreReservationStoreList userReservations={userReservations} isLoading={isLoading} onSignIn={onSignIn} />}
      <Footer />
    </>
  );
};

export default PreReservationContainer;
