import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne, FETCH_ONE } from '../../../modules/userReservation';
import ReservationConfirmResult from '../../../components/reservation/ReservationConfirmResult';
import React from 'react';
import UserCheckModal from '../../../components/common/modal/UserCheckModal';
import Cookies from 'js-cookie';
import * as api from '../../../lib/api';
import { useNavigate } from 'react-router-dom';
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV
const ReservationConfirmResultContainer = ({ storeId, reservationId, hash, isContinue }) => {
  const dispatch = useDispatch();
  const [isPassed, setIsPassed] = useState(false);
  const navigate = useNavigate();

  const { userReservation, isLoading, myInfo } = useSelector((state: any) => ({
    userReservation: state.userReservation.userReservation,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));
  const [waitingCount, setWaitingCount] = useState(0);
  useEffect(() => {
    if (userReservation) {
      if (userReservation.userReservation) {
        setWaitingCount(userReservation.waitingCount);
      }
    }
  }, [userReservation]);
  useEffect(() => {
    dispatch(fetchOne(storeId, reservationId, myInfo ? myInfo.userId : null, hash));
  }, [dispatch]);

  const userCertification = async userInfo => {
    if (userReservation.userReservation.name === userInfo.name && userReservation.userReservation.phone === userInfo.phone) {
      setIsPassed(true);
    } else {
      alert('일치하는 예약 내역이 없습니다.');
    }
  };
  const updateReservationStatus = async () => {
    try {
      const updateStatus = {
        status: 'CANCEL',
        hash: userReservation.userReservation.hash,
      };
      const response = await api.updateUserReservation1({ userReservation: updateStatus });
      if (response.status === 200) {
        alert('예약이 취소되었습니다.');
        navigate(`/`);
      }
    } catch (err: any) {
      if (err.response.status === 400) {
        alert('잘못된 요청입니다.');
      } else if (err.response.status === 401) {
        alert('로그인이 필요합니다.');
        // history.push('/signin');
      } else {
        alert(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    const userCookie = Cookies.get('reservationUser');
    if (userCookie) {
      userCertification(JSON.parse(userCookie));
    }
    if (userReservation && !userReservation.userReservation.reservation.availableGuest) {
      setIsPassed(true);
    }
  }, [userReservation]);
  return (
    <>
      {!isLoading && userReservation && userReservation.userReservation.reservation.availableGuest === true && isPassed === false && <UserCheckModal userCertification={userCertification} />}
      {!isLoading && userReservation && isPassed && <ReservationConfirmResult type={userReservation.userReservation.reservation.type} userReservation={userReservation.userReservation} waitingCount={waitingCount} isLoading={isLoading} myInfo={myInfo} updateReservationStatus={updateReservationStatus} />}
    </>
  );
};

export default ReservationConfirmResultContainer;
