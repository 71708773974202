import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

function StarRate({ reservationType, isLoading, myInfo }: any) {
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rating = parseInt(e.target.value, 10);
    setSelectedRating(rating);

    // 각 라디오 버튼을 클릭했을 때 span.num과 span.text에 값을 할당
    const numSpan = document.querySelector('.star-text .num');
    const textSpan = document.querySelector('.star-text .text');

    if (numSpan && textSpan) {
      numSpan.textContent = String(rating);
      textSpan.textContent = getTextForRating(rating);
    }
  };

  const getTextForRating = (rating: number): string => {
    switch (rating) {
      case 1:
        return '(정말 별로예요)';
      case 2:
        return '(추천 안해요)';
      case 3:
        return '(보통이에요)';
      case 4:
        return '(좋아요)';
      case 5:
        return '(아주 만족해요)';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="q-wrap">
        <p className="q-tit">팝업스토어 방문 전체 경험에 대한 별점은 어떻게 되나요?</p>
        <div className="a-wrap star-wrap">
          <div className="star-inner">
            {[5, 4, 3, 2, 1].map(value => (
              <React.Fragment key={value}>
                <label htmlFor={`rating${value}`}>{value}</label>
                <input type="radio" name="rating" id={`rating${value}`} value={value} onChange={handleRatingChange} required />
              </React.Fragment>
            ))}
          </div>
          <p className={selectedRating ? 'noneselect hide' : 'noneselect'}>
            <span>정말 별로예요</span>
            <span>추천 안해요</span>
            <span>보통이에요</span>
            <span>좋아요</span>
            <span>아주 만족해요</span>
          </p>
          <p className={selectedRating ? 'star-text show' : 'star-text '}>
            <span className="num"></span>
            <span className="text"></span>
          </p>
        </div>
      </div>
    </>
  );
}

export default StarRate;
