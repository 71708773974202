import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisitStoreList from '../../components/member/VisitStoreList';

import { FETCH_VISIT_STORE_LIST, fetchVisitStoreList } from '../../modules/member';

const VisitStoreListContainer = () => {
  const dispatch = useDispatch();

  const { stores, isLoading } = useSelector(({ member, loading }: any) => ({
    stores: member.stores.data,
    isLoading: loading[FETCH_VISIT_STORE_LIST],
  }));

  useEffect(() => {
    dispatch(fetchVisitStoreList(8));
  }, [dispatch]);

  return <VisitStoreList stores={stores} isLoading={isLoading} />;
};

export default VisitStoreListContainer;
