import { useEffect, useState } from 'react';
import BrandQr from '../components/common/BrandQr';
import { qrUpdate } from '../lib/api';
import { useQuery, useQueryClient } from 'react-query';
const BrandQrContainer = ({ storeId, reservationId }) => {
  const queryClient = useQueryClient();
  // TODO 더블하트 이미지 새로고침 X
  const { data } = useQuery('getMenu', () => qrUpdate(storeId, reservationId), {
    // refetchInterval: 10000,
  });
  const [qrData, setQrData] = useState(null);
  useEffect(() => {
    if (data) {
      if (qrData !== data.data) {
        setQrData(data.data);
      }
    }
  }, [data]);

  return (
    <>
      <article>
        <BrandQr qrImg={qrData} />
      </article>
    </>
  );
};

export default BrandQrContainer;
