import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
import { fetchOne, FETCH_ONE } from '../../../modules/qna';
import QnaRead from '../../../components/contact/qna/QnaRead';
import ContactMenus from '../../../components/contact/ContactMenus';
import * as api from '../../../lib/api';
import { useNavigate } from 'react-router';
import httpStatusHandler from '../../../util/httpStatusHandler';
import Footer from '../../../components/common/Footer';
const QnaReadContainer = ({ itemId: qnqId }) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(null);
  // 스토어 상태 조회
  const { qna, isLoading, isAdmin, myInfo } = useSelector((state: any) => ({
    qna: state.qna.qna,
    isLoading: state.loading[FETCH_ONE],
    isAdmin: hasRoleAdmin(state),
    myInfo: state.auth.myInfo,
  }));

  useEffect(() => {
    dispatch(fetchOne(qnqId));
  }, [dispatch, qnqId]);

  useEffect(() => {
    if (myInfo?.userId === 8) {
      setAdmin(myInfo);
    }
    console.log(qna);
  }, []);

  const adminRegister = async (qna, files) => {
    try {
      const userId = myInfo.userId;
      console.log(qna.qnaId);
      const response = await api.answerQna(qna, userId);
      if (response.status === 200) {
        alert('등록이 완료되었습니다.');
        navigate(-1);
      }
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  return (
    <>
      <ContactMenus onMenu={'qna'} />
      <QnaRead qna={qna} isLoading={isLoading} qnaId={qnqId} isAdmin={isAdmin} admin={admin} adminRegister={adminRegister} />
      <Footer />
    </>
  );
};

export default QnaReadContainer;
