import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icon1 from './../../../assets/img/event-template/icon1.png';
import icon2 from './../../../assets/img/event-template/icon2.png';
import iconLogo from './../../../assets/img/event-template/icon-logo.png';
import { ReactComponent as Line } from './../../../assets/img/event-template/endpage-line.svg';
import { ReactComponent as Plane } from './../../../assets/img/event-template/airplane.svg';

function EventEndPage({ isWinner }) {
  let timer = null;

  return (
    // TODO
    <div className="TODO">
      {isWinner !== null && (
        <>
          {/* 당첨됐을 경우 // 떴다가 5초 뒤에 자동으로 사라짐 */}
          <div className="event-end-page event-end-page-winner" style={{ display: isWinner === true && timer === null ? 'block' : 'none' }}>
            <div className="event-end-page-inner">
              <p className="tit">
                <img src={icon1} alt="" />
                즉석 당첨 축하합니다!
              </p>
              <div className="winner-info">
                <p className="user-name">
                  <span>김수x</span>님
                </p>
                <p className="bar"></p>
                {/* 모든 개인정보는 중간에 * 표시 되어야 함 ex) 010-****-0000 */}
                <p className="user-phone">010-****-1157</p>
              </div>
              <div className="winner-item">
                <div className="line-wrap">
                  <Line stroke={'#c04dcc'} className="line line1" />
                  <Line stroke={'#3cb44a'} className="line line2" />
                  <Line stroke={'#ffd03b'} className="line line3" />
                  <Line stroke={'#fb0236'} className="line line4" />
                </div>
                <img src={icon2} alt="" className="winner-icon" />
                <div className="winner-item-banner">
                  <img src={iconLogo} alt="" />
                  <p>팝플리 음료R 1잔</p>
                </div>
              </div>
              <p className="info-message">경품은 팝플리 알림톡으로 발송됩니다!</p>
            </div>
          </div>

          {/* 당첨 안 됐을 경우 // 떴다가 5초 뒤에 자동으로 사라짐  */}
          <div className="event-end-page event-end-page-default" style={{ display: isWinner === false ? 'block' : 'none' }}>
            {/* <div className="event-end-page event-end-page-default" style={{ display: 'none' }}> */}
            <div className="event-end-page-inner">
              <div className="animation-area">
                <div className="background-img"></div>
                <div className="animation-icon-wrap">
                  <Plane width="30" height="auto" fill="#ff0000" className="plane-icon" />
                </div>
                <div className="animation-after">
                  <p>SEND</p>
                </div>
              </div>

              <p className="tit">메시지 전송 완료!</p>
              <p className="info-message">자동으로 메인 화면 페이지로 이동합니다.</p>
              <p className="info-message">화면에 출력되는 나의 메시지를 확인하세요!</p>
              <p className="info-message" style={{ marginTop: '20px' }}>
                작성 완료 3초 후 메인 화면에 출력됩니다.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default EventEndPage;
