import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';

import axios from 'axios';

import searchbg from './../../../assets/img/layout/search-bg.svg';
import dayjs from 'dayjs';

function PopupReportRegisterForm({ attachments, onRegister, onAddAttach, onRemoveAttach }: any) {
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);

  const changeStoreAddressInfos = e => {
    if (storeInfo.address !== '' && storeInfo.address) {
      setStoreInfo({ ...storeInfo, detailAddress: `${e.target.value}` });
    }
  };
  const [storeInfo, setStoreInfo] = useState({
    title: null,
    categoryId: null,
    content: '',
    startDate: dayjs(),
    endDate: dayjs().add(1, 'week'),
    address: '',
    detailAddress: '',
    brandName: '',
  });

  const onUpload = (e: any) => {
    const images = e.target.files;
    setFiles(images);
    let fileURLs = [];
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = () => {
        fileURLs[i] = reader.result;
        setImageSrc([...fileURLs]); // 파일의 컨텐츠
      };
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    alert('등록하시겠습니까?');

    const formData = new FormData();
    if (storeInfo.title === '' || !!!storeInfo.title) {
      alert('팝업명을 입력해주세요.');
    } else {
      if (files.length > 0) {
        for (const image of files) {
          formData.append('files', image);
        }
      }
      onRegister(storeInfo, formData);
    }
  };

  const [address, setAddress] = useState([
    {
      zipcode: '', //addresses[0].addressElements.longName
      jibunAddress: '', // addresses[0].roadAddress
      roadAddress: '',
      detailAddress: '',
      latitude: '', // addresses[0].y
      longitude: '', // addresses[0].x
    },
  ]);

  const [findAddress, setFindAddress] = useState('');
  const chkAddress = e => {
    const value = e.target.value;
    setFindAddress(value);
  };

  const getAddressInfo = async values => {
    // const response = await axios.get(`https://api.popply.co.kr/api/common/getPostCode`, {
    const response = await axios.get(`http://localhost:3003/api/common/getPostCode`, {
      params: {
        address: findAddress,
      },
    });
    const addressInfos = response.data;
    if (addressInfos.length === 0) {
      alert('정확한 주소를 입력해주세요.');
    }
    await setAddress(addressInfos);
  };

  //주소검색 팝업
  const [zipcodeOn, setZipcodeOn] = useState(true);
  const zipcodeClick = (isClick: boolean) => {
    setZipcodeOn(current => !current);
  };

  const changeStoreInfos = e => {
    const { value, id } = e.target;
    console.log(storeInfo);
    setStoreInfo({ ...storeInfo, [id]: value });
  };

  return (
    <article>
      {/* <a className="go-back">&lt; 뒤로</a> */}
      <form onSubmit={handleSubmit} className="text-form">
        <div className="text-form-inner">
          <span>
            팝플리에 등록되어 있지 않은 팝업스토어나 오픈 예정인 스토어가 있다면 팝업스토어 제보로 알려주세요.
            <br />
            확인 후 등록하도록 하겠습니다!
          </span>
          <p>
            <label htmlFor="brandName">브랜드명</label>
            <input type="text" id="brandName" onChange={changeStoreInfos} name="브랜드명" placeholder="브랜드명을 입력해주세요." />
          </p>
          <p>
            <label htmlFor="title">
              팝업명<span className="required-mark">&nbsp;*</span>
            </label>

            <input type="text" id="title" name="스토어이름" onChange={changeStoreInfos} placeholder="팝업스토어 이름을 입력해주세요." />
          </p>
          <p>
            <label htmlFor="address">주소</label>
            <ul className="report-location-wrap">
              <li>
                <p>
                  <input type="text" id="address" name="스토어위치" placeholder="주소 검색하기" value={storeInfo.address} readOnly />
                  <button
                    type="button"
                    onClick={() => {
                      zipcodeClick(false);
                    }}
                    style={{ height: '38px' }}
                  >
                    주소검색
                  </button>
                </p>
              </li>
              <li>
                <input type="text" id="detailAddress" placeholder="상세주소를 입력해주세요" onChange={changeStoreAddressInfos} style={{ width: '100%' }} />
              </li>
            </ul>
          </p>
          <p>
            <label>기간</label>
            <ul className="report-date-wrap">
              <li>
                <input type="date" id="startDate" onChange={changeStoreInfos} name="스토어 시작기간" placeholder="2024-01-01" />
              </li>
              <li> ~ </li>
              <li>
                <input type="date" id="endDate" onChange={changeStoreInfos} name="스토어 종료기간" placeholder="2024-12-31" />
              </li>
            </ul>
          </p>
          <p className="edit-area">
            <label htmlFor="contents">기타 정보</label>

            <Editor
              editor={ClassicEditor as any}
              // data={storeInfo.storeDetail.contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log(data);
                setStoreInfo(prevStoreInfo => ({
                  ...prevStoreInfo,
                  content: data,
                }));
              }}
            />
          </p>
          <p className="upload-file-area">
            <p>
              사진을 첨부해주세요.<span>(용량제한)</span>
            </p>
            <div>
              <div className="preview-wrap">
                <img alt="첨부파일 미리보기" />
              </div>
              <label htmlFor="p_image_upload">
                <span>+</span>
              </label>
              <input type="file" id="p_image_upload" accept="image/*" multiple onChange={e => onUpload(e)} name="files" />
            </div>
            <ul className="popup-input-thumbnail-wrap">
              {imageSrc.length > 0 &&
                imageSrc.map(img => {
                  return (
                    <li>
                      <div className="thumbnail-img-wrap">
                        <img src={img} alt="" />
                      </div>
                    </li>
                  );
                })}
              {imageSrc.length < 1 && (
                <>
                  <li>
                    <div className="thumbnail-img-wrap">
                      <img alt="" />
                    </div>
                  </li>
                </>
              )}
            </ul>
          </p>
        </div>

        <div className="likebutton-wrap">
          <button type="submit" className="likebutton full-btn">
            팝업스토어 제보하기
          </button>
        </div>
      </form>

      {/* 주소검색 팝업 */}
      <div className={zipcodeOn ? 'admin-zipcode-popup' : 'admin-zipcode-popup show'}>
        <div className="popup-close">
          <a
            href="#"
            onClick={() => {
              zipcodeClick(true);
            }}
          >
            X
          </a>
        </div>
        <div className="admin-zipcode-popup-inner">
          <div className="zipcode-search">
            <input type="search" id="zipcode-search-input" placeholder="도로명주소를 입력해주세요. (예: 와우산로35길 13)" onChange={chkAddress} />
            <button onClick={getAddressInfo} className="zipcode-search-icon">
              <img src={searchbg} alt="" />
            </button>
          </div>
          <div className="bar"></div>

          {/* 기본적으로 display:none 상태. 검색 버튼 누르면 className에 show 붙어야 함 */}
          <div className="zipcode-list show">
            <ul>
              {address.length > 0 &&
                address.map((ad, idx) => {
                  return (
                    <li key={idx}>
                      <ul>
                        <li>
                          <p className="zipcode-category">도로명</p>
                          <button className="zipcode-text">{ad.roadAddress}</button>
                        </li>
                        <li>
                          <p className="zipcode-category">지번</p>
                          <button className="zipcode-text">{ad.jibunAddress}</button>
                        </li>
                      </ul>
                      <button
                        className="zipcode-select"
                        onClick={() => {
                          setStoreInfo({ ...storeInfo, address: ad.roadAddress });
                          zipcodeClick(true);
                        }}
                      >
                        선택
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
}

export default PopupReportRegisterForm;
