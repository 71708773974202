import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
function BizQnaRegisterForm({ attachments, onRegister, user }: any) {
  const [bizQnaInfo, setBizQnaInfo] = useState({
    corpName: null,
    deptName: null,
    corpContact: null,
    manager: null,
    managerContact: null,
    site: null,
    email: null,
    content: '문의글을 입력해주세요.',
  });

  const changeContents = contentData => {
    setBizQnaInfo({ ...bizQnaInfo, content: contentData });
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    alert('등록하시겠습니까?');
    onRegister(bizQnaInfo);
  };
  const changeBizQnaInfo = e => {
    const { value, id } = e.target;
    setBizQnaInfo({ ...bizQnaInfo, [id]: value });
  };
  return (
    <article>
      <form onSubmit={handleSubmit} className="text-form biz-form">
        <div className="text-form-inner">
          <span>
            비즈니스 문의를 통해 궁금한 점을 보내주세요.
            <br />
            문의 주신 내용에 대한 답변은 기입해주신 연락처로 연락드리겠습니다.
          </span>

          <p>
            <label htmlFor="inquiry-writer">
              기업명<span className="required-mark">&nbsp;*</span>
            </label>
            <input type="text" onChange={changeBizQnaInfo} id="corpName" name="기업명" placeholder="기업명을 입력해주세요." required />
          </p>
          <p>
            <label htmlFor="inquiry-writer">
              기업 연락처<span className="required-mark">&nbsp;*</span>
            </label>
            <input type="text" onChange={changeBizQnaInfo} id="corpContact" name="연락처" placeholder="기업 연락처를 입력해주세요." required />
          </p>
          <p>
            <label htmlFor="inquiry-writer">부서명</label>
            <input type="text" onChange={changeBizQnaInfo} id="deptName" name="부서명" placeholder="부서명을 입력해주세요." />
          </p>
          <p>
            <label htmlFor="inquiry-writer">
              담당자명<span className="required-mark">&nbsp;*</span>
            </label>
            <input type="text" onChange={changeBizQnaInfo} id="manager" name="담당자명" placeholder="담당자명을 입력해주세요." required />
          </p>
          <p>
            <label htmlFor="inquiry-writer">
              담당자 휴대폰<span className="required-mark">&nbsp;*</span>
            </label>
            <input type="text" onChange={changeBizQnaInfo} id="managerContact" name="담당자 휴대폰" placeholder="담당자 휴대폰을 입력해주세요." required />
          </p>
          <p>
            <label htmlFor="inquiry-writer">
              이메일<span className="required-mark">&nbsp;*</span>
            </label>
            <input type="text" onChange={changeBizQnaInfo} id="email" name="문의자" placeholder="이메일을 입력해주세요." required />
          </p>
          <p>
            <label htmlFor="inquiry-writer">홈페이지 주소</label>
            <input type="text" onChange={changeBizQnaInfo} id="site" name="홈페이지 주소" placeholder="홈페이지 주소를 입력해주세요." />
          </p>
          <p className="edit-area">
            <label htmlFor="inquiry-content">문의 내용</label>

            <Editor
              editor={ClassicEditor as any}
              config={{
                placeholder: bizQnaInfo.content,
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeContents(data);
              }}
            />
          </p>
          {/* <p className="checkbox-wrap">
            <input type="checkbox" id="required-privacy" name="개인정보이용동의" />
            <label htmlFor="required-notice">개인정보수집 동의</label>
          </p>
          <span>1:1문의 답변을 위해 수집한 항목(이름, 이메일주소)는 1:1문의 접수, 답변 안내를 위해 수집합니다. 수집항목은 상담 접수 및 처리 이후 3개월까지 보관하며, 이후 회원 별도 요청 없는 경우 암호화 처리하여 별도 보관합니다.</span> */}
        </div>

        <div className="likebutton-wrap">
          <button type="submit" className="likebutton submit">
            등록
          </button>
          <a href="/bizQna" className="likebutton">
            취소
          </a>
        </div>
      </form>
    </article>
  );
}
export default BizQnaRegisterForm;
