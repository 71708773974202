import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactMenus from '../../../components/contact/ContactMenus';
import PopupReportList from '../../../components/contact/popupreport/PopupReportList';
import { fetchList, FETCH_LIST } from '../../../modules/popupreport';
import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
import Footer from '../../../components/common/Footer';

const PopupReportListContainer = () => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  console.log('!!!!');
  // 스토어 상태 조회
  const { popupReportList, isLoading, isAdmin } = useSelector((state: any) => ({
    popupReportList: state.popupReportList,
    isLoading: state.loading[FETCH_LIST],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <ContactMenus onMenu={'popupreport'} />
      <PopupReportList popupReportList={popupReportList} isLoading={isLoading} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default PopupReportListContainer;
