import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Stars from '../common/Star';
import { addComment, addCommentImage } from '../../lib/api';
import StarRate from '../common/StarRate';
import profile from './../../assets/img/member/profile.svg';
import btn_close from './../../assets/img/common/btn_close.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AlertPopup from '../common/alert/AlertPopup';
import photo from '../../assets/img/popup/image-add.png';
import cmt from './../../assets/img/member/member-icon2.svg';
import GuestAccess from '../auth/GuestAccess';

function StoreDetailComment({ store, myInfo, history }: any) {
  const navigate = useNavigate();
  const comments = store?.storeDetail?.comments;

  let starAvg = 0;
  if (comments) {    
    const totalStars = comments.reduce((a, b) => a + b.stars, 0);
    starAvg = totalStars / comments.length;
  }
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showFillAlert, setShowFillAlert] = useState(false);

  const submitShowAlert = async (show: boolean) => {
    if( show  ) {
      if(rating === 0  && comment?.length === 0 && files?.length === 0) {
        setShowFillAlert(true) 
      } else {
        setShowAlert(true);
      }
    }
  }
  const onUpload = async (e: any) => {
    const images = e.target.files;

    setFiles(images);
    let fileURLs = [];
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = () => {
        fileURLs[i] = reader.result;
        setImageSrc([...fileURLs]); // 파일의 컨텐츠
      };
    }
  };
  const [comment, setComment] = useState('');

  const commentHandler = (event: any) => {
    setComment(event.currentTarget.value);
  };

  const commentSubmit = async isTrue => {
    if (!isTrue || !myInfo) return;
    const formData = new FormData();
    if (files.length > 0) {
      for (const image of files) {
        formData.append('files', image);
      }
    }

    const detailComment = {
      storeDetailComment: {
        userId: myInfo.userId,
        storeId: store.storeId,
        storeDetailId: store.storeDetail.storeDetailId,
        comment: comment,
        stars: rating,
      },
    };

    const addCommentResult = await addComment(store.storeId, detailComment);
    if (addCommentResult.status === 200) {
      const storeId = addCommentResult.data.data.storeId;
      const commentId = addCommentResult.data.data.storeDetailCommentId;
      const uploadImages = await addCommentImage(storeId, commentId, formData);
      if (uploadImages.status === 200) {
        alert('등록이 완료되었습니다.');
      } else {
        alert('등록에 실패했습니다.');
      }
    }
    setComment('');
    // navigate(`/popup/${storeId}`);
    navigate(0);
  };
  const checkMyInfo = () => {
    if (!myInfo) {
      // 로그인
      reviewWriteLayerOpen(false);
      // return false;
    } else {
      return true;
    }
  };
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const onSaveRating = (index: number) => {
    setRating(index);
  };

  //후기쓰기레이어팝업 오픈
  const [reviewWriteLayer, setReviewWriteLayer] = useState(true);
  const reviewWriteLayerOpen = (isClick: boolean) => {
    // if(!myInfo)
    setReviewWriteLayer(current => !current);
  };

  //후기상세보기레이어팝업 오픈
  const componentRef2 = useRef<HTMLInputElement>();
  const [reviewDetail, setReviewDetail] = useState(true);
  const [selectedComment, setSelectedComment] = useState(null);
  const [currentOffsetWidth, setCurrentOffsetWidth] = useState(Number);

  const reviewDetailLayerOpen = (isClick: boolean, comment: Object, e: any) => {
    if (dragging) {
      e.stopPropagation();
      return;
    } else {
      setCurrentOffsetWidth(componentRef2.current.offsetWidth);
      setReviewDetail(current => !current);
      setSelectedComment(comment);
    }
  };

  //신고하기레이어팝업 오픈
  const [notifyLayer, setNotifyLayer] = useState(true);
  const notifyLayerOpen = (isClick: boolean) => {
    setNotifyLayer(current => !current);
  };

  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    draggable: true,
    touchThreshold: 100,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //전체 너비 구하기
  const { width, height } = useContainerDimensions(componentRef2);
  const slidesToShowSize = Number(currentOffsetWidth / 290).toFixed(1);
  const settings2 = {
    dots: false,
    infinite: false,
    slidesToShow: Number(slidesToShowSize),
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
  };

  return (
    <article ref={componentRef2}>
      <div className="review-wrap">
        <div className="review-top">
          <div>
            <span>★{starAvg ? starAvg.toFixed(1) : 0}</span> 후기 {comments.length}개
          </div>
          {/* <p className="moreview">{store.storeDetail.comments.length > 3 && <Link to={`/${store.storeId}/comment/list`}>전체보기</Link>}</p> */}
          <p className="moreview"><Link to={`/${store.storeId}/comment/list`}>전체보기</Link></p>
        </div>
        <div className="review-slide">
          {comments.length < 1 &&
          <div className='review-slide-blank'>
            <img src={cmt} alt="" />
            <span>등록된 후기가 없습니다.<br /><span className='tit'>첫 번째 후기를 작성해 보세요!</span></span>
            
          </div>
          }
          <Slider {...settings}>
            {!!comments.length &&
              comments.map((comment, idx) => (
                <>
                  <div
                    className="review-contents"
                    key={idx}
                    onClick={e => {
                      reviewDetailLayerOpen(true, comment, e);
                    }}
                  >
                    <ul>
                      <li>
                        <img src={profile} alt="프로필사진" />
                        <p>
                          <span>
                            <StarRate starAvg={comment.stars} userName={comment.userName} isDetail={false} />
                          </span>
                        </p>
                      </li>
                      <li>
                        <p className="comment">{comment.comment}</p>
                      </li>
                      <li className="review-image-wrap">
                        <ul className="review-image">
                          {/* 최대 2개까지만 이미지가 보이도록 함 */}
                          {comment.storeDetailCommentImages.map((image, idx) => (
                            <>
                              {idx < 2 && (
                                <li key={idx}>
                                  <img src={image.url} alt="" />
                                </li>
                              )}
                              {idx >= 3 && (
                                <li key={idx} className="more-image">
                                  <div></div>
                                  <img src={image.url} alt="" />
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ))}
          </Slider>
        </div>
        <div className="layer-review-inner">
          <div className="layer-review-inner-write" onClick={checkMyInfo}>
            <span>클릭해서 별점을 매겨주세요!</span>
            <p className="drag-star">
              {[1, 2, 3, 4, 5].map(idx => {
                return <Stars key={idx} index={idx} rating={rating} hoverRating={hoverRating} onSaveRating={onSaveRating} />;
              })}
            </p>
            <div className="review-file-wrap" style={{ position: 'relative', width: '100%' }}>
              <textarea
                rows={5}
                placeholder="방문하신 팝업스토어는 어떠셨나요? 후기를 통해 회원님의 멋진 경험을 다른 분들과 공유해보세요!&#13;&#10;해당 팝업스토어와 관련없는 내용 혹은 이미지를 업로드하신 경우 별도의 통보 없이 삭제 및 혜택이 회수됩니다."
                onChange={commentHandler}
                value={comment}
              />
            </div>
            <div className="popup-input-wrap popup-input-file-wrap">
              <p className="p_input_info_text">* 이미지는 정사이즈를 권장합니다. 가로 또는 세로로 긴 사진의 경우 사진 일부가 잘릴 수 있습니다.</p>
              <label htmlFor="p_image_upload">
                <span><img src={photo} alt="사진 아이콘" /> 사진 첨부하기</span>
                <input type="file" id="p_image_upload" accept="image/*" multiple onChange={e => onUpload(e)} name="files" />
              </label>
              <ul className="popup-input-thumbnail-wrap">
                {imageSrc.length > 0 &&
                  imageSrc.map((img, idx) => {
                    return (
                      <li key={idx}>
                        <div className="thumbnail-img-wrap">
                          <img src={img} alt="" />
                        </div>
                      </li>
                    );
                  })}
                {imageSrc.length < 1 && (
                  <>
                    <li>
                      <div className="thumbnail-img-wrap">{/* <img src={slideimg1} alt="" /> */}</div>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <button type="submit" className="review-btn" onClick={() => submitShowAlert(true)}>
              후기 등록
            </button>
          </div>
        </div>
      </div>

      {/* 후기 작성 완료 시 alert 팝업 */}
      {showAlert && myInfo && <AlertPopup type={'confirm'} msg={'후기 등록을 완료하겠습니까?'} btnText={'취소'} btnText2={'등록'} successfunction={commentSubmit} failFunction={setShowAlert} />}
      {showFillAlert && myInfo && <AlertPopup type={'alert'} msg={'별점,후기 작성 또는 사진 업로드를 완료해주세요'} btnText={'닫기'} failFunction={setShowFillAlert}/>}

      <div className={reviewWriteLayer ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review'}>
        {!myInfo && (
          <>
            <header>
              <p className="layer-tit">
                <button
                  type="button"
                  onClick={() => {
                    reviewWriteLayerOpen(false);
                  }}
                >
                  <img src={btn_close} alt="닫기 버튼" />
                </button>
              </p>
            </header>
            <div className="guest-access">
              <GuestAccess />
              {/* <div>
                  <a className="close-btn" onClick={() => { reviewWriteLayerOpen(false); }} >
                    닫기
                  </a>
                </div> */}
            </div>

            {/* <div className="guest-access">
              <div className="guest-access-inner">
                <h2>
                  <img src={profileNone} />
                </h2>
                <div className="guest-access-inner-box">
                  <p>아직 회원이 아니시라면,</p>
                  <a onClick={onSignIn} className="sign-up">
                    회원가입
                  </a>
                </div>
                <div className="guest-access-inner-box">
                  <p>이미 가입한 아이디가 있다면,</p>
                  <a onClick={onSignIn}>로그인</a>
                </div>
                <div>
                  <a
                    className="close-btn"
                    onClick={() => {
                      reviewWriteLayerOpen(false);
                    }}
                  >
                    닫기
                  </a>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
      {/* 레이어팝업 _ 후기 상세보기 */}
      <div className={reviewDetail ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review'}>
        <header>
          <p className="layer-tit">
            후기 상세보기
            <button
              type="button"
              onClick={e => {
                reviewDetailLayerOpen(false, null, e);
              }}
            >
              <img src={btn_close} alt="닫기 버튼" />
            </button>
          </p>
        </header>
        <div className="layer-review-inner">
          <div className="review-contents review-contents-layer">
            <ul>
              <li>
                <div>
                  <img src={profile} alt="프로필사진" />
                  <p>
                    <span>
                      <StarRate starAvg={selectedComment ? selectedComment.stars : 0} userName={selectedComment ? selectedComment.userName : ''} isDetail={true} />
                    </span>
                  </p>
                </div>
                <button
                  type="button"
                  className="notify"
                  onClick={() => {
                    notifyLayerOpen(true);
                  }}
                >
                  신고
                </button>
              </li>
              <li>
                <p className="comment">{selectedComment ? selectedComment.comment : ''}</p>
              </li>
              <li>
                <div className="detail-review-image">
                  <Slider {...settings2}>
                    {selectedComment &&
                     selectedComment.storeDetailCommentImages.map((image, idx) => (
                        <div className="slide-content review-image-slide">
                          <div className="slide-img-wrap">
                              <>
                                <img key={idx} src={image.url} alt="" />
                              </>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </li>
            </ul>
          </div>
          {/* //TODO  */}
          <div className="layer-page">
            {/* <a>
              <img src={arrow_left} alt="" />
              이전
            </a>
            <a>
              다음
              <img src={arrow_right} alt="" />
            </a> */}
          </div>
          <button
            type="button"
            className="full-btn"
            onClick={e => {
              reviewDetailLayerOpen(false, null, e);
            }}
          >
            닫기
          </button>
        </div>
      </div>
      {/* 레이어팝업 _ 신고 */}
      <div className={notifyLayer ? 'layer-popup layer-popup-review' : 'layer-popup shown layer-popup-review'}>
        <header>
          <p className="layer-tit">
            게시물 신고하기
            <button
              type="button"
              onClick={() => {
                notifyLayerOpen(false);
              }}
            >
              <img src={btn_close} alt="닫기 버튼" />
            </button>
          </p>
        </header>
        <div className="layer-review-inner">
          <p>신고 대상 닉네임 및 내용</p>
          {/* 신고할 후기 표시 */}
          <div className="review-contents">
            <ul>
              <li>
                <img src={profile} alt="프로필사진" />
                <p>
                  {/* {selectedComment ? selectedComment.userName : ''} */}
                  <span>
                    <StarRate starAvg={selectedComment ? selectedComment.stars : 0} userName={selectedComment ? selectedComment.userName : ''} isDetail={true} />
                  </span>
                </p>
              </li>
              <li>
                <p className="comment">{selectedComment ? selectedComment.comment : ''}</p>
              </li>
              <li>
                <ul className="review-image">
                  {/* 최대 2개까지만 이미지가 보이도록 함 */}
                  <li>
                    <img src="https://cf-templates-1gyolugg9zn9q-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/store/23042404SS_KakaoTalk_20230417_191350773.jpg" alt="" />
                  </li>
                  <li>
                    <img src="https://cf-templates-1gyolugg9zn9q-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/store/23042404SS_KakaoTalk_20230417_191350773.jpg" alt="" />
                  </li>
                  {/* 등록된 이미지가 3개 이상일 때 */}
                  <li className="more-image">
                    <div></div>
                    <img src="https://cf-templates-1gyolugg9zn9q-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/store/23042404SS_KakaoTalk_20230417_191350773.jpg" alt="" />
                  </li>
                  <li>
                    {/* 버튼 클릭하면 버튼에 thumb-up 클래스 붙어야 함 */}
                    {/* <button type="button">
                      <div className="thumb-wrap">
                        <p>111 &nbsp;&nbsp;&nbsp;</p>
                      </div>
                    </button> */}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <p style={{ paddingTop: '20px' }}>신고 사유</p>
          <ul className="notify-list">
            <li>
              <input type="radio" id="notify1" name="notify-item" value="notRelevant" />
              <label htmlFor="notify1">관련없는 내용 혹은 이미지</label>
            </li>
            <li>
              <input type="radio" id="notify2" name="notify-item" value="abuse" />
              <label htmlFor="notify2">욕설/비방</label>
            </li>
            <li>
              <input type="radio" id="notify3" name="notify-item" value="promotional" />
              <label htmlFor="notify3">광고/홍보글</label>
            </li>
            <li>
              <input type="radio" id="notify4" name="notify-item" value="privacyLeakage" />
              <label htmlFor="notify4">개인정보 유출 위험</label>
            </li>
            <li>
              <input type="radio" id="notify5" name="notify-item" value="spam" />
              <label htmlFor="notify5">게시글 도배</label>
            </li>
            <li>
              <input type="radio" id="notify6" name="notify-item" value="illegalTheft" />
              <label htmlFor="notify6">저작권 불법 도용(타인이 작성한 글, 사진 등)</label>
            </li>
            <li>
              <input type="radio" id="notify7" name="notify-item" value="suggestive" />
              <label htmlFor="notify7">음란/선정성</label>
            </li>
            <li className="count-txt-wrap">
              <input type="radio" id="notify8" name="notify-item" value="etcReason" />
              <label htmlFor="notify8">기타</label>
              <textarea rows={5}></textarea>
              <p className="count-txt">(0/150)</p>
            </li>
            <li>
              <span>신고해주신 내용은 내부정책에 의해 검토 후 조치됩니다.</span>
            </li>
            <li>
              <button type="button">신고하기</button>
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
}

const getCommentRegisterdTime = registerTime => {
  const today = dayjs();
  const postingDate = dayjs(registerTime);
  const dayDiff = postingDate.diff(today, 'days');
  const hourDiff = postingDate.diff(today, 'hours');
  const minutesDiff = postingDate.diff(today, 'minutes');

  if (dayDiff === 0 && hourDiff === 0) {
    // 작성한지 1시간도 안지났을때
    const minutes = Math.ceil(-minutesDiff);
    return minutes + '분 전'; // '분' ? minutes ago ?
  }

  if (dayDiff === 0 && hourDiff <= 24) {
    // 작성한지 1시간은 넘었지만 하루는 안지났을때,
    const hour = Math.ceil(-hourDiff);
    return hour + '시간 전'; // '시간'으로 표시 ? hours?
  }

  return -dayDiff + '일 전'; // '일'로 표시 ? days
};

export default StoreDetailComment;

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    console.log(myRef.current?.offsetWidth);
    console.log(myRef);
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth ? myRef.current?.offsetWidth : 0,
      height: myRef.current?.offsetHeight ? myRef.current?.offsetHeight : 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
