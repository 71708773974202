import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactMenus from '../../../components/contact/ContactMenus';
import QnaList from '../../../components/contact/qna/QnaList';
import { fetchList, FETCH_LIST } from '../../../modules/qna';
import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
import Footer from '../../../components/common/Footer';

const QnaListContainer = () => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  // 스토어 상태 조회
  const { qnaList, isLoading, isAdmin, myInfo } = useSelector((state: any) => ({
    qnaList: state.qna.qnas,
    isLoading: state.loading[FETCH_LIST],
    isAdmin: hasRoleAdmin(state),
    myInfo: state.auth.myInfo,
  }));

  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <ContactMenus onMenu={'qna'} />
      <QnaList qnaList={qnaList} isLoading={isLoading} isAdmin={isAdmin} myInfo={myInfo} />
      <Footer />
    </>
  );
};

export default QnaListContainer;
