import * as React from 'react';
import { Link } from 'react-router-dom';
import nonethumb from './../../assets/img/noneIMG.png';
import arrow from './../../assets/img/common/arrow_right_small.svg';
import kakao from './../../assets/img/layout/icon-kakao.svg';
import fb from './../../assets/img/layout/icon-facebook.svg';
import blog from './../../assets/img/layout/icon-naverblog.svg';
import close from './../../assets/img/common/btn_close_black.svg';

function PreReservationStoreList({ stores, userReservations, isLoading }: any) {
  //인기순~
  const [filterSelect, setfilterSelect] = React.useState(true);
  const filterClick = (isClick: boolean) => {
    setfilterSelect(current => !current);
  };

  //진행중~
  const [statusSelect, setStatusSelect] = React.useState(true);
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };

  // 더보기버튼
  const [moreBtn, setMoreBtn] = React.useState(true);
  const moreClick = (isClick: boolean) => {
    setMoreBtn(current => !current);
  };

  // 공유하기버튼
  const [shareBtn, setShareBtn] = React.useState(true);
  const shareClick = (isClick: boolean) => {
    setShareBtn(current => !current);
  };
  return (
    <>
      <article className="inner" style={{ paddingBottom: '100px' }}>
        <div className="submenu">
          {/*클릭하면 클래스 on 붙어야 함*/}
          <Link to="/member">
            <h2>내 정보 </h2>
          </Link>
          <Link to="/user/save-store">
            <h2>관심팝업 </h2>
          </Link>
          <Link to="/member/reservation/pre" className="on">
            <h2>사전예약 </h2>
          </Link>
          {/* <Link to="/user/reservation/onSite">
            <h2>현장대기 </h2>
          </Link> */}
        </div>
        {isLoading && '로딩중...'}
        {/* <ul className="pre-status">
          <li>
            예약완료 <span>2</span>
          </li>
          <li>
            방문완료 <span>10</span>
          </li>
          <li>
            예약취소 <span>6</span>
          </li>
        </ul> */}
        <div className="pre-reservation-list">
          {userReservations.length >= 1 &&
            userReservations.map((userReservation, idx) => (
              <>
                {userReservation && userReservation.reservationScheduleMap && (
                  <>
                    <div className="pre-reservation-content">
                      <div className="pre-reservation-content-inner">
                        <img className="thumbnail" src={userReservation.reservation.store.thumbnails ? userReservation.reservation.store.thumbnails : nonethumb} alt="팝플리 썸네일" />
                        <div className="right">
                          <div className="info-text">
                            <p className="popupstore-name">{userReservation.reservation.store.title}</p>
                            <p className={`pre-reservation-status ${userReservation.status}`}>{userReservation.status}</p>
                            <p className="flex-p">
                              <span>{userReservation.reservationScheduleMap.reservationSchedule.reservationDate}</span>|<span>{userReservation.reservationScheduleMap.reservationSchedule.reservationTime}</span>
                            </p>
                            <p>
                              총 방문인원 : <span>{userReservation.withGuest}</span>명
                            </p>
                          </div>
                          {/* <div className="more-btn">
                      <img src={more} alt="" onClick={() => moreClick(true)} />
                      <div className={moreBtn ? 'more-menu' : 'more-menu visible'}>
                        <button
                          onClick={() => {
                            shareClick(true);
                            moreClick(false);
                          }}
                        >
                          <img src={share} />
                          공유하기
                        </button>
                        <button>
                          <img src={add} />
                          팝업스토어 상세 보기
                        </button>
                        <button>
                          <img src={del} />
                          예약내역 삭제
                        </button>
                      </div>
                    </div> */}
                        </div>
                      </div>
                      {/* 링크 안에 해당 사전예약 상세페이지 링크 삽입 */}
                      <Link to={`/member/reservation/pre/${userReservation.userReservationId}`} className="reservation-link-btn">
                        사전예약 상세 보기 <img src={arrow} className="arrow" alt="" />
                      </Link>
                    </div>
                  </>
                )}
              </>
            ))}
        </div>
      </article>

      {/* 공유 팝업 */}
      {userReservations && (
        <div className={shareBtn ? 'layer-popup-white share-popup' : 'layer-popup-white share-popup show'}>
          <div className="header">
            공유하기
            <button type="button" onClick={() => shareClick(false)}>
              <img src={close} />
            </button>
          </div>
          <div className="share-wrap">
            <div>
              <img src={kakao} />
              카카오톡
            </div>
            <div>
              <img src={blog} />
              네이버 블로그
            </div>
            <div>
              <img src={fb} />
              페이스북
            </div>
          </div>
          <div className="link-wrap">
            {/* value 안에 url 첨부 */}
            <input type="text" readOnly value={'ddddd'} />
            <button type="button">복사</button>
          </div>
        </div>
      )}
    </>
  );
}

export default PreReservationStoreList;
