import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../lib/api';
import httpStatusHandler from '../../util/httpStatusHandler';
import { isAdmin as hasRoleAdmin } from '../../modules/selector';
import { fetchOne, FETCH_ONE } from '../../modules/contact';
import ContactRead from '../../components/contact/ContactRead';
import ContactMenus from '../../components/contact/ContactMenus';
import Footer from '../../components/common/Footer';

const ContactReadContainer = ({ itemId }) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  const { noticeItem, isLoading, isAdmin } = useSelector((state: any) => ({
    noticeItem: state.contact.contact,
    // attachments: state.contact.attachments,
    isLoading: state.loading[FETCH_ONE],
    isAdmin: hasRoleAdmin(state),
  }));

  useEffect(() => {
    dispatch(fetchOne(itemId));
  }, [dispatch, itemId]);

  return (
    <>
      <ContactMenus onMenu={'contact'} />
      <ContactRead
        noticeItem={noticeItem}
        // attachments={attachments}
        isLoading={isLoading}
        itemId={itemId}
        isAdmin={isAdmin}
      />
      <Footer />
    </>
  );
};

export default ContactReadContainer;
