import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MemberList from '../../components/member/MemberList';
import { fetchCategoryList, fetchUserFavorites } from '../../lib/api';
import { fetchOne, FETCH_LIST } from '../../modules/member';
import Footer from '../../components/common/Footer';

const MemberListContainer = () => {
  const dispatch = useDispatch();

  const { members, isLoading, comments, myInfo } = useSelector(({ auth, member, loading }: any) => {
    // console.log(member);
    return {
      // members: JSON.parse(localStorage.getItem('user')),
      members: member.member,
      isLoading: loading[FETCH_LIST],
      comments: member.comments,
      myInfo: auth.myInfo
    };
  });

  useEffect(() => {
    if( myInfo ) {
      dispatch(fetchOne(myInfo ? myInfo.userId : null));
    }
  }, [myInfo]);

  useEffect(() => {
    if (members) getCategoryList();
  }, [members]);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const onClickCategory = async (isClick: boolean, clickedCategory) => {
    const newSelectedCategories = [...selectedCategories]; // 현재 선택된 카테고리 배열 복사

    // 클릭된 카테고리가 이미 선택된 카테고리 배열에 있는지 확인
    const isDuplicate = newSelectedCategories.includes(clickedCategory.categoryId);

    // 중복된 값이 있다면 제거하고, 없다면 배열에 추가
    let categoryIds;
    if (isDuplicate) {
      const filteredCategories = newSelectedCategories.filter(categoryId => categoryId !== clickedCategory.categoryId);
      setSelectedCategories(filteredCategories);
      categoryIds = filteredCategories;
    } else {
      newSelectedCategories.push(clickedCategory.categoryId);
      setSelectedCategories(newSelectedCategories);
      categoryIds = newSelectedCategories;
    }

    const result = await fetchUserFavorites(members.userId, { user: { favorites: categoryIds } });

    // 중복시 제거
    // setSelectedCategories(prevState => [...new Set([...prevState, clickedCategory.categoryId])]);
    // TODO 제거후로직!
    if (result.data.data.favorites) {
      const favorites = result.data.data.favorites.split(',').map(Number); // 카테고리
      members.favorites = result.data.data.favorites;
      if (favorites && favorites.length > 0) {
        result.data.data.category.map(category => {
          category.color = 'default';
          if (favorites.includes(category.categoryId)) category.color = 'f0002e';
        });
        setCategories(result.data.data.category);
      }
    }
  };

  const getCategoryList = async () => {
    const response = await fetchCategoryList();
    if (members && response.data.data) {
      if (members.favorites) {
        const favorites = members.favorites.split(',').map(Number); // 카테고리
        setSelectedCategories(favorites);
        if (favorites && favorites.length > 0) {
          response.data.data.map(category => {
            if (favorites.includes(category.categoryId)) {
              category.color = 'f0002e';
            } else {
              category.color = 'default';
            }
          });
        }
      }
      setCategories(response.data.data);
    }
  };
  return (
    <>
      <MemberList members={members} isLoading={isLoading} comments={comments} onClickCategory={onClickCategory} categories={categories} />
      <Footer />
    </>
  );
};

export default MemberListContainer;
