import React from 'react';
import Slider from 'react-slick';

function MagazineSlide ({  images } : any) {

    const CustomPrevArrow = props => {
        const { onClick } = props;
        return <div className="mainPrevious" onClick={onClick} />;
      };
      const CustomNextArrow = props => {
        const { onClick } = props;
        return <div className="mainNext" onClick={onClick} />;
      };
    
      const commonSettings  = {
        dots: true,
        dotsClass: 'popup-banner-dots',
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <Slider {...commonSettings}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Slide ${index + 1}`} />
      ))}
    </Slider>
  );
};

export default MagazineSlide;