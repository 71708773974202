import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function CheckboxForm({ question, changeInformation }: any) {
  const [checkList, setCheckList] = useState([]);

  const checkHandler = e => {
    if (e.target.checked) {
      if (checkList.length >= question.maxLimit) {
        alert(`최대 선택은 ${question.maxLimit}개 입니다.`);
        e.target.checked = false;
        return;
      } else {
        setCheckList([...checkList, e.target.id]);
        changeInformation({ surveyQuestionId: question.surveyQuestionId, surveyQuestionCaseId: Number(e.target.id) }, 'check');
        return;
      }
    } else {
      // 체크 해제
      const filteredCheck = checkList.filter(list => list !== e.target.id.toString());
      setCheckList(filteredCheck);
      changeInformation({ surveyQuestionId: question.surveyQuestionId, surveyQuestionCaseId: Number(e.target.id) }, 'check');
    }
  };

  return (
    <>
      {question && (
        <div className="q-wrap">
          <p className="q-tit">
            {question.title}
            {question.maxLimit ? ` (복수 응답 ${question.maxLimit}개)` : ''}
          </p>
          <div className="a-wrap checkbox">
            {question.surveyQuestionCase.map(c => {
              return (
                <label>
                  <input type="checkbox" id={c.surveyQuestionCaseId} onChange={e => checkHandler(e)} />
                  {c.case}
                </label>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CheckboxForm;
