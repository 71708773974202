import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import lineGif from './../../../assets/img/event-template/popply_lines_gif.gif';

function EventIntro(promotion) {
  console.log(promotion.promotion.data);
  return (
    <>
      {/* 인트로페이지 .. 동작 후 사라짐 */}
      <div className="event-intro-wrap">
        <div className="event-intro-video">
          <img src={lineGif} alt="" />
        </div>
        <div className="event-intro-text-wrap">
          <p>팝플리 팝업스토어에 방문하고, 팝플리 메시지를 만들어 보세요!</p>
          <p className="bold-text">{promotion.promotion.data.promotionDetail.content}</p>
        </div>
      </div>
    </>
  );
}
export default EventIntro;
