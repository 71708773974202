import React from 'react';
import AppleLogin from 'react-apple-login';
const {REACT_APP_APPLE_CLIENT_ID , REACT_APP_APPLE_REDIRECT_URI} = process.env;

const AppleLoginButton = ({returnOrigin, returnState}: any) => {

  const handleAppleLogin = async (response: any) => {
    if (response.authorization) {
      try {
        const result = await fetch('/login/apple-comunication', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: response.authorization.code }),
        });
        const data = await result.json();
        console.log('User data:', data.user);
        // 여기서 로그인 상태를 업데이트하거나 리다이렉트를 수행할 수 있습니다.
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
  };

  const parsedReturnURI = returnOrigin !== undefined ? new URL (returnOrigin) : undefined;  
  if( returnState === undefined || returnState === "/member/login") {
    returnState = "/";
  }
  return (
    <AppleLogin
      clientId={REACT_APP_APPLE_CLIENT_ID}
      redirectURI={REACT_APP_APPLE_REDIRECT_URI}
      usePopup={false}
      callback={handleAppleLogin}
      scope="name email"
      responseMode="form_post"
      responseType="code id_token"
      state={encodeURIComponent(JSON.stringify({ returnOrigin,  state: returnState}))}
      designProp={{        
        color:  "white",
        border_radius: 25,
        border: true,
        type: "sign-in", 
        width: 275,        
        height: 44,
    }}

      // render={renderProps => (
      //   <div id="appleid-signin" className="signin-button" data-border-radius="50" data-mode="logo-only" data-color="white" data-border="true" data-type="sign in"></div>
      //   // <div id="appleid-signin" style={{padding: 0}} className="snsBtnWrap signin-button" data-mode="logo-only" data-border-radius="50" data-color="black" data-border="true" data-type="sign in"></div>        
      // )}
    />
  );
};

export default AppleLoginButton;