import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import createRequestSaga from '../lib/createRequestSaga';
import * as api from '../lib/api';

/* 액션 타입 만들기 */
// Ducks 패턴을 따를땐 액션의 이름에 접두사를 넣어주세요.
// 이렇게 하면 다른 모듈과 액션 이름이 중복되는 것을 방지 할 수 있습니다.
export const FETCH_ONE = 'board/FETCH_ONE';
const FETCH_ONE_SUCCESS = 'board/FETCH_ONE_SUCCESS';
const FETCH_ONE_FAILURE = 'board/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'board/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'board/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'board/FETCH_LIST_FAILURE';

export const FETCH_ONE_PREVIEW = 'board/FETCH_ONE_PREVIEW';
const FETCH_ONE_PREVIEW_SUCCESS = 'board/FETCH_ONE_PREVIEW_SUCCESS';
const FETCH_ONE_PREVIEW_FAILURE = 'board/FETCH_ONE_PREVIEW_FAILURE';

export const FETCH_SEARCH_LIST = 'board/FETCH_SEARCH_LIST';
const FETCH_SEARCH_LIST_SUCCESS = 'board/FETCH_SEARCH_LIST_SUCCESS';
const FETCH_SEARCH_LIST_FAILURE = 'board/FETCH_SEARCH_LIST_FAILURE';

export const FETCH_LIST_MORE = 'board/FETCH_LIST_MORE';
const FETCH_LIST_MORE_SUCCESS = 'board/FETCH_LIST_MORE_SUCCESS';
const FETCH_LIST_MORE_FAILURE = 'board/FETCH_LIST_MORE_FAILURE';

export const FETCH_LIST_BY_DATE = 'board/FETCH_LIST_BY_DATE';
const FETCH_LIST_BY_DATE_SUCCESS = 'board/FETCH_LIST_BY_DATE_SUCCESS';
const FETCH_LIST_BY_DATE_FAILURE = 'board/FETCH_LIST_BY_DATE_FAILURE';

export const TEMP_LIKE_STORE = 'board/TEMP_LIKE_STORE';

export const fetchOne = createAction(FETCH_ONE, (storeId, userId) => ({ storeId, userId }));
export const fetchOneByPreview = createAction(FETCH_ONE_PREVIEW, (storeId, previewId) => ({ storeId, previewId })); 
   
export const fetchList = createAction(FETCH_LIST, userId => ({ userId }));
// export const fetchSearchList = createAction(FETCH_SEARCH_LIST, (searchItem: string, userId: number) => searchItem);
export const fetchSearchList = createAction(FETCH_SEARCH_LIST, (searchItem, userId) => ({ searchItem, userId }));

export const fetchListByDate = createAction(FETCH_LIST_BY_DATE, (date, userId) => ({ date, userId }));

// export const fetchOne = createAction(FETCH_ONE, (groupCode: any, codeValue: any) => ({ groupCode, codeValue }));

export const fetchListMore = createAction(FETCH_LIST_MORE, (page: number) => page);

export const updateTempStoreUserLike = createAction(TEMP_LIKE_STORE, (store) => ({store}));

// 비동기 액션을 수행하는 태스크 작성
const fetchOneSaga = createRequestSaga(FETCH_ONE, api.fetchBoard);
const fetchOneSagaByPreview = createRequestSaga(FETCH_ONE_PREVIEW, api.fetchBoardByPreview);
const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchBoardList);
const fetchSearchListSaga = createRequestSaga(FETCH_SEARCH_LIST, api.fetchBoardSearchList);
const fetchListMoreSaga = createRequestSaga(FETCH_LIST_MORE, api.fetchBoardListMore);
const fetchListByDateSaga = createRequestSaga(FETCH_LIST_BY_DATE, api.fetchListByDate);

export function* boardSaga() {
  yield takeLatest(FETCH_ONE, fetchOneSaga);
  yield takeLatest(FETCH_ONE_PREVIEW, fetchOneSagaByPreview);
  yield takeLatest(FETCH_LIST, fetchListSaga);
  yield takeLatest(FETCH_SEARCH_LIST, fetchSearchListSaga);  
  yield takeLatest(FETCH_LIST_MORE, fetchListMoreSaga);
  yield takeLatest(FETCH_LIST_BY_DATE, fetchListByDateSaga);
}

export const initialState = {
  board: null,
  boards: [],
  page: 1,
  error: null,
  store: null,
  searchStores: [],
};

// 리듀서 함수 정의
const board = createReducer(initialState, {
  [TEMP_LIKE_STORE]: (state, action) => {
    return {
    ...state, store :  action.payload.store
  }},
  [FETCH_ONE]: state => ({
    ...state,
    board: null,
    store: null,
  }),
  [FETCH_ONE_SUCCESS]: (state, action) => ({
    ...state,
    board: action.payload,
    store: action.payload,
  }),
  [FETCH_ONE_FAILURE]: (state, action) => ({
    ...state,
    board: null,
    store: null,
    error: action.payload,
  }),

  [FETCH_ONE_PREVIEW]: state => ({
    ...state,
    board: null,
    store: null,
  }),
  [FETCH_ONE_PREVIEW_SUCCESS]: (state, action) => ({
    ...state,
    board: action.payload,
    store: action.payload,
  }),
  [FETCH_ONE_PREVIEW_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  //default list
  [FETCH_LIST]: state => ({
    ...state,
    boards: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => (
    (state.page = 5),
    {
      ...state,
      boards: action.payload,
    }
  ),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  // search
  [FETCH_SEARCH_LIST]: state => ({
    ...state,
    boards: [],
  }),
  [FETCH_SEARCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    boards: action.payload,
    // searchStores:action.payload.data,
  }),
  [FETCH_SEARCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),


  // pagination
  [FETCH_LIST_MORE]: state => ({
    ...state,
    boards: state.boards,
  }),

  [FETCH_LIST_MORE_SUCCESS]: (state, action) => ({
    ...state,
    boards: state.boards,
    searchStores: action.payload.data,
  }),

  [FETCH_LIST_MORE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [FETCH_LIST_BY_DATE]: state => ({
    ...state,
    boards: [],
  }),

  [FETCH_LIST_BY_DATE_SUCCESS]: (state, action) => ({
    ...state,
    boards: action.payload,
  }),

  [FETCH_LIST_BY_DATE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  // [FETCH_BOARD_VIEW]: (state) =>({
  //     ...state
  // }),
  // [FETCH_BOARD_VIEW_SUCCESS]: (state) =>({
  //     ...state
  // }),
  // [FETCH_BOARD_VIEW_FAILURE]: (state) =>({
  //     ...state
  // })
});

export default board;
