import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import createRequestSaga from '../lib/createRequestSaga';
import * as api from '../lib/api';
export const FETCH_ONE = 'survey/FETCH_ONE';
const FETCH_ONE_SUCCESS = 'survey/FETCH_ONE_SUCCESS';
const FETCH_ONE_FAILURE = 'survey/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'survey/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'survey/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'survey/FETCH_LIST_FAILURE';

export const fetchOne = createAction(FETCH_ONE, (storeId, reservationId, type, userId) => ({ storeId, reservationId, type, userId }));
// export const fetchList = createAction(FETCH_LIST, userId => ({ userId }));

// 비동기 액션을 수행하는 태스크 작성
const fetchOneSaga = createRequestSaga(FETCH_ONE, api.fetchSurvey);
// const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchReservations);

export function* surveySaga() {
  yield takeLatest(FETCH_ONE, fetchOneSaga);
  // yield takeLatest(FETCH_LIST, fetchListSaga);
}

export const initialState = {
  survey: null,
  surveys: [],
  error: null,
};

// 리듀서 함수 정의
const survey = createReducer(initialState, {
  [FETCH_ONE]: state => ({
    ...state,
    survey: null,
  }),
  [FETCH_ONE_SUCCESS]: (state, action) => ({
    ...state,
    survey: action.payload.data,
  }),
  [FETCH_ONE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload.data,
  }),

  // //default list
  // [FETCH_LIST]: state => ({
  //   ...state,
  //   reservations: [],
  // }),
  // [FETCH_LIST_SUCCESS]: (state, action) => (
  //   console.log('!@#!@#!@#'),
  //   {
  //     ...state,
  //     reservations: action.payload,
  //   }
  // ),
  // [FETCH_LIST_FAILURE]: (state, action) => ({
  //   ...state,
  //   error: action.payload,
  // }),
});

export default survey;
