import PreReservationContainer from '../../containers/member/PreReservationContainer';
import MainLayout from '../../layout/MainLayout';

export default function PreReservationListPage() {
  return (
    <>
      <MainLayout>
        <PreReservationContainer />
      </MainLayout>
    </>
  );
}
