import PopupReportReadContainer from '../../../containers/contact/popupreport/PopupReportReadContainer';
import MainLayout from '../../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function PopupReportReadPage({ match }: any) {
  const { itemId } = useParams();

  return (
    <>
      <MainLayout>
        <PopupReportReadContainer itemId={itemId} />
      </MainLayout>
    </>
  );
}

export default PopupReportReadPage;
