import ReactHtmlParser from 'html-react-parser';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './admin_popupform.scss';
import slideimg1 from './../../assets/img/slideImg1.png';
import mapicon from './../../assets/img/map/map-gray.svg';
import bookmark from './../../assets/img/popup/bookmark.svg';
import shareicon from './../../assets/img/popup/share-icon.svg';
import arrow_left from './../../assets/img/common/arrow_left_small.svg';
import arrow_right from './../../assets/img/common/arrow_right_gray.svg';
import view_count from './../../assets/img/popup/view-count.svg';
import parkicon from './../../assets/img/popup/park-icon.png';
import noparkicon from './../../assets/img/popup/no-park-icon.png';
import feeicon from './../../assets/img/popup/fee-icon.png';
import freeicon from './../../assets/img/popup/free-icon.png';
import peticon from './../../assets/img/popup/pet-icon.png';
import kidsokicon from './../../assets/img/popup/kids-ok-icon.png';
import kidsnoicon from './../../assets/img/popup/kids-no-icon.png';
import adulticon from './../../assets/img/popup/adult-icon.png';
import wifiicon from './../../assets/img/popup/wifi-icon.png';
import nodrinkicon from './../../assets/img/popup/no-drink-icon.png';
import photoicon from './../../assets/img/popup/photo-icon.png';
import arrow_top from './../../assets/img/common/arrow_top_gray.svg';
import Slider from 'react-slick';
import fold from './../../assets/img/common/arrow-left-circle-fill.svg';
import open from './../../assets/img/common/arrow-right-circle-fill.svg';
import pcopen from './../../assets/img/common/arrow-right-double-fill.svg';
import pcfold from './../../assets/img/common/arrow-left-double-line.svg';
import searchbg from './../../assets/img/layout/search-bg.svg';

import dayjs from 'dayjs';
import axios from 'axios';
import PopupUploadPreview from './PopupUploadPreview';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as XLSX from 'xlsx';
// import imageCompression from 'browser-image-compression';

let bulkIdx = 0;
function BoardRegisterForm({ onRegister, categories }: any) {
  const keyDown = e => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      // onRegister(storeInfo, formData);
      onRegisterBulk();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', keyDown);
    return () => window.removeEventListener('keydown', keyDown);
  }, []);

  const { pathname } = useLocation();
  const initializeStoreInfo = () => {
    setStoreInfo({
      title: '',
      categoryId: '',
      mainBrand: '메인 브랜드 (필수)',
      brand_1: '',
      brand_2: '',
      brand_3: '',
      brand_4: '',
      startDate: dayjs(),
      endDate: dayjs().add(1, 'week'),
      hashtag: '',
      workingTime: [
        { notice: '' },
        { day: '월', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '화', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '수', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '목', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '금', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '토', startDate: '09:00', endDate: '19:00', holiday: false },
        { day: '일', startDate: '09:00', endDate: '19:00', holiday: false },
      ],

      preRegister: false,
      preRegisterLink: null,
      preRegisterStartDate: dayjs().add(-1, 'week'),
      preRegisterEndDate: dayjs().add(-1, 'days'),
      topLevelAddress: '',
      address: '',
      detailAddress: '',
      latitude: '',
      longitude: '',
      storeDetail: {
        contents: '소개글을 입력해주세요.',
        notice: '',
        brandUrl: 'https://popply.co.kr',
        instaUrl: 'https://www.instagram.com/popply_official/',
        parking: 1,
        free: 1,
        kids: null,
        pet: 0,
        food: 0,
        adult: 0,
        wifi: 0,
        picture: 0,
      },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [applyStartTime, setApplyStartTime] = useState('09:00');
  const [applyEndTime, setApplyEndTime] = useState('18:00');
  const [hashArr, setHashArr] = useState<string[] | []>([]);
  const [imageSrc, setImageSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [bulkStoreInfo, setBulkStoreInfo] = useState([]);

  const hashtagBinding = async hashtags => {
    setHashArr(hashtags);
    hashtags.map(tag => {
      const $HashWrapOuter = document.querySelector('.HashWrapOuter');
      const $HashWrapInner = document.createElement('div');
      $HashWrapInner.className = 'HashWrapInner';
      $HashWrapInner.innerHTML = tag;
      $HashWrapOuter?.appendChild($HashWrapInner);
      setStoreInfo(prevState => ({
        ...prevState,
        hashtag: hashArr.toString(),
      }));

      //TODO hashtag elmenation bug
      $HashWrapInner.addEventListener('click', () => {
        $HashWrapOuter?.removeChild($HashWrapInner);
        // console.log(hashArr);
        // const aww = hashArr.filter(hashtag => hashtag !== tag);
        // console.log(aww);
        // setHashArr(hashtags.filter(hashtag => hashtag === tag));
        setHashArr(hashArr.filter(hashtag => hashtag !== tag));
      });
    });
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async e => {
      const astr = e.target.result;
      const workbook = XLSX.read(astr, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 2 });
      setBulkStoreInfo(jsonData);
      await bindStoreInfo(jsonData[0]);
    };

    reader.readAsArrayBuffer(file);
  };

  const bindStoreInfo = async data => {
    if (data.title) {
      const categoryInfo = categories.filter(category => {
        return data.category === category.name;
      });
      if (data.preRegister === 0) {
        data.preRegisterStartDate = null;
        data.preRegisterEndDate = null;
      }
      const replacedContents = await replaceNewLine(data.contents, 'contents');
      let replacedNotice = '';
      if (data.notice) {
        replacedNotice = await replaceNewLine(data.notice, 'notice');
      }
      await hashtagBinding(data.hashtag.split(', '));
      setStoreInfo({
        title: data.title,
        categoryId: categoryInfo[0].categoryId,
        mainBrand: data.mainBrand,
        brand_1: data.brand_1 ? data.brand_1 : '',
        brand_2: data.brand_2 ? data.brand_2 : '',
        brand_3: data.brand_3 ? data.brand_3 : '',
        brand_4: data.brand_4 ? data.brand_4 : '',
        startDate: dayjs(data.startDate),
        endDate: dayjs(data.endDate),
        hashtag: data.hashtag,
        workingTime: [
          { notice: '' },
          { day: '월', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '화', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '수', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '목', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '금', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '토', startDate: '09:00', endDate: '19:00', holiday: false },
          { day: '일', startDate: '09:00', endDate: '19:00', holiday: false },
        ],
        preRegister: data.preRegister,
        preRegisterLink: data.preRegister === 1 ? data.preRegisterLink : null,
        preRegisterStartDate: data.preRegister === 1 ? data.preRegisterStartDate : null,
        preRegisterEndDate: data.preRegister === 1 ? data.preRegisterEndDate : null,
        topLevelAddress: null,
        address: data.address,
        detailAddress: data.detailAddress,
        latitude: '',
        longitude: '',
        storeDetail: {
          contents: replacedContents,
          notice: replacedNotice,
          brandUrl: data.brandUrl,
          instaUrl: data.instaUrl,
          parking: data.parking ? data.parking : null,
          free: data.free,
          kids: data.kids ? data.kids : null,
          pet: data.pet,
          food: data.food,
          adult: data.adult,
          wifi: data.wifi,
          picture: data.picture,
        },
      });
      setFindAddress(data.address);
    } else {
      initializeStoreInfo();
      setHashArr([]);
      setFindAddress('');
    }
  };

  const onUpload = async (e: any) => {
    const images = e.target.files;

    setFiles(images);
    let fileURLs = [];
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(images[i]);
      reader.onload = () => {
        fileURLs[i] = reader.result;
        setImageSrc([...fileURLs]); // 파일의 컨텐츠
      };
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      const formData = new FormData();

      if (files.length > 0) {
        for (const image of files) {
          formData.append('files', image);
        }
      }
      onRegister(storeInfo, formData);
    }
  };

  const onRegisterBulk = async () => {
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      const formData = new FormData();

      if (files.length > 0) {
        for (const image of files) {
          formData.append('files', image);
        }
      }
      const isRegisterd = await onRegister(storeInfo, formData);
      if (isRegisterd) {
        bulkIdx = bulkIdx + 1;
        bindStoreInfo(bulkStoreInfo[bulkIdx]);
        setHashArr([]);
        setFiles([]);
        setImageSrc([]);
        const $HashWrapOuter = document.querySelector('.HashWrapOuter');
        while ($HashWrapOuter.firstChild) {
          $HashWrapOuter.removeChild($HashWrapOuter.firstChild);
        }
      }
    }
  };
  const [findAddress, setFindAddress] = useState('');
  const chkAddress = e => {
    const value = e.target.value;
    setFindAddress(value);
  };

  const getAddressInfo = async values => {
    const response = await axios.get(`https://api.popply.co.kr/api/common/getPostCode`, {
      params: {
        address: findAddress,
      },
    });
    const addressInfos = response.data;
    if (addressInfos.length === 0) {
      alert('정확한 주소를 입력해주세요.');
    }
    await setAddress(addressInfos);
  };

  const setAddressInfo = ad => {
    a(ad);
  };
  const a = ad => {
    setStoreInfo({ ...storeInfo, ...{ address: ad.roadAddress, topLevelAddress: `${ad.si} ${ad.gun}`, latitude: ad.latitude, longitude: ad.longitude } });
    // console.log(storeInfo);
  };
  //상세주소
  const changeDetailAddreess = e => {
    if (storeInfo.address !== '' && storeInfo.address) {
      setStoreInfo({ ...storeInfo, detailAddress: `${e.target.value}` });
    }
  };

  // 일괄적용 시간
  const applyAllTime = e => {
    const id = e.target.id;
    const value = e.target.value;
    if (id === 'startTime') {
      setApplyStartTime(value);
    } else if (id === 'endTime') {
      setApplyEndTime(value);
    }
  };

  //일괄적용
  const applyAllDay = e => {
    const a = storeInfo.workingTime.map(wt => {
      return {
        day: wt.day,
        startDate: applyStartTime, // 일괄의 오픈시간
        endDate: applyEndTime, // 일괄의 마감시간
        holiday: false,
        disabled: false,
      };
    });
    setStoreInfo({ ...storeInfo, ...storeInfo.workingTime, ['workingTime']: a });
  };

  const applyWorkingTime = e => {
    const id = e.target.id;
    const value = e.target.value;
    if (id.includes('start')) {
      const a = storeInfo.workingTime.map((wt, index) => {
        return {
          day: wt.day,
          startDate: id.includes(index) ? value : wt.startDate,
          endDate: wt.endDate,
          holiday: wt.holiday,
        };
      });
      setStoreInfo({ ...storeInfo, ...storeInfo.workingTime, ['workingTime']: a });
    } else if (id.includes('end')) {
      const a = storeInfo.workingTime.map((wt, index) => {
        return {
          day: wt.day,
          startDate: wt.startDate,
          endDate: id.includes(index) ? value : wt.endDate,
          holiday: wt.holiday,
        };
      });
      setStoreInfo({ ...storeInfo, ...storeInfo.workingTime, ['workingTime']: a });
    } else if (id.includes('p_rest_notice')) {
      // 휴일 공지사항
      storeInfo.workingTime[0].notice = value;
      setStoreInfo({ ...storeInfo, workingTime: storeInfo.workingTime });
    } else {
      const a = storeInfo.workingTime.map((wt, index) => {
        return {
          day: wt.day,
          startDate: wt.startDate,
          endDate: wt.endDate,
          holiday: id.includes(index) ? !wt.holiday : wt.holiday,
        };
      });
      setStoreInfo({ ...storeInfo, ...storeInfo.workingTime, ['workingTime']: a });
    }
  };
  const changeInformation = e => {
    const { id, name, type, value } = e.target;
    let informationVal;
    if (type === 'checkbox') {
      const isChecked = e.target.checked;
      if (isChecked === true) {
        informationVal = 1;
      } else if (isChecked === false) {
        informationVal = 0;
      }
    } else {
      // radio
      if (id.includes('none')) {
        informationVal = null;
      } else if (id.includes('true')) {
        informationVal = 1;
      } else if (id.includes('false')) {
        informationVal = 0;
      }
    }
    setStoreInfo(prevState => ({
      ...prevState,
      storeDetail: { ...prevState.storeDetail, [name]: informationVal },
    }));
  };
  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const settings = {
    dots: true,
    dotsClass: 'popupdetail-banner-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
  };
  const [address, setAddress] = useState([
    {
      zipcode: '', //addresses[0].addressElements.longName
      title: '',
      roadAddress: '',
      topLevelAddress: '',
      detailAddress: '',
      latitude: '', // addresses[0].y
      longitude: '', // addresses[0].x
      si: '',
      gun: '',
    },
  ]);

  //미리보기 접었다 폈다
  const [openFold, setOpenFold] = useState(true);
  const foldClick = (isClick: boolean) => {
    setOpenFold(current => !current);
  };
  const [openPc, setOpenPc] = useState(false);
  const pcOpenClick = (isClick: boolean) => {
    setOpenPc(current => !current);
  };

  //주소검색 팝업
  const [zipcodeOn, setZipcodeOn] = useState(true);
  const zipcodeClick = (isClick: boolean) => {
    setZipcodeOn(current => !current);
  };

  //미리보기 팝업
  const [previewOn, setPreviewOn] = useState(true);
  const popupPreviewClick = (isClick: boolean) => {
    setPreviewOn(current => !current);
  };

  //썸네일
  const [thumbNail, setThumbNail] = useState(false);
  const thumbanilClick = (isClick: boolean) => {
    setThumbNail(current => !current);
  };

  const [storeInfo, setStoreInfo] = useState({
    title: null,
    categoryId: '',
    mainBrand: '메인 브랜드 (필수)',
    brand_1: '',
    brand_2: '',
    brand_3: '',
    brand_4: '',
    startDate: dayjs(),
    endDate: dayjs().add(1, 'week'),
    hashtag: '',
    workingTime: [
      { notice: '' },
      { day: '월', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '화', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '수', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '목', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '금', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '토', startDate: '09:00', endDate: '19:00', holiday: false },
      { day: '일', startDate: '09:00', endDate: '19:00', holiday: false },
    ],

    preRegister: false,
    preRegisterLink: null,
    preRegisterStartDate: dayjs().add(-1, 'week'),
    preRegisterEndDate: dayjs().add(-1, 'days'),
    topLevelAddress: '',
    address: '',
    detailAddress: '',
    latitude: '',
    longitude: '',
    storeDetail: {
      contents: '소개글을 입력해주세요.',
      notice: '',
      brandUrl: 'https://popply.co.kr',
      instaUrl: 'https://www.instagram.com/popply_official/',
      parking: 1,
      free: 1,
      kids: null,
      pet: 0,
      food: 0,
      adult: 0,
      wifi: 0,
      picture: 0,
    },
  });

  const changeStoreInfos = e => {
    const { value, id } = e.target;
    setStoreInfo({ ...storeInfo, [id]: value });
  };

  const changeStoreDate = e => {
    setStoreInfo({ ...storeInfo, [e.target.id]: dayjs(e.target.value).format('YYYY-MM-DD') });
  };

  const hashKeyUp = e => {
    /* 요소 불러오기, 만들기*/

    const tag = e.target.value.replace(/ /g, '');
    const $HashWrapOuter = document.querySelector('.HashWrapOuter');
    const $HashWrapInner = document.createElement('div');
    $HashWrapInner.className = 'HashWrapInner';
    $HashWrapInner.addEventListener('click', () => {
      $HashWrapOuter?.removeChild($HashWrapInner);
      setHashArr(hashArr.filter(hashtag => hashtag));
    });

    /* enter 키 코드 :13 space 키 코드 32*/
    if (e.keyCode === 32 && e.target.value.trim() !== '') {
      $HashWrapInner.innerHTML = e.target.value;
      $HashWrapOuter?.appendChild($HashWrapInner);

      setHashArr([...new Set([...hashArr, tag])]);
      setStoreInfo(prevState => ({
        ...prevState,
        hashtag: hashArr.toString(),
      }));
      e.target.value = '';
    }
  };

  //포커스
  const inputFocusRef = useRef([]);
  const [inputFocus, setInputFocus] = useState();

  return (
    <article className="inner admin">
      <div className="submenu admin">
        <a className="on">
          <h2>팝업스토어 등록</h2>
        </a>
        <a href="/placelist/create">
          <h2>플레이스리스트 등록</h2>
        </a>
        <a>
          <h2>브랜드 관리</h2>
        </a>
        <a>
          <h2>매거진 관리</h2>
        </a>
      </div>
      <p style={{ textAlign: 'center', marginBottom: '20px' }}>*PC 최적화 화면입니다. 가급적 PC에서 작업해주시기 바랍니다.</p>
      <div className="admin-popupform">
        {/* 팝업 미리보기 영역 */}
        <div className={openFold ? 'admin-popup-preview' : openPc ? 'admin-popup-preview pc' : 'admin-popup-preview hide'}>
          <div className="admin-popup-preview-wrap">
            <header>
              <h3>미리보기</h3>
            </header>
            <div className="popupdetail-banner">
              <Slider {...settings}>
                {imageSrc.length > 0 &&
                  imageSrc.map(img => {
                    return (
                      <div className="slide-content">
                        <div className="slide-img-wrap">
                          <img width={'100%'} src={img} />
                        </div>
                      </div>
                    );
                  })}
                {imageSrc.length < 1 && (
                  <>
                    <div className="slide-content">
                      <div className="slide-img-wrap">
                        <img src={slideimg1} alt="" />
                      </div>
                    </div>
                  </>
                )}
              </Slider>
            </div>
            <div className="popupdetail">
              <div className="popupdetail-wrap">
                <ul className="popupdetail-top">
                  <li>
                    <Link to="#">
                      <img src={arrow_left} alt="" style={{ width: '8px' }} />
                    </Link>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <span>252</span>
                        <img src={bookmark} alt="" />
                      </li>
                      <li>
                        <button type="button">
                          <img src={shareicon} alt="" />
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="submenu">
                  <Link to="#" className="on">
                    <h2>정보</h2>
                  </Link>
                  <Link to="#">
                    <h2>후기</h2>
                  </Link>
                </div>
                <div className="popupdetail-inner">
                  <div className="popupdetail-title">
                    <ul className="popupdetail-title-top">
                      <li>
                        <p>{storeInfo.mainBrand ? storeInfo.mainBrand : '메인브랜드(필수)'}</p>
                      </li>
                      <li>
                        <span>3275</span>
                        <img src={view_count} alt="" />
                      </li>
                    </ul>
                    <div className="popupdetail-title-info">
                      <h1 ref={el => (inputFocusRef.current[0] = el)} className="tit">
                        {storeInfo.title ? storeInfo.title : '팝업스토어 이름을 적어주세요.'}
                      </h1>
                      <p className="date">
                        {dayjs(storeInfo.startDate).format('YYYY.MM.DD')} - {dayjs(storeInfo.endDate).format('YYYY.MM.DD')}
                      </p>
                      <p className="location">
                        <img src={mapicon} /> {storeInfo.address} {storeInfo.detailAddress}
                      </p>
                      <div className="search-box-inner">
                        <ul>
                          {hashArr.length > 0 &&
                            hashArr.map((atag, idx) => {
                              return <li key={idx}>{atag}</li>;
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="popupdetail-icon-area">
                    <ul>
                      {storeInfo.storeDetail.parking === 1 && (
                        <li>
                          <img src={parkicon} alt="주차가능" />
                          <p>주차가능</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.parking === 0 && (
                        <li>
                          <img src={noparkicon} alt="주차불가" />
                          <p>주차불가</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.free === 0 && (
                        <li>
                          <img src={feeicon} alt="입장료 유료" />
                          <p>입장료 유료</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.free === 1 && (
                        <li>
                          <img src={freeicon} alt="입장료 무료" />
                          <p>입장료 무료</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.pet === 1 && (
                        <li>
                          <img src={peticon} alt="반려동물 가능" />
                          <p>반려동물</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.kids === 1 && (
                        <li>
                          <img src={kidsokicon} alt="웰컴키즈존" />
                          <p>웰컴 키즈존</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.kids === 0 && (
                        <li>
                          <img src={kidsnoicon} alt="노키즈존" />
                          <p>노키즈존</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.food === 1 && (
                        <li>
                          <img src={nodrinkicon} alt="식음료 반입 금지" />
                          <p>식음료 반입 금지</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.adult === 1 && (
                        <li>
                          <img src={adulticon} alt="19세 이상" />
                          <p>19세 이상</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.wifi === 1 && (
                        <li>
                          <img src={wifiicon} alt="와이파이 가능" />
                          <p>와이파이 가능</p>
                        </li>
                      )}
                      {storeInfo.storeDetail.picture === 1 && (
                        <li>
                          <img src={photoicon} alt="사진촬영 가능" />
                          <p>사진촬영 가능</p>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="popupdetail-time">
                    <header>
                      <h3 className="info-tit">운영 시간</h3>
                      <img src={arrow_top} alt="" className="open" />
                    </header>
                    <ul className="open">
                      {storeInfo.workingTime.map(wt => {
                        if (wt.day && wt.holiday === false) {
                          return (
                            <li>
                              {wt.day} : {wt.startDate} ~ {wt.endDate}
                            </li>
                          );
                        }
                      })}
                      <li className="time-notice">휴일 공지사항</li>
                      <p>{storeInfo.workingTime[0].notice}</p>
                    </ul>
                  </div>
                  <div className="popupdetail-info">
                    <h3 className="info-tit">팝업스토어 소개</h3>
                    <div className="popupdetail-info-inner">
                      <p>{ReactHtmlParser(storeInfo.storeDetail.contents)}</p>
                    </div>
                  </div>
                  <div className="popupdetail-caution">
                    <h3 className="info-tit">안내 및 주의사항</h3>
                    <div>{ReactHtmlParser(storeInfo.storeDetail.notice)}</div>
                  </div>
                  <div className="popupdetail-map">
                    <div>
                      {/* <Map datas={store} zoomControl={true} isStore={false} /> */}
                      {/* <img
                      src={zoom}
                      alt="지도 확대"
                      onClick={() => {
                        mapLayerOpen(true);
                      }}
                    /> */}
                    </div>
                    <ul>
                      <li>
                        <p>
                          {storeInfo.address} {storeInfo.detailAddress}
                        </p>
                      </li>
                      <li>
                        <button type="button">주소복사</button>
                      </li>
                    </ul>
                  </div>
                  <div className="popupdetail-link">
                    <ul>
                      <li>
                        <Link to="#" onClick={() => openInNewTab(storeInfo.storeDetail.brandUrl)}>
                          브랜드 홈페이지 바로가기
                          <img src={arrow_right} alt="" />
                        </Link>
                        <Link to="#" onClick={() => openInNewTab(storeInfo.storeDetail.instaUrl)}>
                          인스타그램 바로가기
                          <img src={arrow_right} alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 미리보기 접었다 폈다 버튼 */}
        <div className="preview-fold-icon-wrap">
          <button
            type="button"
            onClick={() => {
              foldClick(false);
            }}
            className={openFold ? 'preview-fold-icon' : 'preview-open-icon'}
          >
            <img src={openFold ? fold : open} alt="" />
          </button>
          <button
            type="button"
            onClick={() => {
              pcOpenClick(false);
            }}
            className={openFold ? 'preview-open-pc-icon hide' : 'preview-open-pc-icon'}
          >
            <img src={openPc ? pcfold : pcopen} alt="" />
          </button>
        </div>

        {/* 팝업 등록 폼 영역 */}
        <div className={openPc ? 'admin-popupform-write hide' : 'admin-popupform-write'}>
          <header>
            <h3>팝업스토어 폼 작성</h3>
            <span>*사용자에게 노출되는 내용인만큼 정확하게 작성해주시기 바랍니다.</span>
            <br />
            <br />
            <br />
            <input type="file" onChange={handleFileUpload} />
          </header>
          <form onSubmit={handleSubmit}>
            <table>
              <colgroup>
                <col width={'10%'} />
                <col width={'*'} />
              </colgroup>
              <tbody>
                <tr>
                  <td>팝업스토어 이름</td>
                  <td>
                    <input
                      type="text"
                      id="title"
                      onChange={changeStoreInfos}
                      value={storeInfo.title}
                      placeholder={'팝업스토어 이름을 적어주세요.'}
                      onClick={() => {
                        inputFocusRef.current[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
                        setInputFocus(inputFocusRef.current[0]);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>카테고리</td>
                  <td>
                    <select id="categoryId" onChange={changeStoreInfos} value={storeInfo.categoryId}>
                      <option value={0}>카테고리 선택</option>
                      {!!categories.length &&
                        categories.map((category, index) => {
                          return (
                            <option key={index} value={category.categoryId}>
                              {category.name}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>브랜드</td>
                  <td>
                    <input type="text" className="p_brand" id="mainBrand" value={storeInfo.mainBrand} onChange={changeStoreInfos} placeholder={'메인브랜드(필수)'} />
                    <br />
                    <input type="text" className="p_brand" id="brand_1" value={storeInfo.brand_1} onChange={changeStoreInfos} placeholder="브랜드2 (없을 경우 미기재)" />
                    <input type="text" className="p_brand" id="brand_2" value={storeInfo.brand_2} onChange={changeStoreInfos} placeholder="브랜드3 (없을 경우 미기재)" />
                    <input type="text" className="p_brand" id="brand_3" value={storeInfo.brand_3} onChange={changeStoreInfos} placeholder="브랜드4 (없을 경우 미기재)" />
                    <input type="text" className="p_brand" id="brand_4" value={storeInfo.brand_4} onChange={changeStoreInfos} placeholder="브랜드5 (없을 경우 미기재)" />
                  </td>
                </tr>
                <tr>
                  <td>기간</td>
                  <td>
                    <label htmlFor="p_start_date">
                      시작 날짜
                      <input type="date" id="startDate" onChange={changeStoreDate} value={dayjs(storeInfo.startDate).format('YYYY-MM-DD')} min={'2022-01-01'} max={'2025-12-31'} />
                    </label>
                    <label htmlFor="p_end_date">
                      종료 날짜
                      <input type="date" id="endDate" onChange={changeStoreDate} value={dayjs(storeInfo.endDate).format('YYYY-MM-DD')} min={'2022-01-01'} max={'2025-12-31'} />
                    </label>
                  </td>
                </tr>
                <tr>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <td>영업시간</td>
                    <td>
                      <div className="popup-input-wrap popup-time-wrap">
                        <ul className="popuptime-input-all-check">
                          <li>
                            <label htmlFor="p_all_start_time">
                              오픈시간
                              <input type="time" id="startTime" value={applyStartTime} onChange={applyAllTime} />
                            </label>
                          </li>
                          <li>
                            <label htmlFor="p_all_end_time">
                              마감시간
                              <input type="time" id="endTime" value={applyEndTime} onChange={applyAllTime} />
                            </label>
                          </li>
                          <li>
                            <button type="button" className="likebutton" id="p_time_all_agree" onClick={applyAllDay}>
                              일괄적용
                            </button>
                          </li>
                        </ul>
                        <ul className="popuptime-input-check">
                          <li>
                            <ul>
                              {storeInfo &&
                                storeInfo.workingTime.map((wt, index) => {
                                  if (wt.day) {
                                    return (
                                      <>
                                        <li>
                                          <p>{wt.day}</p>
                                          <label>
                                            오픈시간
                                            {/* <input type="time" id="p_mon_start_time" value={wt.startDate} disabled={wt.disabled ? true : false} /> */}
                                            <input type="time" id={`startTime_${index}`} value={wt.startDate} onChange={applyWorkingTime} disabled={wt.holiday ? true : false} />
                                          </label>
                                          <label>
                                            마감시간
                                            <input type="time" id={`endTime_${index}`} value={wt.endDate} onChange={applyWorkingTime} disabled={wt.holiday ? true : false} />
                                          </label>
                                          <label>
                                            <input type="checkbox" id={`disabled_${index}`} defaultChecked={wt.holiday} onChange={applyWorkingTime} />
                                            휴무일
                                          </label>
                                        </li>
                                      </>
                                    );
                                  }
                                })}
                            </ul>
                          </li>
                          <li>
                            <label htmlFor="p_rest_notice">
                              휴일 공지사항
                              <input type="text" id="p_rest_notice" placeholder="정기휴무 외 휴일이 있다면 작성해주세요. (예: 1/1 휴무)" onChange={applyWorkingTime} />
                            </label>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </LocalizationProvider>
                </tr>
                <tr>
                  <td>주소</td>
                  <td>
                    <div className="popup-input-wrap popup-input-address-wrap">
                      <div>
                        <input type="text" id="p_address" disabled placeholder="주소" value={storeInfo.address} />
                        <button
                          type="button"
                          onClick={() => {
                            zipcodeClick(false);
                          }}
                        >
                          검색
                        </button>
                      </div>
                      <input type="text" id="address" value={storeInfo.detailAddress} placeholder="상세주소를 입력해주세요" onChange={changeDetailAddreess} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>해시태그</td>
                  <td>
                    {/* <div className="HashWrap" style={hashDivrap}> */}
                    <p className="p_input_info_text">*스페이스바를 누르면 해시태그가 등록되며, 해시태그를 다시 클릭할 경우 해시태그가 삭제됩니다.</p>
                    <div className="HashWrap">
                      <div className="HashWrapOuter"></div>
                      <input type="text" id="hashTag" onKeyUp={hashKeyUp} placeholder={'해시태그를 입력해주세요'} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>사전예약</td>
                  <td>
                    <div>
                      <label htmlFor="pre-book-n" style={{ cursor: 'pointer' }}>
                        <input type="checkbox" name="pre-book-check" id="pre-book-n" defaultChecked={storeInfo.preRegister ? false : true} />
                        사전예약 없음
                      </label>
                    </div>
                    <div className="popup-input-wrap">
                      <label htmlFor="p_book_start_date">
                        사전예약 시작 날짜
                        <input type="date" onChange={changeStoreDate} value={dayjs(storeInfo.preRegisterStartDate).format('YYYY-MM-DD')} id="preRegisterStartDate" />
                      </label>
                      <label htmlFor="p_book_end_date">
                        사전예약 종료 날짜
                        <input type="date" onChange={changeStoreDate} value={dayjs(storeInfo.preRegisterEndDate).format('YYYY-MM-DD')} id="preRegisterEndDate" />
                      </label>
                      <label htmlFor="p_book_link">
                        <input type="text" id="preRegisterLink" onChange={changeStoreInfos} placeholder={'사전예약 링크를 적어주세요.'} />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>홈페이지 링크</td>
                  <td>
                    <p className="p_input_info_text">*미리보기 화면에서 클릭하면 새창열기로 확인 가능합니다.</p>
                    <input
                      type="text"
                      id="brandUrl"
                      onChange={e => {
                        setStoreInfo(prevState => ({
                          ...prevState,
                          storeDetail: { ...prevState.storeDetail, brandUrl: e.target.value },
                        }));
                      }}
                      placeholder={'https://popply.co.kr'}
                      value={storeInfo.storeDetail.brandUrl}
                    />
                  </td>
                </tr>
                <tr>
                  <td>인스타그램 링크</td>
                  <td>
                    <p className="p_input_info_text">*미리보기 화면에서 클릭하면 새창열기로 확인 가능합니다.</p>
                    <input
                      type="text"
                      id="instaUrl"
                      onChange={e => {
                        setStoreInfo(prevState => ({
                          ...prevState,
                          storeDetail: { ...prevState.storeDetail, instaUrl: e.target.value },
                        }));
                      }}
                      placeholder={'https://www.instagram.com/popply_official/'}
                      value={storeInfo.storeDetail.instaUrl}
                    />
                  </td>
                </tr>
                <tr>
                  <td>팝업내용</td>
                  <td>
                    <Editor
                      editor={ClassicEditor as any}
                      config={{
                        placeholder: storeInfo.storeDetail.contents,
                      }}
                      data={storeInfo.storeDetail.contents}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log(data);
                        setStoreInfo(prevState => ({
                          ...prevState,
                          storeDetail: { ...prevState.storeDetail, contents: data },
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>안내 및 주의사항</td>
                  <td>
                    <Editor
                      editor={ClassicEditor as any}
                      data={storeInfo.storeDetail.notice}
                      config={{
                        placeholder: '공지사항을 입력해주세요',
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setStoreInfo(prevState => ({
                          ...prevState,
                          storeDetail: { ...prevState.storeDetail, notice: data },
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>아이콘</td>
                  <td>
                    <p className="p_input_info_text">*이용자가 쉽게 알 수 있도록 해당하는 항목을 선택해주세요.</p>
                    <div className="popup-input-wrap">
                      <ul className="popup-input-icon-checkbox">
                        <li>
                          <label htmlFor="p_icon_1_none">
                            <input type="radio" id="p_icon_1_none" name="parking" onChange={changeInformation} checked={storeInfo.storeDetail.parking === null ? true : false} />
                            해당없음
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_2_true">
                            <input type="radio" id="p_icon_2_true" name="parking" onChange={changeInformation} checked={storeInfo.storeDetail.parking === 1 ? true : false} />
                            주차가능
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_3_false">
                            <input type="radio" id="p_icon_3_false" name="parking" onChange={changeInformation} checked={storeInfo.storeDetail.parking === 0 ? true : false} />
                            주차불가
                          </label>
                        </li>
                        <li className="bar"></li>
                        <li>
                          <label htmlFor="p_icon_4_true">
                            <input type="radio" id="p_icon_4_true" name="free" onChange={changeInformation} checked={storeInfo.storeDetail.free === 1 ? true : false} />
                            무료입장
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_5_false">
                            <input type="radio" id="p_icon_5_false" name="free" onChange={changeInformation} checked={storeInfo.storeDetail.free === 0 ? true : false} />
                            유료입장
                          </label>
                        </li>
                        <li className="bar"></li>

                        <li>
                          <label htmlFor="p_icon_6_none">
                            <input type="radio" id="p_icon_6_none" name="kids" onChange={changeInformation} checked={storeInfo.storeDetail.kids === null ? true : false} />
                            해당없음
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_7_true">
                            <input type="radio" id="p_icon_7_true" name="kids" onChange={changeInformation} checked={storeInfo.storeDetail.kids === 1 ? true : false} />
                            웰컴키즈존
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_8_false">
                            <input type="radio" id="p_icon_8_false" name="kids" onChange={changeInformation} checked={storeInfo.storeDetail.kids === 0 ? true : false} />
                            노키즈존
                          </label>
                        </li>
                        <li className="bar"></li>

                        <li>
                          <label htmlFor="p_icon_9_true">
                            <input type="checkbox" id="p_icon_9_true" name="pet" onChange={changeInformation} checked={storeInfo.storeDetail.pet === 1 ? true : false} />
                            반려동물 동반 가능
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_10_false">
                            <input type="checkbox" id="p_icon_10_false" name="food" onChange={changeInformation} checked={storeInfo.storeDetail.food === 1 ? true : false} />
                            식음료 반입 금지
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_11_true">
                            <input type="checkbox" id="p_icon_11_true" name="adult" onChange={changeInformation} checked={storeInfo.storeDetail.adult === 1 ? true : false} />
                            19세이상 출입 가능
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_12_true">
                            <input type="checkbox" id="p_icon_12_true" name="wifi" onChange={changeInformation} checked={storeInfo.storeDetail.wifi === 1 ? true : false} />
                            와이파이 가능
                          </label>
                        </li>
                        <li>
                          <label htmlFor="p_icon_13_true">
                            <input type="checkbox" id="p_icon_13_true" name="picture" onChange={changeInformation} checked={storeInfo.storeDetail.picture === 1 ? true : false} />
                            상업적 사진 촬영 가능
                          </label>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>팝업 이미지 업로드</td>
                  <td>
                    <div className="popup-input-wrap popup-input-file-wrap">
                      <label htmlFor="p_image_upload">
                        업로드
                        <input type="file" id="p_image_upload" accept="image/*" multiple onChange={e => onUpload(e)} name="files" />
                      </label>
                      <p className="p_input_info_text">* 이미지는 480*480(px) 사이즈를 권장합니다.</p>
                      <ul className="popup-input-thumbnail-wrap">
                        {imageSrc.length > 0 &&
                          imageSrc.map(img => {
                            return (
                              <li>
                                <div className="thumbnail-img-wrap">
                                  <img src={img} alt="" />
                                </div>
                              </li>
                            );
                          })}
                        {imageSrc.length < 1 && (
                          <>
                            <li>
                              <div className="thumbnail-img-wrap">
                                <img src={slideimg1} alt="" />
                              </div>
                            </li>
                          </>
                        )}
                        {/* <li
                          onClick={() => {
                            thumbanilClick(false);
                          }}
                        >
                          <a className={thumbNail ? 'main-thumbnail-check select' : 'main-thumbnail-check'}>메인</a>
                          <img src={slideimg1} alt="" />
                        </li>
                        <li>
                          <a className={thumbNail ? 'main-thumbnail-check select' : 'main-thumbnail-check'}>메인</a>
                          <img src={slideimg1} alt="" />
                        </li> */}
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="likebutton-wrap">
              <button
                type="button"
                className="likebutton"
                onClick={() => {
                  popupPreviewClick(true);
                }}
              >
                새창으로 미리보기
              </button>
              <button type="submit" className="likebutton">
                등록
              </button>
              <button type="button" className="likebutton">
                승인
              </button>
              <button
                type="button"
                onClick={e => {
                  onRegisterBulk();
                }}
                className="likebutton"
              >
                다음
              </button>
            </div>

            {/* 미리보기 팝업 */}
            <div className={previewOn ? 'admin-preview-popup' : 'admin-preview-popup show'}>
              <div className="popup-close">
                <a
                  href="#"
                  onClick={() => {
                    popupPreviewClick(true);
                  }}
                >
                  X
                </a>
              </div>
              <PopupUploadPreview storeInfo={storeInfo} />
              <div className="btn-wrap">
                <button
                  type="button"
                  className="likebutton full-btn"
                  onClick={() => {
                    popupPreviewClick(true);
                  }}
                >
                  닫기
                </button>
                <button type="submit" className="likebutton full-btn">
                  이대로 등록하기
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* 주소검색 팝업 */}
      <div className={zipcodeOn ? 'admin-zipcode-popup' : 'admin-zipcode-popup show'}>
        <div className="popup-close">
          <a
            href="#"
            onClick={() => {
              zipcodeClick(true);
            }}
          >
            X
          </a>
        </div>
        <div className="admin-zipcode-popup-inner">
          <div className="zipcode-search">
            <input type="search" id="zipcode-search-input" defaultValue={storeInfo.address} placeholder="도로명주소를 입력해주세요. (예: 와우산로35길 13)" onChange={chkAddress} />
            <button onClick={getAddressInfo} className="zipcode-search-icon">
              <img src={searchbg} alt="" />
            </button>
          </div>
          <div className="bar"></div>

          {/* 기본적으로 display:none 상태. 검색 버튼 누르면 className에 show 붙어야 함 */}

          <div className="zipcode-list show">
            <ul>
              {address.length > 0 &&
                address.map((ad, idx) => {
                  return (
                    <li key={idx}>
                      <ul>
                        <li>
                          <p className="zipcode-category">장소</p>
                          <button className="zipcode-text">{ad.title}</button>
                        </li>
                        <li>
                          <p className="zipcode-category">도로명</p>
                          <button className="zipcode-text">{ad.roadAddress}</button>
                        </li>
                      </ul>
                      <button
                        className="zipcode-select"
                        onClick={() => {
                          setAddressInfo(ad);
                          zipcodeClick(true);
                        }}
                      >
                        선택
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
}
const replaceNewLine = async (text, column) => {
  if (column === 'contents') {
    const textArr = text.split('\r\n');
    const abc = textArr.map(t => {
      let paddedStr = t.padStart(t.length + 3, '<p>');
      paddedStr += '</p>';
      return paddedStr;
    });
    return abc.toString().replace(/,/g, '');
  } else if (column === 'notice') {
    if (text.includes('\r\n')) {
      const textArr = text.split('\r\n');
      const abc = textArr.map(t => {
        let paddedStr = t.padStart(t.length + 3, '<p>');
        paddedStr += '</p>';
        return paddedStr;
      });
      return abc.toString().replace(/,/g, '');
    } else {
      let paddedStr = text.padStart(text.length + 3, '<p>');
      paddedStr += '</p>';
      return paddedStr;
    }
  }
};
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
export default BoardRegisterForm;
