// StorageManager.ts
import EventEmitter from 'events';
import { isMobile } from './InusUtils';



class StorageManager extends EventEmitter {
  private resultValue: any = null;

  constructor() {
    super();
    this.initialize();
  }
  
  
  setValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
    console.log(`Set ${key} to ${value} in localStorage`);

    if(isMobile() ) {
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify({
        type: 'setValueToNative',
        data: { key, value }
      }));
    }
  }

  removeValue(key: string) {
    localStorage.removeItem(key);
    if(isMobile()  ) {
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify({
        type: 'removeValueToNative',
        data: { key }
      }));      
    }
  }

  async getValueAsync(key: string, timeoutMs: number = 100): Promise<any> {

    const localValue = localStorage.getItem(key);
    if (localValue) {
       //console.log(`Retrieved "${key}"" from localStorage: ${localValue}`);
       return JSON.parse(localValue);
    }    
    // if( isMobile() && (window as any).ReactNativeWebView ) {
    //   return new Promise((resolve, reject) => {
    //     const requestId = Math.random().toString(36).substr(2, 9);

    //     const timeoutId = setTimeout(() => {
    //       this.removeAllListeners(`getValueResponse:${requestId}`);
    //       reject(new Error(`Timeout after ${timeoutMs}ms for key: ${key}`));
    //     }, timeoutMs);

    //     this.once(`getValueResponse:${requestId}`, (data: any) => {
    //       console.log("Receive getValueResponse", data);
    //       clearTimeout(timeoutId);
    //       if (data.key === key) {
    //         resolve(JSON.parse(data.value));
    //       } else {
    //         reject(new Error('Mismatched key in response'));
    //       }
    //     });

    //     (window as any).ReactNativeWebView?.postMessage(JSON.stringify({
    //       type: 'getValueFromNative',
    //       data: { key, requestId }
    //     }));
    //   });
    // } else {
    //   return null;
    // }
  }

  handleMessage(event: MessageEvent) {
    // console.log("getValueFromNativeResponse:", typeof(event.data) == 'object' ? JSON.stringify(event.data) : event.data  )
    if (typeof event.data !== "string") return; 
    try {   
      const { type, data } = JSON.parse(event.data);
      switch (type) {
        case 'getValueFromNativeResponse':        
          this.emit(`getValueResponse:${data.requestId}`, data);
          break;      
      }
    } catch(e) {
      
    }
  }

  initialize() {    
      window.addEventListener("message", this.handleMessage.bind(this));    
      document.addEventListener("message", this.handleMessage.bind(this));    
  }

  // New method to get the result value
  getResultValue(): any {
    return this.resultValue;
  }
}

const storageManager = new StorageManager();

export default storageManager;