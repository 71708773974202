import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Bookmark from '../common/Bookmark';
import sort_icon from './../../assets/img/common/sort-icon.svg';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import nonethumb from './../../assets/img/noneIMG.png';
import { getFixedLat, getFixedLng } from '../../util/InusUtils';
import { sortOptions, statusOptions } from '../../types/PopplyTypes';

// 사전예약 링크 연결
const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noreferrer');
};

function PopupList({ stores, myInfo, isLoading, query, filters  }: 
  { stores: any; myInfo: any; isLoading: any; query: any;  filters?: { gpsBoundFilter?: any} }) {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortOption, setSortOption] = useState(sortOptions.find(s => s.value = searchParams?.get("sort"))??sortOptions[0]); // 정렬 기본값: 인기순  
  const [statusOption, setStatusOption] = useState(statusOptions.find(s => s.value == searchParams?.get("status"))??statusOptions[1]); // 기간 기본값: 모든 기간  
  
    // 인기순 / 추천순 / 리뷰많은 순 / 별점 높은 순
  const [sortSelect, setSortSelect] = useState(true);
  // 전체 / 진행중 / 오픈 예정 / 종료
  const [statusSelect, setStatusSelect] = useState(true);
  
  const sortClick = (isClick: boolean) => {
    setSortSelect(current => !current);
    if (!statusSelect) statusClick(false);
  };
  const sortChangeClick = option => {
    setSortOption(option);
    sortClick(false);
    searchParams.set('sort[]', option.value);
    setSearchParams(searchParams);        
  };
  useEffect(() => {
    if (searchParams?.get('status') === 'all') {      
      setStatusOption({ name: '전체', value: 'all' });
    }
  }, [searchParams]);


  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
    if (!sortSelect) sortClick(false);
  };

  const statusChangeClick = option => {
    setStatusOption(option);
    setStatusSelect(current => !current);
    searchParams.set('status', option.value);
    setSearchParams(searchParams);    
  };

  const popupListFilter = (s: any)  => {
    /* Bound 안에 있는 팝업만 표시하도록 한다. */
    let retFilter = true;
    if( filters != null) {
      const gpsBoundFilter = filters?.gpsBoundFilter;
      if( gpsBoundFilter  && s.longitude  && s.latitude && gpsBoundFilter._min._lng !== gpsBoundFilter._max._lng
      ) {                
        const lat = getFixedLat(s);
        const lng = getFixedLng(s)        
        retFilter = gpsBoundFilter._min._lng <= lng && lng <= gpsBoundFilter._max._lng &&
        gpsBoundFilter._min._lat <= lat && lat <= gpsBoundFilter._max._lat;
        // console.log('popupListFilter:', retFilter, gpsBoundFilter);
        // console.log('gpsBoundFilter 1:', gpsBoundFilter._min._lng , s.latitude ,  gpsBoundFilter._max._lng, gpsBoundFilter._min._lng <= s.latitude && s.latitude <= gpsBoundFilter._max._lng);
        // console.log('gpsBoundFilter 2:', gpsBoundFilter._min._lat , s.longitude ,  gpsBoundFilter._max._lat, gpsBoundFilter._min._lat <= s.longitude && s.longitude <= gpsBoundFilter._max._lat);                
      }   
    }
    return retFilter;
  }

  return (
    <article>
      <div className="popuplist-filter">
        <p onClick={() => { statusClick(true); }}>
          {statusOption.name} <img src={sort_icon} />
        </p>
      </div>

      <div className={statusSelect === true ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
        <ul>
          {statusOptions.map((option, index) => (
            <li
              className={option.name === statusOption.name ? 'select' : ''}
              key={index}
              onClick={() => {
                statusChangeClick(option);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
      {isLoading && '로딩중...'}
      {!isLoading && stores && (
        <>
          <div className="calendar-popup-list popuplist-board">
            <ul>
              {stores != null && !!stores.length &&
                stores.filter( (s) => popupListFilter(s) ).map((store: any, index) => (
                  PopupListCard({ key: index, store })
                ))
              }
            </ul>
          </div>
        </>
      )}
    </article>
  );

  /**
   * Renders a card for a popup in the popup list.
   *
   * @param index - The index of the popup card.
   * @param store - The store object containing popup details.
   * @returns The JSX element representing the popup card.
   */
  function PopupListCard({ key, store }: { key: any; store: any; }) {
    return <li key={key}>
      <div>
        <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
          <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
        </Link>
        <Link to={`/popup/${store.storeId}`}>
          <ul>
            <li className="popup-name">
              <p>{store.title}</p>
            </li>
            <li className="popup-location">
              <img src={slidemapicon} /> {store.topLevelAddress}
            </li>
          </ul>
        </Link>
        <Bookmark store={store} myInfo={myInfo} />
        <p className="pre-wrap">
          {store.preRegister && store.preRegisterLink !== null && (
            <>
              {/* 외부 사전예약 링크 있는 경우 */}
              <span className="pre">사전예약</span>
            </>
          )}
          {store.reservation.length > 0 && (
            <>
              {/* 우리가 진행하는 사전예약 */}
              <span className="pre own">사전예약</span>
            </>
          )}
        </p>
        <p className="popup-date">
          {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
        </p>
      </div>
    </li>;
  }
}

export default PopupList;
