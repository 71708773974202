import styled from '@emotion/styled';
import GuestAccess from '../../components/auth/GuestAccess';
import Footer from '../../components/common/Footer';
import MainLayout from '../../layout/MainLayout';

const MemberLoginMain = styled.div`
display: grid;
place-items: center;
`;
export default function MemberLoginPage() {
  return (  
      <MainLayout>
        <MemberLoginMain>
          <GuestAccess />
        </MemberLoginMain>
        <Footer />
      </MainLayout>    
  );
}
