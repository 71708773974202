import ReactHtmlParser from 'html-react-parser';
import * as React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import arrow from './../../assets/img/common/arrow_left_small.svg';
import link from './../../assets/img/common/open_new.svg';
import './../reservation/reservation.scss';
import { ReactComponent as QR } from './../reservation/qr.svg';

function PreReservationStoreDetail({ userReservation, isLoading, user, onSignIn, updateReservationStatus }: any) {
  //인기순~
  const today = dayjs();
  const [filterSelect, setfilterSelect] = React.useState(true);
  const filterClick = (isClick: boolean) => {
    setfilterSelect(current => !current);
  };

  //진행중~
  const [statusSelect, setStatusSelect] = React.useState(true);
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };

  // 예약취소팝업
  const [cancelPoup, setCancelPoup] = React.useState(true);
  const cancelClick = (isClick: boolean) => {
    setCancelPoup(current => !current);
  };

  return (
    <>
      <article className="inner" style={{ paddingBottom: '100px' }}>
        <div className="submenu back">
          <a>
            <Link to="/member/reservation/pre" className="arrow">
              <img src={arrow} />
            </Link>
            현장대기 상세 정보
          </a>
        </div>
        {isLoading && '로딩중...'}
        <div className="pre-reservation-deatil-wrap">
          <div className="field-wrap">
            <p className="small-text">예약 상태</p>
            <>{userReservation.status === 'CANCEL' && '예약 취소'}</>
            <>{userReservation.status === 'WAITING' && '입장 전'}</>
            <>{userReservation.status === 'ENTER' && '입장'}</>
            <>{userReservation.status === 'ENTER-DONE' && '입장 완료'}</>
            <>{userReservation.status === 'ENTER-EXPIRED' && '입장 만료'}</>
          </div>
          <div className="field-wrap">
            <p className="small-text">예약 팝업스토어명</p>
            {/* 해당 팝업스토어 상세페이지로 이동 */}
            <Link to={`/popup/${userReservation.reservation.store.storeId}`} className="popupstore-name">
              {userReservation.reservation.store.title} <img src={link} alt="" />
            </Link>
          </div>
          {/* <div className="field-wrap">
            <p className="small-text">예약일자</p>
            <p>{userReservation.reservationScheduleMap.reservationSchedule.reservationDate}</p>
          </div>
          <div className="field-wrap">
            <p className="small-text">예약시간</p>
            <p>{userReservation.reservationScheduleMap.reservationSchedule.reservationTime}</p>
          </div> */}
          <div className="field-wrap">
            <p className="small-text">방문인원</p>
            <p>
              <span>{userReservation.withGuest}</span>명
            </p>
          </div>
          <div className="field-wrap ">
            <p className="field-tit">팝업스토어 방문 안내</p>
            <div className="field-text-wrap">
              <div className="notice-wrap">{ReactHtmlParser(userReservation.reservation.notice)}</div>
            </div>
          </div>
          <div className="qr-area">
            {(userReservation.status === 'WAITING' || userReservation.status === 'ENTER') && (
              <>
                {/* {today.format('YYYY-MM-DD') === userReservation.reservationScheduleMap.reservationSchedule.reservationDate ||
                  (today.add(1, 'day').format('YYYY-MM-DD') === userReservation.reservationScheduleMap.reservationSchedule.reservationDate && ( */}
                <>
                  <img src={userReservation.qrUrl} />
                </>
                {/* ))} */}
              </>
            )}
            {(userReservation.status === 'WAITING' || userReservation.status === 'ENTER-DONE') && (
              <>
                {/* {today.format('YYYY-MM-DD') !== userReservation.reservationScheduleMap.reservationSchedule.reservationDate && ( */}
                <>
                  <div className="svg">
                    <p>QR 코드는 입장 하루 전에 노출됩니다.</p>
                  </div>
                </>
                {/* )} */}
              </>
            )}
          </div>

          {userReservation.status === 'WAITING' && (
            <button type="button" className="gray" onClick={() => cancelClick(true)}>
              예약 취소하기
            </button>
          )}
        </div>
      </article>

      {/* 예약취소 팝업 */}
      <div className={cancelPoup ? 'layer-popup-white cancel-popup' : 'layer-popup-white cancel-popup show'}>
        <div className="txt-wrap">
          <p>지금 예약을 취소할 경우, 재예약이 불가할 수 있습니다.</p>
          <p>정말 취소하시겠습니까?</p>
        </div>
        <div className="btn-wrap">
          <button type="button" onClick={() => updateReservationStatus()}>
            예약 취소하기
          </button>
          <button type="button" onClick={() => cancelClick(false)}>
            닫기
          </button>
        </div>
      </div>
    </>
  );
}

export default PreReservationStoreDetail;
