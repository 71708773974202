import { useCallback, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Slider from 'react-slick';
import { Buffer } from 'buffer';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import './placelist_style.scss';
import share from './../../assets/img/placelist/share.png';
import route from './../../assets/img/placelist/route.png';
import open from './../../assets/img/placelist/open_link.png';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import arrow from './../../assets/img/placelist/arrow.svg';
import axios from 'axios';
import { fetchMainBannerList } from '../../lib/api';
import placelist from '../../modules/placelist';
import { getFixedLat, getFixedLng } from '../../util/InusUtils';
import PopupRead from '../popup/PopupRead';
import StoreDetailComment from '../popup/StoreDetailComment';

function AdminPlaceList({ adminPlaceList, myInfo, isLoading }: any) {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    swipe: false,
    arrows: false,
    // fade: true,
    touchThreshold: 100,
  };

  const openNaver = async storeList => {
    if (!isMobile) {
      let pcDefaultMapUrl = 'https://map.naver.com/p/directions/';
      const coordinatesPromises = storeList.map(async store => {
        const { latitude, longitude, title } = store;
        const { x, y } = await convertCoordinates(getFixedLng(store), getFixedLat(store));
        return `${x},${y},${title.replace(/:| : /g, ' ')}`;
      });
      const coordinates = await Promise.all(coordinatesPromises);

      const start = coordinates[0];
      const end = coordinates[coordinates.length - 1];
      const viaPoints = coordinates.slice(1, -1).join(',:');

      const mapUrl = `${pcDefaultMapUrl}${start},,/${end},,/${viaPoints}/walk?c=12.00,0,0,0,dh`;

      window.open(mapUrl);
      return;
    } else {
      const appName = 'https://popply.co.kr';
      const defaultNMapUrl = `nmap://route/walk?`;
      let naverMapUrl = defaultNMapUrl;
      storeList.forEach((store, index) => {
        store.title = store.title.replace(/:| : /g, ' ');
        if (index === 0) {
          naverMapUrl += `slat=${getFixedLat(store)}&slng=${getFixedLng(store)}&sname=${encodeURIComponent(store.title)}&`;
        } else if (index === storeList.length - 1) {
          naverMapUrl += `dlat=${getFixedLat(store)}&dlng=${getFixedLng(store)}&dname=${encodeURIComponent(store.title)}&`;
        } else {
          naverMapUrl += `v${index}lat=${getFixedLat(store)}&v${index}lng=${getFixedLng(store)}&v${index}name=${encodeURIComponent(store.title)}&`;
        }
      });
      if (isAndroid) {
        // const intent = encodeURIComponent(`#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`);
        const intent = `#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`;
        naverMapUrl = naverMapUrl.replace('nmap', 'intent');
        window.open(`${naverMapUrl}appname=${appName}${intent}`);
        // window.open(`intent://place?lat=37.4979502&lng=127.0276368&name=%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EC%84%B1%EB%82%A8%EC%8B%9C%20%EB%B6%84%EB%8B%B9%EA%B5%AC%20%EC%A0%95%EC%9E%90%EB%8F%99&appname=com.example.myapp#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`);
      }
      if (isIOS) {
        var clickedAt = +new Date();
        window.location.href = `${naverMapUrl}appname=${appName}`;
        setTimeout(function () {
          if (+new Date() - clickedAt < 2000) {
            window.location.href = 'http://itunes.apple.com/app/id311867728?mt=8';
          }
        }, 1500);
      }
    }
  };

  const [sortedAdminPlaceList, setSortedAdminPlaceList] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (adminPlaceList && adminPlaceList.length > 0) {
      const sortedAdminPlaceList = sortAdminPlaceListByWeek(adminPlaceList);
      setSortedAdminPlaceList(sortedAdminPlaceList);
      setCurrentIndex(0);
    }
  }, [adminPlaceList]);

  const sortAdminPlaceListByWeek = data => {
    // const today = new Date();
    // const todayWeek = 4;

    const sortedData = data.sort((a, b) => a.week - b.week && b.adminPlaceListId - a.adminPlaceListId);
    return sortedData;
    // const todayIndex = sortedData.findIndex(item => item.week === todayWeek && item.month === 6);
    // setCurrentWeekIndex(todayIndex);
    // const sortedAdminPlaceList = [...sortedData.slice(todayIndex), ...sortedData.slice(0, todayIndex)];
    // return sortedAdminPlaceList;
  };
  const handleNext = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(sortedAdminPlaceList.length - 1);
    }
    setAnimationTrigger(!animationTrigger);    
  };

  const handlePrevious = () => {
    if (currentIndex < sortedAdminPlaceList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }

    setAnimationTrigger(!animationTrigger);    
  };

  // const getWeekOfMonth = date => {
  //   const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  //   const diff = date.getDate() + firstDayOfMonth.getDay() - 1;
  //   return Math.ceil(diff / 7);
  // };

  // let maxAdminPlaceListId = Math.max(...data.map(item => item.adminPlaceListId));

  //     // adminPlaceListId가 가장 큰 요소를 맨 앞으로 이동하고, 나머지 요소는 오름차순으로 정렬
  //     data.sort((a, b) => {
  //       if (a.adminPlaceListId === maxAdminPlaceListId) return -1; // adminPlaceListId가 가장 큰 요소를 맨 앞으로
  //       if (b.adminPlaceListId === maxAdminPlaceListId) return 1; // adminPlaceListId가 가장 큰 요소를 맨 앞으로
  //       return a.adminPlaceListId - b.adminPlaceListId; // 그 외의 경우 오름차순 정렬
  //     });
  //     return data;

  // const goToPreviousWeek = () => {
  //   const previousWeekIndex = (currentWeekIndex - 1 + adminPlaceList.length) % adminPlaceList.length;
  //   setCurrentWeekIndex(previousWeekIndex);
  //   setSortedAdminPlaceList(prevList => [prevList[prevList.length - 1], ...prevList.slice(0, prevList.length - 1)]);
  //   setAnimationTrigger(currentWeekIndex);
  //   setMainSrc(sortedAdminPlaceList.indexOf[currentWeekIndex]);
  // };

  // const goToNextWeek = () => {
  //   const nextWeekIndex = (currentWeekIndex + 1) % adminPlaceList.length;
  //   setCurrentWeekIndex(nextWeekIndex);
  //   setSortedAdminPlaceList(prevList => {
  //     const nextWeekDataIndex = (prevList.length + 1) % adminPlaceList.length;
  //     const updatedList = [...prevList.slice(nextWeekDataIndex), ...prevList.slice(0, nextWeekDataIndex)];
  //     return updatedList;
  //   });
  //   setAnimationTrigger(currentWeekIndex);
  //   setMainSrc(sortedAdminPlaceList.indexOf[currentWeekIndex]);
  // };

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };

  const [mainSrc, setMainSrc] = useState();
  const mainThumbRef = useRef(null);
  const [popupTitle, setPopupTitle] = useState('');
  const [popupContent, setPopupContent] = useState('');
  const [popupLink, setPopupLink] = useState('');
  const changeImg = (s, idx) => {
    try {
      let currentSrc = s.thumbnails;

      if (mainThumbRef.current) {
        mainThumbRef.current.src = currentSrc;
        setMainSrc(currentSrc);
      } else {
        console.error('mainThumbRef.current is undefined');
      }

      let currentTitle = s.title;
      let currentContent = s.storeDetail.contents;
      let currentLink = s.storeId;
      setPopupTitle(currentTitle);
      setPopupContent(currentContent);
      setPopupLink(currentLink);
    } catch (e) {
      console.error(e);
    }
  };

  const [openFold, setOpenFold] = useState(false);
  const infoClick = (isClick: boolean) => {
    setOpenFold(current => !current);
  };

  useEffect(() => {
    const animateSvgPath = () => {
      const pathElement = document.querySelector('.progress-fill svg > path') as SVGPathElement;

      if (pathElement) {
        // 클래스 제거
        pathElement.classList.remove('progress_path');

        const pathLength = pathElement.getTotalLength();
        pathElement.style.strokeDasharray = `${pathLength} ${pathLength}`;
        pathElement.style.strokeDashoffset = `${pathLength}`;

        requestAnimationFrame(() => {
          pathElement.classList.add('progress_path');
        });
      } else {
        setTimeout(animateSvgPath, 100);
      }
    };

    // 최초 애니메이션 시작
    animateSvgPath();
  }, [mainSrc, currentWeekIndex, animationTrigger]);

  return (
    <article className="placelist-wrap">
      {!isLoading && sortedAdminPlaceList && (
        <Slider {...settings} ref={sliderRef}>
          {
            sortedAdminPlaceList != null && !!sortedAdminPlaceList.length && (
              // sortedAdminPlaceList.map((placeList: any, idx) => (
              <div className="placelist-inner" key={currentIndex}>
                <header>
                  <div>
                    <button type="button" className="prev-arrow" onClick={handlePrevious}></button>
                    <p>
                      {sortedAdminPlaceList[currentIndex].month}월 {sortedAdminPlaceList[currentIndex].week}주차
                    </p>
                    <button type="button" className="next-arrow" onClick={handleNext}></button>
                  </div>
                  <h1>{sortedAdminPlaceList[currentIndex].title}</h1>
                </header>
                <div className="placelist-big-thumb-wrap">
                  <div className="placelist-big-thumb">
                    <img ref={mainThumbRef} src={mainSrc ? mainSrc : sortedAdminPlaceList[currentIndex].thumbnail} alt={sortedAdminPlaceList[currentIndex].title} />
                  </div>

                  <div className="placelist-progress-container">
                    <div className="progress-blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="422" height="210" viewBox="0 0 422 210" fill="none">
                        <path d="M5 5C7.62004 69.6268 52.3348 200.103 210.234 204.994C273.987 205.576 404.638 166.392 417.214 5" stroke="#2C2C2C" strokeWidth="9.29401" strokeLinecap="round" />
                      </svg>
                    </div>
                    <div className="progress-fill">
                      <svg xmlns="http://www.w3.org/2000/svg" width="423" height="210" viewBox="0 0 423 210" fill="none">
                        <path className="fill-path" d="M5.198 5.27466C7.81804 69.9014 52.5328 200.378 210.432 205.268C274.185 205.851 404.836 166.667 417.412 5.27466" stroke="#F0002E" strokeWidth="9.29401" strokeLinecap="round" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="placelist-center">
                  <div className={openFold ? 'placelist-info-wrap open' : 'placelist-info-wrap'}>
                    <button
                      type="button"
                      onClick={() => {
                        infoClick(true);
                      }}
                    >
                      <img src={arrow} alt="" />
                    </button>
                    <div className="placelist-info-box">
                      <p className="placelist-info-title">{popupTitle ? popupTitle : sortedAdminPlaceList[currentIndex].representStore.name}</p>
                      <p className="placelist-info-content">{ReactHtmlParser(popupContent ? popupContent : sortedAdminPlaceList[currentIndex].representStore.contents)}</p>
                      <Link to={`/popup/${popupLink ? popupLink : sortedAdminPlaceList[currentIndex].thumbnailStoreId}`} target="_blank" className="placelist-info-link">
                        더보기
                      </Link>
                    </div>
                  </div>
                  <div className={openFold ? 'placelist-mini-thumb open' : 'placelist-mini-thumb'}>
                    <ul>
                      {sortedAdminPlaceList[currentIndex].store.map((s, idx) => {
                        return (
                          <li key={idx} onClick={() => changeImg(s, idx)}>
                            <img src={s.thumbnails} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="placelist-popuplist-wrap">
                  <div className="placelist-popuplist-btn">
                    <button type="button" className="route-btn" onClick={() => openNaver(sortedAdminPlaceList[currentIndex].store)}>
                      경로보기 <img src={route} style={{ width: '15px', height: '15px' }} />
                    </button>
                    {/* 공유하기 버튼 */}
                    <button
                      type="button"
                      className="share-btn"
                      onClick={async () => {
                        await handleCopyClipBoard(window.location.href);
                      }}
                    >
                      <img src={share} style={{ width: '14px', height: '17px' }} />
                    </button>
                  </div>
                  <ul className="placelist-popuplist-inner">
                    {sortedAdminPlaceList[currentIndex].store.map((s, idx) => {
                      return (
                        <li key={idx}>
                          <div>
                            <Link target="_blank" to={`/popup/${s.storeId}`}>
                              <img src={s.thumbnails} className="popup-thumb" />
                              <div>
                                <div className="top-wrap">
                                  <p className="popup-title">{s.title}</p>
                                  <img src={open} alt="" />
                                </div>
                                <p className="popup-location">
                                  <img src={slidemapicon} /> {s.topLevelAddress}
                                </p>
                                <div className="bottom-wrap">
                                  <p className="popup-date">
                                    {dayjs(s.startDate).format('YY.MM.DD')} - {dayjs(s.endDate).format('YY.MM.DD')}
                                  </p>
                                  <p className={s.preRegister === true ? 'pre' : ''}>{s.preRegister === true ? '사전예약' : ''}</p>
                                </div>
                              </div>
                            </Link>

                            {/* <PopupRead store={s} isLoading={false} myInfo={myInfo} />
                            <StoreDetailComment store={s} myInfo={myInfo}  /> */}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )
            // ))
          }
        </Slider>
      )}
    </article>
  );
}

export default AdminPlaceList;

const convertCoordinates = async (lon, lat) => {
  var x = (lon * 20037508.34) / 180;
  var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * 20037508.34) / 180;
  return { x, y };
};

type FileType = 'pdf' | 'xlsx';

export const bufferToFileDownload = (filename: string, buffer: Buffer, type: FileType = 'pdf') => {
  const blobOptions: BlobPropertyBag = { type: 'application/pdf' };
  if (type === 'pdf') blobOptions.type = 'application/pdf';
  if (type === 'xlsx') blobOptions.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const url = URL.createObjectURL(new Blob([buffer], blobOptions));

  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
};
