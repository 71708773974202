import MemberRegisterContainer from '../../containers/member/MemberRegisterContainer';
import MainLayout from '../../layout/MainLayout';

export default function MemberRegisterPage() {
  return (
    <>
      <MainLayout>
        <MemberRegisterContainer />
      </MainLayout>
    </>
  );
}
