import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ReactHtmlParser from 'html-react-parser';
//이미지
import slideimg1 from './../../assets/img/slideImg1.png';
import arrow_left from './../../assets/img/common/arrow_left_small.svg';
import arrow_right from './../../assets/img/common/arrow_right_gray.svg';
import arrow_top from './../../assets/img/common/arrow_top_gray.svg';
import bookmark from './../../assets/img/popup/bookmark.svg';
import view_count from './../../assets/img/popup/view-count.svg';
import parkicon from './../../assets/img/popup/park-icon.png';
import noparkicon from './../../assets/img/popup/no-park-icon.png';
import feeicon from './../../assets/img/popup/fee-icon.png';
import freeicon from './../../assets/img/popup/free-icon.png';
import peticon from './../../assets/img/popup/pet-icon.png';
import kidsokicon from './../../assets/img/popup/kids-ok-icon.png';
import kidsnoicon from './../../assets/img/popup/kids-no-icon.png';
import adulticon from './../../assets/img/popup/adult-icon.png';
import wifiicon from './../../assets/img/popup/wifi-icon.png';
import nodrinkicon from './../../assets/img/popup/no-drink-icon.png';
import photoicon from './../../assets/img/popup/photo-icon.png';
import shareicon from './../../assets/img/popup/share-icon.svg';
import mapicon from './../../assets/img/map/map-gray.svg';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';

function PopupUploadPreview({ storeInfo }: any) {
  const [hashArr, setHashArr] = useState<string[] | []>([]);

  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const settings = {
    dots: true,
    dotsClass: 'popupdetail-banner-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //포커스
  const inputFocusRef = useRef([]);
  const [inputFocus, setInputFocus] = useState();

  return (
    <>
      <div className="admin-popup-preview">
        <div className="admin-popup-preview-wrap">
          <div className="popupdetail-banner">
            <Slider {...settings}>
              {/* {store.storeImage.map((image: any, index) => ( */}
              <div className="slide-content">
                <div className="slide-img-wrap">
                  {/* <img src={image.url} alt="" /> */}
                  <img src={slideimg1} alt="" />
                </div>
              </div>
              {/* ))} */}
              {/* {imageSrc.length > 0 &&
                  imageSrc.map(img => {
                    return (
                      <div className="slide-content">
                        <div className="slide-img-wrap">
                          <img width={'100%'} src={img} />
                           <img src={slideimg1} alt="" />
                        </div>
                      </div>
                    );
                  })}
                {imageSrc.length < 1 && (
                  <>
                    <div className="slide-content">
                      <div className="slide-img-wrap">
                        <img src={slideimg1} alt="" />
                      </div>
                    </div>
                  </>
                )} */}
            </Slider>
          </div>
          <div className="popupdetail">
            <div className="popupdetail-wrap">
              <ul className="popupdetail-top">
                <li>
                  <Link to="#">
                    <img src={arrow_left} alt="" style={{ width: '8px' }} />
                  </Link>
                </li>
                <li>
                  <ul>
                    <li>
                      <span>252</span>
                      <img src={bookmark} alt="" />
                    </li>
                    <li>
                      <button type="button">
                        <img src={shareicon} alt="" />
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="submenu">
                <Link to="#" className="on">
                  <h2>정보</h2>
                </Link>
                <Link to="#">
                  <h2>후기</h2>
                </Link>
              </div>
              <div className="popupdetail-inner">
                <div className="popupdetail-title">
                  <ul className="popupdetail-title-top">
                    <li>
                      <p>{storeInfo.mainBrand ? storeInfo.mainBrand : '메인브랜드(필수)'}</p>
                    </li>
                    <li>
                      <span>3275</span>
                      <img src={view_count} alt="" />
                    </li>
                  </ul>
                  <div className="popupdetail-title-info">
                    <h1 ref={el => (inputFocusRef.current[0] = el)} className="tit">
                      {storeInfo.title ? storeInfo.title : '팝업스토어 이름을 적어주세요.'}
                    </h1>
                    <p className="date">
                      {dayjs(storeInfo.startDate).format('YYYY.MM.DD')} - {dayjs(storeInfo.endDate).format('YYYY.MM.DD')}
                    </p>
                    <p className="location">
                      <img src={mapicon} /> {storeInfo.address} {storeInfo.detailAddress}
                    </p>
                    <div className="search-box-inner">
                      <ul>
                        {hashArr.length > 0 &&
                          hashArr.map((atag, idx) => {
                            return <li key={idx}>{atag}</li>;
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="popupdetail-icon-area">
                  <ul>
                    {storeInfo.storeDetail.parking === 1 && (
                      <li>
                        <img src={parkicon} alt="주차가능" />
                        <p>주차가능</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.parking === 0 && (
                      <li>
                        <img src={noparkicon} alt="주차불가" />
                        <p>주차불가</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.free === 0 && (
                      <li>
                        <img src={feeicon} alt="입장료 유료" />
                        <p>입장료 유료</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.free === 1 && (
                      <li>
                        <img src={freeicon} alt="입장료 무료" />
                        <p>입장료 무료</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.pet === 1 && (
                      <li>
                        <img src={peticon} alt="반려동물 가능" />
                        <p>반려동물</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.kids === 1 && (
                      <li>
                        <img src={kidsokicon} alt="웰컴키즈존" />
                        <p>웰컴 키즈존</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.kids === 0 && (
                      <li>
                        <img src={kidsnoicon} alt="노키즈존" />
                        <p>노키즈존</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.food === 0 && (
                      <li>
                        <img src={nodrinkicon} alt="식음료 반입 금지" />
                        <p>식음료 반입 금지</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.adult === 1 && (
                      <li>
                        <img src={adulticon} alt="19세 이상" />
                        <p>19세 이상</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.wifi === 1 && (
                      <li>
                        <img src={wifiicon} alt="와이파이 가능" />
                        <p>와이파이 가능</p>
                      </li>
                    )}
                    {storeInfo.storeDetail.picture === 1 && (
                      <li>
                        <img src={photoicon} alt="사진촬영 가능" />
                        <p>사진촬영 가능</p>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="popupdetail-time">
                  <header>
                    <h3 className="info-tit">운영 시간</h3>
                    <img src={arrow_top} alt="" className="open" />
                  </header>
                  <ul className="open">
                    {storeInfo.workingTime.map(wt => {
                      if (wt.day && wt.holiday === false) {
                        return (
                          <li>
                            {wt.day} : {wt.startDate} ~ {wt.endDate}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div className="popupdetail-info">
                  <h3 className="info-tit">팝업스토어 소개</h3>
                  <div className="popupdetail-info-inner">
                    <p>{ReactHtmlParser(storeInfo.storeDetail.contents)}</p>
                  </div>
                </div>
                <div className="popupdetail-caution">
                  <h3 className="info-tit">안내 및 주의사항</h3>
                  <div>{ReactHtmlParser(storeInfo.storeDetail.notice)}</div>
                </div>
                <div className="popupdetail-map">
                  <div>
                    {/* <Map datas={store} zoomControl={true} isStore={false} /> */}
                    {/* <img
                      src={zoom}
                      alt="지도 확대"
                      onClick={() => {
                        mapLayerOpen(true);
                      }}
                    /> */}
                  </div>
                  <ul>
                    <li>
                      <p>
                        {storeInfo.address} {storeInfo.detailAddress}
                      </p>
                    </li>
                    <li>
                      <button type="button">주소복사</button>
                    </li>
                  </ul>
                </div>
                <div className="popupdetail-link">
                  <ul>
                    <li>
                      <Link to="#" onClick={() => openInNewTab(storeInfo.storeDetail.brandUrl)}>
                        브랜드 홈페이지 바로가기
                        <img src={arrow_right} alt="" />
                      </Link>
                      <Link to="#" onClick={() => openInNewTab(storeInfo.storeDetail.instaUrl)}>
                        인스타그램 바로가기
                        <img src={arrow_right} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupUploadPreview;
