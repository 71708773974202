import React from 'react';
import { Link } from 'react-router-dom';
import EventWinnerBanner from './item/EventWinnerBanner';
import EventIntro from './item/EventIntro';
import EventLine from './item/EventLine';
import EventChat from './item/EventChat';
import EventBtn from './item/EventBtn';
import EventAlert from './item/EventAlert';
import EventMessage from './item/EventMessage';
import EventEndPage from './item/EventEndPage';
import backicon from './../../assets/img/event-template/back-btn.png';
import companyLogo from './../../assets/img/POPPLY.png';
import sampleImg from './../../assets/img/popupslide1.png';
import { maskingName, maskingPhoneNumber } from '../common/PopplyUtil';

function EventWinnerList({ promotionWinners, isLoading, promotionId, myInfo }: { promotionWinners: any; isLoading: boolean; promotionId: any; myInfo: any }) {
  console.log(promotionWinners);
  return (
    <article className="event-area winner-list">
      {isLoading && '...로딩중...'}
      {!isLoading && promotionWinners.promotionWinner && (
        <>
          {/*  이벤트 당첨자 리스트 */}
          <div className="event-winner-list">
            <header>
              {/* link 속 숫자는 변동 */}
              <Link to={`/event/${promotionId.eventId}`}>
                <img src={backicon} alt="" />
              </Link>
            </header>
            <div className="event-winner-item">
              <div className="top">
                <img src={companyLogo} alt="" className="company-logo" />
                <p>현장방문 이벤트 경품 당첨자</p>
              </div>
              <div className="event-winner-item-info">
                <p className="tit">추첨 이벤트 경품 목록</p>
                {/* 경품이 여러 개일 때만 노출 */}
                <ul className="item-list">
                  {promotionWinners.promotionGoods.map(pmg => {
                    return (
                      <li>
                        {pmg.goods} {pmg.winner}명
                      </li>
                    );
                  })}
                </ul>
                <p className="info-text">* 모든 경품은 당첨 후 5분 이내 알림톡으로 발송됩니다. 상품을 수령하지 못한 경우 고객센터로 문의 부탁드립니다.</p>
              </div>
              <div className="list">
                {promotionWinners.promotionWinner.map(pmw => {
                  return (
                    <div className="item-wrap">
                      <div className="img-wrap">
                        <img src={sampleImg} alt="" />
                      </div>
                      <div className="text-wrap">
                        <p className="goods">{pmw.promotionGoods.goods}</p>
                        <p>
                          {/* 가운데 별처리 필요 */}
                          <span>{pmw.name}</span> 님
                        </p>
                        {/* 가운데 별처리 필요 */}
                        <p>{pmw.phone}</p>
                      </div>
                      <>
                        {myInfo?.userId === pmw?.userId && (
                          <button type="button" className="send-btn">
                            알림톡 발송
                          </button>
                        )}
                      </>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </article>
  );
}
export default EventWinnerList;
