import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer, { rootSaga } from './modules';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { setAccessToken, checkMyInfo } from './modules/auth';
import client from './lib/client';
// import withNavigateLogging from './util/InusUtils';
// const AppWithLogging = withNavigateLogging(App);


//import "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
// 사가 미들웨어 생성
const sagaMiddleware = createSagaMiddleware();

// 스토어 생성
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

// 저장된 토큰을 가지고 로그인 처리 (스토어 상태에 액세스토큰 저장)를 하고 로그인한 사용자 정보를 얻는다.
function loadUser() {
  try {
    const savedToken = Cookies.get('accessToken');
    if (!savedToken) return;
    store.dispatch(setAccessToken(savedToken));
    client.defaults.headers.common.Authorization = `Bearer ${savedToken}`;
    store.dispatch(checkMyInfo());
  } catch (err) {
    console.log('error from loadUser', err);
  }
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
// 사가 미들웨어 실행
sagaMiddleware.run(rootSaga);

// 저장된 토큰으로 로그인을 처리하는 함수를 실행한다. (사가 실행 이후)
loadUser();
dayjs.locale('ko');

ReactDOM.render(
  // <React.StrictMode>

  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />        
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,

  // </React.StrictMode>,
  document.getElementById('root'),
);
