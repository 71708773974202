import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import awards from './../../../assets/img/banner1.png';
import './alert.scss';
import ReactHtmlParser from 'html-react-parser';

function AlertPopup({ type, msg, btnText, btnText2, successfunction, failFunction }: any) {
  //레이어 닫기 - 임시로 설정해둠
  const [openLayer, setOpenLayer] = useState(true);
  const layerClick = (isClick: boolean) => {
    setOpenLayer(current => !current);
  };

  return (
    <>
      <div className={openLayer ? 'alert-popup' : 'alert-popup hide'}>
        {type === 'alert' && (
          <>
            <div className="main-text">
              <p>{ReactHtmlParser(msg)}</p>
            </div>
            <div className="btn-wrap">
              <button
                type="button"
                className="alert-btn point"
                onClick={() => {
                  layerClick(false);
                  if(failFunction) {
                    failFunction(false);
                  }
                }}
              >
                {btnText}
              </button>
            </div>
          </>
        )}
        {type === 'confirm' && (
          <>
            <div className="main-text">
              <p>{msg}</p>
            </div>
            <div className="btn-wrap">
              <button
                type="button"
                className="alert-btn point"
                onClick={() => {
                  layerClick(false);
                  failFunction(false);
                }}
              >
                {btnText}
              </button>
              <button
                type="button"
                className="alert-btn gray"
                onClick={() => {
                  layerClick(false);
                  successfunction(true);
                }}
              >
                {btnText2}
              </button>
            </div>
          </>
        )}
      </div>
      <div className={openLayer ? 'alert-bg' : 'alert-bg hide'}></div>
    </>
  );
}

export default AlertPopup;
