import QnaReadContainer from '../../../containers/contact/qna/QnaReadContainer';
import MainLayout from '../../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function QnaReadPage({ match }: any) {
  const { itemId } = useParams();

  return (
    <>
      <MainLayout>
        <QnaReadContainer itemId={itemId} />
      </MainLayout>
    </>
  );
}

export default QnaReadPage;
