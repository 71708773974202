import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne, FETCH_ONE } from '../../modules/promotion';

import * as api from '../../lib/api';
import EventRead from '../../components/codegroup/EventRead';

const EventReadContainer = ({ promotionId }) => {
  const dispatch = useDispatch();
  const { promotion, isLoading, myInfo } = useSelector(({ promotion, loading, auth }: any) => ({
    promotion: promotion.promotion,
    isLoading: loading[FETCH_ONE],
    myInfo: auth.myInfo,
  }));

  useEffect(() => {
    console.log(promotionId);
    dispatch(fetchOne(promotionId.eventId));
  }, [dispatch, promotionId]);

  return <EventRead promotion={promotion} isLoading={isLoading} myInfo={myInfo} />;
};

export default EventReadContainer;
