import ReactHtmlParser from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';
import Map from '../common/Map';
import nonethumb from './../../assets/img/noneIMG.png';
// import slideimg1 from './../../assets/img/slideImg1.png';
import mapicon from './../../assets/img/map/map-gray.svg';
import shareicon from './../../assets/img/popup/share-icon.svg';
import arrow_left from './../../assets/img/common/arrow_left_small.svg';
import arrow_right from './../../assets/img/common/arrow_right_gray.svg';
import view_count from './../../assets/img/popup/view-count.svg';
import parkicon from './../../assets/img/popup/park-icon.png';
import noparkicon from './../../assets/img/popup/no-park-icon.png';
import feeicon from './../../assets/img/popup/fee-icon.png';
import freeicon from './../../assets/img/popup/free-icon.png';
import peticon from './../../assets/img/popup/pet-icon.png';
import kidsokicon from './../../assets/img/popup/kids-ok-icon.png';
import kidsnoicon from './../../assets/img/popup/kids-no-icon.png';
import adulticon from './../../assets/img/popup/adult-icon.png';
import wifiicon from './../../assets/img/popup/wifi-icon.png';
import nodrinkicon from './../../assets/img/popup/no-drink-icon.png';
import photoicon from './../../assets/img/popup/photo-icon.png';
import btn_close from './../../assets/img/common/btn_close.svg';
import arrow_top from './../../assets/img/common/arrow_top_gray.svg';
import zoom from './../../assets/img/map/map-zoom.svg';
import dayjs from 'dayjs';
import { useState } from 'react';

import Slider from 'react-slick';
import Bookmark from '../common/Bookmark';
import WorkingHours, { simplifyWorkingHours } from '../common/WorkingHours';

function PopupRead({ store, isLoading, myInfo}: any) {
  const navigate = useNavigate();
  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };
  let hashTags;

  let reserveOpenMinute = null;
  let reserveOpenHours = null;
  let reserveOpenDays = null;
  let reserveEndMinute = null;
  let reserveEndHours = null;
  let reserveEndDays = null;

  if (store) {
    const today = dayjs();    
    hashTags = store.hashtag.split(',');

    if (store.reservation) {
      for (let i = 0; i < store.reservation.length; i++) {
        if (store.reservation[i].type === 'PRE') {
          store.reservation = store.reservation[i];
        }
      }
      const reservationOpenDate = store.reservation.availableStartDate;
      const reservationEndDate = store.reservation.availableEndDate;
      reserveOpenMinute = today.diff(reservationOpenDate, 'minute');
      reserveOpenHours = today.diff(reservationOpenDate, 'hours');
      reserveOpenDays = today.diff(reservationOpenDate, 'days');
      reserveEndMinute = today.diff(reservationEndDate, 'minute');
      reserveEndHours = today.diff(reservationEndDate, 'hours');
      reserveEndDays = today.diff(reservationEndDate, 'days');
    }

    if (store.workingTime && typeof store.workingTime !== 'object') {
      const workingTimeJson = JSON.parse(store.workingTime);
      store.workingTime = workingTimeJson;
    }
  }

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      console.log(e);
      alert('복사에 실패하였습니다');
    }
  };
  const handleHistoryBack = async (replaceUrl: string) => {
    if( window.history.state && window.history.state.idx > 0 ) {
      navigate(-1);
    } else {
      navigate(replaceUrl);
    }
  }

  //운영시간 접었다 폈다
  const [openFold, setOpenFold] = useState(true);
  const foldClick = (isClick: boolean) => {
    setOpenFold(current => !current);
    console.log(store);
  };

  //맵레이어팝업 오픈
  const [maplayer, setMaplayer] = useState(true);
  const mapLayerOpen = (isClick: boolean) => {
    setMaplayer(current => !current);
  };

  // slider custom arrow
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };

  const settings = {
    dots: true,
    dotsClass: 'popupdetail-banner-dots',
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };  
  return (
    <article className='main-article'>
      {!isLoading && store && store.storeDetail && (
        <>
          <div className="popupdetail-banner">
            <Slider {...settings}>
              {!store.storeImage.length && (
                <div className="slide-content">
                  <div className="slide-img-wrap">
                    <img src={nonethumb} alt="" />
                  </div>
                </div>
              )}
              {!!store.storeImage.length &&
                store.storeImage.map((image: any, index) => (
                  <div className="slide-content" key={index}>
                    <div className="slide-img-wrap">
                      <img src={image.url} alt="" />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          {store.reservation && (
            <>
              {reserveOpenDays <= 0 && reserveOpenHours <= 0 && reserveOpenMinute < 0 && (
                <>
                  <button type="button" className="reservation-full pre">
                    {`${dayjs(store.reservation.availableStartDate).format('YYYY-MM-DD HH:MM')} 사전예약 오픈 예정`}
                  </button>
                </>
              )}
              {/* 사전예약 정보가 있으며 사전예약 오픈일자 or 사전예약 가능일자가 오늘과 같거나 빠른 경우*/}
              {reserveOpenDays >= 0 && reserveOpenHours >= 0 && reserveOpenMinute >= 0 && reserveEndDays <= 0 && reserveEndHours <= 0 && reserveEndMinute < 0 && (
                <>
                  <Link to={`/popup/${store.storeId}/reservation/PRE`}>
                    <button type="button" className="reservation-full">
                      사전예약 신청
                    </button>
                  </Link>
                </>
              )}

              {reserveEndDays >= 0 && reserveEndHours >= 0 && reserveEndMinute > 0 && (
                <>
                  <button type="button" className="reservation-full end">
                    사전예약 종료
                  </button>
                </>
              )}
            </>
          )}

          <div className="popupdetail">
            <div className="popupdetail-wrap">
              <ul className="popupdetail-top">
                <li>                  
                  <img src={arrow_left} onClick={() => handleHistoryBack("/popup")} alt="" style={{ width: '8px' }} />                  
                </li>
                <li>
                  <ul>
                    <li>
                      <span>{store.totalUserLike}</span>                      
                      <Bookmark store={store} myInfo={myInfo}/>                      
                    </li>
                    <li>
                      <button type="button" onClick={async () => {await handleCopyClipBoard(window.location.href);}}>
                        <img src={shareicon} alt="" />
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="submenu">
                <Link to={`/popup/${store.storeId}`} className="on">
                  <h2>정보</h2>
                </Link>
                <Link to={`/${store.storeId}/comment/list`}>
                  <h2>후기</h2>
                </Link>
                {/* <Link to="/user/visit-store">
                  <h2>주변추천</h2>
                </Link> */}
              </div>
              <div className="popupdetail-inner">
                <div className="popupdetail-title">
                  <ul className="popupdetail-title-top">
                    <li>
                      <p
                        className="popupbrand"
                        onClick={() => {
                          window.location.href = '/popup?query=' + store.mainBrand;
                        }}
                      >
                        {store.mainBrand}
                        <img src={arrow_right} alt="" />
                      </p>
                    </li>
                    <li>
                      <span>{store.views}</span>
                      <img src={view_count} alt="" />
                    </li>
                  </ul>
                  <div className="popupdetail-title-info">
                    <h1 className="tit">{store.title}</h1>
                    <p className="date">
                      {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
                    </p>
                    <p className="location">
                      <img src={mapicon} />
                      {store.address}
                    </p>
                    <div className="search-box-inner">
                      <ul>
                        {!!hashTags.length &&
                          hashTags.map((hashTag: any, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                window.location.href = '/popup?query=' + hashTag;
                              }}
                            >
                              {hashTag}
                            </li>
                          ))}
                        {/* <li className="key-hashtag">한정판 굿즈</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="popupdetail-icon-area">
                  <ul>
                    <li className={store.storeDetail.parking === true ? '' : 'false'}>
                      <img src={parkicon} alt="주차가능" />
                      <p>주차가능</p>
                    </li>
                    <li className={store.storeDetail.parking === false ? '' : 'false'}>
                      <img src={noparkicon} alt="주차불가" />
                      <p>주차불가</p>
                    </li>
                    <li className={store.storeDetail.free === false ? '' : 'false'}>
                      <img src={feeicon} alt="입장료 유료" />
                      <p>입장료 유료</p>
                    </li>
                    <li className={store.storeDetail.free === true ? '' : 'false'}>
                      <img src={freeicon} alt="입장료 무료" />
                      <p>입장료 무료</p>
                    </li>
                    <li className={store.storeDetail.pet === true ? '' : 'false'}>
                      <img src={peticon} alt="반려동물 가능" />
                      <p>반려동물</p>
                    </li>
                    <li className={store.storeDetail.kids === true ? '' : 'false'}>
                      <img src={kidsokicon} alt="웰컴키즈존" />
                      <p>웰컴 키즈존</p>
                    </li>
                    <li className={store.storeDetail.noKids === true ? '' : 'false'}>
                      <img src={kidsnoicon} alt="노키즈존" />
                      <p>노키즈존</p>
                    </li>
                    <li className={store.storeDetail.food ? '' : 'false'}>
                      <img src={nodrinkicon} alt="식음료 반입 금지" />
                      <p>식음료 반입 금지</p>
                    </li>
                    <li className={store.storeDetail.adult === true ? '' : 'false'}>
                      <img src={adulticon} alt="19세 이상" />
                      <p>19세 이상</p>
                    </li>
                    <li className={store.storeDetail.wifi ? '' : 'false'}>
                      <img src={wifiicon} alt="와이파이 가능" />
                      <p>와이파이 가능</p>
                    </li>
                    <li className={store.storeDetail.photo ? '' : 'false'}>
                      <img src={photoicon} alt="사진촬영 가능" />
                      <p>사진촬영 가능</p>
                    </li>
                  </ul>
                </div>
                <div className="popupdetail-time">
                  <header
                    onClick={() => {
                      foldClick(false);
                    }}
                  >
                    <h3 className="info-tit">운영 시간</h3>
                    <img src={arrow_top} alt="" className={openFold ? 'open' : ''} />
                  </header>
                  <ul className={'open'}>

                    {!!store.workingTime.length &&                    
                      <WorkingHours workingTime={store.workingTime} expanded={openFold} />}
                    {/* {!!store.workingTime.length && */}
                    {/* {!!store.workingTime.length &&
                      store.workingTime.map((wt, index) => (
                        <li key={index}>
                          {wt.day && wt.holiday === false ? `${wt.day} : ${wt.startDate} ~ ${wt.endDate}` : ''} {wt.holiday === true ? `${wt.day} : 휴무` : ''}
                        </li>
                      ))} */}
                    {!!store.workingTime[0].notice && (
                      <>
                        <li className="time-notice">휴일 공지사항</li>
                        <p>{store.workingTime[0].notice}</p>
                      </>
                    )}
                  </ul>
                </div>
                <div className="popupdetail-info">
                  <h3 className="info-tit">팝업스토어 소개</h3>
                  <div className="popupdetail-info-inner">{ReactHtmlParser(store.storeDetail.contents)}</div>
                </div>
                <div className="popupdetail-caution">
                  <h3 className="info-tit">안내 및 주의사항</h3>
                  <div>{ReactHtmlParser(store.storeDetail.notice ? store.storeDetail.notice : '')}</div>
                </div>

                <div className="popupdetail-map">
                  <div>
                    <Map datas={store} zoomControl={false} isStore={true} />
                    <img
                      src={zoom}
                      alt="지도 확대"
                      onClick={() => {
                        mapLayerOpen(true);
                      }}
                    />
                  </div>
                  <ul>
                    <li>
                      <p>{store.address}</p>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={async () => {
                          await handleCopyClipBoard(store.address);
                        }}
                      >
                        주소복사
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="popupdetail-link">
                  <ul>
                    <li>
                      {store.storeDetail.brandUrl !== '' && store.storeDetail.brandUrl && (
                        <Link to="#" onClick={() => openInNewTab(store.storeDetail.brandUrl)}>
                          브랜드 홈페이지 바로가기
                          <img src={arrow_right} alt="" />
                        </Link>
                      )}
                    </li>
                    <li>
                      {store.storeDetail.instaUrl !== '' && store.storeDetail.instaUrl && (
                        <Link to="#" onClick={() => openInNewTab(store.storeDetail.instaUrl)}>
                          SNS 바로가기
                          <img src={arrow_right} alt="" />
                        </Link>
                      )}
                    </li>
                    <li>
                      {store.preRegister === true && (
                        <Link to="#" onClick={() => (store.preRegisterLink && store.preRegisterLink !== '' ? openInNewTab(store.preRegisterLink) : '')}>
                          사전예약 바로가기
                          <img src={arrow_right} alt="" />
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* 레이어팝업 _ 지도 */}
          <div className={maplayer ? 'layer-popup layer-popup-map' : 'layer-popup layer-popup-map shown'}>
            <header>
              <button type="button">
                <img src={shareicon} alt="" />
              </button>
              <button
                type="button"
                onClick={() => {
                  mapLayerOpen(false);
                }}
              >
                <img src={btn_close} alt="" />
              </button>
            </header>
            <article>
              <Map datas={store} zoomControl={true} isStore={true} />
              <ul>
                <li>
                  <p>{store.address}</p>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={async () => {
                      await handleCopyClipBoard(store.address);
                    }}
                  >
                    주소복사
                  </button>
                </li>
              </ul>
            </article>
          </div>
        </>
      )}
    </article>
  );
}

export default PopupRead;
