import PopupReportRegisterContainer from '../../../containers/contact/popupreport/PopupReportRegisterContainer';
import MainLayout from '../../../layout/MainLayout';

function PopupReportRegisterPage() {
  return (
    <>
      <MainLayout>
        <PopupReportRegisterContainer />
      </MainLayout>
    </>
  );
}

export default PopupReportRegisterPage;
