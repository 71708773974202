import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactMenus from '../../../components/contact/ContactMenus';
import QnaModifyForm from '../../../components/contact/qna/QnaModifyForm';
import * as api from '../../../lib/api';
import { addAttach, fetchAttachList, fetchOne, FETCH_ONE, removeAttach, resetAttach } from '../../../modules/qna';
import httpStatusHandler from '../../../util/httpStatusHandler';

const QnaModifyContainer = ({ itemId }) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 스토어 상태 조회
  const { qnaItem, attachments, isLoading } = useSelector(({ qna, loading }: any) => ({
    qnaItem: qna.qnaItem,
    attachments: qna.attachments,
    isLoading: loading[FETCH_ONE],
  }));

  const onModify = async (itemId: number, itemName: string, description: string) => {
    const itemObject = {
      itemId,
      itemName,
      description,
      files: attachments,
    };

    try {
      await api.modifyQna(itemId, itemObject);
      alert('수정이 완료되었습니다.');
      // history.push(`/qna/read/${itemId}`);
      navigate(`/qna/read/${itemId}`);
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  // // 공개자료파일 첨부
  // const onAddAttach = async (file: File) => {
  //   try {
  //     let formData = new FormData();
  //     formData.append('file', file);
  //     const response = await api.addAttach(formData);
  //     const attach = response.data;
  //     dispatch(addAttach(attach));
  //   } catch (err) {
  //     httpStatusHandler(err, history);
  //   }
  // };

  // // 선택한 공개자료파일 삭제
  // const onRemoveAttach = (index: number) => {
  //   dispatch(removeAttach(index));
  // };

  // // 첨부파일 목록 가져오기
  // const getAttachList = useCallback(async () => {
  //   try {
  //     const response = await api.fetchAttachList(itemId);
  //     dispatch(fetchAttachList(response.data));
  //   } catch (err: any) {
  //     throw err;
  //   }
  // }, [dispatch, itemId]);
  // // 마운트될 때 첨부파일 목록을 가져옴
  // useEffect(() => {
  //   getAttachList();
  //   return () => {
  //     dispatch(resetAttach());
  //   };
  // }, [dispatch, getAttachList]);

  useEffect(() => {
    dispatch(fetchOne(itemId));
  }, [dispatch, itemId]);

  return (
    <>
      <ContactMenus onMenu={'qna'} />
      <QnaModifyForm qnaItem={qnaItem} attachments={attachments} isLoading={isLoading} onModify={onModify} />
    </>
  );
};

export default QnaModifyContainer;
