import React, { useState } from 'react'

export default function InputDate(props) {  
  return (
    <>
    <input type="date" id={props._id} 
      min={props.min !== undefined ? props.min : "2020-01-01"} max={props.max !== undefined ? props.max : "9999-12-31"} {...props}/>
    </>    
  )
}
