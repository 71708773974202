import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne, FETCH_ONE } from '../../../modules/userReservation';
import ReservationQR from '../../../components/reservation/ReservationQR';
import { updateUserReservation } from '../../../lib/api';
import * as api from '../../../lib/api';
import { useNavigate } from 'react-router-dom';
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
// const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV
// 알림톡을 통해서 들어오는 경우
const ReservationQRContainer = ({ storeId, reservationId, hash }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReservation, isLoading, myInfo } = useSelector((state: any) => ({
    userReservation: state.userReservation.userReservation,
    isLoading: state.loading[FETCH_ONE],
    myInfo: state.auth.myInfo,
  }));

  const updateReservationStatus = async () => {
    const isConfirm = window.confirm('정말로 취소하시겠습니까?');
    if (!isConfirm) return;
    const updateStatus = {
      status: 'CANCEL',
      hash: hash,
    };
    // id기반 예약 취소
    // const response = await updateUserReservation({ userId: myInfo.userId, userReservation: updateStatus });
    // hash 기반 예약 취소
    const response = await api.updateUserReservation1({ userReservation: updateStatus });
    if (response.status === 200 && response.data.data[0] === 1) {
      alert('현장 대기가 취소되었습니다.');
      navigate(`/`);
    }
  };

  useEffect(() => {
    // hash 정보로 예약 정보 조회
    dispatch(fetchOne(storeId, reservationId, myInfo ? myInfo.userId : null, hash));
  }, [dispatch]);

  const [waitingCount, setWaitingCount] = useState(0);
  useEffect(() => {
    if (userReservation) {
      if (userReservation.userReservation) {
        setWaitingCount(userReservation.waitingCount);
      }
    }
  }, [userReservation]);

  return <>{!isLoading && userReservation && userReservation.userReservation && userReservation.reservation !== null && <ReservationQR type={userReservation.userReservation.reservation.type} userReservation={userReservation.userReservation} waitingCount={waitingCount} isLoading={isLoading} updateReservationStatus={updateReservationStatus} />}</>;
};

export default ReservationQRContainer;
