import dayjs from "dayjs";

export interface NameValuePairExt {
  name: string;
  value: string | null;
  pageSlideName?: string;
}
// 정렬 옵션 데이터 설정
export const sortOptions : NameValuePairExt[] = [
  { name: '인기 순', value: '["views","desc"]' },
  { name: '추천 순', value: '["totalUserLike","desc"]' },
  { name: '리뷰 많은 순', value: '["totalComments","desc"]' },
  { name: '별점 높은 순', value: '["totalStars","desc"]' },
];
export const sortStore = (stores, s) => {
  if(s) {
    const sortCondition = JSON.parse(s.value);
    return [...stores].sort((a, b) => sortCondition[0] === "desc" ? a[sortCondition[0]] - b[sortCondition[0]] : b[sortCondition[0]] - a[sortCondition[0]] )
  } else {
    return stores
  }
}
// 팝업스토어 상태 필터
export const statusOptions: NameValuePairExt[] = [
  { name: '전체', value: 'all' , pageSlideName: '전체 팝업'},
  { name: '진행 중', value: 'open' , pageSlideName: '진행 중인 팝업'},
  { name: '오픈 예정', value: 'scheduled' , pageSlideName: '오픈 예정인 팝업'},
  { name: '종료', value: 'done', pageSlideName: '종료된 팝업' },
];



export const saveListStatusOptions: NameValuePairExt[] = [
  { name: '전체', value: 'all' , pageSlideName: '전체 팝업'},
  { name: '진행 중', value: 'open' , pageSlideName: '진행 중인 팝업'},
  { name: '사전예약 중', value: 'reserved' , pageSlideName: '진행 중인 팝업'},
  { name: '오픈 예정', value: 'scheduled' , pageSlideName: '오픈 예정인 팝업'},
  { name: '종료', value: 'done', pageSlideName: '종료된 팝업' },
];

export const filterStore = ( stores, filterCondition) => {
  return [...stores].filter( (s) => {
    switch(filterCondition?.value) {
      case 'all':
        return true;
      case 'open':
        return dayjs(s.startDate) < dayjs() && dayjs() < dayjs(s.endDate);
      case 'reserved':
        return dayjs(s.preRegisterStartDate) < dayjs() && dayjs() < dayjs(s.preRegisterEndDate);
      case 'scheduled':
        return dayjs(s.startDate) > dayjs() ;
      case 'done':
        return dayjs() > dayjs(s.endDate);
      default:
        return true;
    }
  })
}
