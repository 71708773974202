import { useState } from 'react';
import closeIcon from './../../../assets/img/common/btn_close_black.svg';

function EventMessage({ isLogin, myInfo, onSignIn, showPromotionMsg, onRegister, changePrivacy, changePromotionComment, promotionComment }) {
  //개인정보약관 팝업
  const [openBtn, setOpenBtn] = useState(true);
  const setBtnOpen = (isClick: boolean) => {
    setOpenBtn(current => !current);
  };
  //TODO TAEHO
  // if (!myInfo && showPromotionMsg === true) {
  //   onSignIn();
  // }

  return (
    <>
      {/* 메시지 작성하는 공간 */}
      {/* {!myInfo && (
        <>
          <div className="event-message" style={{ display: 'block' }}>
            <div className="event-message-header">
              <button onClick={onSignIn()}> 로그인 버튼 클릭~ </button>
            </div>
          </div>
        </>
      )} */}
      {/* {myInfo && ( */}
      <>
        {/* TAEHO */}
        {/* <div className="event-message" style={{ display: showPromotionMsg === true && myInfo ? 'block' : 'none' }}> */}
        <div className="event-message" style={{ display: showPromotionMsg === true ? 'block' : 'none' }}>
          <div className="event-message-header">
            <p className="tit">팝플리 방명록 이벤트의 방문 메시지를 남겨보세요!</p>
          </div>
          <div className="event-message-box">
            <textarea id="event-message" rows={4} onChange={e => changePromotionComment(e)} placeholder={promotionComment === null ? '메시지를 작성해주세요. (작성은 2000자까지 가능하나 3줄까지만 출력됩니다.)' : promotionComment}></textarea>
            <button
              type="button"
              className="event-message-send-btn"
              onClick={e => {
                onRegister(e);
              }}
            ></button>
          </div>

          <div className="event-message-checkbox-wrap">
            <label htmlFor="event-message-checkbox">
              <input
                type="checkbox"
                id="event-message-checkbox"
                required
                onClick={e => {
                  changePrivacy(e);
                }}
              />
              개인정보 수집 및 이용동의 (필수)
            </label>
            <button
              type="button"
              className="popup-open-btn"
              onClick={() => {
                setBtnOpen(true);
              }}
            >
              보기
            </button>
          </div>
          <p className="warning-text">* 작성하는 메시지는 모두가 보는 장소에 게시됩니다. 개인정보 관련 피해 방지를 위해 이름, 연락처, 주소 등 개인정보가 노출되지 않도록 주의하시기 바랍니다.</p>
        </div>
      </>
      {/* )} */}

      {/* 개인정보 약관 팝업 */}
      <div className={openBtn ? 'layer-popup event-layer-popup' : 'layer-popup event-layer-popup shown'}>
        <div className="close-icon">
          <button
            type="button"
            onClick={() => {
              setBtnOpen(false);
            }}
          >
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <div className="layer-popup-header">
          <p>개인정보 수집 및 이용 동의</p>
        </div>
        <div className="layer-popup-inner">
          <p className="popup-subtext">'팝플리'는 이벤트 응모에 대한 서비스를 제공하기 위해 아래와 같은 개인 정보를 수집하고 있습니다.</p>
          <div className="popup-content">
            <p className="popup-article">
              1.<span className="bold-text">수집하는 개인정보 항목</span>: 이름, 연락처
            </p>
            <p className="popup-article">
              2.<span className="bold-text">개인정보의 수집 이용 목적</span>: 회사는 개인정보를 다음의 목적을 위해 활용합니다.
            </p>
            <p className="popup-sub-article">· 이벤트 당첨 시 경품발송 및 안내</p>
            <p className="popup-sub-article">· 이벤트 응모 및 서비스 제공(이벤트, 마케팅, 프로모션)</p>
            <p className="popup-article">
              3.<span className="bold-text">개인정보의 보유기간</span>: 보유기간 만료 후 참가자들로부터 수집한 정보는 지체없이 파기합니다.
            </p>
            <p className="popup-sub-article bold-text">· 이벤트 종료일로부터 6개월</p>
          </div>
          <p className="popup-subtext">필수적인 개인정보 수집 및 이용에 동의하지 않을 권리가 있습니다.</p>
          <p className="popup-subtext">다만, 동의하지 않을 경우 서비스 이용이 제한됩니다.</p>
        </div>
      </div>
    </>
  );
}
export default EventMessage;
