import { Link } from 'react-router-dom';
import gdmark from './../../assets/img/layout/gd-mark.png';
import kakaoicon from './../../assets/img/layout/icon-kakao.svg';
import instaIcon from './../../assets/img/layout/icon-instagram.svg';

import naverblogIcon from './../../assets/img/layout/icon-naverblog.svg';
import facebookIcon from './../../assets/img/layout/icon-facebook.svg';
import styled from '@emotion/styled';

const FlexLi = styled.li`
  flex: 1 0 200px;
  min-width: 200px;
  max-width: 250px;
`;

// import blogIcon from './../../assets/img/blog.png';

function Footer() {
  return (
    <footer>
      <ul className="footer-top">
        <li>
          <Link to="/policy/service"> 서비스 이용 약관 </Link>
        </li>
        <li>
          <Link to="/policy/privacy"> 개인정보 처리방침</Link>
        </li>
        <li>
          <Link to="/policy/marketing"> 마케팅 수신 동의</Link>
        </li>
        <li>
          <Link to="/faq">고객센터</Link>
        </li>
        <li>
          <div>
            <a href="https://biz.popply.co.kr" target="_blank" rel="noopener noreferrer">
              비즈니스
            </a>
          </div>
        </li>
      </ul>
      <div className="footer-bottom">
        <ul className="info-list">
          <FlexLi>(주)이너스커뮤니티</FlexLi>
          <FlexLi>주소 : 서울 금천구 가산디지털1로 186 7층</FlexLi>
          <FlexLi className="tel">
            <p>문의전화 : (02)519-1246</p> <p>이메일 : popply@popply.co.kr</p>
          </FlexLi>
          <FlexLi className="tel">
            <p>대표전화 : (02)519-1231</p> <p>FAX : (02)519-1292</p>
          </FlexLi>
        </ul>
        <ul className="icon-list">
          <li>
            <a target="_blank" href="https://pf.kakao.com/_cxaiexj">
              <img src={kakaoicon} alt="팝플리 카카오채널" />
              카카오톡
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/popply_official/">
              <img src={instaIcon} alt="팝플리 인스타그램" />
              인스타그램
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=61550219009802">
              <img src={facebookIcon} alt="팝플리 페이스북" />
              페이스북
            </a>
          </li>
          <li>
            <a target="_blank" href="https://blog.naver.com/popply_official">
              <img src={naverblogIcon} alt="팝플리 네이버블로그" />
              블로그
            </a>
          </li>
        </ul>
      </div>
      <span>Copyright 2023. Inuscommunity Co.,Ltd. All rights reserved</span>
      {/* <ul className="footer-bottom" style={{ display: 'flex', flexWrap: 'wrap', gap: '5%' }}>
        <FlexLi>(주)이너스커뮤니티</FlexLi>
        <FlexLi>주소 : 서울 금천구 가산디지털1로 186 7층</FlexLi>
        <FlexLi>ad@inuscomm.co.kr</FlexLi>
        <FlexLi>대표전화 : (02)519-1231</FlexLi>
        <FlexLi>FAX : (02)519-1292</FlexLi>

        <FlexLi style={{ flexBasis: '100%', maxWidth: '100%' }}>
          <span>Copyright 2023. Inuscommunity Co.,Ltd. All rights reserved</span>
        </FlexLi>
        <FlexLi className="kakao-link">
          <img src={kakaoicon} alt="팝플리 카카오채널" />
          <a target="_blank" href="https://pf.kakao.com/_cxaiexj">
            카카오톡 @popply
          </a>
        </FlexLi>
        <FlexLi className="kakao-link">
          <img src={instaIcon} alt="팝플리 인스타그램" style={{ width: '18px', height: '18px', objectFit: 'cover' }} />
          <a target="_blank" href="https://www.instagram.com/popply_official/">
            인스타그램 @popply_official
          </a>
        </FlexLi>
        <FlexLi className="kakao-link">
          <img src={facebookIcon} alt="팝플리 페이스북" style={{ width: '18px', height: '18px', objectFit: 'cover' }} />
          <a target="_blank" href="https://www.facebook.com/profile.php?id=61550219009802">
            페이스북
          </a>
        </FlexLi>
        <FlexLi className="kakao-link">
          <img src={naverblogIcon} alt="팝플리 네이버블로그" style={{ width: '18px', height: '18px', objectFit: 'cover' }} />
          <a target="_blank" href="https://blog.naver.com/popply_official">
            네이버블로그 @popply_official
          </a>
        </FlexLi>
      </ul> */}
      <div className="mark-wrap">
        <Link to={'/explore'}>
          <img src={gdmark} alt="GDWEB DESIGN AWARDS - WINNER PRIZE" />
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
