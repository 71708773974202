import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Menu } from '@material-ui/core';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './common.scss';

//img
import bottomnav1 from './../../assets/img/layout/navbar-1.svg';
import bottomnav2 from './../../assets/img/layout/navbar-2.svg';
import bottomnav3 from './../../assets/img/layout/navbar-3.svg';
import bottomnav4 from './../../assets/img/layout/navbar-4.svg';
import bottomnav5 from './../../assets/img/layout/navbar-5.svg';
import { useSelector } from 'react-redux';

const MenuLabels = [
  { label: "팝업발견", value: "/popup", icon: bottomnav1 },
  { label: "관심팝업", value: "/user/save-store", icon: bottomnav2 },
  { label: "내주변", value: "/maps", icon: bottomnav3 },
  { label: "마이플리", value: "/placelist", icon: bottomnav4 },
  { label: "마이페이지", value: "/member", icon: bottomnav5}
]
function BottomNavigations() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);  
  const [menuLabels, setMenuLabels] = useState(MenuLabels);
  const {myInfo} = useSelector( ({ auth } : any) => ({myInfo:auth.myInfo}) )

  const handleAction = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);    
  };
  useEffect( () => {
    console.log("BottomNavigations", JSON.stringify(myInfo))
    if( !myInfo ) {
      menuLabels[4].value = `/member?state=${encodeURIComponent(location.pathname + location.search + (location.search ? '&': '?')
         + 'scroll_position=' + window.scrollY)}`;
         menuLabels[4].label = "로그인";
      setMenuLabels(menuLabels);
    } else {
      menuLabels[4].value = `/member`;
      menuLabels[4].label = "마이페이지";
      setMenuLabels(menuLabels);
    }
  }, [myInfo]
  )
  

  return (
    <BottomNavigation showLabels className="bottom-inner"  value={value} onChange={handleAction}>      
      {menuLabels.map((menu, index) => (
        <BottomNavigationAction key={index} label={menu.label} value={menu.value} icon={(<img src={menu.icon} alt={menu.label} />)} />
      ))}        
        {/* <Link to="/popup">
          <img src={bottomnav1} />
          <span>팝업발견</span>
        </Link>
        <Link to="/user/save-store">
          <img src={bottomnav2} />
          <span>관심팝업</span>
        </Link>
        <Link to="/maps">
          <img src={bottomnav3} />
          <span>내주변</span>
        </Link>
        <Link to="/placelist">
          <img src={bottomnav4} />
          <span>마이플리</span>
        </Link>
        <Link to="/member">
          <img src={bottomnav5} />
          {user === null && <span>로그인</span>}
          {user !== null && <span>마이페이지</span>}
        </Link>       */}
    </BottomNavigation>
  );
}
export default BottomNavigations;
