import FaqReadContainer from '../../../containers/contact/faq/FaqReadContainer';
import MainLayout from '../../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function ContactReadPage({ match }: any) {
  const { itemId } = useParams();

  return (
    <>
      <MainLayout>
        <FaqReadContainer itemId={itemId} />
      </MainLayout>
    </>
  );
}

export default ContactReadPage;
