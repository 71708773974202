import { useParams } from 'react-router-dom';
import PopupReadContainer from '../../containers/popup/PopupReadContainer';
import MainLayout from '../../layout/MainLayout';

function PopupReadPage({ match }: any) {
  const { storeId } = useParams();

  return (
    <>
      <MainLayout>
        <PopupReadContainer storeId={storeId} />
      </MainLayout>
    </>
  );
}

export default PopupReadPage;
