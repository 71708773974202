import React from 'react';
import { useParams } from 'react-router-dom';
import EventWinnerListContainer from '../../containers/event/EventWinnerListContainer';
import MainLayout from '../../layout/MainLayout';

function EventWinnerListPage({ match }: { match: any }) {
  const promotionId = useParams();
  return (
    <>
      <MainLayout>
        <EventWinnerListContainer promotionId={promotionId} />
      </MainLayout>
    </>
  );
}

export default EventWinnerListPage;
