import React from 'react';
import { Link } from 'react-router-dom';
import icon from './../../../assets/img/popply_icon_t.png';

function EventWinnerBanner() {
  return (
    <>
      {/* 일반적인 상황일 때 */}
      <div className="winner-banner" style={{ display: 'none' }}>
        <div className="bar"></div>
        <div className="banner">
          <div className="img-wrap">
            <img src={icon} alt="" className="icon" />
          </div>
          <p>방문 후기를 남겨주시면 푸짐한 선물을 드려요!</p>
        </div>
      </div>

      <div className="winner-banner">
        <div className="bar"></div>
        <div className="banner">
          <div className="img-wrap">
            <img src={icon} alt="" className="icon" />
          </div>
          <div className="winner-text">
            <p className="winner-line">
              <span>문인*</span>님 축하합니다
            </p>
            <p>
              <span>스타벅스 기프티콘</span> 당첨!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default EventWinnerBanner;
