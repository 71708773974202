import AdminPlaceListContainer from '../../containers/placelist/AdminPlaceListContainer';
import MainLayout from '../../layout/MainLayout';
function AdminPlaceListPage({ match }: any) {
  return (
    <>
      <MainLayout>
        <AdminPlaceListContainer />
      </MainLayout>
    </>
  );
}

export default AdminPlaceListPage;
