import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventList from '../../components/codegroup/EventList';
import { fetchList, FETCH_LIST } from '../../modules/promotion';
import Footer from '../../components/common/Footer';

const EventListContainer = () => {
  // 스토어 dispatch 사용 가능
  const dispatch = useDispatch();

  // 스토어 상태 조회
  const { promotions, isLoading } = useSelector((state: any) => ({
    promotions: state.promotion.promotions,
    isLoading: state.loading[FETCH_LIST],
  }));

  // 마운트될 때 코드그룹 목록을 가져옴
  useEffect(() => {
    dispatch(fetchList());
  }, [dispatch]);

  return (
    <>
      <EventList promotions={promotions} isLoading={isLoading} />
      <Footer />
    </>
  );
};
export default EventListContainer;
