import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import EventWinnerBanner from './item/EventWinnerBanner';
import EventIntro from './item/EventIntro';
import EventLine from './item/EventLine';
import EventChat from './item/EventChat';
import EventBtn from './item/EventBtn';
import EventAlert from './item/EventAlert';
import EventMessage from './item/EventMessage';
import EventEndPage from './item/EventEndPage';
import backicon from './../../assets/img/event-template/back-btn.png';
import httpStatusHandler from '../../util/httpStatusHandler';
import { addPromotionComment } from '../../lib/api';
const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; //PROD
//const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

function EventRead({ promotion, isLoading, myInfo }) {
  const [showIntro, setShowIntro] = useState(true);
  const [showPromotionMsg, setShowPromotionMsg] = useState(false);
  const [isWinner, setIsWinner] = useState(null); //메세지 보낸 이후 당첨여부
  const [isLogin, setIsLogin] = useState(true);
  const [agreePrivacy, setAgreePrivacy] = useState(null);
  const [isHateSpeech, setIsHateSpeech] = useState(null);
  const [promotionComment, setPromotionComment] = useState(null);
  const [promotionChats, setPromotionChats] = useState([]);
  const location = useLocation();
  const changePromotionComment = e => {
    setPromotionComment(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowIntro(false);
    }, 3200);
  });

  useEffect(() => {
    if (promotion) {
      setPromotionChats(promotion.comments);
    }
  }, [promotion]);
  const changePromotionMsg = async e => {
    setShowPromotionMsg(true);
  };

  const changePrivacy = async e => {
    if (e.target.type === 'button') {
      // 알림창의 버튼을 클릭한 경우
      setAgreePrivacy(null);
    }
    setAgreePrivacy(e.target.checked);
  };

  // 혐오 표현 확인버튼 클릭 시
  const changeHateSpeech = async e => {
    if (e.target.type === 'button') {
      setIsHateSpeech(null);
    }
  };

  const checkHateSpeech = async message => {
    // const result = await checkHateSpeech(message);
    const result = {
      data: { isHate: false },
    };
    if (result.data.isHate) {
      setIsHateSpeech(true);
    } else {
      setIsHateSpeech(false);
    }
    return result.data.isHate;
  };

  const onRegister = async files => {
    try {
      const hateSpchResult = await checkHateSpeech(promotionComment);
      const commentInfo = {
        agree: agreePrivacy,
        promotionId: promotion.data.promotionId,
        userId: myInfo.userId,
        name: myInfo.name,
        phone: myInfo.phone,
        comment: promotionComment,
      };

      if (agreePrivacy && hateSpchResult === false) {
        // 이벤트 참여완료
        const result = await addPromotionComment(promotion.data.promotionId, commentInfo);
        if (result.data.isWinner) {
          setIsWinner(true);
        } else {
          setIsWinner(false);
        }
        setPromotionChats([...promotionChats, result.data.comment]);
        // TODO  초기화 필요한 것들
        setShowPromotionMsg(false);
        setPromotionComment(null);
      } else {
        setAgreePrivacy(false);
      }
      console.log(agreePrivacy);
    } catch (err) {
      httpStatusHandler(err, null);
    }
  };

  const state = location.pathname + location.search;
  const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
  const onSignIn = () => {
    window.location.href = kauthUrl;
  };

  useEffect(() => {
    setTimeout(function () {
      setIsWinner(null); // 당첨여부 안내 후 3초 후 안내 창 닫기
    }, 3000);
  }, [isWinner]);

  return (
    <article className="event-area" onClick={() => setShowIntro(false)}>
      {isLoading && '...로딩중...'}
      {!isLoading && promotion && (
        <>
          {/* 인트로 화면 */}
          {showIntro && promotion && <EventIntro promotion={promotion} />}
          {/* 이벤트 화면 */}

          {!showIntro && (
            <div className="event-area-main">
              <header>
                {/* link 속 숫자는 변동 */}
                <Link to="/event">
                  <img src={backicon} alt="" />
                </Link>
                <EventWinnerBanner />
              </header>
              <div className="event-center">
                <EventLine />
                <EventChat promotionChats={promotionChats} />
              </div>
              <EventBtn changePromotionMsg={changePromotionMsg} />
              <EventAlert isLogin={isLogin} myInfo={myInfo} isHateSpeech={isHateSpeech} agreePrivacy={agreePrivacy} changePrivacy={changePrivacy} changeHateSpeech={changeHateSpeech} />
              <EventMessage isLogin={isLogin} onSignIn={onSignIn} myInfo={myInfo} showPromotionMsg={showPromotionMsg} onRegister={onRegister} changePrivacy={changePrivacy} changePromotionComment={changePromotionComment} promotionComment={promotionComment} />
              <EventEndPage isWinner={isWinner} />
            </div>
          )}
        </>
      )}
    </article>
  );
}
export default EventRead;
