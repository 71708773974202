import profileNone from './../../assets/img/member/profile.svg';
import kakaoicon from './../../assets/img/layout/kakao-icon.svg';
import kakaoLineIcon from './../../assets/img/member/member_login_kakao_btn.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AppleLoginButton from './AppleLoginButton';
import styled from '@emotion/styled';
import { useEffect } from 'react';


export default function GuestAccess() {
  const APPLE_CLIENT_ID: string = process.env.REACT_APP_APPLE_CLIENT_ID??'';  
  const [ searchParams, ] = useSearchParams();
  const location = useLocation();
  const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
  const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; //PROD  
  // const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV  
  const state = searchParams.has("state") ? decodeURIComponent(searchParams.get("state"))  : location.pathname + location.search;  
  const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
  const onSignIn = () => {
    // window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_HOME}&response_type=code&state=${state}`;
    window.location.href = kauthUrl;
  };
  
  return <div className="social-login">
  <div className="social-login-inner">
    <h2>
      <img src={profileNone} />
    </h2>
    <div className="social-login-inner-box">
      <p>POPPLY 회원으로 계속 하기</p>    
    </div>
    <div className="social-login-inner-btn">
      <a onClick={onSignIn}><img className="social-login-inner-kakao" src={kakaoLineIcon} /></a>
      <AppleLoginButton returnOrigin={window.location.origin} returnState={state} />          
    </div>
  </div>
</div>
}