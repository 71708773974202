import React from 'react';
import EventListContainer from '../../containers/event/EventListContainer';
import MainLayout from '../../layout/MainLayout';

function EventListPage() {
  // 메인 레이아웃 적용
  return (
    <>
      <MainLayout>
        <EventListContainer />
      </MainLayout>
    </>
  );
}

export default EventListPage;
