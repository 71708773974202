import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import createRequestSaga from '../lib/createRequestSaga';
import * as api from '../lib/api';
import dayjs from 'dayjs';

export const FETCH_SEARCH_INFO_LIST = 'searchInfo/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'searchInfo/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'searchInfo/FETCH_LIST_FAILURE';

export const fetchSearchInfoList = createAction(FETCH_SEARCH_INFO_LIST);

const fetchListSaga = createRequestSaga(FETCH_SEARCH_INFO_LIST, api.fetchSearchInfo);

export function* searchInfoSaga() {
  yield takeLatest(FETCH_SEARCH_INFO_LIST, fetchListSaga);
}

const initialState = {
  searchInfos: [],
  searchInfoQueryTime : dayjs().add(-1, 'day') ,
  error: null,
};

// 리듀서 함수 정의
const searchInfo = createReducer(initialState, {
  [FETCH_SEARCH_INFO_LIST]: state => state
  ,
  [FETCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    searchInfos: action.payload,
    searchInfoQueryTime : dayjs(),
  }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
});

export default searchInfo;
