import './common.scss';
import { Link, useNavigate } from 'react-router-dom';


function MainHeader({ myInfo, member, onLogout }: { myInfo: any; member?: any; onLogout: any;  }) {
  const navigate = useNavigate();
 

  return (
    <header className="div-main-header">
      {/* {isAuthorized && myInfo && ( */}
      {myInfo && (
        <ul className="menu-box-bottom">
          <li>
            <span>{member?.nickname} </span>회원님, 환영합니다.
          </li>
          <li className="mypage-btn">
            <Link to="/member">마이페이지</Link>
          </li>
          <li>
            <a onClick={onLogout} className="logout-btn">
              로그아웃
            </a>
          </li>
        </ul>
      )}
      {!myInfo && (
        <ul className="menu-box-bottom">
          <li onClick={() => navigate("/member/login")}>
            <a>회원가입</a>
          </li>
          <li onClick={() => navigate("/member/login")}>
            <a>로그인</a>
          </li>
        </ul>
      )}
    </header>
  );
}

export default MainHeader;

// 시작 끝 공백
// 공백
// stripHtml
// 템플릿 넣어놓고
// 정 뭐시기하면 template에 fixed 컬럼 추가해서 변경 가능 불가능 여부. ( 어 이거 값 변경되었는지 여부만 확인되어도 편하겠네 )
