import { useParams } from 'react-router-dom';
import PostSurveyContainer from '../../containers/survey/PostSurveyContainer';
import MainLayout from '../../layout/MainLayout';

function PostSurveyRegisterPage({ match }: any) {
  const { storeId, reservationId } = useParams();
  return (
    <>
      <MainLayout>
        <PostSurveyContainer storeId={storeId} reservationId={reservationId} />
      </MainLayout>
    </>
  );
}

export default PostSurveyRegisterPage;
