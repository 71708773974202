import Privacy from '../../components/policy/Privacy';
import MainLayout from '../../layout/MainLayout';

function PrivacyPage() {
  return (
    <>
      <MainLayout>
        <Privacy />
      </MainLayout>
    </>
  );
}

export default PrivacyPage;
