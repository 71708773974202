import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import createRequestSaga from '../lib/createRequestSaga';
import * as api from '../lib/api';

export const FETCH_ONE = 'userReservation/FETCH_ONE';
const FETCH_ONE_SUCCESS = 'userReservation/FETCH_ONE_SUCCESS';
const FETCH_ONE_FAILURE = 'userReservation/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'userReservation/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'userReservation/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'userReservation/FETCH_LIST_FAILURE';

export const FETCH_DETAIL = 'userReservation/FETCH_DETAIL';
const FETCH_DETAIL_SUCCESS = 'userReservation/FETCH_DETAIL_SUCCESS';
const FETCH_DETAIL_FAILURE = 'userReservation/FETCH_DETAIL_FAILURE';

export const fetchOne = createAction(FETCH_ONE, (storeId, reservationId, userId, hash) => ({ storeId, reservationId, userId, hash }));
export const fetchList = createAction(FETCH_LIST, (userId, type) => ({ userId, type }));
export const fetchDetail = createAction(FETCH_DETAIL, (userReservationId: number, userId: number, type) => ({ userReservationId, userId, type }));

// 비동기 액션을 수행하는 태스크 작성
const fetchOneSaga = createRequestSaga(FETCH_ONE, api.fetchUserReservation);
const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchUserReservationList);
const fetchDetailSaga = createRequestSaga(FETCH_DETAIL, api.fetchUserReservationByUserId);

export function* userReservationSaga() {
  yield takeLatest(FETCH_ONE, fetchOneSaga);
  yield takeLatest(FETCH_LIST, fetchListSaga);
  yield takeLatest(FETCH_DETAIL, fetchDetailSaga);
}

export const initialState = {
  userReservation: null,
  userReservations: [],
  error: null,
};

// 리듀서 함수 정의
const userReservation = createReducer(initialState, {
  [FETCH_ONE]: state => ({
    ...state,
    userReservation: null,
  }),
  [FETCH_ONE_SUCCESS]: (state, action) => ({
    ...state,
    userReservation: action.payload.data,
  }),
  [FETCH_ONE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload.data,
  }),

  [FETCH_LIST]: state => ({
    ...state,
    userReservations: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    userReservations: action.payload.data,
  }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload.data,
  }),

  [FETCH_DETAIL]: state => ({
    ...state,
    userReservation: null,
  }),
  [FETCH_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    userReservation: action.payload.data,
  }),
  [FETCH_DETAIL_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload.data,
  }),
});

export default userReservation;
