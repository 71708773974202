import * as React from 'react';
import nonethumb from './../../assets/img/noneIMG.png';
import { Button } from '@material-ui/core';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import sort_icon from './../../assets/img/common/sort-icon.svg';
import filter_icon from './../../assets/img/common/filter-icon.svg';
import calImg from './../../assets/img/cal-pop.png';
import slidemapicon from './../../assets/img/common/slidemapicon.svg';
import bookmark from './../../assets/img/popup/bookmark.svg';
import bookmark_on from './../../assets/img/popup/bookmark_on.svg';
import searchbg from './../../assets/img/layout/search-bg.svg';
import profileNone from './../../assets/img/member/profile.svg';
import bookmark_icon from './../../assets/img/popup/icon_bookmark.svg';

import Footer from '../common/Footer';
import Bookmark from '../common/Bookmark';
import GuestAccess from '../auth/GuestAccess';
import { filterStore, NameValuePairExt, saveListStatusOptions, sortOptions, sortStore } from '../../types/PopplyTypes';

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noreferrer');
};

function SaveStoreList({ stores, isLoading, myInfo, onSignIn }: any) {

  const [displayStores , setDisplayStores] = useState(stores)
  //인기순~
  // const [sortSelect, setSortSelect] = React.useState(true);
  // const [sortOption, setSortOption] = useState(sortOptions[0])
  // const filterClick = (isClick: boolean) => {
  //   setSortSelect(current => !current);
  // };

  // const sortChangeClick = (s: NameValuePairExt) =>{
  //   setSortSelect(current => !current);
  //   setSortOption(s);    
  // }
  useEffect(()=>{
    if(stores) {
      setDisplayStores(stores)
    }
  }, [stores])
  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //진행중~  
  const [statusSelect, setStatusSelect] = React.useState(true);
  const [statusOption, setStatusOption] = useState(saveListStatusOptions[0]); // 기간 기본값: 모든 기간  
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };
  console.log("SaveStoreList", myInfo);
  const statusChangeClick = (s: NameValuePairExt) => {
    setStatusSelect(current => !current);
    setStatusOption(s);
  }

  //검색어
  const [search, setSearch] = useState('');

  useEffect(() => {
    if(stores) {
      const searchStoreList = search ? stores.filter((store: any) => search.split(" ").every((word: string) => store.searchItems.includes(word))) : stores;      
      const filterStoreList = statusOption ? filterStore(searchStoreList,statusOption) : searchStoreList;
      // const sortSToreList = sortOption ? sortStore(filterStoreList, sortOption) : filterStoreList
      setDisplayStores(filterStoreList);
    }
  }, [search, statusOption ]);


  return (
    <article className="inner" style={{ paddingBottom: '100px', position: 'relative', height: 'auto' }}>
      {!myInfo && (
        <GuestAccess />        
      )}
      {/* {isLoading && '로딩중...'} */}
      {!isLoading && myInfo && (
        <>
          {stores && stores.length < 1 && (
            <div className="page-info-area" style={{ padding: '0 60px' }}>
              <div className="page-info-area-inner">
                <img src={bookmark_icon} alt="" />
                <h5>저장된 관심 팝업스토어가 아직 없어요!</h5>
                <p>관심있는 팝업스토어는 ♡하트를 눌러 관심팝업에서 모아볼 수 있습니다.</p>
              </div>
            </div>
          )}
          <div className="submenu">
            {/*클릭하면 클래스 on 붙어야 함*/}
            <Link to="/member">
              <h2>내 정보 </h2>
            </Link>
            <Link to="/user/save-store" className="on">
              <h2>관심팝업 </h2>
            </Link>
            <Link to="/member/reservation/pre">
              <h2>사전예약 </h2>
            </Link>
            {/* <Link to="/user/reservation/onSite">
              <h2>사전예약 </h2>
            </Link> */}
            {/* <Link to="/user/visit-store">
                <h2>다녀온 팝업 </h2>
              </Link> */}
          </div>
          <div className="search-box search-box-cont">
            <header>
              <p>
                <input type="search" placeholder="지역, 팝업스토어명, 테마 키워드 검색" onChange={(e) =>setSearch(e.target.value)} />
                <a>
                  <img src={searchbg} alt="" />
                </a>
              </p>
            </header>
          </div>
          <div className="popuplist-filter">
            <p
              onClick={() => {
                statusClick(true);
              }}
            >
              {statusOption.name} <img src={sort_icon} />
            </p>
            {/* <p
              onClick={() => {
                filterClick(true);
              }}
            >
              <img src={filter_icon} /> {sortOption.name}</p> */}
          </div>
          <div className={statusSelect ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
            <ul>
            {saveListStatusOptions.map( (s, idx) => (<li key={idx} onClick={() => {statusChangeClick(s);}} className={s.value == statusOption.value ? "select" :"" }>{s.name}</li>))}           
            </ul>
          </div>
          {/* <div className={sortSelect ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
            <ul>
            {sortOptions.map( (s, idx) => (<li key={idx} onClick={() => {sortChangeClick(s);}} className={s.value == sortOption.value ? "select" :"" }>{s.name}</li>))}                    
            </ul>
          </div> */}
          <div className="calendar-popup-list popuplist-board">
            <ul>
              {displayStores != null &&
                !!displayStores.length &&
                displayStores.map((store: any, index) => PopupSaveStoreCard(index, store))
              }
            </ul>
          </div>
        </>
      )}
    </article>
  );

  function PopupSaveStoreCard(index: any, store: any) {    
    return <li key={index}>
      <div>
        <Link to={`/popup/${store.storeId}`} className="popup-img-wrap">
          <img src={store.thumbnails ? store.thumbnails : nonethumb} alt="" className="popup-img" />
        </Link>
        <Link to={`/popup/${store.storeId}`}>
          <ul>
            <li className="popup-name">
              <p>{store.title}</p>
            </li>
            <li className="popup-location">
              <img src={slidemapicon} /> {store.topLevelAddress}
            </li>
          </ul>
        </Link>
        {/* <img src={bookmark_on} alt="" className="popup-bookmark" /> */}
        <Bookmark store={store} myInfo={myInfo} />
        <p className="popup-date">
          {dayjs(store.startDate).format('YY.MM.DD')} - {dayjs(store.endDate).format('YY.MM.DD')}
        </p>
      </div>
    </li>;
  }
}

export default SaveStoreList;
