import BusinessContainer from '../../containers/business/BusinessContainer';
import MainLayout from '../../layout/MainLayout';

function BusinessPage() {
  return (
    <>
      <MainLayout>
        <BusinessContainer />
      </MainLayout>
    </>
  );
}

export default BusinessPage;
