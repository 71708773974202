import { Route, Routes } from 'react-router-dom';
import SignInPage from './pages/auth/SignInPage';
import SignUpPage from './pages/auth/SignUpPage';
import PopupListPage from './pages/popup/PopupListPage';
import PopupReadPage from './pages/popup/PopupReadPage';
import PopupPreviewPage from './pages/popup/PopupPreviewPage';
import BoardModifyPage from './pages/popup/BoardModifyPage';
import BoardRegisterPage from './pages/popup/BoardRegisterPage';
import StoreCommentListPage from './pages/comment/StoreCommentListPage';

import EventListPage from './pages/event/EventListPage';
import EventReadPage from './pages/event/EventReadPage';
import EventWinnerListPage from './pages/event/EventWinnerListPage';

import CoinChargeListPage from './pages/coin/CoinChargeListPage';
import CoinChargeRegisterPage from './pages/coin/CoinChargeRegisterPage';
import CoinPayListPage from './pages/coin/CoinPayListPage';
import HomePage from './pages/HomePage';
import ExplorePage from './pages/explore/ExploreListPage';
import ItemModifyPage from './pages/explore/ItemModifyPage';
import ItemReadPage from './pages/explore/ItemReadPage';
import ItemRegisterPage from './pages/explore/ItemRegisterPage';
import AdminSetupPage from './pages/member/AdminSetupPage';
import MemberListPage from './pages/member/MemberListPage';
import MemberModifyPage from './pages/member/MemberModifyPage';
import MemberLoginPage from './pages/member/MemberLoginPage';
import MemberReadPage from './pages/member/MemberReadPage';
import MemberRegisterPage from './pages/member/MemberRegisterPage';
import SaveStoreListPage from './pages/member/SaveStoreListPage';
import VisitStoreListPage from './pages/member/VisitStoreListPage';

import MapListPage from './pages/map/MapListPage';
// import MapModifyPage from './pages/map/MapModifyPage';
// import MapReadPage from './pages/map/MapReadPage';
// import MapRegisterPage from './pages/map/MapReadPage';
// import MapListPage from './pages/map/MapListPage';
// import MapModifyPage from './pages/map/MapModifyPage';
// import MapReadPage from './pages/map/MapReadPage';
// import MapRegisterPage from './pages/map/MapRegisterPage';
import MagazineListPage from './pages/magazine/MagazineListPage';
import MagazineReadPage from './pages/magazine/MagazineReadPage';
// import PdsModifyPage from './pages/pds/PdsModifyPage';
// import PdsRegisterPage from './pages/pds/PdsRegisterPage';
import UserItemListPage from './pages/useritem/UserItemListPage';
// import UserItemReadPage from './pages/useritem/UserItemReadPage';

import ContactListPage from './pages/contact/ContactListPage';
import ContactModifyPage from './pages/contact/ContactModifyPage';
import ContactReadPage from './pages/contact/ContactReadPage';
import ContactRegisterPage from './pages/contact/ContactRegisterPage';

import QnaListPage from './pages/contact/qna/QnaListPage';
import QnaModifyPage from './pages/contact/qna/QnaModifyPage';
import QnaReadPage from './pages/contact/qna/QnaReadPage';
import QnaRegisterPage from './pages/contact/qna/QnaRegisterPage';

import BizQnaRegisterPage from './pages/contact/bizqna/BizQnaRegisterPage';

import FaqListPage from './pages/contact/faq/FaqListPage';
// import FaqModifyPage from './pages/contact/faq/FaqModifyPage';
import FaqReadPage from './pages/contact/faq/FaqReadPage';
import FaqRegisterPage from './pages/contact/faq/FaqRegisterPage';

import PopupReportListPage from './pages/contact/popupreport/PopupreportListPage';
import PopupReportReadPage from './pages/contact/popupreport/PopupreportReadPage';
import PopupReportRegisterPage from './pages/contact/popupreport/PopupreportRegisterPage';

// import CodeDetailListPage from './pages/codedetail/CodeDetailListPage';
// import CodeDetailModifyPage from './pages/codedetail/CodeDetailModifyPage';

import PrivacyPage from './pages/policy/PrivacyPage';
import ServicePage from './pages/policy/ServicePage';
import MarketingPage from './pages/policy/MarketingPage';

import OnSiteReservationRegisterPage from './pages/popup/reservation/OnSiteReservationDetailPage';
import PreReservationRegisterPage from './pages/popup/reservation/PreReservationDetailPage';
import ReservationConfirmResultPage from './pages/popup/reservation/ReservationConfirmResultPage';
import PreReservationListPage from './pages/member/PreReservationListPage';
import PreReservationDetailPage from './pages/member/PreReservationDetailPage';
import OnSiteReservationListPage from './pages/member/OnSiteReservationListPage';
import OnSiteReservationDetailPage from './pages/member/OnSiteReservationDetailPage';
import PreSurveyRegisterPage from './pages/survey/PreSurveyRegisterPage';
import PostSurveyRegisterPage from './pages/survey/PostSurveyRegisterPage';

import ReservationQrPage from './pages/popup/reservation/ReservationQrPage';
import PopupSearchListPage from './pages/popup/PopupSearchListPage';

import AdminPlaceListPage from './pages/placelist/AdminPlaceListPage';
import AdminPlaceListRegisterPage from './pages/placelist/AdminPlaceListRegisterPage';

import BusinessPage from './pages/business/BusinessPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import BrandQrPage from './pages/BrandQrPage';

import { isMobile } from 'react-device-detect';
import ScrollToPostionProvider from './provider/ScrollToPostionProvider';

const queryClient = new QueryClient();
function App() {
  // var Agent = navigator.userAgent.toLowerCase();
  // if (isMobile && Agent.includes('insta')) {
  // window.close();
  // window.location.assign(encodeURIComponent('intent://popply.co.kr#Intent;scheme=http;package=com.android.chrome;end'));
  // }
  window.addEventListener('error', event => {
    const a = `${event.type}: ${event.message}\n`;
    <div>{a}</div>;
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ScrollToPostionProvider>
          <Routes>
            <Route element={<HomePage />} path="/" exact />
            <Route element={<SignInPage />} path="/signin" exact />
            <Route element={<AdminSetupPage />} path="/member/setup" exact />
            <Route element={<EventListPage />} path="/event" exact />
            {/* <Route element={<CodeGroupRegisterPage/>} path="/codegroup/create" /> */}
            {/* <Route element={<CodeGroupModifyPage/>} path="/codegroup/edit/:groupCode" /> */}
            <Route element={<EventWinnerListPage />} path="/event/:eventId/winner" exact />
            <Route element={<EventReadPage />} path="/event/:eventId" exact />
            {/* <Route element={<CodeDetailListPage/>} path="/event/join"  /> */}
            {/* <Route element={<CodeDetailModifyPage/>} path="/chat" /> */}
            {/* <Route element={<CodeDetailRegisterPage/>} path="/codedetail/create" /> */}
            {/* <Route element={<CodeDetailModifyPage/>} path="/codedetail/edit/:groupCode/:codeValue" /> */}
            {/* <Route element={<CodeDetailReadPage/>} path="/codedetail/read/:groupCode/:codeValue" /> */}
            <Route element={<SignUpPage />} path="/signup" exact />
            <Route element={<MemberListPage />} path="/member" exact />
            <Route element={<MemberRegisterPage />} path="/member/create" exact />
            <Route element={<MemberModifyPage />} path="/member/edit/:userNo" exact />
            <Route element={<MemberReadPage />} path="/member/read/:userNo" exact />
            <Route element={<MemberLoginPage />} path="/member/login" exact />

            <Route element={<SaveStoreListPage />} path="/user/save-store" exact />
            <Route element={<VisitStoreListPage />} path="/user/visit-store" exact />

            <Route element={<PopupListPage />} path="/popup" exact />
            <Route element={<PopupSearchListPage />} path="/popup/search" exact />

            <Route element={<PopupReadPage />} path="/popup/:storeId" exact />
            <Route element={<PopupPreviewPage />} path="/popuppreview/:storeId/:previewId" exact />
            <Route element={<BoardRegisterPage />} path="/board/create" exact />
            {/* <Route element={<BoardModifyPage />} path="/board/edit/:boardNo" exact /> */}

            <Route element={<StoreCommentListPage />} path="/:storeId/comment/list" exact />
            <Route element={<MapListPage />} path="/maps" exact />
            <Route element={<BusinessPage />} path="/business" exact />

            {/* <Route element={<MapRegisterPage/>} path="/notice/create" />
      <Route element={<MapModifyPage/>} path="/notice/edit/:noticeNo" />
      <Route element={<MapReadPage/>} path="/notice/read/:noticeNo" /> */}
            {/* <Route element={<ExplorePage />} path="/explore" exact /> */}
            {/* <Route element={<ItemRegisterPage/>} path="/item/create" /> */}
            {/* <Route element={<ItemModifyPage/>} path="/item/edit/:itemId" /> */}
            {/* <Route element={<ItemReadPage/>} path="/item/read/:itemId" /> */}
            {/* <Route element={<CoinChargeListPage />} path="/coin/charge" exact /> */}
            {/* <Route element={<CoinChargeRegisterPage />} path="/coin/create" exact /> */}
            {/* <Route element={<UserItemListPage />} path="/useritem" exact /> */}
            {/* <Route element={<UserItemReadPage/>} path="/useritem/read/:userItemNo" /> */}
            {/* <Route element={<CoinPayListPage />} path="/coin/pay" exact /> */}
            <Route element={<MagazineListPage />} path="/magazine" exact />
            <Route element={<MagazineReadPage />} path="/magazine/:magazineId" exact />
            {/* <Route element={<PdsRegisterPage/>} path="/pds/create" /> */}
            {/* <Route element={<PdsModifyPage/>} path="/pds/edit/:itemId" /> */}
            <Route element={<ContactListPage />} path="/contact" exact />
            {/* <Route element={<ContactRegisterPage/>} path="/contact/create" /> */}
            {/* <Route element={<ContactModifyPage/>} path="/contact/edit/:itemId" /> */}
            <Route element={<ContactReadPage />} path="/contact/:itemId" exact />
            <Route element={<QnaListPage />} path="/qna" exact />
            <Route element={<QnaRegisterPage />} path="/qna/create" exact />
            <Route element={<QnaModifyPage />} path="/qna/edit/:itemId" exact />
            <Route element={<QnaReadPage />} path="/qna/:itemId" exact />
            <Route element={<BizQnaRegisterPage />} path="/bizqna" exact />
            <Route element={<FaqListPage />} path="/faq" exact />
            <Route element={<FaqRegisterPage />} path="/faq/create" exact />
            {/* <Route element={<FaqModifyPage/>} path="/contact/faq/edit/:itemId" /> */}
            <Route element={<FaqReadPage />} path="/faq/:itemId" />
            <Route element={<PopupReportListPage />} path="/popupReport" exact />
            <Route element={<PopupReportRegisterPage />} path="/popupReport/create" exact />
            <Route element={<PopupReportReadPage />} path="/popupReport/:itemId" exact />
            <Route element={<PrivacyPage />} path="/policy/privacy" exact />
            <Route element={<ServicePage />} path="/policy/service" exact />
            <Route element={<MarketingPage />} path="/policy/marketing" exact />

            {/* PreReservation && OnSiteReservation */}
            {/* 사전예약 신청 페이지 */}
            <Route element={<PreReservationRegisterPage />} path="/popup/:storeId/reservation/pre" exact />
            {/* 현장예약 신청 페이지, hash 필수 */}
            <Route element={<OnSiteReservationRegisterPage />} path="/popup/:storeId/reservation/onSite/:hash" exact />
            {/* 예약 후 확정 페이지 랜딩 */}
            <Route element={<ReservationConfirmResultPage />} path="/popup/:storeId/reservation/:reservationId/:hash" exact />
            {/* 알림톡으로 확인 페이지 */}
            <Route element={<ReservationQrPage />} path="/popup/:storeId/reservation/:reservationId/certification/:hash" exact />

            <Route element={<BrandQrPage />} path="/store/:storeId/reservation/:reservationId/qr" exact />

            {/* 유저 마이페이지 확인페이지 */}
            <Route element={<PreReservationListPage />} path="/member/reservation/pre" exact />
            <Route element={<PreReservationDetailPage />} path="/member/reservation/pre/:userReservationId" exact />
            <Route element={<OnSiteReservationListPage />} path="/member/reservation/onSite" exact />
            <Route element={<OnSiteReservationDetailPage />} path="/member/reservation/onSite/:userReservationId" exact />

            {/* Survey */}
            <Route element={<PreSurveyRegisterPage />} path="/popup/:storeId/reservation/:reservationId/survey/pre" exact />
            <Route element={<PreSurveyRegisterPage />} path="/popup/:storeId/reservation/:reservationId/survey/pre/:hash" exact />
            <Route element={<PostSurveyRegisterPage />} path="/popup/:storeId/reservation/:reservationId/survey/post/:hash" exact />

            {/* placeList */}
            <Route element={<AdminPlaceListPage />} path="/placeList" exact />
            <Route element={<AdminPlaceListRegisterPage />} path="/placeList/create" exact />
          </Routes>
        </ScrollToPostionProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
