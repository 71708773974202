import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const addBanner = ({ title, content }) => {
  return (
    <>
      <div className="addbanner-inner">
        <Link to="./../contact">
          <p>{title}</p>
          <span>{content}</span>
        </Link>
      </div>
    </>
  );
};

export default addBanner;
