import './reservation.scss';
import { Link } from 'react-router-dom';
import notice from './../../assets/img/common/notice.png';
import React, { useEffect, useState } from 'react';

function ReservationConfirmResult({ type, userReservation, waitingCount, isLoading, updateReservationStatus }: any) {
  const [cancelPoup, setCancelPoup] = useState(true);
  const cancelClick = (isClick: boolean) => {
    setCancelPoup(current => !current);
  };

  return (
    <article className="inner reservation-wrap">
      {userReservation && type === 'PRE' && (
        <>
          <header>
            <p>사전예약</p>
          </header>
          <div className="reservation-form">
            <div className="field-wrap">
              <p className="small-text">예약 팝업스토어명</p>
              <h1 className="popupstore-name">{userReservation.reservation.store.title}</h1>
            </div>
            <div className="field-wrap">
              <p className="small-text">예약일자</p>
              <p className="bold">{userReservation.reservationScheduleMap.reservationSchedule.reservationDate}</p>
            </div>
            <div className="field-wrap">
              <p className="small-text">예약시간</p>
              <p>{userReservation.reservationScheduleMap.reservationSchedule.reservationTime}</p>
            </div>
            <div className="field-wrap">
              <p className="small-text">예약확정 시간</p>
              <p>{userReservation.reservationTime}</p>
            </div>
            <div className="field-wrap">
              <p className="small-text">총 방문인원</p>
              <p>
                <span>{userReservation.withGuest}</span>명
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10%' }}>
              <img src={userReservation.qrUrl} alt={`${userReservation.reservation.store.title} 사전예약`} />
            </div>
            <div className="reservation-result-bottom">
              <p>위와 같은 정보로 사전예약이 완료되었습니다.</p>
              <p>예약 내역을 다시 한 번 꼼꼼하게 확인해 주세요.</p>
            </div>
            {/* 더블하트 */}
            {/* <div className="field-wrap marketing-wrap">
              <p>
                <span className="red-text">마케팅 수신에 동의</span>하면
              </p>
              <p>
                <span className="red-text">다양한 팝플리 소식</span>을 받아보실 수 있습니다!
              </p>
            </div>
            <Link to="/member" className="reservation-btn red" style={{ marginBottom: 0 }}>
              마케팅동의 하러 가기
            </Link>

            <Link to="/member/reservation/pre" className="reservation-btn red" style={{ marginTop: '10px' }}>
              목록으로
            </Link> */}
            <button type="button" className="reservation-btn red" onClick={() => cancelClick(true)}>
              예약 취소하기
            </button>
            <div className={cancelPoup ? 'layer-popup-white cancel-popup' : 'layer-popup-white cancel-popup show'}>
              <div className="txt-wrap">
                <p>지금 예약을 취소할 경우, 재예약이 불가할 수 있습니다.</p>
                <p>정말 취소하시겠습니까?</p>
              </div>
              <div className="btn-wrap">
                <button type="button" onClick={() => updateReservationStatus()}>
                  예약 취소하기
                </button>
                <button type="button" onClick={() => cancelClick(false)}>
                  닫기
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'ON-SITE' && (
        <>
          <div className="waiting-form white-back">
            <div className="waiting-header">
              <p className="popupstore-name">{userReservation.reservation.store.title}</p>
              <p className="big-text">현장 대기 신청</p>
            </div>
            <div className="waiting-content">
              <div className="red-header">
                <p>대기 신청이 완료되었습니다.</p>
                <p>고객님의 순서가 되면</p>
                <p>알림톡으로 알려드릴게요!</p>
              </div>
              <div className="waiting-info">
                <p>
                  대기 팝업스토어 : <span>{userReservation.reservation.store.title}</span>
                </p>
                <p>
                  대기번호 : <span>{userReservation.inOrder}</span>
                </p>
                <p>
                  고객님 앞 : <span>{waitingCount === 0 ? 0 : waitingCount}팀</span>
                </p>
                <p>
                  접수일시 : <span>{userReservation.reservationTime}</span>
                </p>
              </div>
            </div>

            {/* <div className="waiting-option">
              <p>
                대기 중, <span>{userReservation.reservation.store.title}방문 전 설문</span>을 진행해주세요!
              </p> */}

            {/* 설문 링크로 이동시키기 */}
            {/* <Link to="/" className="reservation-btn red">
                방문 전 설문하기
              </Link>
              <div className="notice-area">
                <img src={notice} alt="" />
                <ul className="notice-list">
                  <li>대기순서는 현장 상황에 따라 달라질 수 있습니다.</li>
                  <li>중복 예약은 불가하오니 취소 후 예약해 주세요.</li>
                  <li>{userReservation.reservation.notice}</li>
                </ul>
              </div>
            </div> */}
          </div>
        </>
      )}
    </article>
  );
}

export default ReservationConfirmResult;
