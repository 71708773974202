import MagazineReadContainer from '../../containers/magazine/MagazineReadContainer';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function MagazineReadPage({ match }: any) {
  const { magazineId } = useParams();
  return (
    <>
      <MainLayout>
        <MagazineReadContainer magazineId={magazineId} />
      </MainLayout>
    </>
  );
}

export default MagazineReadPage;
