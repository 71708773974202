import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import sort_icon from './../../assets/img/common/sort-icon.svg';
import mapicon from './../../assets/img/map/map-gray.svg';
import dayjs from 'dayjs';
import './event.scss';
import blank from './../../assets/img/popup/blank-list.png';
import eventImg from './../../assets/img/review_event.png';

function EventList({ promotions, isLoading }) {
  //진행중~
  const [statusSelect, setStatusSelect] = React.useState(true);
  const statusClick = (isClick: boolean) => {
    setStatusSelect(current => !current);
  };
  const [progressPromotions, setProgressPromotions] = useState([]);
  useEffect(() => {
    if (promotions.length > 0) {
      const filteredPromotions = promotions.filter(pm => pm.status === 'scheduled' || pm.status === 'progress');
      setProgressPromotions(filteredPromotions);
    }
  }, [promotions]);

  return (
    <article className="inner">
      {isLoading && '로딩중'}
      {isLoading ||
        (progressPromotions.length < 1 && (
          <div className="blank-list">
            <img src={blank} alt="" />
            <p className="keyword-wrap">진행중인 이벤트가 없네요</p>
          </div>
        ))}
      {!isLoading && progressPromotions.length > 0 && promotions && (
        <>
          {/* <div className="popuplist-filter event-filter">
            <p
              onClick={() => {
                statusClick(true);
              }}
            >
              진행 중 <img src={sort_icon} />
            </p>
          </div>
          <div className={statusSelect ? 'popuplist-filter-box' : 'popuplist-filter-box popuplist-filter-box-up'}>
            <ul>
                  // 클릭하면 다른 li에는 select가 없어지고 클릭한 요소에만 select클래스 추가
              <li className="select">진행 중</li>
              <li>사전예약 중</li>
              <li>오픈 예정</li>
              <li>종료</li>
            </ul>
          </div> */}
          <div className="item-wrap-list">
            {/* {promotions.map(pm => {
              return pm.status === 'open' ? (
                <div className="item-wrap">
                  <div className="item-inner">
                    <Link to={`/event/${pm.promotionId}`} className="img-wrap">
                      <img src="https://cf-templates-1gyolugg9zn9q-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/store/32fadffb%2C1797%2C41b1%2Cbe78%2C295428440afd" alt="" className="main-thumbnail" />
                    </Link>
                    <Link to={`/event/${pm.promotionId}`}>
                      <div className="text-wrap">
                        <p className="title">{pm.promotionDetail.title}</p>
                        <div className="info-wrap">
                          {pm.store !== null && (
                            <>
                              <img src={mapicon} alt="" />
                              <p className="location">{pm.store.topLevelAddress}</p>
                              <p className="bar">|</p>
                            </>
                          )}
                          <p className="date">
                            {dayjs(pm.promotionDetail.startDate).format('YYYY-MM-DD')} - {dayjs(pm.promotionDetail.endDate).format('YYYY-MM-DD')}
                          </p>
                        </div>
                        <div className="content-wrap">
                          <p>{pm.promotionDetail.content}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ''
              );
            })} */}
            <div className='item-wrap'>
              <div className='item-inner'>
                <Link to="/contact/4" className="img-wrap">
                  <img className="main-thumbnail" src={eventImg} alt="리뷰이벤트 - 찐후기 부탁해" />
                </Link>
                <Link to="/contact">
                  <div className='text-wrap'>
                    <p className='title'>[팝플리] 팝업스토어 리뷰이벤트 '찐후기를 부탁해!'</p>
                    <div className='info-wrap'>
                      <p className='date'>2024.06.01 - 2024.12.31</p>
                    </div>
                    <div className='content-wrap'>
                      <p>#팝업스토어 #사진 #별점 #리뷰</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </article>
  );
}

export default EventList;
