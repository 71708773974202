import QnaModifyContainer from '../../../containers/contact/qna/QnaModifyContainer';
import MainLayout from '../../../layout/MainLayout';
import { useParams } from 'react-router-dom';
function QnaModifyPage({ match }: any) {
  const { itemId } = useParams();

  return (
    <>
      <MainLayout>
        <QnaModifyContainer itemId={itemId} />
      </MainLayout>
    </>
  );
}

export default QnaModifyPage;
