import MagazineListContainer from '../../containers/magazine/MagazineListContainer';
import MainLayout from '../../layout/MainLayout';

function MagazineListPage() {
  return (
    <>
      <MainLayout>
        <MagazineListContainer />
      </MainLayout>
    </>
  );
}

export default MagazineListPage;
