import { Link } from 'react-router-dom';

function ContactMenus({ onMenu }: any) {
  return (
    // 공지사항
    <article className="inner submenu-wrap">
      <div className="submenu">
        <Link to="/contact" className={onMenu === 'contact' ? 'on' : ''}>
          <h2>공지사항</h2>
        </Link>
        <Link to="/faq" className={onMenu === 'faq' ? 'on' : ''}>
          <h2>FAQ</h2>
        </Link>
        <Link to="/qna" className={onMenu === 'qna' ? 'on' : ''}>
          <h2>1:1문의</h2>
        </Link>
        <a href="https://biz.popply.co.kr/contact" target="_blank" rel="noopener noreferrer">
          <h2>비즈니스 문의</h2>
        </a>
        <Link to="/popupreport" className={onMenu === 'popupreport' ? 'on' : ''}>
          <h2>팝업스토어 제보</h2>
        </Link>
      </div>
    </article>
  );
}

export default ContactMenus;
