import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function LongTextForm({ reservationType, isLoading, myInfo, qTitle, changeInformation }: any) {
  return (
    <>
      <div className="q-wrap">
        <p className="q-tit">{qTitle}</p>
        <div className="a-wrap">
          <Editor
            editor={ClassicEditor as any}
            config={{
              // placeholder: storeInfo.storeDetail.contents,
              toolbar: [],
            }}
            //   data={storeInfo.storeDetail.contents}
            //   onChange={(event, editor) => {
            //     const data = editor.getData();
            //     console.log(data);
            //     setStoreInfo(prevState => ({
            //       ...prevState,
            //       storeDetail: { ...prevState.storeDetail, contents: data },
            //     }));
            //   }}
          />
        </div>
      </div>
    </>
  );
}

export default LongTextForm;
