import { FormEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RadioForm from './RadioForm';
import CheckboxForm from './CheckboxForm';
import ShortTextForm from './ShortTextForm';
import LongTextForm from './LongTextForm';
import { ShortText } from '@mui/icons-material';
import profileNone from './../../assets/img/member/profile.svg';
import { addUserSurvey } from '../../lib/api';
import GuestAccess from '../auth/GuestAccess';

function preSurvey({ reservationId, survey, reservationType, isLoading, myInfo, hash, onRegister, continueWithUser }: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [surveyUserInfo, setSurveyUserInfo] = useState([
    {
      surveyId: null,
      surveyQuestionId: null,
      surveyQuestionCaseId: null,
      surveyQuestionText: null,
    },
  ]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  const handleSubmit = (e: FormEvent) => {
    const reservation = surveyUserInfo;
    console.log(surveyUserInfo);
    e.preventDefault();
    const isConfirm = window.confirm('등록하시겠습니까?');
    if (isConfirm) {
      onRegister(surveyUserInfo);
    } else {
      return;
    }
  };

  const changeInformation = async (e, type) => {
    const surveyUser = {
      surveyId: survey.surveyId,
      surveyQuestionId: e.surveyQuestionId,
      surveyQuestionCaseId: e.surveyQuestionCaseId,
      surveyQuestionText: e.surveyQuestionText ? e.surveyQuestionText : null,
    };

    if (surveyUserInfo.length <= 0) {
      setSurveyUserInfo([surveyUser]);
    } else {
      setSurveyUserInfo([...surveyUserInfo, surveyUser]);
    }
    await addUserSurvey({ reservationId, type, surveyId: survey.surveyId, surveyUserInfo: surveyUser, userId: myInfo ? myInfo.userId : undefined, hash: hash });
  };

  const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const K_AUTH_URL = process.env.REACT_APP_KAKAO_AUTH_URL;
  const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; //PROD
  // const REDIRECT_HOME = process.env.REACT_APP_DEV_REDIRECT_HOME; // DEV

  const state = location.pathname + location.search;
  const kauthUrl = `${K_AUTH_URL}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_HOME}&response_type=code&state=${state}`;
  const onSignIn = () => {
    window.location.href = kauthUrl;
  };
  const preReview = '팝업스토어에 대한 기대를 듬뿍 담은 메시지를 작성해 주세요!';

  return (
    <div>
      {!myInfo && continueWithUser === true && (
        <article>
          <GuestAccess />          
        </article>
      )}

      {survey && continueWithUser === false && (
        // {(survey && myInfo) ||
        //   (survey && continueWithUser === false && (
        <article className="inner white-back survey-wrap">
          <>
            <div className="header">
              <h1>{survey.reservationSurveyMap[0].reservation.store.title}</h1>
              <p>방문 전 설문</p>
            </div>
            <div className="sub-text">
              <p>안녕하세요. 우리들의 팝한 플레이스 리스트 팝플리입니다.</p>
              <p>
                {/* <b>팝업스토어 이름 팝업스토어</b> */}
                <b>{survey.reservationSurveyMap[0].reservation.store.title}</b>
              </p>
              <p>방문 전 간단한 설문을 진행하고자 합니다.</p>
              <p style={{ marginTop: '20px' }}>응답해 주시는 내용은 통계적인 자료 분석용으로만 이용되며, 보다 나은 상품 및 서비스를 제공하는 자료로 활용됩니다.</p>
            </div>
            <div className="survey-form-wrap">
              <form onSubmit={handleSubmit}>
                <>
                  {survey.surveyQuestion &&
                    survey.surveyQuestion.length &&
                    survey.surveyQuestion.map(q => {
                      if (q.type === 'radio' || q.type === null) {
                        return <RadioForm question={q} changeInformation={changeInformation} />;
                      } else if (q.type === 'check') {
                        return <CheckboxForm question={q} changeInformation={changeInformation} />;
                      } else if (q.type === 'essay') {
                        return <ShortTextForm question={q} changeInformation={changeInformation} />;
                      } else {
                        <LongTextForm qTitle={preReview} changeInformation={changeInformation} />;
                      }
                    })}
                </>
                <button type="submit" className="reservation-btn red">
                  완료
                </button>
              </form>
            </div>
          </>
        </article>
      )}
    </div>
  );
}

export default preSurvey;
