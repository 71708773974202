import { useParams } from 'react-router-dom';
import ReservationConfirmResultContainer from '../../../containers/popup/reservation/ReservationConfirmResultContainer';
import MainLayout from '../../../layout/MainLayout';

function ReservationConfirmResultPage({ match }: any) {
  const { storeId, reservationId, hash, isContinue } = useParams();
  return (
    <>
      <MainLayout>
        <ReservationConfirmResultContainer storeId={storeId} reservationId={reservationId} hash={hash} isContinue={isContinue} />
      </MainLayout>
    </>
  );
}

export default ReservationConfirmResultPage;
