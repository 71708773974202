import { useParams } from 'react-router-dom';
import OnSiteReservationDetailContainer from '../../containers/member/OnSiteReservationDetailContainer';
import MainLayout from '../../layout/MainLayout';

export default function OnSiteReservationDetailPage() {
  const { userReservationId } = useParams();
  return (
    <>
      <MainLayout>
        <OnSiteReservationDetailContainer userReservationId={userReservationId} />
      </MainLayout>
    </>
  );
}
