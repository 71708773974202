import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sampleimg from './../../assets/img/magazine-img.png';
import searchIcon from './../../assets/img/layout/search-point.svg';

import awards1 from './../../assets/magazine/awards_listThumbnail1.png';
import awards2 from './../../assets/magazine/awards_listThumbnail2.png';
import awards3 from './../../assets/magazine/awards_listThumbnail3.png';
import awards4 from './../../assets/magazine/awards_listThumbnail4.png';
import awards5 from './../../assets/magazine/awards_listThumbnail5.png';
import sort_icon from './../../assets/img/common/sort-icon.svg';

function MagazineList({ magazineItems, isLoading, isAdmin }: any) {
  // 레이아웃
  useEffect(() => {
    const adjustGridRows = () => {
      const magazineItems = document.querySelectorAll('.magazine-item');
      magazineItems.forEach((item, index) => {
        const element = item as HTMLElement;

        // 1번째 요소와 4n+1번째 요소에 대해 width와 height를 같은 정사각형으로 설정
        const isFirstOr4nPlus1 = index === 0 || (index + 1) % 4 === 1;
        if (isFirstOr4nPlus1) {
          element.style.height = `${element.clientWidth}px`;
          element.style.gridRowEnd = `span ${element.clientHeight + 10}`;
        } else {
          element.style.height = ''; // 다른 요소에 대해서는 초기화
          element.style.gridRowEnd = `span ${element.clientHeight + 10}`;
        }
      });
    };

    adjustGridRows();

    window.addEventListener('resize', adjustGridRows);

    return () => {
      window.removeEventListener('resize', adjustGridRows);
    };
  }, []);

  return (
    <article className="inner" style={{ paddingBottom: '100px' }}>
      <div className="magazine-list-wrap" style={{ paddingTop: '20px' }}>
        <ul className="magazine-list">
          {/* 제일 바깥 ul 바로 아래 li 반복 */}
          <li className="magazine-item">
            <Link to={'/magazine/5'}>
              <p className="magazine-thumbnail-wrap">
                <h2 className="magazine-main-info">
                  MZ세대가 선정한 올해의 팝업스토어
                  <br />- 연예·콘텐츠·취미/여가 편
                </h2>
                <img src={awards5} alt="" className="magazine-thumbnail" />
              </p>
            </Link>
          </li>
          <li className="magazine-item">
            <Link to={'/magazine/4'}>
              <p className="magazine-thumbnail-wrap">
                <h2 className="magazine-main-info">
                  MZ세대가 선정한 올해의 팝업스토어
                  <br />- 리빙·금융·가전/디지털 편
                </h2>
                <img src={awards4} alt="" className="magazine-thumbnail" />
              </p>
            </Link>
          </li>
          <li className="magazine-item">
            <Link to={'/magazine/3'}>
              <p className="magazine-thumbnail-wrap">
                <h2 className="magazine-main-info">
                  MZ세대가 선정한 올해의 팝업스토어
                  <br />- 캐릭터·게임 편
                </h2>
                <img src={awards3} alt="" className="magazine-thumbnail" />
              </p>
            </Link>
          </li>
          <li className="magazine-item">
            <Link to={'/magazine/2'}>
              <p className="magazine-thumbnail-wrap">
                <h2 className="magazine-main-info">
                  MZ세대가 선정한 올해의 팝업스토어
                  <br />- 음식·음료 편
                </h2>
                <img src={awards2} alt="" className="magazine-thumbnail" />
              </p>
            </Link>
          </li>
          <li className="magazine-item">
            <Link to={'/magazine/1'}>
              <p className="magazine-thumbnail-wrap">
                <h2 className="magazine-main-info">
                  MZ세대가 선정한 올해의 팝업스토어
                  <br />- 뷰티·패션 편
                </h2>
                <img src={awards1} alt="" className="magazine-thumbnail" />
              </p>
            </Link>
          </li>
        </ul>
      </div>

      {/* {isLoading && '로딩중...'}
      {!isLoading && pdsItems && (
        <>
          {isAdmin && (
            <div className="upside-button-area">
              <Link to="/pds/create" className="likebutton">
                새로 만들기
              </Link>
            </div>
          )}
          <table className="table-board">
            <thead>
              <tr>
                <th align="center">뉴스 idx</th>
                <th align="center">제목</th>
                <th align="center">조회수</th>
              </tr>
            </thead>
            <tbody>
              {!pdsItems.length && (
                <tr>
                  <td colSpan={3}>List is emtpy.</td>
                </tr>
              )}
              {!!pdsItems.length &&
                pdsItems.map((pdsItem: any) => (
                  <tr key={pdsItem.itemId}>
                    <td align="center">{pdsItem.itemId}</td>
                    <td align="left">
                      <Link to={`/pds/read/${pdsItem.itemId}`}>{pdsItem.itemName}</Link>
                    </td>
                    <td align="center">{pdsItem.viewCnt}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )} */}
    </article>
  );
}

export default MagazineList;
