import Home from '../components/Home';

const HomeContainer = () => {
  // // 스토어 Dispatch 사용 가능
  // const dispatch = useDispatch();

  // // 스토어 상태 조회
  // const { isApiOpened, isLoading } = useSelector((state: any) => ({
  //   isApiOpened: !!state.home.success,
  //   isLoading: state.loading[FETCH_STATUS],
  // }));

  // useEffect(() => {
  //   // dispatch(fetchStatus());
  // }, [dispatch]);

  return <Home isApiOpened={null} isLoading={null} />;
};

export default HomeContainer;
