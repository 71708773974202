import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignInForm from '../../components/auth/SignInForm';
import { checkMyInfo, login } from '../../modules/auth';

// withRotuer 함수의 기능이 적용되어 속성으로 history를 전달받음
const SignInContainer = () => {
  // 스토어 디스패치 사용 가능
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 스토어 상태 조회
  const { accessToken, myInfo } = useSelector(({ auth }: { auth: any }) => ({
    accessToken: auth.accessToken,
    myInfo: auth.myInfo,
  }));

  // 로그인 처리
  const onSignIn = (userId: string, password: string) => {
    // 유효성 검사
    if (!userId || !password) {
      alert('아이디나 비밀번호를 입력하세요.');
      return;
    }

    try {
      dispatch(login({ userId, password }));
    } catch (err: any) {
      alert('문제가 발생하여 로그인할 수 없습니다.');
      console.log('loginError', err);
    }
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(checkMyInfo());
    }
  }, [accessToken, dispatch]);

  // 마운트 될 때 액세스토큰 상태 정보 확인
  useEffect(() => {
    if (myInfo) {
      alert('로그인 되었습니다.');
      // history.push('/');
      navigate('/');
    }
  }, [myInfo]);

  // 로그인 컴포넌트 표시
  return <SignInForm onSignIn={onSignIn} />;
};

export default SignInContainer;
