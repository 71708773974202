import { useEffect, useState } from 'react';
import MagazineSlide from '../MagazineSlide';

// 인사이트3 - 캐릭터, 게임
import awards3 from './../../../assets/magazine/magazine3/c_main.jpg';
import gameMain from './../../../assets/magazine/magazine3/game.png';
import cSlide1 from './../../../assets/magazine/magazine3/slide1.jpg';
import cSlide2 from './../../../assets/magazine/magazine3/slide2.jpg';
import cSlide3 from './../../../assets/magazine/magazine3/slide3.jpg';
import cSlide4 from './../../../assets/magazine/magazine3/slide4.jpg';
import cSlide5 from './../../../assets/magazine/magazine3/slide5.jpg';
import cSlide6 from './../../../assets/magazine/magazine3/slide7.png';
import cSlide7 from './../../../assets/magazine/magazine3/slide6.png';
import creason from './../../../assets/magazine/magazine3/reason1.png';
import gSlide1 from './../../../assets/magazine/magazine3/slide21.jpg';
import gSlide2 from './../../../assets/magazine/magazine3/slide22.jpg';
import gSlide3 from './../../../assets/magazine/magazine3/slide23.jpg';
import gSlide4 from './../../../assets/magazine/magazine3/slide24.jpg';
import greason from './../../../assets/magazine/magazine3/reason2.png';

function MagazineReadDetail3() {
  // 슬라이드 화살표 버튼
  const CustomPrevArrow = props => {
    const { onClick } = props;
    return <div className="mainPrevious" onClick={onClick} />;
  };
  const CustomNextArrow = props => {
    const { onClick } = props;
    return <div className="mainNext" onClick={onClick} />;
  };
  // 슬라이드 기본세팅
  const commonSettings = {
    dots: true,
    dotsClass: 'popup-banner-dots',
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* 썸네일 */}
      <div className="magazine-detail-header">
        <h2>MAGAZINE</h2>
        <h1>MZ세대가 선정한 올해의 팝업스토어 - 캐릭터·게임 편</h1>
        <ul>
          <li>
            <span>2023.12.21</span>
          </li>
          {/* <li>
                <span>·</span>
              </li>
              <li>
                <span>조회수</span>
                <span>102</span>
              </li>
              <li>
                <span>·</span>
              </li>
              <li>
                <span>좋아요</span>
                <span>5</span>
              </li> */}
        </ul>
      </div>

      {/* 매거진 본문내용 -> 글자는 <p> 태그로 감쌀 수 있도록 해주세여 */}
      <div className="magazine-detail-text-wrap">
        <div className="magazine-detail-content">
          <p className="big-text">캐릭터 1위</p>
          <p>마루X춘배 팝업스토어</p>
          <img src={awards3} alt="마루X춘배 팝업스토어 메인 포스터 이미지" />
        </div>
        <div className="magazine-detail-content">
          <p className="big-text">
            웹툰 속 마루와 춘배가 팝업스토어에 살아났다!
            <br />
            MZ세대 소장 욕구 자극하는 한정판 굿즈로 대박 난 '마루X춘배 툰 스트리트 팝업스토어'
          </p>
        </div>
        <div className="magazine-detail-content">
          <MagazineSlide settings={commonSettings} images={[cSlide1, cSlide2, cSlide3, cSlide4]} />
          <p className="caption">이미지 출처 : 인스타그램 @0seo_1212, @tldbs1966</p>
          <p>네이버 웹툰은 자사의 인기 웹툰인 '마루는 강쥐', '냐한남자' 의 주인공들과 함께 6월과 9월 두 차례 '마루x춘배 툰 스트리트 팝업스토어'를 진행했다. 웹툰 속 주인공인 ‘마루’와 ‘춘배’ 동네로 놀러 가는 컨셉으로 제작된 공간에는 가방과 키링 등 소장각을 부르는 굿즈 구비뿐만 아니라, 모든 고객에게 캐릭터 부채를 무료로 제공하며 팝업스토어 방문을 부추겼다.</p>
        </div>
        <div className="magazine-detail-content">
          <p>6월 2주 동안 진행된 스타필드 코엑스몰 팝업스토어는 약 5만 5천 명이 방문하며 자사 최고 기록을 세웠을 뿐만 아니라, 1인 굿즈 구매 금액이 116만 원을 기록했을 정도로 놀라운 관심을 받았다. 뒤이어 진행된 더현대 서울 팝업스토어에서는 방문객 6만 3천 명으로 가뿐히 이전 기록을 넘었으며, '슬램덩크'가 가지고 있던 더현대 서울 팝업스토어 최고 매출 자리까지 갈아치워 버렸다.</p>
          <img src={creason} alt="마루x춘배 툰 스트리트 팝업스토어 선택 이유 중 1위는 '증정품, 굿즈 구성이 다양했어요'이다." />
          <p>이번 어워즈 캐릭터 부문에서도 대상을 차지하며, 47%의 유저가 ‘굿즈’와 '증정 이벤트'를 '마루X춘배 툰 스트리트 팝업스토어' 선택 이유로 뽑았을 만큼 팝업스토어 성공에는 방문객들의 발길을 끌게 만드는 매력적인 상품 및 이벤트를 구비하는 것이 중요하다는 것을 보여주었다.</p>
        </div>
        <div className="magazine-detail-content">
          <MagazineSlide settings={commonSettings} images={[cSlide5, cSlide6, cSlide7]} />
          <p className="caption">이미지 출처 : 인스타그램 @cestbeau_pierre / 카카오</p>
          <p>
            이러한 배경에는 MZ세대의 새로운 소비문화도 관련이 있는 것으로 보여진다. 최근 MZ세대는 '희소성'을 중시하여 좋아하는 대상의 한정판 굿즈(goods) 상품을 수집하고, 이를 통해 내 취향을 드러내는 것을 즐기며, 이를 위해 새벽 오픈런도 마다하지 않는 등 새롭고 적극적인 소비문화를 만들고 있다. 인기 웹툰 '마루는 강쥐'와 '냐한남자'는 반려동물을 소재로 한 캐릭터라는 점, 귀여운 생김새와 캐릭터로
            이모티콘으로 출시되었을 뿐만 아니라 '나 OO 됐다. 짱이지∙∙∙', '이 OO를 봐, 대박임' 등 밈으로 입소문을 타는 등 2030 여성 팬덤을 대량 형성했고, 웹툰 한정판 굿즈를 판매하는 팝업스토어가 최초로 진행되며 기록적인 방문객과 매출을 얻을 수 있었던 것으로 보인다.
          </p>
        </div>

        <div className="magazine-detail-content" style={{ marginTop: '100px' }}>
          <p className="big-text">게임 1위</p>
          <p>닌텐도 팝업스토어 IN SEOUL</p>
          <img src={gameMain} alt="'닌텐도 팝업스토어 IN SEOUL' 팝업스토어 메인 포스터 이미지" />
        </div>
        <div className="magazine-detail-content">
          <p className="big-text">겜덕들 행복한 '구매지옥'에 가둬버린 '닌텐도 팝업스토어 in SEOUL'</p>
          <MagazineSlide settings={commonSettings} images={[gSlide1, gSlide2]} />
          <p>지난 10월, 대원미디어가 한국 닌텐도와 함께 '닌텐도 팝업스토어 in SEOUL'을 용산 아이파크몰에서 진행했다. 특히 이번 팝업스토어는 일본 닌텐도 직영 공식 스토어인 '닌텐도 도쿄', '닌텐도 오사카'에서만 판매하고 있는 한정 굿즈들을 아시아권 내 최초로 선보여 더욱 화제를 모았다.</p>
        </div>
        <div className="magazine-detail-content">
          <MagazineSlide settings={commonSettings} images={[gSlide3, gSlide4]} />
          <p>팝업스토어에는 '슈퍼 마리오', '동물의 숲', '젤다의 전설', '스플래툰', '피크민' 등 국내에서도 많은 사랑을 받고 있는 게임 소프트웨어 오리지널 굿즈 400여 종뿐만 아니라, 닌텐도 캐릭터 일러스트를 활용한 특별 굿즈가 준비되었고, 그 중 슈퍼 마리오와 동물의 숲 테마의 생활용품 대다수가 일본 현지에서조차 얻기 힘든 희귀 굿즈로 밝혀져 많은 게임 팬의 이목을 끌었다.</p>
        </div>
        <div className="magazine-detail-content">
          <p>또한 닌텐도의 새로운 게임인 '슈퍼 마리오 브라더스 원더' 의 발매 시기에 맞춰 팝업스토어를 오픈해 최신 게임을 체험해 볼 수 있는 공간을 만들었고, 현장에서 게임 소프트웨어를 구매할 시 한정판 키링을 증정하는 이벤트도 함께 진행해 팬들의 구매욕을 돋우었다.</p>
        </div>
        <div className="magazine-detail-content">
          <p>
            그 결과 팝업스토어 오픈 이후, 일평균 1만 명이 넘는 방문객이 몰리고 아이파크몰 3층 리빙파크 앞은 현장 예약을 위한 오픈런 행렬로 연일 인산인해를 이루어 결국 오픈 2주 만에 일부 굿즈 품절 사태가 벌어지는 상황이 펼쳐졌다. 동시에 팝업스토어가 진행된 한 달 동안 7천 6백 개 이상의 콘텐츠가 재생산되는가 하면, 브랜드 검색량 또한 네이버 기준 29만 건을 기록해 전달 대비 11% 의 상승률을 보이는 등
            온.오프라인 모두에서 뜨거운 현장 반응을 확인할 수 있었다.
          </p>
        </div>
        <div className="magazine-detail-content">
          <img src={greason} alt="닌텐도 팝업스토어 in SEOUL 선택 이유 1위는 '증정품, 굿즈 구성이 다양했어요'이다." />
          <p>
            어워즈 발표 중 유저들의 50%가 팝업스토어 투표 이유로 '굿즈'와 '브랜드'를 뽑으며 캐릭터 부문의 대상을 차지한 것에서도 확인할 수 있듯, 닌텐도는 희소성 높은 굿즈를 국내 최초로 구비한 동시에, 자사의 신상품을 직접 체험할 수 있도록 하고 이를 구매하는 고객에 한해 또 다른 한정판 굿즈를 증정하는, 이른바 개미지옥이 아닌 '구매지옥' 전략을 통해 MZ세대의 니즈를 반영한 성공적인 팝업스토어를 운영할
            수 있었던 것으로 보인다.
          </p>
        </div>
      </div>
    </>
  );
}

export default MagazineReadDetail3;
