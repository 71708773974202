import AdminPlaceListRegisterContainer from '../../containers/placelist/AdminPlaceListRegisterContainer';
import MainLayout from '../../layout/MainLayout';

function AdminPlaceListRegisterPage() {
  return (
    <>
      <MainLayout>
        <AdminPlaceListRegisterContainer />
      </MainLayout>
    </>
  );
}

export default AdminPlaceListRegisterPage;
