import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../../lib/api';
import httpStatusHandler from '../../../util/httpStatusHandler';
import { isAdmin as hasRoleAdmin } from '../../../modules/selector';
// import { fetchAttachList, fetchOne, FETCH_ONE, resetAttach } from '../../../modules/faq';
import { fetchOne, FETCH_ONE } from '../../../modules/faq';
import FaqRead from '../../../components/contact/faq/FaqRead';
import ContactMenus from '../../../components/contact/ContactMenus';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/common/Footer';

const FaqReadContainer = ({ itemId }) => {
  // 스토어 Dispatch 사용 가능
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 스토어 상태 조회
  const { faq, attachments, isLoading, isAdmin } = useSelector((state: any) => ({
    faq: state.faq,
    attachments: state.faq.attachments,
    isLoading: state.loading[FETCH_ONE],
    isAdmin: hasRoleAdmin(state),
  }));

  const onRemove = async () => {
    try {
      await api.removeFaq(itemId);
      alert('삭제가 완료되었습니다.');
      navigate(`/faq`);
    } catch (err) {
      httpStatusHandler(err, navigate(-1));
    }
  };

  useEffect(() => {
    console.log('!!!');
    dispatch(fetchOne(itemId));
  }, [dispatch, itemId]);

  return (
    <>
      <ContactMenus onMenu={'faq'} />
      <FaqRead faq={faq} attachments={attachments} isLoading={isLoading} itemId={itemId} onRemove={onRemove} isAdmin={isAdmin} />
      <Footer />
    </>
  );
};

export default FaqReadContainer;
