import { Link } from 'react-router-dom';
import { getOriginalFileName } from '../ContactUtil';

function PopupReportRead({ popupReportItem, attachments, isLoading, itemId, onRemove, isAdmin }: any) {
  return (
    <article className="inner">
      {/* <div className="submenu">
        <a className="on">
          <h2>공지사항</h2>
        </a>
        <a>
          <h2>FAQ</h2>
        </a>
        <a>
          <h2>1:1문의</h2>
        </a>
        <a>
          <h2>팝업스토어 제보</h2>
        </a>
      </div>
      <a className="go-back">&lt; 뒤로</a> */}
      {isLoading && '로딩중...'}
      {!isLoading && popupReportItem && (
        <>
          {/* <table>
            <tbody>
              <tr>
                <td>자료번호</td>
                <td>
                  <input type="text" value={popupReportItem.itemId} readOnly />
                </td>
              </tr>
              <tr>
                <td>자료명</td>
                <td>
                  <input type="text" value={popupReportItem.itemName} readOnly />
                </td>
              </tr>
              <tr>
                <td>파일</td>
                <td>
                  <div>
                    {attachments.map((attachment: string, index: number) => (
                      <div key={index}>
                        <a href={getDownloadURL(attachment)} download>
                          {getOriginalFileName(attachment)}
                        </a>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <td>자료설명</td>
                <td>
                  <textarea value={popupReportItem.description} readOnly></textarea>
                </td>
              </tr>
            </tbody>
          </table> */}
          {/* <div className="view-wrap">
            <h3 className="subject-title">POPUPREPORT.</h3>
            <p>2023-02-10</p>
            <div className="view-inner">
              내용내용내용내용 내용 내용내용내용 내용내용내용내용 내용 내용내용내용 내용내용내용내용 내용 내용내용내용 내용내용내용내용 내용
              내용내용내용
            </div>
          </div>
          <div className="likebutton-wrap">
            {isAdmin && (
              <>
                <Link to={`/popupReport/edit/${itemId}`} className="likebutton">
                  편집
                </Link>
                <button onClick={onRemove} className="likebutton danger">
                  삭제
                </button>
              </>
            )}
            <Link to={`/popupReport`} className="likebutton">
              목록
            </Link>
          </div> */}

          <div className="view-wrap popup-report-view-wrap">
            <h1 className="subject-title">POPUPREPORT.</h1>
            <div className="subject-bottom">
              <p className="check check-ok">확인</p>
              <p>2023-02-10</p>
            </div>
            <div className="view-inner">
              <ul>
                <li>
                  <p>브랜드명: </p>브랜드명 들어오는 자리
                </li>
                <li>
                  <p>스토어이름: </p>스토어이름 들어오는 자리
                </li>
                <li>
                  <p>위치: </p>위치 들어오는 자리
                </li>
                <li>
                  <p>기간: </p>기간 들어오는 자리
                </li>
                <li>
                  <p>기타정보: </p>기타정보 들어오는 자리
                </li>
              </ul>
            </div>
          </div>
          <div className="likebutton-wrap">
            {isAdmin && (
              <>
                <Link to={`/popupReport/edit/${itemId}`} className="likebutton">
                  편집
                </Link>
                <button onClick={onRemove} className="likebutton danger">
                  삭제
                </button>
              </>
            )}
            <Link to={`/popupReport`} className="likebutton">
              목록
            </Link>
          </div>
        </>
      )}
    </article>
  );
}

export default PopupReportRead;
