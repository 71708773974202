import { createAction } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import * as api from '../lib/api';
import createRequestSaga from '../lib/createRequestSaga';

// action type
export const FETCH_ONE = 'map/FETCH_ONE';
const FETCH_ONE_SUCCESS = 'map/FETCH_ONE_SUCCESS';
const FETCH_ONE_FAILURE = 'map/FETCH_ONE_FAILURE';

export const FETCH_LIST = 'map/FETCH_LIST';
const FETCH_LIST_SUCCESS = 'map/FETCH_LIST_SUCCESS';
const FETCH_LIST_FAILURE = 'map/FETCH_LIST_FAILURE';

export const FETCH_SEARCH_LIST = 'map/FETCH_SEARCH_LIST';
const FETCH_SEARCH_LIST_SUCCESS = 'map/FETCH_SEARCH_LIST_SUCCESS';
const FETCH_SEARCH_LIST_FAILURE = 'map/FETCH_SEARCH_LIST_FAILURE';

// export const fetchList = createAction(FETCH_LIST);
// export const fetchList = createAction(FETCH_LIST, userId => ({ userId }));
export const fetchList = createAction(FETCH_LIST, (serachItem, userId) => ({ serachItem, userId }));
export const fetchSearchList = createAction(FETCH_SEARCH_LIST, (searchItem, userId) => ({ searchItem, userId }));
// 비동기 액션을 수행하는 태스크 작성
const fetchListSaga = createRequestSaga(FETCH_LIST, api.fetchMapList);
const fetchSearchListSaga = createRequestSaga(FETCH_SEARCH_LIST, api.fetchBoardSearchList);
export function* mapSaga() {
  yield takeLatest(FETCH_LIST, fetchListSaga);
  yield takeLatest(FETCH_SEARCH_LIST, fetchSearchListSaga);
}

const initialState = {
  boards: [],
  map: null,
  maps: [],
  error: null,
};

// 리듀서 함수 정의
const map = createReducer(initialState, {
  // [FETCH_ONE]: state => ({
  //   ...state,
  //   map: null,
  // }),
  // [FETCH_ONE_SUCCESS]: (state, action) => ({
  //   ...state,
  //   map: action.payload,
  // }),
  // [FETCH_ONE_FAILURE]: (state, action) => ({
  //   ...state,
  //   error: action.payload,
  // }),
  [FETCH_LIST]: state => ({
    ...state,
    maps: [],
  }),
  [FETCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    boards: action.payload,
    maps: action.payload,
  }),
  [FETCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  // search
  [FETCH_SEARCH_LIST]: state => ({
    ...state,
    boards: [],
  }),
  [FETCH_SEARCH_LIST_SUCCESS]: (state, action) => ({
    ...state,
    boards: action.payload,
    maps: action.payload,
    // searchStores:action.payload.data,
  }),
  [FETCH_SEARCH_LIST_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
});

export default map;
